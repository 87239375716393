import { SettingStorage } from "data/SettingStorage";
import { UIHandler } from "frontend/UIHandler";
import { AppContextHandler } from "infra/AppContextHandler";
import { BrowserMediaHandler } from "media/BrowserMediaHandler";
import { Helpers } from "utils/Helpers";
import { Logger } from "utils/Logger";
import { UIMessenger } from "utils/UIMessenger";
import { ConfCamSettings } from "./ConfCamSettings";
import { ConfMicSettings } from "./ConfMicSettings";
import { ConfTeamSettings } from "./ConfTeamSettings";
import { ConfUserSettings } from "./ConfUserSettings";
import { I_ConfigElement } from "./I_ConfigElement";

export class ConfigHandler implements I_ConfigElement {
  private help: Helpers;
  private context: AppContextHandler;
  private logger: Logger;
  private settings: SettingStorage;
  private uiMessenger: UIMessenger;
  private media: BrowserMediaHandler;
  private uiHandler: UIHandler;

  private userSettings: ConfUserSettings;
  private teamSettings: ConfTeamSettings;
  private camSettings: ConfCamSettings;
  private micSettings: ConfMicSettings;

  constructor(
    _help: Helpers,
    _context: AppContextHandler,
    _logger: Logger,
    _settings: SettingStorage,
    _uiMessenger: UIMessenger,
    _media: BrowserMediaHandler,
    _uiHandler: UIHandler
  ) {
    this.help = _help;
    this.context = _context;
    this.logger = _logger;
    this.settings = _settings;
    this.uiMessenger = _uiMessenger;
    this.media = _media;
    this.uiHandler = _uiHandler;

    this.userSettings = new ConfUserSettings(
      this.logger,
      this.settings,
      this.uiMessenger
    );
    this.teamSettings = new ConfTeamSettings(
      this.help,
      this.logger,
      this.settings,
      this.uiMessenger
    );
    this.camSettings = new ConfCamSettings(
      this.logger,
      this.settings,
      this.uiMessenger,
      this.media
    );
    this.micSettings = new ConfMicSettings(
      this.logger,
      this.settings,
      this.uiMessenger,
      this.media
    );
  }

  initialize = () => {
    this.uiHandler.getAppUIHTML().getConfHTML().registerConfElement(this);
    this.userSettings.initialize(
      this.uiHandler.getAppUIHTML().getConfHTML().getUserConfSettingsHTML()
    );
    this.teamSettings.initialize(
      this.uiHandler.getAppUIHTML().getConfHTML().getTeamConfSettingsHTML()
    );
    this.camSettings.initialize(
      this.uiHandler.getAppUIHTML().getConfHTML().getCamConfSettingsHTML()
    );
    this.micSettings.initialize(
      this.uiHandler.getAppUIHTML().getConfHTML().getMicConfSettingsHTML()
    );
  };

  initializeUI = (): void => {
    this.userSettings.initializeUI();
    this.teamSettings.initializeUI();
    this.camSettings.initializeUI();
    this.micSettings.initializeUI();
  };

  onUserSave = (): void => {
    this.userSettings.onUserSave();
    this.teamSettings.onUserSave();
    this.camSettings.onUserSave();
    this.micSettings.onUserSave();
    //this.uiMessenger.informUser("Restarting the app for changes to take effect...");
    this.uiHandler.reloadVISAVIS(
      "ReloadingAppAfterSavingSettings",
      "Restarting the app for changes to take effect...",
      1500
    );
  };

  onUserCancel = (): void => {
    this.userSettings.onUserCancel();
    this.teamSettings.onUserCancel();
    this.camSettings.onUserCancel();
    this.micSettings.onUserCancel();
    this.uiMessenger.informUser("Leaving settings unchanged...");
  };
}
