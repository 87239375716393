import { I_ConfigElement } from "configMgmt/I_ConfigElement";
import { Logger } from "utils/Logger";
import { I_HTMLReferenceUser } from "../I_HTMLReferenceUser";

export class ConfMicSettingsHTML implements I_HTMLReferenceUser {
  private logger: Logger;

  private doc: Document;
  private confContentItem: HTMLDivElement;

  private availMicList: HTMLDivElement;
  private activeMicLabel: HTMLLabelElement;
  private micChoiceInputHTML: HTMLInputElement;

  constructor(_logger: Logger) {
    this.logger = _logger;
  }

  initialize = () => {};

  initializeUI = (_doc: Document): void => {
    this.doc = _doc;
    this.initializeHTMLReferences();
  };

  private initializeHTMLReferences = () => {
    try {
      this.confContentItem = this.doc.getElementById(
        "ConfContentMic"
      ) as HTMLDivElement;
      this.availMicList = this.doc.getElementById(
        "confAvailableMicrophonesList"
      ) as HTMLDivElement;
      /*this.activeMicLabel = this.doc.getElementById(
        "confActiveMicrophoneIDLabel"
      ) as HTMLLabelElement;*/
      this.micChoiceInputHTML = this.doc.getElementById(
        "confMicrophoneChoiceInput"
      ) as HTMLInputElement;
    } catch (e) {
      this.logger.logError([
        "Error obtaining HTML handles for conf window elements (microphone): ",
        e,
      ]);
    }
  };

  tearDownUIReferencesPriorClosure = (): void => {
    this.confContentItem = null;
    this.availMicList = null;
    this.activeMicLabel = null;
    this.micChoiceInputHTML = null;
  };

  getMicChoiceInputValue = (): string => {
    return this.micChoiceInputHTML.value;
  };

  setMicChoiceInputValue = (_newValue: string): void => {
    this.micChoiceInputHTML.value = _newValue;
  };

  setAvailableMicrophonesList = (_newValue: string): void => {
    this.availMicList.innerHTML = _newValue;
  };

  /*setActiveMicrohponeLabel = (_newValue: string): void => {
    this.activeMicLabel.innerText = _newValue;
  };*/
}
