import { Helpers } from "../utils/Helpers";

export class CSSClassModifier {
  //ensures that app-wide css classes are set / removed in all relevant places - e.g. in sidebar AND in VC window

  private entireAppMain: HTMLDivElement;
  private entireAppVC: HTMLDivElement;

  private rootHTMLMain: HTMLElement;
  private rootHTMLVC: HTMLElement;
  private rootHTMLUIM: HTMLElement;

  private help: Helpers;
  //private hasDedicatedVCScreen: boolean;
  private appIsActive: boolean;

  constructor(_help: Helpers) {
    this.help = _help;
    this.rootHTMLUIM = null;
  }

  initialize = (
    _entAppMainScreen: HTMLDivElement,
    _entAppVCScreen: HTMLDivElement,
    _rootMain: HTMLElement,
    _rootVC: HTMLElement,
    _rootUIM: HTMLElement
  ) => {
    this.appIsActive = this.help.appIsActive();
    //this.hasDedicatedVCScreen = this.help.appIsActive();

    this.entireAppMain = _entAppMainScreen;
    this.entireAppVC = _entAppVCScreen;

    this.rootHTMLMain = _rootMain;
    this.rootHTMLVC = _rootVC;
    this.rootHTMLUIM = _rootUIM;
  };

  setClassOnEntireAppBasedOnBoolean = (_class: string, active: boolean) => {
    if (active) this.addClassOnEntireApp(_class);
    else this.removeClassOnEntireApp(_class);
  };

  addClassOnEntireApp = (_class: string): void => {
    this.entireAppMain.classList.add(_class);
    if (this.appIsActive) this.entireAppVC.classList.add(_class);
  };

  removeClassOnEntireApp = (_class: string): void => {
    this.entireAppMain.classList.remove(_class);
    if (this.appIsActive) this.entireAppVC.classList.remove(_class);
  };

  toggleClassOnEntireApp = (_class: string): void => {
    this.entireAppMain.classList.toggle(_class);
    if (this.appIsActive) this.entireAppVC.classList.toggle(_class);
  };

  containsClassOnEntireApp = (_class: string): boolean => {
    return this.entireAppMain.classList.contains(_class);
  };

  addClassOnRootHTML = (_class: string): void => {
    this.rootHTMLMain.classList.add(_class);
    if (this.appIsActive) this.rootHTMLVC.classList.add(_class);
    if (this.rootHTMLUIM !== null) this.rootHTMLUIM.classList.add(_class);
  };

  removeClassOnRootHTML = (_class: string): void => {
    this.rootHTMLMain.classList.remove(_class);
    if (this.appIsActive) this.rootHTMLVC.classList.remove(_class);
    if (this.rootHTMLUIM !== null) this.rootHTMLUIM.classList.remove(_class);
  };

  toggleClassOnRootHTML = (_class: string): void => {
    this.rootHTMLMain.classList.toggle(_class);
    if (this.appIsActive) this.rootHTMLVC.classList.toggle(_class);
    if (this.rootHTMLUIM !== null) this.rootHTMLUIM.classList.toggle(_class);
  };

  containsClassOnRootHTML = (_class: string): boolean => {
    return this.rootHTMLMain.classList.contains(_class);
  };
}
