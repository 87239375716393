export class ChatMessage {
  private msg: string;
  private senderID: string;

  constructor(_msg: string, _senderID: string) {
    this.msg = _msg;
    this.senderID = _senderID;
  }

  getMessage = (): string => {
    return this.msg;
  };

  getSenderID = (): string => {
    return this.senderID;
  };
}
