import { I_ConfigElement } from "configMgmt/I_ConfigElement";
import { Logger } from "utils/Logger";
import { I_HTMLReferenceUser } from "../I_HTMLReferenceUser";

export class ConfCamSettingsHTML implements I_HTMLReferenceUser {
  private logger: Logger;

  private doc: Document;
  private confContentItem: HTMLDivElement;

  private availCamList: HTMLDivElement;
  private activeCamLabel: HTMLLabelElement;
  private camChoiceInputHTML: HTMLInputElement;

  constructor(_logger: Logger) {
    this.logger = _logger;
  }

  initialize = () => {};

  initializeUI = (_doc: Document): void => {
    this.doc = _doc;
    this.initializeHTMLReferences();
  };

  private initializeHTMLReferences = () => {
    try {
      this.confContentItem = this.doc.getElementById(
        "ConfContentCam"
      ) as HTMLDivElement;
      this.availCamList = this.doc.getElementById(
        "confAvailableCamerasList"
      ) as HTMLDivElement;
      /*this.activeCamLabel = this.doc.getElementById(
        "confActiveCameraIDLabel"
      ) as HTMLLabelElement;*/
      this.camChoiceInputHTML = this.doc.getElementById(
        "confCameraChoiceInput"
      ) as HTMLInputElement;
    } catch (e) {
      this.logger.logError([
        "Error obtaining HTML handles for conf window elements (camera): ",
        e,
      ]);
    }
  };

  tearDownUIReferencesPriorClosure = (): void => {
    this.confContentItem = null;
    this.availCamList = null;
    this.activeCamLabel = null;
    this.camChoiceInputHTML = null;
  };

  getCamChoiceInputValue = (): string => {
    return this.camChoiceInputHTML.value;
  };

  setCamChoiceInputValue = (_newValue: string): void => {
    this.camChoiceInputHTML.value = _newValue;
  };

  setAvailableCamerasList = (_newValue: string): void => {
    this.availCamList.innerHTML = _newValue;
  };

  /*setActiveCameraLabel = (_newValue: string): void => {
    this.activeCamLabel.innerText = _newValue;
  };*/
}
