import { BusLogicVC } from "../../buslogic/BusLogicVC";
import { Config } from "config/Config";
import { I_ConfigUI } from "frontend/decoupler/I_ConfigUI";
import { I_DialogueBackgroundUI } from "frontend/decoupler/I_DialogueBackgroundUI";
import { I_SidebarUI } from "frontend/decoupler/I_SidebarUI";
import { I_UIMessengerWindow } from "frontend/decoupler/I_UIMessengerWindow";
import { I_UITechHandler } from "frontend/decoupler/I_UITechHandler";
import { I_URLBrowserWindowUI } from "frontend/decoupler/I_URLBrowserWindowUI";
import { I_VCWindowUI } from "frontend/decoupler/I_VCWindowUI";
import { AppUIHTML } from "frontend/html/AppUIHTML";
import { Helpers } from "utils/Helpers";
import { Logger } from "utils/Logger";
import { UIMessenger } from "utils/UIMessenger";
import { UIMessengerNotifyJS } from "utils/UIMessengerNotifyJS";
import { ConfWebMeet } from "./ConfWebMeet";
import { DialogueBackgroundWebMeet } from "./DialogueBackgroundWebMeet";
import { SidebarWebMeet } from "./SidebarWebMeet";
import { UIMessengerWindowWebMeet } from "./UIMessengerWindowWebMeet";
import { URLBrowserWindowWebMeet } from "./URLBrowserWindowWebMeet";
import { VCWindowWebMeet } from "./VCWindowWebMeet";
import { CSSClassModifier } from "frontend/CSSModifierOverarching";

export class UIHandlerWebMeet implements I_UITechHandler {
  private help: Helpers;
  private config: Config;
  private logger: Logger;
  private uiMessenger: UIMessengerNotifyJS;
  private cssModifier: CSSClassModifier;

  private appUI: AppUIHTML;
  private busLogic: BusLogicVC;

  //private screenHandler: MultiScreenHandler;
  private urlBrowserWindows: URLBrowserWindowWebMeet;
  private dialogueBackground: DialogueBackgroundWebMeet;
  private sidebarWindow: SidebarWebMeet;
  private messengerWindow: UIMessengerWindowWebMeet;
  private vcWindow: VCWindowWebMeet;
  private confWindow: ConfWebMeet;

  constructor(
    _help: Helpers,
    _config: Config,
    _logger: Logger,
    _uiMessenger: UIMessenger
  ) {
    console.log("Local client loading extension: UI Actions WebMeet");

    this.help = _help;
    this.config = _config;
    this.logger = _logger;
    this.uiMessenger = _uiMessenger as UIMessengerNotifyJS;

    //this.screenHandler = new MultiScreenHandler(this.help, this.logger);
    this.sidebarWindow = new SidebarWebMeet(
      this.help,
      this.logger //, this.screenHandler
    );
    this.urlBrowserWindows = new URLBrowserWindowWebMeet(this.logger);
    this.dialogueBackground = new DialogueBackgroundWebMeet();
    this.messengerWindow = new UIMessengerWindowWebMeet(
      this.help,
      this.logger,
      this.uiMessenger,
      this.sidebarWindow
    );
    this.confWindow = new ConfWebMeet(this.help, this.logger, this.uiMessenger);
    this.vcWindow = new VCWindowWebMeet(
      this.help,
      this.logger,
      this.sidebarWindow
    );
  }

  initialize = (
    _appUI: AppUIHTML,
    _cssModifier: CSSClassModifier = null
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.cssModifier = _cssModifier;
      this.setAppUIReference(_appUI);
      //initialize UI accordingly if app is active
      if (this.help.isWebApp()) {
        this.sidebarWindow.initialize(this.appUI);
        this.urlBrowserWindows.initialize();
        this.dialogueBackground.initialize();
        this.messengerWindow.initialize(this.appUI);
        this.confWindow.initialize(this.cssModifier);
        this.vcWindow.initialize(this.appUI);

        this.uiMessenger
          .UITechInitializationTriggerPostUIInitialization(this)
          /*.then(() =>
                this.appUI
                  .getConfHTML()
                  .NWJSInitializationTriggerPostUIInitialization(this)
              )*/
          .then(() => {
            this.logger.logLocal(["web ui handler initialized"]);
            resolve(true);
            return;
          })
          .catch((e) => {
            this.logger.logError(["Error initializing UI handler, ", e]);
            reject(false);
            return;
          });
      } else {
        this.logger.logError([
          "Error initializing UI handler - web client expected, but not present",
        ]);
        reject(false);
        return;
      }
    });
  };

  setBusLogicReference = (_busLogic: BusLogicVC): void => {
    this.busLogic = _busLogic;
  };

  private setAppUIReference = (_appUI: AppUIHTML): void => {
    this.appUI = _appUI;
  };

  initializationClosure = (): void => {
    this.configureAppClosureCleanupCall();
  };

  configureAppClosureCleanupCall = (): void => {
    if (!(this.busLogic instanceof BusLogicVC)) return null;
    try {
      window.addEventListener("beforeunload", (e) => {
        //this.sidebarWindow.getSidebarNWJSWindowReference().on("close", () => {

        this.hideEntireAppForClosure(); //avoids app being visible for 1.5secs if VC open
        let timeLagIfVCActive = 500;
        if (this.busLogic.getVCDataMgr().isVCOpen()) {
          timeLagIfVCActive = 1500;
        }
        this.busLogic.fullLocalHangupOnExit(
          "DisconnectAsPartOfOnCloseEventForSidebarWindow"
        );
        setTimeout(() => {
          //TODO: manage ui shutdown at end of VC
          //TODO: ensure everything is disconnected / media devices are released on shutdown
        }, timeLagIfVCActive);
      });
    } catch (e) {
      this.logger.logLocal([
        "not in app - thus window closure process for app was not configured",
        e,
      ]);
    }
  };

  closeAllWindowsExceptSideBar = (): void => {
    this.dialogueBackground.closeMaximizedScreenShareBackgroundWindow();
    this.vcWindow.closeVCWindowIfOnApp();
    this.messengerWindow.closeUIMWindowIfOnApp();
    this.confWindow.closeConfWindow();
    this.urlBrowserWindows.closeFeedbackFormWindow();
    this.urlBrowserWindows.closeSupportFormWindow();
    this.urlBrowserWindows.closewebRTCInternalsWindow();
  };

  hideEntireAppForClosure = (): void => {
    //do nothing for now - potentially show good bye dialogue going forward
  };

  getURLBrowserWindows = (): I_URLBrowserWindowUI => {
    return this.urlBrowserWindows;
  };

  getSidebarWindow = (): I_SidebarUI => {
    return this.sidebarWindow;
  };

  getVCWindow = (): I_VCWindowUI => {
    return this.vcWindow;
  };

  getUIMessengerWindow = (): I_UIMessengerWindow => {
    return this.messengerWindow;
  };

  getDialogueBackgroundWindow = (): I_DialogueBackgroundUI => {
    return this.dialogueBackground;
  };

  getConfWindow = (): I_ConfigUI => {
    return this.confWindow;
  };
}
