import { UIHandler } from "frontend/UIHandler";
import { SignalingCore } from "signaling/SignalingCore";

export class StayAliveMgr {
  private signaling: SignalingCore;
  private uiHandler: UIHandler;

  private intervalLengthUI: number;
  private intervalLengthPingServer: number;

  private intervalAnchorUI;
  private intervalAnchorPingServer;

  private stayAliveActive: boolean = false;

  constructor(_signaling: SignalingCore, _uiHandler: UIHandler) {
    this.signaling = _signaling;
    this.uiHandler = _uiHandler;
  }

  initialize = () => {
    this.intervalLengthUI = 39000;
    this.intervalLengthPingServer = 20000;
    this.intervalAnchorUI = null;
    this.intervalAnchorPingServer = null;
  };

  start = () => {
    if (!this.stayAliveActive) return;
    if (this.intervalAnchorPingServer != null || this.intervalAnchorUI != null)
      this.stop();
    this.intervalAnchorUI = setInterval(() => {
      this.uiHandler.refreshUIToStayAlive();
    }, this.intervalLengthUI);
    this.intervalAnchorPingServer = setInterval(() => {
      this.signaling.pingToStayAlive();
    }, this.intervalLengthPingServer);
  };

  stop = () => {
    try {
      if (this.intervalAnchorUI != null) clearInterval(this.intervalAnchorUI);
      if (this.intervalAnchorPingServer != null)
        clearInterval(this.intervalAnchorPingServer);
    } catch (e) {}
    this.intervalAnchorUI = null;
    this.intervalAnchorPingServer = null;
  };
}
