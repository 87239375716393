import { CompressionUIHTML } from "../frontend/html/CompressionUIHTML";
import { Config } from "../config/Config";
import { Logger } from "../utils/Logger";
import { DataMgmt } from "../data/DataMgmt";
import { AppUIHTML } from "../frontend/html/AppUIHTML";
import { I_VideoScaler } from "./I_VideoScaler";
import { Helpers } from "utils/Helpers";

export class VideoScalerManualCompression implements I_VideoScaler {
  private config: Config;
  private help: Helpers;
  private logger: Logger;
  private data: DataMgmt;
  private appUI: AppUIHTML;
  private compUI: CompressionUIHTML;

  constructor(
    _conf: Config,
    _help: Helpers,
    _log: Logger,
    _data: DataMgmt,
    _appUI: AppUIHTML
  ) {
    this.config = _conf;
    this.help = _help;
    this.logger = _log;
    this.data = _data;
    this.appUI = _appUI;

    this.compUI = new CompressionUIHTML();
  }

  initialize = () => {
    this.compUI.initialize();
  };

  //_compressionIntervallIDs= new Map(); //stores the IDs requried to stop the regular execution of the video compression method - mapped against stream quality label
  private _compressionIntervallIDs: any[] = []; //stores the IDs requried to stop the regular execution of the video compression method
  private _debugNumberCompressionRuns = 0; //debugging tracker of number of compression runs
  private _compressionPaused = false; //flag, whether compression runs should pause until flag is set to false again (saves local CPU resource and prevents peers from seeing updated video stream)

  /*configureCompressionCanvas = (constraints: any): void => {
        //console.error('Initializing video compression parameters');
        //this._compressionCanvasM.width = constraints.M.video.width.ideal;
        //this._compressionCanvasM.height = constraints.M.video.height.ideal;
        //this._compressionCanvasM.targetFrameRate = constraints.M.video.frameRate.ideal;
        //this._compressionCanvasL.width = constraints['L'].video.width.ideal;
        //this._compressionCanvasL.height = constraints['L'].video.height.ideal;
        //this._compressionCanvasL.targetFrameRate = constraints["L"].video.frameRate.ideal;
    };*/

  startCompressionOfLocalVideoStream = (): void => {
    this.logger.logLocal(["Starting video compression"]);
    if (this.data.getLocalMS() == null) {
      this.logger.logWarning([
        "Attempted to start local video stream compression but no video stream present (is null) - presumably related to orientation change with no access to camera",
      ]);
      return;
    }

    let actualVideoWidth = this.data
      .getLocalMS() /*._localMediaStream*/
      .getVideoTracks()[0]
      .getSettings().width;
    let actualVideoHeight = this.data
      .getLocalMS() /*._localMediaStream*/
      .getVideoTracks()[0]
      .getSettings().height;

    let scalingFactorWidthL =
      this.config.getVideoConstraintsByQuality("L").video.width.ideal /
      actualVideoWidth;
    let scalingFactorHeightL =
      this.config.getVideoConstraintsByQuality("L").video.height.ideal /
      actualVideoHeight;
    if (scalingFactorHeightL < scalingFactorWidthL) {
      scalingFactorHeightL = scalingFactorWidthL;
    } else {
      scalingFactorWidthL = scalingFactorHeightL;
    }
    let targetFrameRateL =
      this.config.getVideoConstraintsByQuality("L").video.frameRate.ideal;
    let targetWidthL =
      this.data
        .getLocalMS() /*._localMediaStream*/
        .getVideoTracks()[0]
        .getSettings().width * scalingFactorWidthL;
    let targetHeightL =
      this.data
        .getLocalMS() /*._localMediaStream*/
        .getVideoTracks()[0]
        .getSettings().height * scalingFactorHeightL;
    this.compUI.getCompressionCanvasL().width = targetWidthL;
    this.compUI.getCompressionCanvasL().height = targetHeightL;
    this.logger.logLocal([
      "Compression width/heightL set to ",
      targetWidthL,
      " / ",
      targetHeightL,
      " while actual width/height is ",
      actualVideoWidth,
      " / ",
      actualVideoHeight,
    ]);
    //informUser('Compression width/heightL set to ' + targetWidthL + ' / ' + targetHeightL);

    let scalingFactorWidthM =
      this.config.getVideoConstraintsByQuality("M").video.width.ideal /
      actualVideoWidth;
    let scalingFactorHeightM =
      this.config.getVideoConstraintsByQuality("M").video.height.ideal /
      actualVideoHeight;
    if (scalingFactorHeightM < scalingFactorWidthM) {
      scalingFactorHeightM = scalingFactorWidthM;
    } else {
      scalingFactorWidthM = scalingFactorHeightM;
    }
    let targetFrameRateM =
      this.config.getVideoConstraintsByQuality("M").video.frameRate.ideal;
    let targetWidthM =
      this.data.getLocalMS().getVideoTracks()[0].getSettings().width *
      scalingFactorWidthM;
    let targetHeightM =
      this.data.getLocalMS().getVideoTracks()[0].getSettings().height *
      scalingFactorHeightM;
    this.compUI.getCompressionCanvasM().width = targetWidthM;
    this.compUI.getCompressionCanvasM().height = targetHeightM;
    //informUser('Compression width/heightM set to ' + targetWidthM + ' / ' + targetHeightM);

    if (this.config.isCompressionActiveMedRes()) {
      this._compressionIntervallIDs.unshift(
        setInterval(
          this.drawCompressedVideo,
          Math.floor(1000 / targetFrameRateM),
          this.appUI.getSideBarHTML().getLocalHTMLVideoElement(),
          this.compUI.getCompressionCanvasM().getContext("2d"),
          targetWidthM,
          targetHeightM,
          targetFrameRateM
        )
      );
    } else {
      this._compressionIntervallIDs.unshift("000"); // use this to disable med compression - placeholder in compressionInterval-table is required
    }
    if (this.config.isCompressionActiveLowRes()) {
      this._compressionIntervallIDs.unshift(
        setInterval(
          this.drawCompressedVideo,
          Math.floor(1000 / targetFrameRateL),
          this.appUI.getSideBarHTML().getLocalHTMLVideoElement(),
          this.compUI.getCompressionCanvasL().getContext("2d"),
          targetWidthL,
          targetHeightL,
          targetFrameRateL
        )
      );
    } else {
      this._compressionIntervallIDs.unshift("000"); // use this to disable low compression - placeholder in compressionInterval-table is required
    }
  };
  stopCompressionOfLocalVideoStream = (): void => {
    clearInterval(this._compressionIntervallIDs.pop());
    clearInterval(this._compressionIntervallIDs.pop());
  };
  private drawCompressedVideo = (
    UIVideoElement: HTMLVideoElement,
    compressionCanvas2DC: CanvasRenderingContext2D,
    outputWidth: number,
    outputHeight: number,
    frameRate: number
  ): void => {
    if (!this.isCompressionPaused()) {
      this._debugNumberCompressionRuns = this._debugNumberCompressionRuns + 1;
      compressionCanvas2DC.drawImage(
        UIVideoElement,
        0,
        0,
        outputWidth,
        outputHeight
      );
      /*if (this._debugNumberCompressionRuns % 2500 === 0) { //todo= replace by time-driven trigger - e.g. output every 5minutes
                this.logger.logLocal(['compression runs completed= ', this._debugNumberCompressionRuns]);
            }*/
    }
  };
  isCompressionPaused = (): boolean => {
    return this._compressionPaused;
  };
  setCompressionPaused = (isPaused: boolean): void => {
    this._compressionPaused = isPaused;
  };

  createLocalMediaStreamVariants = (): void => {
    try {
      this.compUI.getCompressionCanvasL().getContext("2d");
      this.compUI.getCompressionCanvasM().getContext("2d");
      if (this.help.appIsActive())
        // app to be able to send HR video on video huddle screen
        this.data.setLocalMSByQuality(this.data.getLocalMS(), "H");
      // web client to always send LR video
      else
        this.data.setLocalMSByQuality(
          this.compUI.getCompressionCanvasL().captureStream(),
          "H"
        );
      this.data.setLocalMSByQuality(
        this.compUI.getCompressionCanvasM().captureStream(),
        "M"
      );
      this.data.setLocalMSByQuality(
        this.compUI.getCompressionCanvasL().captureStream(),
        "L"
      );
      //this._localMediaStreamM.videoQualityLevel = "M";
      //this._localMediaStreamL.videoQualityLevel = "L";
      //console.error('video stream / tracks for L quality= ';this._localMediaStreamL;' / ';this._localMediaStreamL.getVideoTracks());
      //console.error('video stream / tracks for M quality= ';this._localMediaStreamM;' / ';this._localMediaStreamM.getVideoTracks());
    } catch (err) {
      console.error(
        "Problem capturing video streams from compression canvas(es) to produce M/L quality variants - thrown error= " +
          err.toString()
      );
      throw (
        "Problem capturing video streams from compression canvas(es) to produce M/L quality variants - thrown error= " +
        err.toString()
      );
    }
    //find audio track on original stream and add it to other streams
    try {
      this.data
        .getLocalMS()
        .getTracks()
        .forEach((track) => {
          if (track.kind === "audio") {
            if (this.help.isWebApp())
              this.data.getLocalMSByQuality("H").addTrack(track);
            this.data.getLocalMSByQuality("M").addTrack(track);
            this.data.getLocalMSByQuality("L").addTrack(track);
            //no quality variations for audio as of now - all streams reuse original audio track
          }
        });
    } catch (err) {
      console.error(
        "Problem adding audio stream to compressed media streams with M/L quality - thrown error= " +
          err.toString()
      );
      throw (
        "Problem adding audio stream to compressed media streams with M/L quality - thrown error= " +
        err.toString()
      );
    }
  };
}
