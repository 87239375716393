import { Helpers } from "utils/Helpers";
import { Logger } from "../../utils/Logger";

export class VCChatHTML {
  private logger: Logger;
  private help: Helpers;

  private chatSectionHTML: HTMLDivElement;
  private chatSectionContentAreaHTML: HTMLDivElement;
  private chatSectionInputAreaHTML: HTMLDivElement;
  private chatSectionInputFieldHTML: HTMLInputElement;

  private doc: Document;

  constructor(_logger: Logger, _help: Helpers) {
    this.logger = _logger;
    this.help = _help;

    this.chatSectionHTML = null;
    this.chatSectionContentAreaHTML = null;
    this.chatSectionInputAreaHTML = null;
    this.chatSectionInputFieldHTML = null;
  }

  initialize = (rootDocIncludingChat: Document) => {
    this.doc = rootDocIncludingChat;
    this.createHTML();
    this.initializeHTMLReferences();
    this.logger.logLocal(["VC chat UI initialized"]);
  };

  private createHTML = (): void => {
    //insert adequate HTML code if entry point is present - else, assume it's already there
    try {
      if (!this.doc.getElementById("chatHTMLInsertPoint")) return;
      this.logger.logLocal(["Now inserting HTML for VC chat UI"]);
      let chatDiv: HTMLDivElement = this.doc.getElementById(
        "chatHTMLInsertPoint"
      ) as HTMLDivElement;
      chatDiv.innerHTML =
        "<div " +
        'class="chatSectionContentArea" ' +
        'id="VC1ChatSectionContentArea" ' +
        "></div> " +
        '<div class="chatSectionInputArea" id="VC1ChatSectionInputArea"> ' +
        "<input " +
        'class="chatSectionInputField" ' +
        'id="VC1ChatSectionInputField" ' +
        'type="text" ' +
        'placeholder="enter your chat message here" ' +
        'maxlength="999" ' +
        "/> " +
        "</div>";
    } catch (e) {
      this.logger.logError(["Error creating HTML for chat functionality: ", e]);
    }
  };

  private initializeHTMLReferences = () => {
    this.chatSectionHTML = this.doc.getElementById(
      "chatHTMLInsertPoint"
    ) as HTMLDivElement;
    this.chatSectionContentAreaHTML = this.doc.getElementById(
      "VC1ChatSectionContentArea"
    ) as HTMLDivElement;
    this.chatSectionInputAreaHTML = this.doc.getElementById(
      "VC1ChatSectionInputArea"
    ) as HTMLDivElement;
    this.chatSectionInputFieldHTML = this.doc.getElementById(
      "VC1ChatSectionInputField"
    ) as HTMLInputElement;
  };

  registerChatMessageListener = (
    chatSubmitCallback: (chatMsg: string) => void
  ) => {
    if (this.chatSectionInputFieldHTML != null)
      this.chatSectionInputFieldHTML.addEventListener("keyup", (event) => {
        if (event.code === "Enter" || event.keyCode == 13) {
          chatSubmitCallback(this.chatSectionInputFieldHTML.value);
          this.chatSectionInputFieldHTML.value = "";
        }
      });
  };

  getChatSectionHTML = (): HTMLDivElement => {
    return this.chatSectionHTML;
  };
  getChatSectionContentAreaHTML = (): HTMLDivElement => {
    return this.chatSectionContentAreaHTML;
  };
  getChatSectionInputAreaHTML = (): HTMLDivElement => {
    return this.chatSectionInputAreaHTML;
  };
  getChatSectionInputFieldHTML = (): HTMLInputElement => {
    return this.chatSectionInputFieldHTML;
  };

  setChatOutput = (output: string) => {
    if (this.chatSectionContentAreaHTML)
      this.chatSectionContentAreaHTML.innerHTML = output;
    else
      this.logger.logWarning([
        "Dropping request to set chat output as chat ui not initialized",
      ]);
  };
}
