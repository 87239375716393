export class CompressionUIHTML {
  constructor() {}

  initialize = () => {
    this.initializeHTMLReferences();
  };

  private _compressionCanvasM: HTMLCanvasElement = null; //stores a reference to the canvas element used to produce a medium quality version of the local video stream
  private _compressionCanvasL: HTMLCanvasElement = null; //stores a reference to the canvas element used to produce a low quality version of the local video stream
  //private _compressionCanvasS = new Map<string, HTMLCanvasElement>(); // map to hold reference to canvas elements on UI that are used for creating different quality video / picture streams to be sent to peers - mapped against stream quality label

  initializeHTMLReferences = () => {
    //get video-downscaling-related canvas elements
    this._compressionCanvasM = document.getElementById(
      "compLocalMedQualityVideo"
    ) as HTMLCanvasElement;
    this._compressionCanvasL = document.getElementById(
      "compLocalLowQualityVideo"
    ) as HTMLCanvasElement;
  };

  getCompressionCanvasM = (): HTMLCanvasElement => {
    return this._compressionCanvasM;
  };

  getCompressionCanvasL = (): HTMLCanvasElement => {
    return this._compressionCanvasL;
  };
}
