import { VIDEO_CODEC } from "data/enums/VIDEO_CODEC";
import { Helpers } from "utils/Helpers";
import { Logger } from "utils/Logger";

export class CodecManager {
  private logger: Logger;
  private help: Helpers;

  constructor(_help: Helpers, _logger: Logger) {
    this.help = _help;
    this.logger = _logger;
  }

  initialize = (): void => {};

  getSupportedCodecs = (): RTCRtpCodecCapability[] => {
    return RTCRtpReceiver.getCapabilities("video").codecs;
  };

  logSupportedCodecs = (): void => {
    let codecs = this.getSupportedCodecs();
    // iterate over supported codecs and pull out the codecs we want
    for (let i = 0; i < codecs.length; i++) {
      this.logger.logLocal(["pc supports video codec: ", codecs[i]]);
    }
  };

  removeCodecFromSupportedCodecsOfPC = (
    _pc: RTCPeerConnection,
    _codecToBeRemoved: VIDEO_CODEC
  ): void => {
    try {
      if (_pc == null) return;
      // note the following should be called before before calling either RTCPeerConnection.createOffer() or createAnswer()
      let tcvr = _pc.getTransceivers()[0];
      if (tcvr == null) {
        this.logger.logWarning([
          "Unable to adjust codecs because Transceivers returned as null",
        ]);
        return;
      }
      let codecs: RTCRtpCodecCapability[] = this.getSupportedCodecs();
      let remaining_codecs: RTCRtpCodecCapability[] = [];
      for (let i = 0; i < codecs.length; i++) {
        if (codecs[i].mimeType != _codecToBeRemoved) {
          remaining_codecs.push(codecs[i]);
        }
      }
      // apply codec preferences if supported by browser
      //@ts-ignore
      if (tcvr.setCodecPreferences) {
        //@ts-ignore
        tcvr.setCodecPreferences(remaining_codecs);
      }
    } catch (e) {
      this.logger.logError([
        "Error removing codec ",
        _codecToBeRemoved,
        " from peer connection ",
        _pc,
        ": ",
        e,
      ]);
    }
  };
}
