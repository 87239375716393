import { I_RaiseHandSignalingInterface } from "../../buslogic/raiseHand/I_RaiseHandSignalingInterface";
import { I_IncomingRaiseHandMessageHandler } from "../../buslogic/raiseHand/I_IncomingRaiseHandMessageHandler";
import { RaiseHandMessage } from "../../buslogic/raiseHand/RaiseHandMessage";
import { Logger } from "../../utils/Logger";
import { BusLogicCore } from "../../buslogic/BusLogicCore";
import { SIGNALING_PURPOSE } from "../../data/enums/SIGNALING_PURPOSE";
import { I_MessageListenerFunction121PeerMessage } from "./I_MessageListenerFunction121PeerMessage";
import { I_OneToOneSignalingProvider } from "./I_OneToOneSignalingProvider";

export class SignalingRaiseHand implements I_RaiseHandSignalingInterface {
  private logger: Logger;
  private signaling: I_OneToOneSignalingProvider;
  private busLogic: BusLogicCore;

  private raiseHandMessageHandler: I_IncomingRaiseHandMessageHandler;

  constructor(_logger: Logger) {
    this.logger = _logger;
  }

  initialize = (_signaling: I_OneToOneSignalingProvider) => {
    this.signaling = _signaling;
    this.signaling.register121MessageListener(
      "121RaiseHandMessage",
      this.process121MessageRaiseHandMessageToLocalClient
    );
  };

  setBusLogicReference(_busLogic: BusLogicCore) {
    this.busLogic = _busLogic;
  }

  shareRaiseHandUpdateWithPeer = (raiseHandMsg: RaiseHandMessage): void => {
    //local client wants to send message
    let localID: string = this.signaling.getUniqueClientID();
    //let raiseHandMsg = new RaiseHandMessage(localID, recipientID, handRaised);
    if (raiseHandMsg.getSenderID() == null)
      throw new Error(
        "Error processing raise hand message - no message provided (null received)"
      );
    if (raiseHandMsg.getSenderID() != localID)
      throw new Error(
        "Error processing raise hand message - invalid sender information"
      );

    this.signaling.send121Message(
      raiseHandMsg.getRecipientID(),
      this.signaling.getMainRoom(),
      {
        type: "121RaiseHandMessage",
        raiseHandSender: raiseHandMsg.getSenderID(),
        raiseHandRecipient: raiseHandMsg.getRecipientID(),
        raiseHandStatus: raiseHandMsg.getHandRaisedValue(),
      }
    );
  };

  private process121MessageRaiseHandMessageToLocalClient: I_MessageListenerFunction121PeerMessage =
    (
      senderID: string,
      room: string,
      message: any,
      PCSigP: SIGNALING_PURPOSE
    ): void => {
      if (message.type != "121RaiseHandMessage")
        throw new Error(
          "Error processing raise hand message - client received wrong message type: " +
            message.messageType
        );
      let localID: string = this.signaling.getUniqueClientID();
      if (senderID != message.raiseHandSender)
        throw new Error(
          "Error processing raise hand message - incorrect sender information"
        );
      if (localID != message.raiseHandRecipient)
        throw new Error(
          "Error processing raise hand message - incorrect recipient information"
        );
      if (!this.busLogic.peerConnectionExists(senderID)) {
        this.logger.logWarning([
          "Raise hand message ignored as sender is no longer part of room: ",
          senderID,
        ]);
        return;
      }
      this.raiseHandMessageHandler.handleIncomingRaiseHandMessage(
        new RaiseHandMessage(
          message.raiseHandSender,
          message.raiseHandRecipient,
          message.raiseHandStatus
        )
      );
    };

  //RaiseHand instance registers itself as recipient of any chat messages
  registerIncomingMessageHandler = (
    raiseHandHandler: I_IncomingRaiseHandMessageHandler
  ): void => {
    this.raiseHandMessageHandler = raiseHandHandler;
  };
}
