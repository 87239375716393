import { Helpers } from "utils/Helpers";
import { Logger } from "../../utils/Logger";
import { LabeledHTMLVideoSection } from "./LabeledHTMLVideoSection";

export class VCRemoteScreenShareHTML {
  private logger: Logger;
  private help: Helpers;

  private _VCRemoteScreenShareVideoSectionUI: LabeledHTMLVideoSection; //video conference UI= reference to UI remote screen share video section incl. label and video element
  private _peerScreenShareFullscreenVCButton: HTMLButtonElement; // reference to button on peer screen share that opens full screen mode

  private doc: Document;

  constructor(_logger: Logger, _help: Helpers) {
    this.logger = _logger;
    this.help = _help;

    this._VCRemoteScreenShareVideoSectionUI = null;
    this._peerScreenShareFullscreenVCButton = null;
  }

  initialize = (rootDocIncludingChat: Document) => {
    this.doc = rootDocIncludingChat;
    this.createHTML();
    this.initializeHTMLReferences();
    this.logger.logLocal(["Remote Screen Share UI initialized"]);
  };

  private createHTML = (): void => {
    //insert adequate HTML code if entry point is present - else, assume it's already there
    try {
      if (
        !this.doc.getElementById(
          "VC1LabeledVideoRemoteScreenShareHTMLInsertPoint"
        )
      )
        return;
      this.logger.logLocal(["Now inserting HTML for remote screenshare UI"]);
      let remSSDiv: HTMLDivElement = this.doc.getElementById(
        "VC1LabeledVideoRemoteScreenShareHTMLInsertPoint"
      ) as HTMLDivElement;
      remSSDiv.innerHTML =
        '<div class="videoLabel" id="VC1LabelRemoteScreenShare"> ' +
        "   Host Screen " +
        "</div> " +
        "<video " +
        '   class="VCVideo" ' +
        '   id="VC1VideoRemoteScreenShare" ' +
        "   autoplay " +
        "   muted " +
        "   playsinline " +
        "></video> " +
        "<button " +
        '   title="show full screen" ' +
        '   class=" ' +
        "   basicButton " +
        "   buttonOnVideo " +
        "   button1FromBottom " +
        '   fullScreenButton" ' +
        '   id="VC1ButtonRemoteScreenShareFullScreen" ' +
        "></button> " +
        "<br /> ";
    } catch (e) {
      this.logger.logError([
        "Error creating HTML for remote screen share functionality: ",
        e,
      ]);
    }
  };

  private initializeHTMLReferences = () => {
    this._VCRemoteScreenShareVideoSectionUI = this.doc.getElementById(
      "VC1LabeledVideoRemoteScreenShareHTMLInsertPoint"
    ) as LabeledHTMLVideoSection;
    this._VCRemoteScreenShareVideoSectionUI.HTMLLabelElement =
      this.doc.getElementById("VC1LabelRemoteScreenShare") as HTMLLabelElement;
    this._VCRemoteScreenShareVideoSectionUI.HTMLVideoElement =
      this.doc.getElementById("VC1VideoRemoteScreenShare") as HTMLVideoElement;
    this._peerScreenShareFullscreenVCButton = this.doc.getElementById(
      "VC1ButtonRemoteScreenShareFullScreen"
    ) as HTMLButtonElement;
  };

  getRemoteScreenShareVSVC = (): LabeledHTMLVideoSection => {
    return this._VCRemoteScreenShareVideoSectionUI;
  };
  getRemoteScreenShareHTMLVideoElementVC = (): HTMLVideoElement => {
    return this._VCRemoteScreenShareVideoSectionUI.HTMLVideoElement;
  };
  getremoteScreenShareHTMLLabelElementVC = (): HTMLLabelElement => {
    return this._VCRemoteScreenShareVideoSectionUI.HTMLLabelElement;
  };
  getUIPeerScreenShareFullScreenButton = (): HTMLButtonElement => {
    return this._peerScreenShareFullscreenVCButton;
  };
}
