import { I_URLBrowserWindowUI } from "frontend/decoupler/I_URLBrowserWindowUI";
import { Logger } from "utils/Logger";

export class URLBrowserWindowWebMeet implements I_URLBrowserWindowUI {
  private logger: Logger;

  private feedbackWindow: any; //reference to nwjs window
  private supportWindow: any; //reference to nwjs window
  private inviteTeamWindow: any; //reference to nwjs window
  private webRTCInternalsWindow: any; //reference to nwjs window

  constructor(_logger: Logger) {
    this.logger = _logger;
    this.feedbackWindow = null;
    this.supportWindow = null;
    this.inviteTeamWindow = null;
    this.webRTCInternalsWindow = null;
  }

  initialize = () => {};

  private openURLInNewBrowserWindow = (
    targetURL: string,
    windowTitle: string,
    initfunc: (any: any) => void,
    closefunc: () => void
  ): void => {
    //TODO: display iframe for respective window
    //TODO: assign and/or call initfunc and closefunc
  };

  openFeedbackFormWindow = (feedbackURL: string): void => {
    //TODO: open iframe or tab
  };

  closeFeedbackFormWindow = (): void => {
    //TODO: close webRTC iframe or tab
  };

  openSupportFormWindow = (supportURL: string): void => {
    //TODO: open iframe or tab
  };

  closeSupportFormWindow = (): void => {
    //TODO: close webRTC iframe or tab
  };

  openInviteTeamWindow = (inviteTeamURL: string): void => {
    //TODO: open iframe or tab
  };

  closeInviteTeamWindow = (): void => {
    //TODO: close webRTC iframe or tab
  };

  openwebRTCInternalsWindow = (webRTCInternalsURL: string): void => {
    //TODO: open iframe or tab
  };

  closewebRTCInternalsWindow = (): void => {
    //TODO: close webRTC iframe or tab
  };
}
