/* COPYRIGHT 2021 Michael Maur - any form of reuse requires written consent by the author */

//import {} from "notifyjs";
import * as $ from "jquery";
import "../lib/notify";
import { UIMessenger } from "./UIMessenger";
import { UIMessengerCore } from "./UIMessengerCore";

export class UIMessengerNotifyJS
  extends UIMessengerCore
  implements UIMessenger
{
  constructor() {
    super();
    console.log("Local client loading extension: UserMessages NotifyJS");
  }

  initialize = () => {
    this.initializeCore();
  };

  informUser = (message: string): void => {
    //$(".contactBench").notify(message, {className: 'info', position: 'left bottom'});
    //$(".contactBench").notify(message, {className: 'myCustomInfo'});
    //$(".contactBench").notify(message, {style: 'foo'});
    if (this.isRightScreenSide()) {
      $["notify"](message, {
        className: "info",
        globalPosition: "right bottom",
      });
    } else if (this.isLeftScreenSide()) {
      $["notify"](message, {
        className: "info",
        globalPosition: "left bottom",
      });
    }
  };

  confirmToUser = (message: string): void => {
    //$(".contactBench").notify(message, {className: 'success', position: 'left bottom'});
    //$(".contactBench").notify(message, {className: 'success'});
    if (this.isRightScreenSide()) {
      $["notify"](message, {
        className: "success",
        globalPosition: "right bottom",
      });
    } else if (this.isLeftScreenSide()) {
      $["notify"](message, {
        className: "success",
        globalPosition: "left bottom",
      });
    }
  };

  warningToUser = (message: string): void => {
    if (this.isRightScreenSide())
      $["notify"](message, {
        className: "warn",
        globalPosition: "right bottom",
      });
    else if (this.isLeftScreenSide())
      $["notify"](message, {
        className: "warn",
        globalPosition: "left bottom",
      });
  };

  errorToUser = (message: string): void => {
    if (this.isRightScreenSide())
      $["notify"](message, {
        className: "error",
        globalPosition: "right bottom",
      });
    else if (this.isLeftScreenSide())
      $["notify"](message, {
        className: "error",
        globalPosition: "left bottom",
      });
  };

  informUserSticky = (message: string, delayUntilHide: number = 7000): void => {
    $(".notifyjs-corner").empty();
    if (this.isRightScreenSide())
      $["notify"](message, {
        className: "info",
        globalPosition: "right top",
        autoHide: true,
        autoHideDelay: delayUntilHide,
      });
    else if (this.isLeftScreenSide())
      $["notify"](message, {
        className: "info",
        globalPosition: "left top",
        autoHide: true,
        autoHideDelay: delayUntilHide,
      });
  };

  confirmToUserSticky = (
    message: string,
    delayUntilHide: number = 7000
  ): void => {
    //$(".contactBench").notify(message, {className: 'success', position: 'left bottom'});
    //$(".contactBench").notify(message, {className: 'success'});
    if (this.isRightScreenSide())
      $["notify"](message, {
        className: "success",
        globalPosition: "right top",
        autoHide: true,
        autoHideDelay: delayUntilHide,
      });
    else if (this.isLeftScreenSide())
      $["notify"](message, {
        className: "success",
        globalPosition: "left top",
        autoHide: true,
        autoHideDelay: delayUntilHide,
      });
  };

  warningToUserSticky = (
    message: string,
    delayUntilHide: number = 7000
  ): void => {
    if (this.isRightScreenSide())
      $["notify"](message, {
        className: "warn",
        globalPosition: "right top",
        autoHide: true,
        autoHideDelay: delayUntilHide,
      });
    else if (this.isLeftScreenSide())
      $["notify"](message, {
        className: "warn",
        globalPosition: "left top",
        autoHide: true,
        autoHideDelay: delayUntilHide,
      });
  };

  errorToUserSticky = (
    message: string,
    delayUntilHide: number = 7000
  ): void => {
    if (this.isRightScreenSide())
      $["notify"](message, {
        className: "error",
        globalPosition: "right top",
        autoHide: true,
        autoHideDelay: delayUntilHide,
      });
    else if (this.isLeftScreenSide())
      $["notify"](message, {
        className: "error",
        globalPosition: "left top",
        autoHide: true,
        autoHideDelay: delayUntilHide,
      });
  };
}

/*var confirmDialogueToUser = function (message, confirmCallback, cancelCallback) {
      if (viwo_dataMgmt.getEntireApp().classList.contains('rightScreenSide')) $['notify'](message, {className: 'error', globalPosition: 'right bottom', autoHide: 'false', autoHide: true, autoHideDelay: 7000});
      if (viwo_dataMgmt.getEntireApp().classList.contains('leftScreenSide')) $['notify'](message, {className: 'error', globalPosition: 'left bottom', autoHide: 'false', autoHide: true, autoHideDelay: 7000});
  
  };
  
  /*
  var registerNotifyStyle = function() {
  //add a new style 'customConfirmDialogue'
  $['notify'].addStyle('customConfirmDialogue', {
      html: 
        "<div>" +
          "<div class='clearfix'>" +
            "<div class='title' data-notify-html='title'/>" +
            "<div class='buttons'>" +
              "<button class='no'>Cancel</button>" +
              "<button class='yes' data-notify-text='button'></button>" +
            "</div>" +
          "</div>" +
        "</div>"
    });
    
      //listen for click events from this style
    $(document).on('click', '.notifyjs-customConfirmDialogue-base .no', function() {
      //programmatically trigger propogating hide event
      $(this).trigger('notify-hide');
    });
    $(document).on('click', '.notifyjs-customConfirmDialogue-base .yes', function() {
      //show button text
      alert($(this).text() + " clicked!");
      //hide notification
      $(this).trigger('notify-hide');
    });
  
  };
  
  var showNotify = function() {
  
    $['notify']({
      title: 'Would you like some Foo ?',
      button: 'Confirm'
    }, { 
      style: 'customConfirmDialogue',
      autoHide: false,
      clickToHide: false
    });
  };*/
