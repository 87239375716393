import { Config } from "../../config/Config";
import { Logger } from "../../utils/Logger";
import { LabeledHTMLVideoSection } from "./LabeledHTMLVideoSection";
import { UINwjsHandler } from "../nwjs/UINwjsHandler";
import { VCChatHTML } from "./VCChatHTML";
import { I_UITechHandler } from "frontend/decoupler/I_UITechHandler";
import { Helpers } from "utils/Helpers";
import { VCRemoteScreenShareHTML } from "./VCRemoteScreenShareHTML";

export class VCScreenHTML {
  private config: Config;
  private logger: Logger;
  private help: Helpers;
  private nwjsHandler: I_UITechHandler;

  private vcChatHTML: VCChatHTML;
  private vcScreenShareHTML: VCRemoteScreenShareHTML;

  //private nwjsWindow: any;
  private doc: Document;

  private VCScreenURL: string;

  constructor(
    _config: Config,
    _log: Logger,
    _help: Helpers,
    _nwjsH: I_UITechHandler
  ) {
    this.config = _config;
    this.logger = _log;
    this.help = _help;
    this.nwjsHandler = _nwjsH;
    this.vcChatHTML = new VCChatHTML(this.logger, this.help);
    if (this.help.appIsActive())
      this.vcScreenShareHTML = new VCRemoteScreenShareHTML(
        this.logger,
        this.help
      );

    //    this.nwjsWindow = null;
    this.doc = null;
  }

  initialize = (initFuncEntireAppVC: {
    (HTMLDivElement): void;
  }): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.initializeVCScreenURL();
      this.initializeHTMLReferences(initFuncEntireAppVC)
        .then(() => {
          this.vcChatHTML.initialize(this.doc);
        })
        .then(() => {
          if (this.help.appIsActive())
            this.vcScreenShareHTML.initialize(this.doc);
        })
        .then(
          this.nwjsHandler.getVCWindow().initializeScreenShareAutoSizingIfOnApp
        )
        .then(() => {
          resolve();
          return;
        })
        .catch((e) => {
          this.logger.logError([
            "Error initializing html references and/or autosizing for dedicated VC window",
            e,
          ]);
          reject(e);
          return;
        });
    });
  };

  private initializeVCScreenURL() {
    switch (window.location.hostname) {
      case this.config.devEnvHostname: {
        this.VCScreenURL = this.config.devEnvBaseURL;
        break;
      }
      case this.config.localEnvHostname: {
        this.VCScreenURL = this.config.localEnvBaseURL;
        break;
      }
      case this.config.prodEnvHostnameNEW: {
        this.VCScreenURL = this.config.prodEnvBaseURLNEW;
        break;
      }
      default: {
        //if in doubt, use prod code
        this.VCScreenURL = this.config.prodEnvBaseURL;
        break;
      }
    }
    this.VCScreenURL =
      this.VCScreenURL +
      this.config.dedicatedVCWindowFileName +
      "?rand=" +
      Math.random() * 10000;
  }

  getVCWindowDocument = (): Document => {
    return this.doc;
  };

  getVCChatHTML = (): VCChatHTML => {
    return this.vcChatHTML;
  };

  getVCScreenShareHTML = (): VCRemoteScreenShareHTML => {
    return this.vcScreenShareHTML;
  };

  private _VCUI: HTMLDivElement = null; //reference to the overall container within which the video conference section is displayed (this is to allow hiding / showing it)
  private _VCLocalVideoSectionUI: LabeledHTMLVideoSection = null; //video conference UI= reference to UI local video section incl. label and video element
  private _VCLocalScreenShareVideoSectionUI: LabeledHTMLVideoSection = null; //video conference UI= reference to UI local screen share video section incl. label and video element
  private _VCPeerVideoSectionsUI = new Map<number, LabeledHTMLVideoSection>(); //video conference UI= map to hold reference to UI remote video sections incl. label and video element - mapped against ID used on UI (i.e. their position)
  private _VCPeerVideoSectionsUIinUse = new Map<
    string,
    LabeledHTMLVideoSection
  >(); //video conference UI= map to hold reference to UI remote video sections incl. label and video element - only contains those video elements that are in use for a peer - mapped against socket.IDs of peers
  private _VCPeersUsingVideoSectionsUI = new Map<number, string>(); //map holding peerIDs of peers currently using a video section on the UI - mapped against ID used on UI (i.e. their position)
  private _UISectionsUsedByPeers = new Map<string, number>(); //map holding UI positions held by a peer (i.e. is it 1st, 2nd, 3rd peer) - mapped against their peer ID

  private _pauseLocalButtons: HTMLCollectionOf<HTMLButtonElement> = null; //array of all local pause buttons - it does not matter which one got clicked - thus simply putting them into an array
  private _muteLocalButtons: HTMLCollectionOf<HTMLButtonElement> = null; //array of all local mute buttons - it does not matter which one got clicked - thus simply putting them into an array
  private _localClientLeaveVCButton: HTMLButtonElement = null; // reference to button allowing local client to leave VC
  private _localClientShareScreenVCButton: HTMLButtonElement = null; // reference to button allowing local client to share its screen
  private _localClientShareScreenCloseVCButton: HTMLButtonElement = null; // reference to button next to shared screen that allows to close/hide screenshare
  private _localClientShareScreenPauseVCButton: HTMLButtonElement = null; // reference to button next to shared screen that allows to pause screen transmission
  private _peerVideoFullscreenVCButton = new Map<number, HTMLButtonElement>(); //stores references to buttons on peer videos in VC section - key is their position on the UI (1..20)
  private _peerRemoteMuteVCButton = new Map<number, HTMLButtonElement>(); //stores references to buttons on peer videos in VC section - key is their position on the UI (1..20)
  private _topCornerCloseVCButton: HTMLButtonElement = null; // reference to additional "close VC" button in top right corner of VC dialogue

  private _topCornerVCToSideBarButton: HTMLButtonElement = null; // button that is only displayed when in audio-only VC on side-bar - to continue VC to dedicated window
  private _topCornerMinimizeRemoteScreenShareButton: HTMLButtonElement = null; // button that is only displayed when remote screenshare is active - to minimize VC window in order to see things behind it

  private _topCornerShowVCChatButton: HTMLButtonElement = null; // reference to "show chat" button on VC dialogue
  private _topCornerHideVCChatButton: HTMLButtonElement = null; // reference to "hide chat" button on VC dialogue

  initializeHTMLReferences = (initFuncEntireAppVC: {
    (HTMLDivElement): void;
  }): Promise<void> => {
    //opens hidden VC window and initializes references based on DOM in dedicated window - if not on app, initializes references bsed on div in current window
    return new Promise((resolve, reject) => {
      this.nwjsHandler
        .getVCWindow()
        .initializeDedicatedVCWindow(
          document,
          this.VCScreenURL,
          this.initializeHTMLReferencesFromDoc,
          initFuncEntireAppVC
        )
        .then(() => {
          this.logger.logLocal([
            "Completed procedure to initialize NWJS window for VC",
          ]);
          this.disablePauseOnVideos();
        })
        .then(() => {
          resolve();
        })
        .catch((e) => {
          this.logger.logError(["Error initializing NWJS window for VC, ", e]);
          reject(e);
        })
        .finally(() => {
          this.logger.logLocal([
            "Closing procedure to initialize NWJS window for VC",
          ]);
        });
    });
  };

  private initializeHTMLReferencesFromDoc = (_win: any, _doc: Document) => {
    try {
      //this.nwjsWindow = _win;
      this.doc = _doc;
    } catch (e) {
      this.logger.logError(["Error obtaining NWJS window references: ", e]);
    }
    try {
      //get elements for VC section
      this._VCUI = this.doc.getElementById("VC1") as HTMLDivElement;
      this._VCLocalVideoSectionUI = this.doc.getElementById(
        "VC1LabeledVideoLocal"
      ) as LabeledHTMLVideoSection;
      this._VCLocalVideoSectionUI.HTMLLabelElement = this.doc.getElementById(
        "VC1LabelLocal"
      ) as HTMLLabelElement;
      this._VCLocalVideoSectionUI.HTMLVideoElement = this.doc.getElementById(
        "VC1VideoLocal"
      ) as HTMLVideoElement;
      this._VCLocalScreenShareVideoSectionUI = this.doc.getElementById(
        "VC1LabeledVideoLocalScreenShare"
      ) as LabeledHTMLVideoSection;
      this._VCLocalScreenShareVideoSectionUI.HTMLLabelElement =
        this.doc.getElementById("VC1LabelLocalScreenShare") as HTMLLabelElement;
      this._VCLocalScreenShareVideoSectionUI.HTMLVideoElement =
        this.doc.getElementById("VC1VideoLocalScreenShare") as HTMLVideoElement;
    } catch (e) {
      this.logger.logError([
        "Error obtaining HTML handles for VC local video and screen share elements: ",
        e,
      ]);
    }
    try {
      for (let i = 1; i <= 20; i++) {
        let labeledVideo: LabeledHTMLVideoSection = this.doc.getElementById(
          "VC1LabeledVideoPeer" + i
        ) as LabeledHTMLVideoSection;
        labeledVideo.numberOnUI = i;
        labeledVideo.inUse = false;
        labeledVideo.inUseByPeerID = null;
        labeledVideo.HTMLLabelElement = this.doc.getElementById(
          "VC1LabelPeer" + i
        ) as HTMLLabelElement;
        labeledVideo.HTMLVideoElement = this.doc.getElementById(
          "VC1VideoPeer" + i
        ) as HTMLVideoElement;

        this._VCPeerVideoSectionsUI.set(i, labeledVideo);
        this._peerVideoFullscreenVCButton.set(
          i,
          this.doc.getElementById(
            "VC1ButtonFullScreenPeer" + i
          ) as HTMLButtonElement
        );
        this._peerRemoteMuteVCButton.set(
          i,
          this.doc.getElementById(
            "VC1ButtonRemoteMutePeer" + i
          ) as HTMLButtonElement
        );
      }
    } catch (e) {
      this.logger.logError([
        "Error obtaining HTML handles for VC peer video elements: ",
        e,
      ]);
    }
    try {
      //get screen-share-related buttons in VC
      this._localClientShareScreenVCButton = this.doc.getElementById(
        "VC1ButtonShareScreen"
      ) as HTMLButtonElement;
      this._localClientShareScreenCloseVCButton = this.doc.getElementById(
        "VC1ButtonLocalScreenShareClose"
      ) as HTMLButtonElement;
      this._localClientShareScreenPauseVCButton = this.doc.getElementById(
        "VC1ButtonLocalScreenSharePause"
      ) as HTMLButtonElement;
    } catch (e) {
      this.logger.logError([
        "Error obtaining HTML handles for screen share elements: ",
        e,
      ]);
    }
    try {
      this._topCornerCloseVCButton = this.doc.getElementById(
        "VC1LeaveVCButtonTopCorner"
      ) as HTMLButtonElement;
      this._localClientLeaveVCButton = this.doc.getElementById(
        "VC1ButtonLeaveVCLocal"
      ) as HTMLButtonElement;
    } catch (e) {
      this.logger.logError([
        "Error obtaining HTML handles for leave VC buttons: ",
        e,
      ]);
    }
    try {
      this._topCornerVCToSideBarButton = this.doc.getElementById(
        "VC1VCToSideBarButtonTopCorner"
      ) as HTMLButtonElement;
      this._topCornerMinimizeRemoteScreenShareButton = this.doc.getElementById(
        "VC1MinimizeScreenShareButtonTopCorner"
      ) as HTMLButtonElement;
      this._topCornerShowVCChatButton = this.doc.getElementById(
        "VC1ChatButtonTopCorner"
      ) as HTMLButtonElement;
      this._topCornerHideVCChatButton = this.doc.getElementById(
        "VC1HideChatButtonTopCorner"
      ) as HTMLButtonElement;
    } catch (e) {
      this.logger.logError([
        "Error obtaining HTML handles for selected top corner buttons: ",
        e,
      ]);
    }
    try {
      //get local pause and mute buttons
      this._pauseLocalButtons = this.doc.getElementsByClassName(
        "pauseLocalButton"
      ) as HTMLCollectionOf<HTMLButtonElement>;
      this._muteLocalButtons = this.doc.getElementsByClassName(
        "muteLocalButton"
      ) as HTMLCollectionOf<HTMLButtonElement>;
    } catch (e) {
      this.logger.logError([
        "Error obtaining HTML handles for mute and pause video buttons: ",
        e,
      ]);
    }
  };

  private disablePauseOnVideos = (): void => {
    try {
      for (let i = 1; i <= 20; i++) {
        let curVideo: HTMLVideoElement =
          this.getPeerVSVCbyUIPos(i).HTMLVideoElement;
        curVideo.addEventListener(
          "pause",
          //this.playAfterPause.bind(curVideo.HTMLVideoElement));
          () => {
            this.logger.logLocal([
              "registered pause event on a video player (presumably in full screen) - attempting to automatically restart playing",
            ]);
            curVideo.play();
          }
        );

        //THE BELOW CODE ALLOWS ACCESSING SOME OF THE NATIVE VIDEO PLAYER CONTROLS / MIGHT ALLOW CHANGING THEM GOING FORWARD
        /*let currentWin: Window = this.nwjsWindow.window;
        let playBut: CSSStyleDeclaration = currentWin.getComputedStyle(
          curVideo,
          ":-webkit-media-controls-start-playback-button"
        );
        let fsBut: CSSStyleDeclaration = currentWin.getComputedStyle(
          curVideo,
          ":-webkit-media-controls-fullscreen-button"
        );
        this.logger.logWarning([
          "presumably, we retrieved a playButton: ",
          fsBut,
        ]);*/
      }
    } catch (e) {
      this.logger.logError([
        "Error setting up auto-play-again feature (disabling pause) on html video players, ",
        e,
      ]);
    }
  };

  //provide data structure to access UI video sections for VC01
  getVCUI = (): HTMLDivElement => {
    return this._VCUI;
  };
  getLocalVSVC = (): LabeledHTMLVideoSection => {
    return this._VCLocalVideoSectionUI;
  };
  getLocalHTMLVideoElementVC = (): HTMLVideoElement => {
    return this._VCLocalVideoSectionUI.HTMLVideoElement;
  };
  getLocalHTMLLabelElementVC = (): HTMLLabelElement => {
    return this._VCLocalVideoSectionUI.HTMLLabelElement;
  };
  getLocalScreenShareVSVC = (): LabeledHTMLVideoSection => {
    return this._VCLocalScreenShareVideoSectionUI;
  };
  getLocalScreenShareHTMLVideoElementVC = (): HTMLVideoElement => {
    return this._VCLocalScreenShareVideoSectionUI.HTMLVideoElement;
  };
  getLocalScreenShareHTMLLabelElementVC = (): HTMLLabelElement => {
    return this._VCLocalScreenShareVideoSectionUI.HTMLLabelElement;
  };

  registerUnusedPeerVSVC = (peerID: string): void => {
    if (this._VCPeerVideoSectionsUIinUse.size < 20) {
      let _hasBeenRegistered = false;
      this._VCPeerVideoSectionsUI.forEach(
        (val: LabeledHTMLVideoSection, key, map) => {
          if (!val.inUse && !_hasBeenRegistered) {
            val.inUse = true;
            _hasBeenRegistered = true;
            val.inUseByPeerID = peerID;
            this._VCPeerVideoSectionsUIinUse.set(peerID, val);
            this._VCPeersUsingVideoSectionsUI.set(val.numberOnUI, peerID);
            this._UISectionsUsedByPeers.set(peerID, val.numberOnUI);
            this.logger.logLocal([
              "VC UI section ",
              val.numberOnUI,
              " got assigned to client ",
              peerID,
            ]);
            return val.numberOnUI;
          }
        }
      );
    } else {
      this.logger.logLocal([
        "No VC UI section could be assigned to peer ",
        peerID,
        " - all 20 sections are in use",
      ]);
    }
  };

  getPeerVSVC = (peerID: string) => {
    return this._VCPeerVideoSectionsUIinUse.get(peerID);
  };
  getPeerVSVCbyUIPos = (elementIDonUI: number): LabeledHTMLVideoSection => {
    return this._VCPeerVideoSectionsUI.get(elementIDonUI);
  };
  getPeerHTMLVideoElementVC = (peerID: string): HTMLVideoElement => {
    return this._VCPeerVideoSectionsUIinUse.get(peerID).HTMLVideoElement;
  };
  getPeerHTMLLabelElementVC = (peerID: string): HTMLLabelElement =>
    this._VCPeerVideoSectionsUIinUse.get(peerID).HTMLLabelElement;
  getPeerHTMLVideoElementVCOnUIByPosition = (
    elementIDonUI: number
  ): HTMLVideoElement => {
    return this._VCPeerVideoSectionsUI.get(elementIDonUI).HTMLVideoElement;
  };
  unregisterPeerVSVC = (peerID: string): void => {
    this.getPeerHTMLVideoElementVC(peerID).srcObject = null;
    let registeredVS: LabeledHTMLVideoSection =
      this._VCPeerVideoSectionsUIinUse.get(peerID);
    registeredVS.inUse = false;
    registeredVS.inUseByPeerID = null;
    this._VCPeersUsingVideoSectionsUI.delete(registeredVS.numberOnUI);
    this._VCPeerVideoSectionsUIinUse.delete(peerID);
    this._UISectionsUsedByPeers.delete(peerID);
  };
  hasRegisteredPeerVSVC = (peerID: string): boolean => {
    return this._VCPeerVideoSectionsUIinUse.has(peerID);
  };
  isVSregisteredVC = (posOnUI: number): boolean => {
    return this._VCPeersUsingVideoSectionsUI.has(posOnUI);
  };
  getPeerIDForVSVC = (posOnUI: number): string => {
    return this._VCPeersUsingVideoSectionsUI.get(posOnUI);
  };
  getUISectionForPeer = (peerID: string): number => {
    return this._UISectionsUsedByPeers.get(peerID);
  }; //get position on screen (1st, 2nd, 3rd, etc peer) based on peerID

  getUIPauseLocalButtons = (): HTMLCollectionOf<HTMLButtonElement> => {
    return this._pauseLocalButtons;
  };
  getUIMuteLocalButtons = (): HTMLCollectionOf<HTMLButtonElement> => {
    return this._muteLocalButtons;
  };

  getUITopCornerLeaveVCButton = (): HTMLButtonElement => {
    return this._topCornerCloseVCButton;
  };
  getUITopCornerVCToSideBarButton = (): HTMLElement => {
    return this._topCornerVCToSideBarButton;
  };
  getUITopCornerMinimizeRemoteScreenShareButton = (): HTMLElement => {
    return this._topCornerMinimizeRemoteScreenShareButton;
  };

  getUILocalClientLeaveVCButton = (): HTMLButtonElement => {
    return this._localClientLeaveVCButton;
  };
  getUILocalClientShareScreenButton = (): HTMLButtonElement => {
    return this._localClientShareScreenVCButton;
  };
  getUILocalClientShareScreenCloseButton = (): HTMLButtonElement => {
    return this._localClientShareScreenCloseVCButton;
  };
  getUILocalClientShareScreenPauseButton = (): HTMLButtonElement => {
    return this._localClientShareScreenPauseVCButton;
  };

  getVideoFullScreenButtonAtUIPos(uiPos: number) {
    return this._peerVideoFullscreenVCButton.get(uiPos);
  }
  getPeerButtonRemoteMuteAtUIPos(uiPos: number) {
    return this._peerRemoteMuteVCButton.get(uiPos);
  }

  getShowVCChatButton = (): HTMLButtonElement => {
    return this._topCornerShowVCChatButton;
  };
  getHideVCChatButton = (): HTMLButtonElement => {
    return this._topCornerHideVCChatButton;
  };
}
