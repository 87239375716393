import { APP_SCREEN_SIDE } from "data/enums/APP_SCREEN_SIDE";
import { I_UIMessengerWindow } from "frontend/decoupler/I_UIMessengerWindow";
import { AppUIHTML } from "frontend/html/AppUIHTML";
import { Helpers } from "utils/Helpers";
import { Logger } from "utils/Logger";
import { UIMessengerNotifyJS } from "utils/UIMessengerNotifyJS";
import { SidebarWebMeet } from "./SidebarWebMeet";

export class UIMessengerWindowWebMeet implements I_UIMessengerWindow {
  /* 
    
    DOES NOTHING UNLESS USING A CUSTOM HTML-BASED MESSENGER LIBRARY GOING FORWARD
    
    */

  private help: Helpers;
  private logger: Logger;
  private uiMessenger: UIMessengerNotifyJS;
  private appUI: AppUIHTML;
  private sidebarWindow: SidebarWebMeet;

  //private customUIMessengerWindow: any; //reference to nwjs window

  constructor(
    _help: Helpers,
    _logger: Logger,
    _uiMessenger: UIMessengerNotifyJS,
    _sidebarWindow: SidebarWebMeet
  ) {
    this.help = _help;
    this.logger = _logger;
    this.uiMessenger = _uiMessenger;
    this.sidebarWindow = _sidebarWindow;
  }

  initialize = (_appUI: AppUIHTML): void => {
    this.appUI = _appUI;
  };

  closeUIMWindowIfOnApp = (): void => {};

  initializeCustomUIMessengerWindow = (
    _doc: Document,
    _UIMScreenURL: string,
    initfunc: (any: any, Document: any) => void
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  showCustomUIMessengerWindow = (): void => {};

  hideCustomUIMessengerWindow = (): void => {};

  switchUIMessengerWindowToScreenSide = (
    newScreenSide: APP_SCREEN_SIDE
  ): void => {}; // changing this may be valuable going forward

  updateUIMWindowSizeAndPositionToReflectSizeRequirementOfCurrentText =
    (): void => {};
}
