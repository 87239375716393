export class LocalMuteStateInfoMessage {
  private senderID: string;
  private recipientID: string;
  private senderMuteState: boolean;

  constructor(
    _senderID: string,
    _recipientID: string,
    _senderMuteState: boolean
  ) {
    this.senderID = _senderID;
    this.recipientID = _recipientID;
    this.senderMuteState = _senderMuteState;
  }

  getSenderID = (): string => {
    return this.senderID;
  };

  getRecipientID = (): string => {
    return this.recipientID;
  };

  getSenderMuteState = (): boolean => {
    return this.senderMuteState;
  };
}
