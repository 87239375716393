/* COPYRIGHT 2021 Michael Maur - any form of reuse requires written consent by the author */

import { Config } from "../config/Config";
import { BOOL } from "../data/enums/BOOL";

export class Helpers {
  private config: Config;
  /* TODO: HOST NAMES MUST BE KEPT IN SYNCH WITH CONFIG */
  private readonly devEnvHostname: string = "app-main-dev.visav.is";
  private readonly devEnvHostnameLEGACY: string = "dev.prototype.visav.is";
  private readonly localEnvHostname: string = "localhost";
  private readonly meetWebClientDevHostname: string = "meet-dev.visav.is";
  private readonly teamWebClientDevHostname: string = "go-dev.visav.is";

  constructor() {
    console.log("Local client loading extension: Helpers");
    /*console.log("--app version detected: " + this.determineAppVersion());
    console.log("--production environment detected: " + !this.isDevEnv());
    console.log("--mac os detected: " + this.isMacApp());*/
    this.initializeAppType(); //pre-initialization needed as respective app type checks are used pre-initialization in other classes
  }

  initialize = (_config: Config) => {
    return new Promise((resolve, reject) => {
      this.config = _config;
      console.log("Initializing Extension: Helpers");
      console.log("--app version detected: " + this.determineAppVersion());
      console.log("--production environment detected: " + !this.isDevEnv());
      console.log("--mac os detected: " + this.isMacApp());
      resolve(true);
      return;
    });
  };

  private isMacAppFlag: boolean;
  private isWinAppFlag: boolean;
  private isNWJSAppFlag: boolean;
  private isWebAppMeetFlag: boolean;
  private isWebAppGOFlag: boolean;
  private isWebAppFlag: boolean;

  private initializeAppType = () => {
    this.isMacAppFlag =
      this.URLParameterPresent("app") &&
      this.getURLParameter("app").includes("m");
    this.isWinAppFlag =
      this.URLParameterPresent("app") &&
      this.getURLParameter("app").includes("w");
    this.isNWJSAppFlag = this.isMacAppFlag || this.isWinAppFlag;
    this.isWebAppMeetFlag =
      this.URLParameterPresent("web") &&
      this.getURLParameter("web") == "webMeet";
    this.isWebAppGOFlag =
      this.URLParameterPresent("web") && this.getURLParameter("web") == "webGO";
    this.isWebAppFlag = this.isWebAppMeetFlag || this.isWebAppGOFlag;
  };

  appIsActive = (): boolean => {
    return this.isNWJSAppFlag;
    //return this.isMacApp() || this.isWindowsApp();
  };

  determineAppVersion = () => {
    if (this.URLParameterPresent("app")) return this.getURLParameter("app");
    else if (this.URLParameterPresent("web"))
      return this.getURLParameter("web");
    else return null;
    /*const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("app")) {
      const app_para = urlParams.get("app");
      return app_para;
    } else return null;*/
  };

  URLParameterPresent = (_urlPara: string) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has(_urlPara)) {
      return true;
    } else return false;
  };

  getURLParameter = (_urlPara: string) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has(_urlPara)) {
      const app_para = urlParams.get(_urlPara);
      return app_para;
    } else return null;
  };

  getRandomInt = (max: number): number => {
    return Math.floor(Math.random() * max);
  };

  isMacApp = (): boolean => {
    return this.isMacAppFlag;
  };

  isWindowsApp = (): boolean => {
    return this.isWinAppFlag;
  };

  isDevEnv = (): boolean => {
    return (
      /*window.location.hostname == this.config.devEnvHostname ||
      window.location.hostname == this.config.devEnvHostnameLEGACY ||
      window.location.hostname == this.config.localEnvHostname ||
      window.location.hostname == this.config.meetWebClientDevHostname*/
      window.location.hostname == this.devEnvHostname ||
      window.location.hostname == this.devEnvHostnameLEGACY ||
      window.location.hostname == this.localEnvHostname ||
      window.location.hostname == this.meetWebClientDevHostname ||
      window.location.hostname == this.teamWebClientDevHostname
    );
  };

  isMainAppURL = (): boolean => {
    return (
      window.location.hostname == this.config.prodEnvHostname ||
      window.location.hostname == this.config.prodEnvHostnameNEW ||
      window.location.hostname == this.config.devEnvHostname ||
      window.location.hostname == this.config.devEnvHostnameLEGACY ||
      window.location.hostname == this.config.localEnvHostname
    );
  };

  isWebApp = (): boolean => {
    return this.isWebAppFlag;
  };

  isWebAppMeeting = (): boolean => {
    return this.isWebAppMeetFlag;
  };

  isWebAppGO = (): boolean => {
    return this.isWebAppGOFlag;
  };

  chromiumIsActive = (): boolean => {
    //@ts-ignore
    let isChromium = window.chrome;
    let winNav = window.navigator;
    let vendorName = winNav.vendor;
    //@ts-ignore
    let isOpera = typeof window.opr !== "undefined";
    let isIEedge = winNav.userAgent.indexOf("Edg") > -1;
    let isIOSChrome = winNav.userAgent.match("CriOS");

    return (
      isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false
    );
  };

  firefoxIsActive = (): boolean => {
    return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  };

  safariIsActive = (): boolean => { // this includes safari backend under chrome on iOS - will also return true if chrome on ios
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };

  isApple = (): boolean => {
    return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  };

  isMacOS = (): boolean => {
    return navigator.platform.toUpperCase().indexOf("MAC") >= 0;
  };

  isIOS = (): boolean => {
    return /(iPhone|iPod|iPad)/i.test(navigator.platform);
  };

  isAndroid = (): boolean => {
    var ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("android") > -1;
  };

  isWindowsSeven = (): boolean => {
    return navigator.appVersion.indexOf("Windows NT 6.1") != -1;
  };

  localPeerIDIsTheOneToInitiateConnection = (
    localPeerID: string,
    remotePeerID: string
  ): boolean => {
    // reproducibly always determine one of two peers as the one who needs to initiate the connection - in order to avoid colliding connection attempts from two sides
    return String(remotePeerID).localeCompare(localPeerID) > 0;
  };

  isDeviceConnectedToTheInternet() {
    return window.navigator.onLine;
  }

  BOOL2boolean = (BOOLval: BOOL): boolean => {
    if (BOOLval == BOOL.B_TRUE) return true;
    else return false;
  };
  boolean2BOOL = (booleanVal: boolean): BOOL => {
    if (booleanVal) return BOOL.B_TRUE;
    else return BOOL.B_FALSE;
  };

  hash = (str: string, seed: number = 81761087): number => {
    //hash method covered online as cyrb53 - standard seed=0
    let h1: number = 0xdeadbeef ^ seed,
      h2: number = 0x41c6ce57 ^ seed;
    for (let i: number = 0, ch: number; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 =
      Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
      Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 =
      Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
      Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };

  standardizeString = (roomNameInput: string): string => {
    //for room names etc, remove space characters and make everything lower-case
    //must not be changed as also used for room pwds pre-hash
    return roomNameInput.toLowerCase().replace(/\s+/g, "");
  };
}
