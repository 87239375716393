import { ConfUserSettings } from "configMgmt/ConfUserSettings";
import { Helpers } from "utils/Helpers";

export class SettingStorage {
  private help: Helpers;

  public readonly _varNameCam: string = "settings.devices.cam.groupID";
  public readonly _varNameMic: string = "settings.devices.mic.groupID";
  public readonly _varNameMainRoom: string = "settings.rooms.mainRoom";
  public readonly _varNameMainRoomPass: string = "anchor.d";
  public readonly _varNameMainRoomPassVersion: string = "anchor.e";
  public readonly _varNamePreClosureStatusLocalAudioMuted: string =
    "preclosurestatus.audio.muted";
  public readonly _varNamePreClosureStatusLocalVideoPaused: string =
    "preclosurestatus.video.paused";
  public readonly _varNameUID: string = "anchor.b";
  public readonly _varNameUIDPass: string = "anchor.a";
  public readonly _varNameUIDVersion: string = "anchor.c";

  public readonly _varNameMainRoomLegacy: string = "room";

  public readonly _varNameUserEMail: string = "user.email";
  public readonly _varNameUserUILabel: string = "user.UIlabel";
  public readonly _varNameLastUserInfoPingToServer: string = "anchor.f";

  public readonly _varNameWebClientAutoLoginFlag: string =
    "webclient.autologin";

  constructor(_help: Helpers) {
    console.log("Local client loading extension: SettingStorage");

    this.help = _help;
  }

  initialize = () => {
    return new Promise((resolve, reject) => {
      this.cleanUpLegacySettings();
      this.saveWebClientSettingsIfRequested();
      resolve(true);
      return;
    });
  };

  cleanUpLegacySettings = (): void => {
    if (this.storedSettingExists(this._varNameMainRoomLegacy)) {
      this.storeSetting(
        this._varNameMainRoom,
        this.getStoredSetting(this._varNameMainRoomLegacy)
      );
      this.deleteStoredSetting(this._varNameMainRoomLegacy);
    }
  };

  storedSettingExists = (set: string): boolean => {
    let setVal = localStorage.getItem(set);
    if (setVal != null && setVal != "") return true;
    else return false;
  };

  getStoredSetting = (set: string): string => {
    return localStorage.getItem(set);
  };
  getStoredSettingBoolean = (set: string): boolean => {
    let val = localStorage.getItem(set);
    if (val == "true") return true;
    else if (val == "false") return false;
    throw new Error(
      "trying to retrieve boolean attribute from localstorage - no boolean value returned / potentially does not exist"
    );
  };

  deleteStoredSetting = (set: string): void => {
    localStorage.removeItem(set);
  };

  storeSetting = (set: string, val: string): void => {
    localStorage.setItem(set, val);
  };

  storeSettingBoolean = (set: string, val: boolean): void => {
    let strval: string = "false";
    if (val) strval = "true";
    localStorage.setItem(set, strval);
  };

  getCamDeviceID = () => {
    if (this.storedSettingExists(this._varNameCam)) {
      return this.getStoredSetting(this._varNameCam);
    } else return null;
  };

  getMicDeviceID = () => {
    if (this.storedSettingExists(this._varNameMic)) {
      return this.getStoredSetting(this._varNameMic);
    } else return null;
  };

  getMainRoom = () => {
    if (this.storedSettingExists(this._varNameMainRoom)) {
      return this.getStoredSetting(this._varNameMainRoom);
    } else return null;
  };

  setCamDeviceID = (camDID: string): void => {
    if (camDID == "") this.deleteStoredSetting(this._varNameCam);
    else {
      this.storeSetting(this._varNameCam, camDID);
    }
  };

  setMicDeviceID = (micDID: string): void => {
    if (micDID == "") this.deleteStoredSetting(this._varNameMic);
    else {
      this.storeSetting(this._varNameMic, micDID);
    }
  };

  setMainRoom = (mRoom: string): void => {
    if (mRoom == "") {
      this.deleteStoredSetting(this._varNameMainRoom);
    } else {
      this.storeSetting(this._varNameMainRoom, mRoom);
    }
  };

  existsCamSetting = (): boolean => {
    return this.storedSettingExists(this._varNameCam);
  };

  existsMicSetting = (): boolean => {
    return this.storedSettingExists(this._varNameMic);
  };

  existsMainRoomSetting = (): boolean => {
    return this.storedSettingExists(this._varNameMainRoom);
  };

  deleteMainRoomSetting = (): void => {
    this.deleteStoredSetting(this._varNameMainRoom);
  };

  setStatusAudioMuted = (newVal: boolean) => {
    this.storeSettingBoolean(
      this._varNamePreClosureStatusLocalAudioMuted,
      newVal
    );
  };
  setStatusVideoPaused = (newVal: boolean) => {
    this.storeSettingBoolean(
      this._varNamePreClosureStatusLocalVideoPaused,
      newVal
    );
  };
  getStatusAudioMuted = (): boolean => {
    return this.getStoredSettingBoolean(
      this._varNamePreClosureStatusLocalAudioMuted
    );
  };
  getStatusVideoPaused = (): boolean => {
    return this.getStoredSettingBoolean(
      this._varNamePreClosureStatusLocalVideoPaused
    );
  };
  existsAudioMutedSetting = (): boolean => {
    return this.storedSettingExists(
      this._varNamePreClosureStatusLocalAudioMuted
    );
  };
  existsVideoPausedSetting = (): boolean => {
    return this.storedSettingExists(
      this._varNamePreClosureStatusLocalVideoPaused
    );
  };

  existsUIDSetting = (): boolean => {
    return this.storedSettingExists(this._varNameUID);
  };
  getUID = (): string => {
    return this.getStoredSetting(this._varNameUID);
  };
  setUID = (newUID: string): void => {
    this.storeSetting(this._varNameUID, newUID);
  };
  existsUIDVersionSetting = (): boolean => {
    return this.storedSettingExists(this._varNameUIDVersion);
  };
  getUIDVersion = (): string => {
    return this.getStoredSetting(this._varNameUIDVersion);
  };
  setUIDVersion = (newUIDVersion: string): void => {
    this.storeSetting(this._varNameUIDVersion, newUIDVersion);
  };
  existsUIDPassSetting = (): boolean => {
    return this.storedSettingExists(this._varNameUIDPass);
  };
  getUIDPass = (): string => {
    return this.getStoredSetting(this._varNameUIDPass);
  };
  setUIDPass = (newUIDPass: string): void => {
    this.storeSetting(this._varNameUIDPass, newUIDPass);
  };
  getUserEmail = (): string => {
    return this.getStoredSetting(this._varNameUserEMail);
  };
  setUserEmail = (newUserEmail: string): void => {
    this.storeSetting(this._varNameUserEMail, newUserEmail);
  };
  existsUserEmail = (): boolean => {
    return this.storedSettingExists(this._varNameUserEMail);
  };
  getUserUILabel = (): string => {
    return this.getStoredSetting(this._varNameUserUILabel);
  };
  setUserUILabel = (newUserUILabel: string): void => {
    this.storeSetting(this._varNameUserUILabel, newUserUILabel);
  };
  existsUserUILabel = (): boolean => {
    return this.storedSettingExists(this._varNameUserUILabel);
  };
  deleteUserUILabel = (): void => {
    this.deleteStoredSetting(this._varNameUserUILabel);
  };
  setLastUserInfoPingToServerDate = (newVal: string = null): void => {
    if (newVal != null) {
      this.storeSetting(this._varNameLastUserInfoPingToServer, newVal);
    } else {
      let today = new Date();
      this.storeSetting(
        this._varNameLastUserInfoPingToServer,
        "" + today.getFullYear() + today.getMonth() + today.getDate()
      );
    }
  };
  getLastUserInfoPingToServerDate = (): string => {
    return this.getStoredSetting(this._varNameLastUserInfoPingToServer);
  };
  wasLastUserInfoPingToServerToday = (): boolean => {
    if (!this.existsLastUserInfoPingToServerDate()) return false;
    let today = new Date();
    return (
      this.getLastUserInfoPingToServerDate() ==
      "" + today.getFullYear() + today.getMonth() + today.getDate()
    );
  };
  existsLastUserInfoPingToServerDate = (): boolean => {
    return this.storedSettingExists(this._varNameLastUserInfoPingToServer);
  };
  getMainRoomPass = (): string => {
    return this.getStoredSetting(this._varNameMainRoomPass);
  };
  setMainRoomPass = (newPass: string): void => {
    this.storeSetting(this._varNameMainRoomPass, newPass);
  };
  existsMainRoomPass = (): boolean => {
    return this.storedSettingExists(this._varNameMainRoomPass);
  };
  deleteMainRoomPass = (): void => {
    this.deleteStoredSetting(this._varNameMainRoomPass);
    this.deleteStoredSetting(this._varNameMainRoomPassVersion);
  };
  getMainRoomPassVersion = (): string => {
    return this.getStoredSetting(this._varNameMainRoomPassVersion);
  };
  setMainRoomPassVersion = (newPassVersion: string): void => {
    this.storeSetting(this._varNameMainRoomPassVersion, newPassVersion);
  };
  existsMainRoomPassVersion = (): boolean => {
    return this.storedSettingExists(this._varNameMainRoomPassVersion);
  };
  getWebClientAutoLoginFlag = (): string => {
    return this.getStoredSetting(this._varNameWebClientAutoLoginFlag);
  };
  setWebClientAutoLoginFlag = (newAutoLoginValue: string): void => {
    this.storeSetting(this._varNameWebClientAutoLoginFlag, newAutoLoginValue);
  };
  existsWebClientAutoLoginFlag = (): boolean => {
    return this.storedSettingExists(this._varNameWebClientAutoLoginFlag);
  };
  deleteWebClientAutoLoginFlag = (): void => {
    this.deleteStoredSetting(this._varNameWebClientAutoLoginFlag);
  };
  saveWebClientSettingsIfRequested = (): void => {
    if (
      this.help.isWebApp() &&
      this.help.URLParameterPresent("saveLoginSettings") &&
      this.help.getURLParameter("saveLoginSettings") == "yes"
    ) {
      if (
        this.help.URLParameterPresent("room") &&
        this.help.URLParameterPresent("username")
      ) {
        this.setMainRoom(this.help.getURLParameter("room"));
        this.setUserUILabel(this.help.getURLParameter("username"));
        if (this.help.URLParameterPresent("sizing")) {
          try {
            let passPara: string = this.help.getURLParameter("sizing");
            let saltyDelta: number = 7 * 81761087;
            let defaultPassPhrase: string = "webgo-teamRoom.html";
            if (
              "" + passPara !=
              "" +
                (this.help.hash(
                  this.help.standardizeString(defaultPassPhrase)
                ) +
                  saltyDelta)
            ) {
              //only if pass phrase is not default placeholder, a relevant password hash is present and should be saved
              let passNumber: number =
                (passPara as unknown as number) - saltyDelta;
              this.setMainRoomPass(passNumber + "");
              this.setMainRoomPassVersion("01");
            }
            this.setWebClientAutoLoginFlag("enabled");
          } catch (e) {
            this.deleteMainRoomSetting();
            this.deleteUserUILabel();
            this.deleteWebClientAutoLoginFlag();
            console.error(
              "Corrupted room password has been provided - transformation to number failed"
            );
            alert(
              "Note: a corrupted room password has been provided. Thus, no password has been applied. Please double-check the password you're entering. Now taking you back to the login screen."
            );
            let newURL =
              "./ui/webgo-login.html?rand=" + Math.ceil(Math.random() * 10000);
            window.open(newURL, "_self");
          }
        }
      } else {
        this.deleteWebClientAutoLoginFlag(); // just in case present from prior login / to resolve weird setups and send them back to login
        alert(
          "visav.is web client has been started with insufficient login information (room and username required) - now taking you back to the login screen."
        );
        let newURL =
          "./ui/webgo-login.html?rand=" + Math.ceil(Math.random() * 10000);
        window.open(newURL, "_self");
      }
    }
  };
  validateWebClientSettingAvailabilityIfAutoLoginRequestedElseReturnToLoginPage =
    () => {
      if (
        this.help.isWebApp() &&
        this.help.URLParameterPresent("loginmode") &&
        this.help.getURLParameter("loginmode") == "autologin" &&
        (!this.existsMainRoomSetting() || !this.existsUserUILabel())
      ) {
        this.deleteWebClientAutoLoginFlag(); // if required fields not there, disable auto-login
        alert(
          "visav.is web client has been started with insufficient login information (room and username required) - now taking you back to the login screen."
        );
        let newURL =
          "./ui/webgo-login.html?rand=" + Math.ceil(Math.random() * 10000);
        window.open(newURL, "_self");
      }
    };
}
