import { SettingStorage } from "data/SettingStorage";
import { ConfTeamSettingsHTML } from "frontend/html/configElementsHTML/ConfTeamSettingsHTML";
import { Helpers } from "utils/Helpers";
import { Logger } from "utils/Logger";
import { UIMessenger } from "utils/UIMessenger";
import { I_ConfigElement } from "./I_ConfigElement";

export class ConfTeamSettings implements I_ConfigElement {
  private help: Helpers;
  private logger: Logger;
  private settings: SettingStorage;
  private uiMessenger: UIMessenger;

  private confTeamHTML: ConfTeamSettingsHTML;

  constructor(
    _help: Helpers,
    _logger: Logger,
    _storage: SettingStorage,
    _uiMessenger: UIMessenger
  ) {
    this.help = _help;
    this.logger = _logger;
    this.settings = _storage;
    this.uiMessenger = _uiMessenger;
  }

  initialize = (_teamHTML: ConfTeamSettingsHTML): void => {
    this.confTeamHTML = _teamHTML;
  };

  initializeUI = () => {
    try {
      let storedTeamRoom = this.settings.getMainRoom();
      this.confTeamHTML.setTeamRoomNameInputValue(storedTeamRoom);
      if (
        this.settings.existsMainRoomPass() &&
        this.settings.getMainRoomPass() != ""
      )
        this.confTeamHTML.setTeamRoomPwdInputValue("xxxxXXXX");
    } catch (e) {
      this.logger.logError([
        "Error initializing team settings on conf window, ",
        e,
      ]);
    }
  };

  onUserSave = (): void => {
    try {
      let chosenRoomName = this.confTeamHTML.getTeamRoomNameInputValue();
      this.settings.setMainRoom(chosenRoomName);
      let pwdInput = this.confTeamHTML.getTeamRoomPwdInputValue();
      if (pwdInput == "") {
        this.settings.deleteMainRoomPass();
      } else if (pwdInput == "xxxxXXXX") {
        //do nothing - password exists and has not been changed
      } else {
        let roomPwHash: number = this.help.hash(
          this.help.standardizeString(pwdInput)
        );
        this.settings.setMainRoomPass(roomPwHash + "");
        this.settings.setMainRoomPassVersion("01");
      }
    } catch (e) {
      this.logger.logError(["Error saving team settings via conf window, ", e]);
    }
  };

  onUserCancel = (): void => {};

  /*switchRoomsButtonClick = () => {
    let manualRoomInput = prompt(
      "In order to ...",
      this.signaling.getMainRoom()
    );
    if (manualRoomInput != null) {
      this.settings.setMainRoom(manualRoomInput);
    }

  };*/
}
