//this class is not really in use - it is a prep for future object-oriented peer representations
export class Peer {
  private peerID: string;

  constructor(_peerID: string) {
    this.peerID = _peerID;
  }

  initialize = () => {};

  getPeerID = (): string => {
    return this.peerID;
  };
}
