import { SettingStorage } from "data/SettingStorage";
import { ConfUserSettingsHTML } from "frontend/html/configElementsHTML/ConfUserSettingsHTML";
import { UIHandler } from "frontend/UIHandler";
import { Logger } from "utils/Logger";
import { UIMessenger } from "utils/UIMessenger";
import { I_ConfigElement } from "./I_ConfigElement";

export class ConfUserSettings implements I_ConfigElement {
  private logger: Logger;
  private settings: SettingStorage;
  private uiMessenger: UIMessenger;

  private confUserHTML: ConfUserSettingsHTML;

  constructor(
    _logger: Logger,
    _storage: SettingStorage,
    _uiMessenger: UIMessenger
  ) {
    this.logger = _logger;
    this.settings = _storage;
    this.uiMessenger = _uiMessenger;
  }

  initialize = (_userHTML: ConfUserSettingsHTML): void => {
    this.confUserHTML = _userHTML;
  };

  initializeUI = () => {
    try {
      if (this.settings.existsUserEmail())
        this.confUserHTML.setUserEmailInputElement(
          this.settings.getUserEmail()
        );
      if (this.settings.existsUserUILabel())
        this.confUserHTML.setUserLabelInputValue(
          this.settings.getUserUILabel()
        );
      //TODO: prepopulate UI and add click methods
    } catch (e) {
      this.logger.logError([
        "Error initializing user settings on conf window, ",
        e,
      ]);
    }
  };

  onUserSave = (): void => {
    try {
      this.settings.setUserEmail(this.confUserHTML.getUserEmailInputElement());
      this.settings.setUserUILabel(this.confUserHTML.getUserLabelInputValue());
      this.settings.setLastUserInfoPingToServerDate("N/A");
    } catch (e) {
      this.logger.logError(["Error saving user settings via conf window, ", e]);
    }
  };

  onUserCancel = (): void => {};
}
