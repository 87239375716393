import { I_VCWindowUI } from "../decoupler/I_VCWindowUI";
import { VCWindowSizer } from "../decoupler/jointUITech/VCWindowSizer";
import { APP_SCREEN_SIDE } from "../../data/enums/APP_SCREEN_SIDE";
import { Helpers } from "../../utils/Helpers";
import { Logger } from "../../utils/Logger";
import { AppUIHTML } from "../html/AppUIHTML";
import { SidebarWindowNWJS } from "./SidebarWindowNWJS";

export class VCWindowNWJS implements I_VCWindowUI {
  private help: Helpers;
  private logger: Logger;
  private appUI: AppUIHTML;
  private sidebarWindow: SidebarWindowNWJS;

  private windowSizer: VCWindowSizer;

  private dedicatedVCWindow: any; //reference to nwjs window

  private dedicatedVCWindowIsTmpHidden: boolean;

  private readonly sideBarWidth: number = 200;

  constructor(
    _help: Helpers,
    _logger: Logger,
    _sidebarWindow: SidebarWindowNWJS
  ) {
    this.help = _help;
    this.logger = _logger;
    this.sidebarWindow = _sidebarWindow;

    this.windowSizer = new VCWindowSizer(this.logger, this.sideBarWidth);

    this.dedicatedVCWindow = null;
  }

  initialize = (_appUI: AppUIHTML): void => {
    this.appUI = _appUI;
    this.dedicatedVCWindowIsTmpHidden = false;

    this.windowSizer.initialize(this.appUI);
  };

  initializeDedicatedVCWindow = (
    _doc: Document,
    _VCScreenURL: string,
    initfunc: { (any, Document): void },
    initFuncEntireAppVC: { (HTMLDivElement): void }
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (this.help.appIsActive()) {
        try {
          //@ts-ignore
          nw.Window.open(
            _VCScreenURL,
            {
              title: "visavis video huddle screen",
              position: "center",
              //icon: "favicon.icns",
              show: false,
              frame: false,
              transparent: true,
              always_on_top: true,
              show_in_taskbar: true,
              visible_on_all_workspaces: true,
              //focus: true,
            },
            (win) => {
              win.on("loaded", () => {
                this.dedicatedVCWindow = win;
                initfunc(win, win.window.document);
                initFuncEntireAppVC(
                  win.window.document.getElementById(
                    "entireApp"
                  ) as HTMLDivElement
                );
                if (this.help.isMacApp())
                  this.dedicatedVCWindow.setShadow(false);
                this.dedicatedVCWindow.setVisibleOnAllWorkspaces(true);
                this.sidebarWindow.focusMainApp();
                resolve();
                return;
              });
            }
          );
        } catch (e) {
          this.logger.logError(["Error initializing dedicated VC window, ", e]);
          reject(e);
          return;
        }
      } else {
        initfunc(null, _doc);
        initFuncEntireAppVC(null);
        resolve();
        return;
      }
    });
  };

  showDedicatedVCWindowIfOnApp = (): void => {
    if (this.help.appIsActive()) {
      try {
        if (!this.dedicatedVCWindowIsTmpHidden) {
          this.dedicatedVCWindow.show();
          this.dedicatedVCWindow.setAlwaysOnTop(true);
          this.dedicatedVCWindow.focus(); //note: this was not there before bugfix to properly hide / show vc window while in scs dialogue - remove, in case of issues
          //@ts-ignore
          //this.dedicatedVCWindow.show();
        } else {
          //this.tmpHideDedicatedVCWindowForScSDialogueIfOnApp();
          this.logger.logWarning([
            "Could not unhide dedicated window because flag for screenshare dialogue is still set",
          ]);
        }
      } catch (e) {
        this.logger.logError(["error showing nwjs window, ", e]);
      }
    }
  };

  hideDedicatedVCWindowIfOnApp = (): void => {
    if (this.help.appIsActive()) {
      try {
        //@ts-ignore
        this.dedicatedVCWindow.hide();
      } catch (e) {
        this.logger.logError(["error hiding nwjs window, ", e]);
      }
    }
  };

  tmpHideDedicatedVCWindowForScSDialogueIfOnApp = (
    activelyUnfocusVCWindow: boolean = false
  ): void => {
    if (this.help.appIsActive()) {
      try {
        this.dedicatedVCWindowIsTmpHidden = true;
        /*if (this.busLogic.getUIHandler().VCUIIsActive()) {
              this.dedicatedVCWindowIsTmpHidden = true;
              this.hideDedicatedVCWindowIfOnApp();
            }*/
        if (activelyUnfocusVCWindow) {
          //do this only if window is really there - this avoids other windows outside visav.is to becoming active / inactive when starting a screenshare (problem on windows only)
          this.dedicatedVCWindow.setAlwaysOnTop(false);
          this.dedicatedVCWindow.blur();
        }
      } catch (e) {
        this.logger.logError([
          "error hiding nwjs window temporarily for screen share dialogue, ",
          e,
        ]);
      }
    }
  };

  unhideDedicatedVCWindowAfterTMPHideForScSDialogueIfOnApp = (
    activelyShowWindowAfterRemovingTMPHideFlag: boolean = false
  ): void => {
    if (this.help.appIsActive()) {
      try {
        this.logger.logLocal([
          "now processing request to unhide vc window in case it has been tmp hidden before - parameter dedicatedVCWindowIsTmpHidden: ",
          this.dedicatedVCWindowIsTmpHidden,
        ]);
        if (this.dedicatedVCWindowIsTmpHidden) {
          this.dedicatedVCWindowIsTmpHidden = false;
          if (activelyShowWindowAfterRemovingTMPHideFlag) {
            this.showDedicatedVCWindowIfOnApp();
          }
          /*if (this.dedicatedVCWindowIsTmpHidden) {
                  this.dedicatedVCWindowIsTmpHidden = false;
                  this.showDedicatedVCWindowIfOnApp();
                }*/
          /*this.dedicatedVCWindow.show();
          this.dedicatedVCWindow.setAlwaysOnTop(true);
          this.dedicatedVCWindow.focus();*/
        }
      } catch (e) {
        this.logger.logError([
          "error unhiding nwjs window after screen share dialogue, ",
          e,
        ]);
      }
    }
  };

  setWindowSizeIfOnApp = (
    _width: number,
    _height: number,
    _resetPosition: boolean = false,
    _win: Window = this.dedicatedVCWindow
  ): void => {
    if (this.help.appIsActive()) {
      try {
        //@ts-ignore
        _win.width = _width;
        //@ts-ignore
        _win.height = _height;
        if (_resetPosition) {
          //@ts-ignore
          _win.setPosition("center");
          if ((this.appUI._appScreenSide = APP_SCREEN_SIDE.RIGHT)) {
            _win.moveBy(-0.5 * this.sideBarWidth, 0);
          } else if ((this.appUI._appScreenSide = APP_SCREEN_SIDE.LEFT)) {
            _win.moveBy(+0.5 * this.sideBarWidth, 0);
          }
        }
      } catch (e) {
        this.logger.logError(["error setting nwjs window size, ", e]);
      }
    }
  };

  resetVCWindowPositionIfOnApp = (): void => {
    if (this.help.appIsActive()) {
      try {
        //@ts-ignore
        this.dedicatedVCWindow.setPosition("center");
        this.dedicatedVCWindow.moveBy(-0.5 * this.sideBarWidth, 0);
      } catch (e) {
        this.logger.logError(["error resetting nwjs window position, ", e]);
      }
    }
  };

  setVCWindowSizeForNumberOfParticipantsIfOnApp = (
    numberOfParticipants: number,
    chatActive: boolean,
    resetPosition: boolean
  ): void => {
    this.windowSizer.setVCWindowSizeForNumberOfParticipantsIfOnApp(
      numberOfParticipants,
      chatActive,
      resetPosition,
      this.setWindowSizeIfOnApp
    );
  };

  setVCWindowSizeScreenShareIfOnApp = (
    relativeWidth: number,
    relativeHeight: number,
    resetPosition: boolean
  ): void => {
    this.windowSizer.setVCWindowSizeScreenShareIfOnApp(
      relativeWidth,
      relativeHeight,
      resetPosition,
      this.setWindowSizeIfOnApp
    );
  };

  initializeScreenShareAutoSizingIfOnApp = (): void => {
    if (this.help.appIsActive())
      this.windowSizer.initializeScreenShareAutoSizingIfOnApp(
        this.setVCWindowSizeScreenShareIfOnApp
      );
  };

  closeVCWindowIfOnApp = (): void => {
    if (this.help.appIsActive())
      //enforceClosureOfDedicatedVCWindow
      this.dedicatedVCWindow.close();
  };

  refreshDedicatedVCWindowAlwaysOnTop = (): void => {
    if (this.help.appIsActive()) this.dedicatedVCWindow.setAlwaysOnTop(true);
  };

  /*reloadIgnoringCache = (): void => {
    this.dedicatedVCWindow.reloadIgnoringCache();
  };*/
}
