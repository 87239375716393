import { SettingStorage } from "data/SettingStorage";
import { ConfCamSettingsHTML } from "frontend/html/configElementsHTML/ConfCamSettingsHTML";
import { ConfTeamSettingsHTML } from "frontend/html/configElementsHTML/ConfTeamSettingsHTML";
import { BrowserMediaHandler } from "media/BrowserMediaHandler";
import { Logger } from "utils/Logger";
import { UIMessenger } from "utils/UIMessenger";
import { I_ConfigElement } from "./I_ConfigElement";

export class ConfCamSettings implements I_ConfigElement {
  private logger: Logger;
  private settings: SettingStorage;
  private uiMessenger: UIMessenger;
  private media: BrowserMediaHandler;

  private confCamHTML: ConfCamSettingsHTML;

  constructor(
    _logger: Logger,
    _storage: SettingStorage,
    _uiMessenger: UIMessenger,
    _media: BrowserMediaHandler
  ) {
    this.logger = _logger;
    this.settings = _storage;
    this.uiMessenger = _uiMessenger;
    this.media = _media;
  }

  initialize = (_camHTML: ConfCamSettingsHTML): void => {
    this.confCamHTML = _camHTML;
  };

  private cams: MediaDeviceInfo[];
  private currentlyActiveCam: number;

  initializeUI = () => {
    try {
      this.media.getUniqueAvailableMediaDevices(true).then(() => {
        this.cams = this.media
          .getMediaDeviceMgr()
          .getAvailableMediaDevicesCameras();
        this.currentlyActiveCam =
          this.media.getMediaDeviceMgr().getCurrentMediaDeviceIndexCamera() + 1;
        let camChoices: string = "";
        for (let i = 0; i < this.cams.length; i++) {
          if (i + 1 == this.currentlyActiveCam) {
            camChoices =
              camChoices +
              "<b>     " +
              (i + 1) +
              ":  " +
              this.cams[i].label +
              "[ACTIVE]</b></br>";
          } else {
            camChoices =
              camChoices +
              "     " +
              (i + 1) +
              ":  " +
              this.cams[i].label +
              "</br>";
          }
        }
        this.confCamHTML.setAvailableCamerasList("<p>" + camChoices + "</p>");
        this.confCamHTML.setCamChoiceInputValue(this.currentlyActiveCam + "");
      });
    } catch (e) {
      this.logger.logError([
        "Error initializing camera settings on conf window, ",
        e,
      ]);
    }
  };

  onUserSave = (): void => {
    try {
      let chosenCamID = this.confCamHTML.getCamChoiceInputValue();
      if (chosenCamID == "") {
        this.settings.setCamDeviceID("");
        /*} else if (
        +chosenCamID == this.currentlyActiveCam &&
        this.data.getLocalMS() != null
      ) {
        //store camera in case there is only one - to overwrite potential leftovers from previous sessions in the settings
        let devID = this.cams[+chosenCamID - 1].deviceId;
        this.settings.setCamDeviceID(devID);
        //when setting unchanged and current cam access working, do nothing
        /*this.uiMessenger.informUser(
          "No new camera selected - leaving settings unchanged"
        );*/
      } else if (
        +chosenCamID < 1 ||
        +chosenCamID > this.cams.length ||
        isNaN(+chosenCamID)
      ) {
        this.uiMessenger.warningToUser(
          "Invalid camera ID selected - plese try again / chose only valid numbers from shown list"
        );
      } else {
        //TODO: correct changes could actually be applied without restart - to investigate later whether this can be re-enabled - to be differentiated from cases where camera ID is unchanged and active
        /*this.media
          .getMediaDeviceMgr()
          .setCurrentMediaDeviceIndexCamera(+chosenCamID - 1);*/
        let devID = this.cams[+chosenCamID - 1].deviceId;
        this.settings.setCamDeviceID(devID);
        //this.media.applyMediaDeviceChange();
      }
    } catch (e) {
      this.logger.logError([
        "Error saving camera settings via conf window, ",
        e,
      ]);
    }
  };

  onUserCancel = (): void => {};
}
