import { UIHandler } from "../../frontend/UIHandler";
import { SoundEffects } from "../../media/SoundEffects";
import { SignalingCore } from "../../signaling/SignalingCore";
import { UIMessenger } from "../../utils/UIMessenger";
import { I_IncomingRaiseHandMessageHandler } from "./I_IncomingRaiseHandMessageHandler";
import { RaiseHandMessage } from "./RaiseHandMessage";

export class RaiseHand implements I_IncomingRaiseHandMessageHandler {
  private signaling: SignalingCore;
  private uiMessenger: UIMessenger;
  private uiHandler: UIHandler;
  private sound: SoundEffects;

  private raisedHands: Set<string>;

  constructor(
    _signaling: SignalingCore,
    _uiMessenger: UIMessenger,
    _uiHandler: UIHandler,
    _sound: SoundEffects
  ) {
    this.signaling = _signaling;
    this.uiMessenger = _uiMessenger;
    this.uiHandler = _uiHandler;
    this.sound = _sound;
  }

  initialize = () => {
    this.raisedHands = new Set<string>();
    this.signaling.getRaiseHandSignaling().registerIncomingMessageHandler(this);
  };

  toggleHandForPeer = (peerID: string): void => {
    let handToBeRaised = !this.raisedHands.has(peerID);
    if (handToBeRaised) {
      this.raisedHands.add(peerID);
    } else {
      this.raisedHands.delete(peerID);
    }
    this.signaling
      .getRaiseHandSignaling()
      .shareRaiseHandUpdateWithPeer(
        new RaiseHandMessage(
          this.signaling.getUniqueClientID(),
          peerID,
          handToBeRaised
        )
      );
    this.setRaiseHandStatusForPeerOnUI(peerID, handToBeRaised);
  };

  handleIncomingRaiseHandMessage = (incomingMsg: RaiseHandMessage): void => {
    if (incomingMsg.getHandRaisedValue()) {
      this.raisedHands.add(incomingMsg.getSenderID());
    } else {
      this.raisedHands.delete(incomingMsg.getSenderID());
    }
    this.setRaiseHandStatusForPeerOnUI(
      incomingMsg.getSenderID(),
      incomingMsg.getHandRaisedValue()
    );
  };

  private setRaiseHandStatusForPeerOnUI = (
    peerID: string,
    handRaisedStatus: boolean
  ) => {
    this.uiHandler.markButtonsAsPressed(
      [
        this.uiHandler
          .getAppUIHTML()
          .getSideBarHTML()
          .getPeerButtonRaiseHandAtUIPos(
            this.uiHandler
              .getAppUIHTML()
              .getSideBarHTML()
              .getUISectionForPeer(peerID)
          ),
      ],
      handRaisedStatus
    );
    if (handRaisedStatus) this.sound.playRaiseHandSound();
  };

  lowerRaisedHandThatHasBecomeObsolete = (peerID: string): void => {
    //suboptimal: simulate incoming message as simple workaround to lower a hand locally (e.g. when peer left or user speaks to peer)
    this.handleIncomingRaiseHandMessage(
      new RaiseHandMessage(peerID, null, false)
    );
  };

  isHandRaisedForPeer = (peerID: string): boolean => {
    return this.raisedHands.has(peerID);
  };
  //make hand go away when in conversation for more than 30sec
}
