import { I_IncomingChatMessageHandler } from "../../buslogic/chat/I_IncomingChatMessageHandler";
import { ChatMessage } from "../../buslogic/chat/ChatMessage";
import { I_ChatSignalingInterface } from "../../buslogic/chat/I_ChatSignalingInterface";
import { BusLogicVC } from "../../buslogic/BusLogicVC";
import { SignalingVC } from "../SignalingVC";

export class SignalingChat implements I_ChatSignalingInterface {
  private signaling: SignalingVC;
  private busLogic: BusLogicVC;

  private chatMessageHandler: I_IncomingChatMessageHandler;

  constructor() {}

  initialize = (_signaling: SignalingVC, _busLogic: BusLogicVC) => {
    this.signaling = _signaling;
    this.busLogic = _busLogic;
  };

  shareChatMessageWithinVC = (msg: string): void => {
    //local client wants to send message
    let localID: string = this.signaling.getUniqueClientID();
    let curHost: string = this.busLogic.getVCDataMgr().getVCCurrentHost();
    let chatMsg = new ChatMessage(msg, localID);

    if (this.busLogic.getVCDataMgr().localClientIsVCCurrentHost()) {
      this.distributeChatMessageWithinRoomAsHost(chatMsg);
      this.chatMessageHandler.handleIncomingChatMessage(
        chatMsg,
        this.signaling.getUniqueClientID()
      );
    } else {
      this.signaling.sendVC121Message(
        curHost,
        "121VCChatMessageForDistributionToTeam",
        {
          chatmessagesender: localID,
          chatmessage: msg,
        }
      );
    }
  };

  private distributeChatMessageWithinRoomAsHost = (chatMsg: ChatMessage) => {
    //local client is host and redistributes message to other clients
    if (!this.busLogic.getVCDataMgr().localClientIsVCCurrentHost()) {
      throw new Error(
        "Error processing chat message - non-host client tried to distribute chat message in VC"
      );
    }
    let localID: string = this.signaling.getUniqueClientID();
    let VCPs: string[] = this.busLogic.getVCDataMgr().getVCPeers();
    for (let i = 0; i < VCPs.length; i++) {
      if (VCPs[i] != localID) {
        //sender not excluded as sender should also receive message and display it on chat output
        this.signaling.sendVC121Message(VCPs[i], "121VCChatMessage", {
          chatmessagesender: chatMsg.getSenderID(),
          chatmessage: chatMsg.getMessage(),
        });
      }
    }
  };

  private processIncomingChatMessageDistributionRequestToHost = (
    sendingSocketID: string,
    chatMsg: ChatMessage
  ) => {
    let sender = chatMsg.getSenderID();
    if (!this.busLogic.getVCDataMgr().localClientIsVCCurrentHost()) {
      throw new Error(
        "Error processing chat message - non-host client received host-only request to distribute chat message in VC"
      );
    }
    if (!this.busLogic.getVCDataMgr().isPartOfVC(sendingSocketID)) {
      throw new Error(
        "Error processing chat message - originator not part of VC"
      );
    }
    if (sendingSocketID != sender)
      throw new Error(
        "Error processing chat message - message was provided with incorrect senderID"
      );
    this.distributeChatMessageWithinRoomAsHost(chatMsg);
    this.chatMessageHandler.handleIncomingChatMessage(
      chatMsg,
      this.signaling.getUniqueClientID()
    );
  };

  private processIncomingChatMessage = (
    sendingSocketID: string,
    chatMsg: ChatMessage
  ) => {
    //local client receiving chat message from someone else in room
    let curHost: string = this.busLogic.getVCDataMgr().getVCCurrentHost();
    let localID: string = this.signaling.getUniqueClientID();
    let msgSender: string = chatMsg.getSenderID();
    if (sendingSocketID != curHost)
      throw new Error(
        "Error processing chat message - host-type message was shared by non-host"
      );
    if (
      msgSender != localID &&
      !this.busLogic.getVCDataMgr().isPartOfVC(msgSender)
    ) {
      throw new Error(
        "Error processing chat message - originator not part of VC"
      );
    }
    if (this.busLogic.getVCDataMgr().localClientIsVCCurrentHost())
      throw new Error(
        "Error processing chat message - host received message type that is only intended for non-host clients"
      );
    this.chatMessageHandler.handleIncomingChatMessage(
      chatMsg,
      this.signaling.getUniqueClientID()
    );
  };

  process121VCMessageChatMessageDistributionRequestToHost(
    peerID: string,
    room: string,
    VCHostID: string,
    messageType: string,
    messageAttributes: any
  ) {
    if (messageType != "121VCChatMessageForDistributionToTeam")
      throw new Error(
        "Error processing chat message - host received wrong message type: " +
          messageType
      );
    this.processIncomingChatMessageDistributionRequestToHost(
      peerID,
      new ChatMessage(
        messageAttributes.chatmessage,
        messageAttributes.chatmessagesender
      )
    );
  }

  process121VCMessageChatMessageToClientInVC(
    peerID: string,
    room: string,
    VCHostID: string,
    messageType: string,
    messageAttributes: any
  ) {
    if (messageType != "121VCChatMessage")
      throw new Error(
        "Error processing chat message - client received wrong message type: " +
          messageType
      );
    this.processIncomingChatMessage(
      peerID,
      new ChatMessage(
        messageAttributes.chatmessage,
        messageAttributes.chatmessagesender
      )
    );
  }

  //Chat instance registers itself as recipient of any chat messages
  registerIncomingMessageHandler = (
    chatHandler: I_IncomingChatMessageHandler
  ): void => {
    this.chatMessageHandler = chatHandler;
  };
}
