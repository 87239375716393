import { Logger } from "utils/Logger";
import { I_HTMLReferenceUser } from "../I_HTMLReferenceUser";

export class ConfTeamSettingsHTML implements I_HTMLReferenceUser {
  private logger: Logger;

  private doc: Document;
  private confContentItem: HTMLDivElement;

  private teamRoomNameInputHTML: HTMLInputElement;
  private teamRoomPwdInputHTML: HTMLInputElement;

  constructor(_logger: Logger) {
    this.logger = _logger;
  }

  initialize = () => {};

  initializeUI = (_doc: Document): void => {
    this.doc = _doc;
    this.initializeHTMLReferences();
  };

  private initializeHTMLReferences = () => {
    try {
      this.confContentItem = this.doc.getElementById(
        "ConfContentTeam"
      ) as HTMLDivElement;
      this.teamRoomNameInputHTML = this.doc.getElementById(
        "confTeamRoomNameInput"
      ) as HTMLInputElement;
      this.teamRoomPwdInputHTML = this.doc.getElementById(
        "confTeamRoomPwdInput"
      ) as HTMLInputElement;
    } catch (e) {
      this.logger.logError([
        "Error obtaining HTML handles for conf window elements (team): ",
        e,
      ]);
    }
  };

  tearDownUIReferencesPriorClosure = (): void => {
    this.confContentItem = null;
    this.teamRoomNameInputHTML = null;
    this.teamRoomPwdInputHTML = null;
  };

  getTeamRoomNameInputValue = (): string => {
    return this.teamRoomNameInputHTML.value;
  };

  setTeamRoomNameInputValue = (_newValue: string): void => {
    this.teamRoomNameInputHTML.value = _newValue;
  };

  getTeamRoomPwdInputValue = (): string => {
    return this.teamRoomPwdInputHTML.value;
  };
  setTeamRoomPwdInputValue = (_newValue: string): void => {
    this.teamRoomPwdInputHTML.value = _newValue;
  };
}
