import { Helpers } from "utils/Helpers";
import { Logger } from "../../utils/Logger";
import { LabeledHTMLVideoSection } from "./LabeledHTMLVideoSection";
import { VCChatHTML } from "./VCChatHTML";
import { VCRemoteScreenShareHTML } from "./VCRemoteScreenShareHTML";
import { WebClientSideBarEnhancementsHTML } from "./WebClientSideBarEnhancementsHTML";

export class SideBarHTML {
  private logger: Logger;
  private help: Helpers;
  private chat: VCChatHTML;
  private screenShare: VCRemoteScreenShareHTML;
  private webClientSideBarEnhancements: WebClientSideBarEnhancementsHTML;

  private zooomFactor: number;

  constructor(_log: Logger, _help: Helpers) {
    this.logger = _log;
    this.help = _help;

    if (this.help.isWebApp()) {
      this.webClientSideBarEnhancements = new WebClientSideBarEnhancementsHTML(
        this.logger,
        this.help
      );
      this.chat = new VCChatHTML(this.logger, this.help);
      this.screenShare = new VCRemoteScreenShareHTML(this.logger, this.help);
    } else {
      this.chat = null;
      this.screenShare = null;
    }
  }

  initialize = () => {
    this.initializeHTMLReferences();
    if (this.help.isWebApp()) {
      this.webClientSideBarEnhancements.initialize(document);
      this.chat.initialize(document);
      this.screenShare.initialize(document);
    }
    this.logger.logLocal(["Sidebar html element initialized"]);
  };

  getVCChatHTML = (): VCChatHTML => {
    return this.chat;
  };

  getVCScreenShareHTML = (): VCRemoteScreenShareHTML => {
    return this.screenShare;
  };

  getWebClientSideBarEnhancementsHTML =
    (): WebClientSideBarEnhancementsHTML => {
      return this.webClientSideBarEnhancements;
    };

  private _contactBenchUI: HTMLDivElement = null; //reference to visav.is contact bar

  private _contactBenchTopHeadingsUI: HTMLDivElement = null; //reference to heading element at top of contact bench

  private _localVideoSectionUI: LabeledHTMLVideoSection = null; //reference to UI local video section incl. label and video element
  private _peerVideoSectionsUI = new Map<number, LabeledHTMLVideoSection>(); //map to hold reference to UI remote video sections incl. label and video element - mapped against ID used on UI (i.e. their position)

  private _peerVideoSectionsUIinUse = new Map<
    string,
    LabeledHTMLVideoSection
  >(); //map to hold reference to UI remote video sections incl. label and video element - only contains those video elements that are in use for a peer - mapped against socket.IDs of peers
  private _peersUsingVideoSectionsUI = new Map<number, string>(); //map holding peerIDs of peers currently using a video section on the UI - mapped against ID used on UI (i.e. their position)
  private _UISectionsUsedByPeers = new Map(); //map holding UI positions held by a peer (i.e. is it 1st, 2nd, 3rd peer) - mapped against their peer ID

  private _hideScreenButton: HTMLButtonElement = null; // reference to button that hides the app (to allow user accessing the screen behind the app)
  private _peerButtonsVCStart = new Map<number, HTMLButtonElement>(); //stores references to buttons on contact bench that initiatie a VC with a particular peer - key is their position on the UI (1..20)
  private _peerButtonsTalk = new Map<number, HTMLButtonElement>(); //stores references to buttons on contact bench that allow to immediately talk to a particular peer - key is their position on the UI (1..20)
  private _peerButtonsRaiseHand = new Map<number, HTMLButtonElement>(); //stores references to buttons on contact bench that allow to raise a virtual hand vs a particular peer - key is their position on the UI (1..20)
  private _peerButtonsRemoteMute = new Map<number, HTMLButtonElement>(); //stores references to buttons on contact bench that allow to request muting a remote peer - key is their position on the UI (1..20)
  private _pauseLocalButtons: HTMLCollectionOf<HTMLButtonElement> = null; //array of all local pause buttons - it does not matter which one got clicked - thus simply putting them into an array
  private _muteLocalButtons: HTMLCollectionOf<HTMLButtonElement> = null; //array of all local mute buttons - it does not matter which one got clicked - thus simply putting them into an array

  private _settingsButtonL: HTMLButtonElement = null; //overarching button to open settings dialogues / options / etc
  private _settingsButtonR: HTMLButtonElement = null; //overarching button to open settings dialogues / options / etc
  private _switchSidesButton: HTMLButtonElement = null; // button to switch visav.is bar between left and right side of screen
  private _hideOwnVideoButton: HTMLButtonElement = null; // button to switch off own video on sidebar
  private _zoomInButton: HTMLButtonElement = null; // button to zoom in on the entire UI
  private _zoomOutButton: HTMLButtonElement = null; // button to zoom out of the entire UI
  private _switchRoomsButton: HTMLButtonElement = null; // button to switch between different team rooms
  private _settingsDialogueButton: HTMLButtonElement = null; // button to open settings dialogue
  private _changeCameraButton: HTMLButtonElement = null; //button to select active camera
  private _changeMicrophoneButton: HTMLButtonElement = null; //button to select active camera
  private _testButton: HTMLButtonElement = null; //button that can be used for testing new features / making them accessible on UI of dev environment
  private _optionButtonArea: HTMLDivElement = null; //container that holds all options (=settings) buttonts that is to be displayed (or hidden) when "settings" button is clicked
  private _feedbackButton: HTMLButtonElement = null; //button to share feedback with visav.is team
  private _inviteTeamButton: HTMLButtonElement = null; //button to invite teammates to join visav.is
  private _reloadButton: HTMLButtonElement = null; //button to force reload browser
  private _pauseVISAVISButton: HTMLButtonElement = null; //button to pause access to camera and microphone to allow using other VC aps in between
  private _quitButton: HTMLButtonElement = null; //button to close app
  private _downloadWindowsAppButton: HTMLButtonElement = null; // button to download windows executable
  private _helpButton: HTMLButtonElement = null; // help button on UI - to request help on an issue
  private _VCSideBarCloseVCButton: HTMLButtonElement = null; // button that is only displayed when in audio-only VC on side-bar - to close that VC
  private _VCSideBarShareScreenButton: HTMLButtonElement = null; // button that is only displayed when in audio-only VC on side-bar - to start a screen share
  private _VCSideBarResumeRemoteScreenShareButton: HTMLButtonElement = null; // button that is only displayed when in audio-only VC on side-bar - to start a screen share
  private _VCSideBarVCToVCUIButton: HTMLButtonElement = null; // button that is only displayed when in audio-only VC on side-bar - to continue VC to dedicated window
  private _VCSideBarVCToSideBarButton: HTMLButtonElement = null; // button that is only displayed when in audio-only VC on side-bar - to continue VC to dedicated window

  initializeHTMLReferences = () => {
    this._contactBenchUI = document.getElementById(
      "mainScreen"
    ) as HTMLDivElement;

    this._contactBenchTopHeadingsUI = document.getElementById(
      "TopHeadings"
    ) as HTMLDivElement;

    //get elements for contact bench
    this._localVideoSectionUI = document.getElementById(
      "labeledContactBenchVideoLocal"
    ) as LabeledHTMLVideoSection;
    this._localVideoSectionUI.HTMLLabelElement = document.getElementById(
      "contactBenchLabelLocal"
    ) as HTMLLabelElement;
    this._localVideoSectionUI.HTMLVideoElement = document.getElementById(
      "contactBenchVideoLocal"
    ) as HTMLVideoElement;
    for (let i = 1; i <= 20; i++) {
      let labeledVideo: LabeledHTMLVideoSection = document.getElementById(
        "labeledContactBenchVideoPeer" + i
      ) as LabeledHTMLVideoSection;
      labeledVideo.numberOnUI = i;
      labeledVideo.inUse = false;
      labeledVideo.inUseByPeerID = null;
      labeledVideo.HTMLLabelElement = document.getElementById(
        "contactBenchLabelPeer" + i
      ) as HTMLLabelElement;
      labeledVideo.HTMLVideoElement = document.getElementById(
        "contactBenchVideoPeer" + i
      ) as HTMLVideoElement;
      /*let labeledVideo: LabeledHTMLVideoSection = {
            ...document.getElementById("labeledContactBenchVideoPeer" + i) as LabeledHTMLVideoSection,
            numberOnUI: i,
            inUse: false,
            inUseByPeerID: null,
            HTMLLabelElement: document.getElementById("contactBenchLabelPeer" + i) as HTMLLabelElement,
            HTMLVideoElement: document.getElementById("contactBenchVideoPeer" + i) as HTMLVideoElement
            };*/
      this._peerVideoSectionsUI.set(i, labeledVideo);
      this._peerButtonsVCStart.set(
        i,
        document.getElementById(
          "contactBenchVCButtonPeer" + i
        ) as HTMLButtonElement
      ); // also capture buttons on peer videos on contact bench
      this._peerButtonsTalk.set(
        i,
        document.getElementById(
          "contactBenchTalkButtonPeer" + i
        ) as HTMLButtonElement
      );
      this._peerButtonsRaiseHand.set(
        i,
        document.getElementById(
          "contactBenchRaiseHandButtonPeer" + i
        ) as HTMLButtonElement
      );
      this._peerButtonsRemoteMute.set(
        i,
        document.getElementById(
          "contactBenchRemoteMuteButtonPeer" + i
        ) as HTMLButtonElement
      );

      /* this is not working
            this.logger.logLocal(['adding event listeners for video resize']);
            labeledVideo.addEventListener("resize", ev => {
            let w = labeledVideo.videoWidth;
            let h = labeledVideo.videoHeight;
            this.logger.logLocal(['Event Listener on resize event of a peer html video element has been triggered - width is ';w;', height is ';h]);
            warnUser('HALLOHALLO');
    
            /*if (w && h) {
            v.style.width = w;
            v.style.height = h;
            }*
            }, false);*/
    }

    //get local pause and mute buttons
    this._pauseLocalButtons = document.getElementsByClassName(
      "pauseLocalButton"
    ) as HTMLCollectionOf<HTMLButtonElement>;
    this._muteLocalButtons = document.getElementsByClassName(
      "muteLocalButton"
    ) as HTMLCollectionOf<HTMLButtonElement>;

    //get key items re settings
    this._settingsButtonL = document.getElementById(
      "LBSettingsButtonL"
    ) as HTMLButtonElement;
    this._settingsButtonR = document.getElementById(
      "LBSettingsButtonR"
    ) as HTMLButtonElement;
    this._optionButtonArea = document.getElementById("OBA1") as HTMLDivElement;
    this._switchSidesButton = document.getElementById(
      "LBSwitchSidesButton"
    ) as HTMLButtonElement;
    this._hideOwnVideoButton = document.getElementById(
      "LBHideOwnVideoButton"
    ) as HTMLButtonElement;
    this._zoomInButton = document.getElementById(
      "LBZoomInButton"
    ) as HTMLButtonElement;
    this._zoomOutButton = document.getElementById(
      "LBZoomOutButton"
    ) as HTMLButtonElement;
    this._switchRoomsButton = document.getElementById(
      "LBSwitchRoomsButton"
    ) as HTMLButtonElement;
    this._settingsDialogueButton = document.getElementById(
      "LBSettingsDialogueButton"
    ) as HTMLButtonElement;
    this._changeCameraButton = document.getElementById(
      "LBSelectCameraButton"
    ) as HTMLButtonElement;
    this._changeMicrophoneButton = document.getElementById(
      "LBSelectMicrophoneButton"
    ) as HTMLButtonElement;
    this._testButton = document.getElementById(
      "LBTestButton"
    ) as HTMLButtonElement;

    this._hideScreenButton = document.getElementById(
      "TopHideAppButton"
    ) as HTMLButtonElement;
    this._feedbackButton = document.getElementById(
      "UBFeedbackButton"
    ) as HTMLButtonElement;
    this._inviteTeamButton = document.getElementById(
      "UBInviteTeamButton"
    ) as HTMLButtonElement;
    this._reloadButton = document.getElementById(
      "UBReloadButton"
    ) as HTMLButtonElement;
    this._quitButton = document.getElementById(
      "UBCloseButton"
    ) as HTMLButtonElement;
    this._pauseVISAVISButton = document.getElementById(
      "UBPauseButton"
    ) as HTMLButtonElement;
    this._downloadWindowsAppButton = document.getElementById(
      "UBDownloadButton"
    ) as HTMLButtonElement;
    this._helpButton = document.getElementById(
      "UBHelpButton"
    ) as HTMLButtonElement;

    //buttons that are displayed only when in VC on sidebar (not on main VC UI)
    this._VCSideBarCloseVCButton = document.getElementById(
      "contactBenchButtonLeaveVC"
    ) as HTMLButtonElement;
    this._VCSideBarShareScreenButton = document.getElementById(
      "contactBenchButtonShareScreen"
    ) as HTMLButtonElement;
    this._VCSideBarResumeRemoteScreenShareButton = document.getElementById(
      "contactBenchButtonResumeRemoteScreenShare"
    ) as HTMLButtonElement;
    this._VCSideBarVCToVCUIButton = document.getElementById(
      "contactBenchButtonVCToVCUI"
    ) as HTMLButtonElement;
    this._VCSideBarVCToSideBarButton = document.getElementById(
      "contactBenchButtonVCToSideBar"
    ) as HTMLButtonElement;
  };

  getContactBenchUI = (): HTMLDivElement => {
    return this._contactBenchUI;
  };

  getContactBenchTopHeadingsUI = (): HTMLDivElement => {
    return this._contactBenchTopHeadingsUI;
  };

  //provide data structure to access UI video sections
  getLocalVS = (): LabeledHTMLVideoSection => {
    return this._localVideoSectionUI;
  };
  getLocalHTMLVideoElement = (): HTMLVideoElement => {
    return this._localVideoSectionUI.HTMLVideoElement;
  };
  getLocalHTMLLabelElement = (): HTMLLabelElement => {
    return this._localVideoSectionUI.HTMLLabelElement;
  };

  registerUnusedPeerVS = (peerID: string) => {
    if (this._peerVideoSectionsUIinUse.size < 20) {
      let _hasBeenRegistered = false;
      this._peerVideoSectionsUI.forEach(
        (val: LabeledHTMLVideoSection, key, map) => {
          if (!val.inUse && !_hasBeenRegistered) {
            val.inUse = true;
            _hasBeenRegistered = true;
            val.inUseByPeerID = peerID;
            this._peerVideoSectionsUIinUse.set(
              peerID as string,
              val as LabeledHTMLVideoSection
            );
            this._peersUsingVideoSectionsUI.set(val.numberOnUI, peerID);
            this._UISectionsUsedByPeers.set(peerID, val.numberOnUI);
            this.logger.logLocal([
              "UI section ",
              val.numberOnUI,
              " got assigned to client ",
              peerID,
            ]);
            return val.numberOnUI;
          }
        }
      );
    } else {
      this.logger.logLocal([
        "No UI section could be assigned to peer ",
        peerID,
        " - all 20 sections are in use",
      ]);
    }
  };
  getPeerVS = (peerID: string): LabeledHTMLVideoSection => {
    return this._peerVideoSectionsUIinUse.get(peerID);
  };
  getPeerVSbyUIPos = (_uiPos: number): LabeledHTMLVideoSection => {
    return this._peerVideoSectionsUI.get(_uiPos);
  };
  getPeerHTMLVideoElement = (peerID: string): HTMLVideoElement => {
    return this._peerVideoSectionsUIinUse.get(peerID).HTMLVideoElement;
  };
  getPeerHTMLLabelElement = (peerID: string): HTMLLabelElement => {
    return this._peerVideoSectionsUIinUse.get(peerID).HTMLLabelElement;
  };
  unregisterPeerVS = (peerID: string): void => {
    this.getPeerHTMLVideoElement(peerID).srcObject = null;
    let registeredVS = this._peerVideoSectionsUIinUse.get(peerID);
    registeredVS.inUse = false;
    registeredVS.inUseByPeerID = null;
    this._peersUsingVideoSectionsUI.delete(registeredVS.numberOnUI);
    this._UISectionsUsedByPeers.delete(peerID);
    this._peerVideoSectionsUIinUse.delete(peerID);
  };
  hasRegisteredPeerVS = (peerID: string): boolean => {
    return this._peerVideoSectionsUIinUse.has(peerID);
  };
  isVSregistered = (posOnUI: number): boolean => {
    return this._peersUsingVideoSectionsUI.has(posOnUI);
  };
  getPeerIDForVS = (posOnUI: number): string => {
    return this._peersUsingVideoSectionsUI.get(posOnUI);
  };
  getUISectionForPeer = (peerID: string): number => {
    return this._UISectionsUsedByPeers.get(peerID);
  }; //get position on screen (1st, 2nd, 3rd, etc peer) based on peerID

  /*/provide access to IDs of all active peers
      getPeerIDs = (): Iterable<string> => {
        return this._peerVideoSectionsUIinUse.keys(); //returns a MapIterator
      };*/

  getHideAppButton = (): HTMLButtonElement => {
    return this._hideScreenButton;
  };

  getUIPauseLocalButtons = (): HTMLCollectionOf<HTMLButtonElement> => {
    return this._pauseLocalButtons;
  };
  getUIMuteLocalButtons = (): HTMLCollectionOf<HTMLButtonElement> => {
    return this._muteLocalButtons;
  };

  getUISettingsButtonL = (): HTMLButtonElement => {
    return this._settingsButtonL;
  };
  getUISettingsButtonR = (): HTMLButtonElement => {
    return this._settingsButtonR;
  };
  getUIOptionButtonArea = (): HTMLDivElement => {
    return this._optionButtonArea;
  };
  getSwitchSidesButton = (): HTMLButtonElement => {
    return this._switchSidesButton;
  };
  getHideOwnVideoButton = (): HTMLButtonElement => {
    return this._hideOwnVideoButton;
  };
  getUIZoomInButton = (): HTMLButtonElement => {
    return this._zoomInButton;
  };
  getUIZoomOutButton = (): HTMLButtonElement => {
    return this._zoomOutButton;
  };
  getSwitchRoomsButton = (): HTMLButtonElement => {
    return this._switchRoomsButton;
  };
  getSettingsDialogueButton = (): HTMLButtonElement => {
    return this._settingsDialogueButton;
  };
  getUIChangeCameraButton = (): HTMLButtonElement => {
    return this._changeCameraButton;
  };
  getUIChangeMicrophoneButton = (): HTMLButtonElement => {
    return this._changeMicrophoneButton;
  };
  getUITestButton = (): HTMLButtonElement => {
    return this._testButton;
  };
  getFeedbackButton = (): HTMLButtonElement => {
    return this._feedbackButton;
  };
  getInviteTeamButton = (): HTMLButtonElement => {
    return this._inviteTeamButton;
  };
  getReloadButton = (): HTMLButtonElement => {
    return this._reloadButton;
  };
  getQuitButton = (): HTMLButtonElement => {
    return this._quitButton;
  };
  getPauseVISAVISButton = (): HTMLButtonElement => {
    return this._pauseVISAVISButton;
  };
  getDownloadWindowsButton = (): HTMLButtonElement => {
    return this._downloadWindowsAppButton;
  };
  getHelpButton = (): HTMLButtonElement => {
    return this._helpButton;
  };

  getSideBarVCCloseVCButton = (): HTMLButtonElement => {
    return this._VCSideBarCloseVCButton;
  };
  getSideBarVCScreenShareButton = (): HTMLButtonElement => {
    return this._VCSideBarShareScreenButton;
  };
  getSideBarVCResumeRemoteScreenShareButton = (): HTMLButtonElement => {
    return this._VCSideBarResumeRemoteScreenShareButton;
  };
  getSideBarVCtoVCUIButton = (): HTMLButtonElement => {
    return this._VCSideBarVCToVCUIButton;
  };
  getSideBarVCtoSideBarButton = (): HTMLButtonElement => {
    return this._VCSideBarVCToSideBarButton;
  };

  getPeerButtonVCStartAtUIPos(uiPos: number): HTMLButtonElement {
    return this._peerButtonsVCStart.get(uiPos);
  }
  getPeerButtonTalkAtUIPos(uiPos: number): HTMLButtonElement {
    return this._peerButtonsTalk.get(uiPos);
  }
  getPeerButtonRaiseHandAtUIPos(uiPos: number): HTMLButtonElement {
    return this._peerButtonsRaiseHand.get(uiPos);
  }
  getPeerButtonRemoteMuteAtUIPos(uiPos: number): HTMLButtonElement {
    return this._peerButtonsRemoteMute.get(uiPos);
  }

  setZoomFactor = (_zoomFactor: number): void => {
    this.zooomFactor = _zoomFactor;
  };
  getZoomFactor = (): number => {
    return this.zooomFactor;
  };
}
