import { UIMessenger } from "../../utils/UIMessenger";
import { UIHandler } from "../../frontend/UIHandler";
import { SignalingVC } from "../../signaling/SignalingVC";
import { ChatMessage } from "./ChatMessage";
import { I_IncomingChatMessageHandler } from "./I_IncomingChatMessageHandler";
import { UserNameLabel } from "../userNameLabel/UserNameLabel";
import { SIGNALING_PURPOSE } from "data/enums/SIGNALING_PURPOSE";
import { BusLogicVC } from "buslogic/BusLogicVC";

export class Chat implements I_IncomingChatMessageHandler {
  private signaling: SignalingVC;
  private uiMessenger: UIMessenger;
  private uiHandler: UIHandler;
  private busLogic: BusLogicVC;
  private userNames: UserNameLabel;

  private outputContent: string;
  private chatWindowHasBeenManuallyHidden: boolean;

  constructor(
    _signaling: SignalingVC,
    _uiMessenger: UIMessenger,
    _uiHandler: UIHandler,
    _busLogic: BusLogicVC,
    _userNames: UserNameLabel
  ) {
    this.signaling = _signaling;
    this.uiMessenger = _uiMessenger;
    this.uiHandler = _uiHandler;
    this.busLogic = _busLogic;
    this.userNames = _userNames;
  }

  initialize = () => {
    this.outputContent = "";
    this.chatWindowHasBeenManuallyHidden = false;
    this.signaling.getChatSignaling().registerIncomingMessageHandler(this);
    this.uiHandler
      .getAppUIHTML()
      .getVCChatHTML()
      .registerChatMessageListener(this.shareChatMessage);
  };

  shareChatMessage = (msg: string): void => {
    let maxLenMsg = msg;
    if (maxLenMsg.length > 1001) {
      maxLenMsg = msg.substring(1, 1000);
      this.uiMessenger.warningToUser(
        "Note that your chat message had to be shortened to 1000 characters prior submission"
      );
    }
    this.signaling.getChatSignaling().shareChatMessageWithinVC(maxLenMsg);
    this.takeVCToEnlargedScreenIfLocalClientIsVCHostAndVCScreenNotActiveYet();
  };

  private takeVCToEnlargedScreenIfLocalClientIsVCHostAndVCScreenNotActiveYet =
    (): void => {
      if (
        this.busLogic.getVCDataMgr().localClientIsVCCurrentHost() &&
        !this.uiHandler.VCUIIsActive()
      ) {
        this.signaling.informParticipantsOfVCUpgradeToDedicatedWindowRequest(
          SIGNALING_PURPOSE.HR_VIDEO
        );
        this.busLogic.processIncomingVCTypeChange(SIGNALING_PURPOSE.HR_VIDEO);
      }
    };

  handleIncomingChatMessage = (
    chatMsg: ChatMessage,
    localUID: string
  ): void => {
    let date: Date = new Date();
    this.outputContent =
      this.outputContent +
      "<p><SMALL>" +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2);
    if (this.userNames.hasUserNameLabelForPeer(chatMsg.getSenderID())) {
      this.outputContent =
        this.outputContent +
        " (" +
        this.userNames.getUserNameLabelForPeer(chatMsg.getSenderID()) +
        ")";
    } else if (chatMsg.getSenderID() == localUID) {
      this.outputContent = this.outputContent + " (me)";
    }
    this.outputContent =
      this.outputContent +
      "</SMALL>&nbsp;&nbsp;&nbsp;" +
      chatMsg.getMessage() +
      "</p>";
    if (
      this.uiHandler
        .getAppUIHTML()
        .getVCChatHTML()
        .getChatSectionContentAreaHTML()
    ) {
      this.uiHandler
        .getAppUIHTML()
        .getVCChatHTML()
        .setChatOutput(this.outputContent);
      this.uiHandler
        .getAppUIHTML()
        .getVCChatHTML()
        .getChatSectionContentAreaHTML().scrollTop = this.uiHandler
        .getAppUIHTML()
        .getVCChatHTML()
        .getChatSectionContentAreaHTML().scrollHeight;
      this.uiHandler.indicateNewVCChatMessageToUser();
      if (!this.chatWindowHasBeenManuallyHidden)
        setTimeout(() => {
          this.uiHandler.showVCChat();
        }, 500);
    } else {
      //TODO: adequate chat integration for web client - to replace below work-around via uimessenger
      this.uiMessenger.informUserSticky(
        "Chat message received: " + chatMsg.getMessage(),
        6000
      );
    }
  };

  resetChatInstance = (): void => {
    this.outputContent = "";
    this.uiHandler.hideVCChat();
    this.chatWindowHasBeenManuallyHidden = false;
    this.uiHandler
      .getAppUIHTML()
      .getVCChatHTML()
      .setChatOutput(this.outputContent);
  };

  registerManualHideChat = (): void => {
    this.chatWindowHasBeenManuallyHidden = true;
  };

  registerManualShowChat = (): void => {
    this.chatWindowHasBeenManuallyHidden = false;
  };

  /*initializeOutput = (initMsg: string) : void => {
        this.outputContent = initMsg;
    }*/
}
