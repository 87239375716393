import { Logger } from "utils/Logger";
import { BusLogicCore } from "buslogic/BusLogicCore";
import { SIGNALING_PURPOSE } from "data/enums/SIGNALING_PURPOSE";
import { I_MessageListenerFunction121PeerMessage } from "./I_MessageListenerFunction121PeerMessage";
import { I_OneToOneSignalingProvider } from "./I_OneToOneSignalingProvider";
import { I_UserNameLabelSignalingInterface } from "buslogic/userNameLabel/I_UserNameLabelSignalingInterface";
import { I_IncomingUserNameLabelMessageHandler } from "buslogic/userNameLabel/I_IncomingUserNameLabelMessageHandler";
import { UserNameLabelMessage } from "../../buslogic/userNameLabel/UserNameLabelMessage";

export class SignalingUserNameLabel
  implements I_UserNameLabelSignalingInterface
{
  private logger: Logger;
  private signaling: I_OneToOneSignalingProvider;
  private busLogic: BusLogicCore;

  private userNameLabelMessageHandler: I_IncomingUserNameLabelMessageHandler;

  constructor(_logger: Logger) {
    this.logger = _logger;
  }

  initialize = (_signaling: I_OneToOneSignalingProvider) => {
    this.signaling = _signaling;
    this.signaling.register121MessageListener(
      "121UserNameLabelMessage",
      this.process121MessageUserNameLabelMessageToLocalClient
    );
  };

  setBusLogicReference(_busLogic: BusLogicCore) {
    this.busLogic = _busLogic;
  }

  shareUserNameLabelWithPeer = (
    userNameLabelMsg: UserNameLabelMessage
  ): void => {
    //local client wants to send message
    let localID: string = this.signaling.getUniqueClientID();
    //let raiseHandMsg = new RaiseHandMessage(localID, recipientID, handRaised);
    if (userNameLabelMsg.getSenderID() == null)
      throw new Error(
        "Error processing user name label message - no sender provided (null received)"
      );
    if (userNameLabelMsg.getSenderID() != localID)
      throw new Error(
        "Error processing user name label message - invalid sender information"
      );

    this.signaling.send121Message(
      userNameLabelMsg.getRecipientID(),
      this.signaling.getMainRoom(),
      {
        type: "121UserNameLabelMessage",
        userNameLabelSender: userNameLabelMsg.getSenderID(),
        userNameLabelRecipient: userNameLabelMsg.getRecipientID(),
        userNameLabel: userNameLabelMsg.getUserNameLabel(),
      }
    );
  };

  private process121MessageUserNameLabelMessageToLocalClient: I_MessageListenerFunction121PeerMessage =
    (
      senderID: string,
      room: string,
      message: any,
      PCSigP: SIGNALING_PURPOSE
    ): void => {
      if (message.type != "121UserNameLabelMessage")
        throw new Error(
          "Error processing user name label message - client received wrong message type: " +
            message.messageType
        );
      let localID: string = this.signaling.getUniqueClientID();
      if (senderID != message.userNameLabelSender)
        throw new Error(
          "Error processing user name label message - incorrect sender information"
        );
      if (localID != message.userNameLabelRecipient)
        throw new Error(
          "Error processing user name label message - incorrect recipient information"
        );
      if (!this.busLogic.peerConnectionExists(senderID)) {
        this.logger.logWarning([
          "User name label message ignored as sender is no longer part of room: ",
          senderID,
        ]);
        return;
      }
      this.userNameLabelMessageHandler.handleIncomingUserNameLabelMessage(
        new UserNameLabelMessage(
          message.userNameLabelSender,
          message.userNameLabelRecipient,
          message.userNameLabel
        )
      );
    };

  //UserNameLabel instance registers itself as recipient of any chat messages
  registerIncomingMessageHandler = (
    userNameLabelHandler: I_IncomingUserNameLabelMessageHandler
  ): void => {
    this.userNameLabelMessageHandler = userNameLabelHandler;
  };
}
