import { I_ConfigElement } from "configMgmt/I_ConfigElement";
import { Logger } from "utils/Logger";
import { I_HTMLReferenceUser } from "../I_HTMLReferenceUser";

export class ConfUserSettingsHTML implements I_HTMLReferenceUser {
  private logger: Logger;

  private doc: Document;
  private confContentItem: HTMLDivElement;

  private userLabelInputHTML: HTMLInputElement;
  private userEmailInputHTML: HTMLInputElement;

  constructor(_logger: Logger) {
    this.logger = _logger;
  }

  initialize = () => {};

  initializeUI = (_doc: Document): void => {
    this.doc = _doc;
    this.initializeHTMLReferences();
  };

  private initializeHTMLReferences = () => {
    try {
      this.confContentItem = this.doc.getElementById(
        "ConfContentUser"
      ) as HTMLDivElement;
      this.userLabelInputHTML = this.doc.getElementById(
        "confUserLabelInput"
      ) as HTMLInputElement;
      this.userEmailInputHTML = this.doc.getElementById(
        "confUserEmailInput"
      ) as HTMLInputElement;
    } catch (e) {
      this.logger.logError([
        "Error obtaining HTML handles for conf window elements (user): ",
        e,
      ]);
    }
  };

  tearDownUIReferencesPriorClosure = (): void => {
    this.confContentItem = null;
    this.userLabelInputHTML = null;
    this.userEmailInputHTML = null;
  };

  getUserLabelInputValue = (): string => {
    return this.userLabelInputHTML.value;
  };

  setUserLabelInputValue = (_newValue: string): void => {
    this.userLabelInputHTML.value = _newValue;
  };

  getUserEmailInputElement = (): string => {
    return this.userEmailInputHTML.value;
  };
  setUserEmailInputElement = (_newValue: string): void => {
    this.userEmailInputHTML.value = _newValue;
  };
}
