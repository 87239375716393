import { Logger } from "../../utils/Logger";
import { Helpers } from "../../utils/Helpers";
import { SidebarWindowNWJS } from "./SidebarWindowNWJS";
import { ScreenDevice } from "../../data/ScreenDevice";

export class MultiScreenHandler {
  private help: Helpers;
  private logger: Logger;
  private sideBarWindow: SidebarWindowNWJS;

  constructor(_help: Helpers, _logger: Logger) {
    this.help = _help;
    this.logger = _logger;
  }

  initialize = (_sidebarWindow: SidebarWindowNWJS) => {
    this.sideBarWindow = _sidebarWindow;
    //@ts-ignore
    nw.Screen.Init(); //initialize for multi-screen handling
  };

  getXOffsetInHorizontalMultiScreenEnvironment = (currentX: number): number => {
    let xOffset: number = 0;
    if (this.help.appIsActive()) {
      try {
        //@ts-ignore
        let screens: nw.screen[] = nw.Screen.screens;
        let numberOfScreens: number = screens.length;
        for (let i = 0; i < numberOfScreens; i++) {
          let nextScreenOffset = screens[i]["work_area"].x;
          let nextScreenEnd =
            screens[i]["work_area"].x + screens[i]["work_area"].width;
          if (currentX >= nextScreenOffset && currentX <= nextScreenEnd)
            xOffset = nextScreenOffset;
        }
        //this.logger.logWarning(["detected the following screens: ", screens]);
      } catch (e) {
        this.logger.logError([
          "error determining vertical offset for multi-screen environments, ",
          e,
        ]);
      }
    }
    //this.logger.logWarning(["returning multi-screen x-offset: ", xOffset]);
    return Math.ceil(xOffset);
  };

  getCurrentScreenOfMainApp = (): ScreenDevice => {
    let currentX = this.sideBarWindow.getSidebarNWJSWindowReference().x;
    let currentY = this.sideBarWindow.getSidebarNWJSWindowReference().y;
    let xOffset: number = 0;
    let yOffset: number = 0;
    let screenW: number = 0;
    let screenH: number = 0;
    if (this.help.appIsActive()) {
      try {
        //@ts-ignore
        let screens: nw.screen[] = nw.Screen.screens;
        let numberOfScreens: number = screens.length;
        for (let i = 0; i < numberOfScreens; i++) {
          let nextScreenOffsetX = screens[i]["work_area"].x;
          let nextScreenEndX =
            screens[i]["work_area"].x + screens[i]["work_area"].width;
          if (currentX >= nextScreenOffsetX && currentX <= nextScreenEndX) {
            xOffset = nextScreenOffsetX;
            screenW = screens[i]["work_area"].width;
          }
          let nextScreenOffsetY = screens[i]["work_area"].y;
          let nextScreenEndY =
            screens[i]["work_area"].y + screens[i]["work_area"].height;
          if (currentY >= nextScreenOffsetY && currentY <= nextScreenEndY) {
            yOffset = nextScreenOffsetY;
            screenH = screens[i]["work_area"].height;
          }
        }
        return new ScreenDevice(xOffset, yOffset, screenW, screenH);
      } catch (e) {
        this.logger.logError([
          "error determining parameters of current screen for handling multi-screen environments, ",
          e,
        ]);
      }
    }
    return null;
  };
}
