import { UIMessenger } from "../../utils/UIMessenger";
import { APP_SCREEN_SIDE } from "../../data/enums/APP_SCREEN_SIDE";
import { Logger } from "../../utils/Logger";
import { SideBarHTML } from "./SideBarHTML";
import { VCScreenHTML } from "./VCScreenHTML";
import { BUTTON_SECTION } from "../../data/enums/BUTTON_SECTION";
import { BUTTON_OBJECTIVE } from "../../data/enums/BUTTON_OBJECTIVE";
import { UINwjsHandler } from "../nwjs/UINwjsHandler";
import { Config } from "../../config/Config";
import { ConfigHTML } from "./ConfigHTML";
import { I_UITechHandler } from "frontend/decoupler/I_UITechHandler";
import { Helpers } from "utils/Helpers";
import { VCChatHTML } from "./VCChatHTML";
import { VCRemoteScreenShareHTML } from "./VCRemoteScreenShareHTML";

export class AppUIHTML {
  private config: Config;
  private logger: Logger;
  private help: Helpers;
  private uiMessenger: UIMessenger;
  private uiTechHandler: I_UITechHandler;

  private sidebar: SideBarHTML;
  private VCScreen: VCScreenHTML;
  private confScreen: ConfigHTML;

  constructor(
    _config: Config,
    _log: Logger,
    _help: Helpers,
    _uiM: UIMessenger,
    _nwjsH: I_UITechHandler
  ) {
    this.config = _config;
    this.logger = _log;
    this.help = _help;
    this.uiMessenger = _uiM;
    this.uiTechHandler = _nwjsH;

    this.sidebar = new SideBarHTML(this.logger, this.help);
    this.confScreen = new ConfigHTML(
      this.config,
      this.logger,
      this.uiTechHandler
    );
    this.VCScreen = new VCScreenHTML(
      this.config,
      this.logger,
      this.help,
      this.uiTechHandler
    );

    //this.initializeHTMLReferences();
    //this.refreshAppScreenSide();
  }

  _appScreenSide: APP_SCREEN_SIDE | null;

  private _entireApp: HTMLDivElement = null; //reference to overarching HTML div encompassing the entire app as part of the HTML page body
  private _entireAppVC: HTMLDivElement | null = null; //reference to overarching HTML div encompassing the entire app as part of the HTML page body - this is the copy of the element inside the VC screen html - null if not present

  //audio elements playing join / leave sound when peer joins / leaves VC
  private _audioElementJoin: HTMLAudioElement = null; // reference to html audio element that plays 'join' sound when peer joins VC
  private _audioElementLeave: HTMLAudioElement = null; // reference to html audio element that plays 'leave' sound when peer leaves VC
  private _audioElementRaiseHand: HTMLAudioElement = null; // reference to html audio element that plays 'raise hand' sound

  initialize = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.initializeHTMLReferences();
      this.sidebar.initialize();
      this.confScreen.initialize();

      this.logger.logLocal([
        "App ui html elements for sidebar and conf screen initialized",
      ]);

      if (this.help.appIsActive()) {
        this.VCScreen.initialize(this.setEntireAppReferenceForDedicatedVCScreen)
          .catch((e) => {
            this.logger.logError([
              "Error initializing AppUI VCScreen references, ",
              e,
            ]);
          })
          .then(() => {
            this.logger.logLocal([
              "Completed procedure to initialize VCScreen HTML references",
            ]);
          })
          .finally(() => {
            this.refreshAppScreenSide();
            this.logger.logLocal([
              "Closing procedure to initialize VCScreen HTML References",
            ]);
            resolve();
            return;
          });
      } else if (this.help.isWebApp()) {
        this.logger.logLocal([
          "Skipping the initilaization of VC screen as on web app",
        ]);
        resolve();
        return;
      }
      /* as this code is outside the promise chain, it is called immediately after initiating the async promise chain above
      this.logger.logError([
        "Code to initialize AppUI HTML References reaches point that it should never reach",
      ]);
      reject(false);
      return;
      */
    });

    /*    return new Promise((resolve, reject) => {
      this.initializeHTMLReferences()
        .then((x) => {
          this.logger.logLocal(["Completed procedure to initialize AppUI"]);
        })
        .catch((e) => {
          this.logger.logError(["Error initializing AppUI, ", e]);
        })
        .finally(() => {
          this.logger.logLocal(["Closing procedure to initialize App UI"]);
          this.refreshAppScreenSide();
          resolve(true);
        });
      /* as this code is outside the promise chain, it is called immediately after initiating the async promise chain above
      this.logger.logError([
        "Code to initialize AppUI reaches point that it should never reach",
      ]);
      reject(false);
      return;*/
    /*});*/
  };

  initializeHTMLReferences = () => {
    this._entireApp = document.getElementById("entireApp") as HTMLDivElement;

    this._audioElementJoin = document.getElementById(
      "soundEffectJoinVC"
    ) as HTMLAudioElement;
    this._audioElementLeave = document.getElementById(
      "soundEffectLeaveVC"
    ) as HTMLAudioElement;
    this._audioElementRaiseHand = document.getElementById(
      "soundEffectRaiseHand"
    ) as HTMLAudioElement;
  };

  private setEntireAppReferenceForDedicatedVCScreen = (
    _entAppVC: HTMLDivElement
  ) => {
    this._entireAppVC = _entAppVC;
  };

  getEntireApp = (): HTMLDivElement => {
    return this._entireApp;
  };

  getEntireAppElementOfVCScreen = (): HTMLDivElement => {
    return this._entireAppVC;
  };

  getSideBarHTML = (): SideBarHTML => {
    return this.sidebar;
  };

  getConfHTML = (): ConfigHTML => {
    return this.confScreen;
  };

  getVCScreenHTML = (): VCScreenHTML => {
    return this.VCScreen;
  };

  getVCChatHTML = (): VCChatHTML => {
    if (this.help.appIsActive()) return this.getVCScreenHTML().getVCChatHTML();
    else if (this.help.isWebApp()) return this.getSideBarHTML().getVCChatHTML();
  };

  getVCScreenShareHTML = (): VCRemoteScreenShareHTML => {
    if (this.help.appIsActive())
      return this.getVCScreenHTML().getVCScreenShareHTML();
    else if (this.help.isWebApp())
      return this.getSideBarHTML().getVCScreenShareHTML();
    else return null;
  };

  refreshAppScreenSide = (): void => {
    if (this.getEntireApp().classList.contains("rightScreenSide")) {
      this._appScreenSide = APP_SCREEN_SIDE.RIGHT;
    } else if (this.getEntireApp().classList.contains("leftScreenSide")) {
      this._appScreenSide = APP_SCREEN_SIDE.LEFT;
    } else this._appScreenSide = null;
    this.uiMessenger.updateAppScreenSide(this._appScreenSide);
  };

  isAppOnRightScreenSide = (): boolean => {
    return this._appScreenSide == APP_SCREEN_SIDE.RIGHT;
  };
  isAppOnLeftScreenSide = (): boolean => {
    return this._appScreenSide == APP_SCREEN_SIDE.LEFT;
  };
  getAppScreenSide = (): APP_SCREEN_SIDE => {
    return this._appScreenSide;
  };

  getUIPeerButtonByPos = (
    UISection: BUTTON_SECTION,
    UIPosition: number,
    objective: BUTTON_OBJECTIVE
  ): HTMLButtonElement => {
    if (UISection === BUTTON_SECTION.CONTACT_BENCH) {
      // button on contact bench UI
      if (Number.isInteger(UIPosition) && UIPosition > 0 && UIPosition <= 20) {
        // buttons for peer user
        if (objective === BUTTON_OBJECTIVE.START_VC)
          return this.sidebar.getPeerButtonVCStartAtUIPos(UIPosition);
        else if (objective === BUTTON_OBJECTIVE.TALK)
          return this.sidebar.getPeerButtonTalkAtUIPos(UIPosition);
        else if (objective === BUTTON_OBJECTIVE.RAISE_HAND)
          return this.sidebar.getPeerButtonRaiseHandAtUIPos(UIPosition);
        else if (objective === BUTTON_OBJECTIVE.REMOTE_MUTE)
          return this.sidebar.getPeerButtonRemoteMuteAtUIPos(UIPosition);
        //other buttons TODO
      } else {
        throw "Error retrieving button for invalid UIPosition=" + UIPosition;
      }
    } else if (UISection === BUTTON_SECTION.VIDEO_CONF) {
      //button on video conf UI
      if (Number.isInteger(UIPosition) && UIPosition > 0 && UIPosition <= 20) {
        if (objective === BUTTON_OBJECTIVE.FULL_SCREEN_VC_VIDEO)
          return this.VCScreen.getVideoFullScreenButtonAtUIPos(UIPosition);
        else if (objective === BUTTON_OBJECTIVE.REMOTE_MUTE)
          return this.VCScreen.getPeerButtonRemoteMuteAtUIPos(UIPosition);

        //other buttons TODO
      } else {
        throw "Error retrieving button for invalid UIPosition=" + UIPosition;
      }
    } else {
      throw "Error retrieving button for unknown UI section= " + UISection;
    }
    throw (
      "Error retrieving button for following parameters= " +
      UISection +
      ", " +
      UIPosition +
      ", " +
      objective
    ); //thow error if button not found
  };

  getAudioElementJoinVC = (): HTMLAudioElement => {
    return this._audioElementJoin;
  };
  getAudioElementLeaveVC = (): HTMLAudioElement => {
    return this._audioElementLeave;
  };
  getAudioElementRaiseHand = (): HTMLAudioElement => {
    return this._audioElementRaiseHand;
  };

  markVideoSectionsAsPartOfActiveVCOnBothSidebarAndVCWindow = (
    peerID: string,
    inVC: boolean
  ): void => {
    if (inVC) {
      if (this.getSideBarHTML().hasRegisteredPeerVS(peerID))
        this.getSideBarHTML().getPeerVS(peerID).classList.add("inVC");
      if (
        this.help.appIsActive() &&
        this.getVCScreenHTML().hasRegisteredPeerVSVC(peerID)
      )
        this.getVCScreenHTML().getPeerVSVC(peerID).classList.add("inVC");
    } else {
      if (this.getSideBarHTML().hasRegisteredPeerVS(peerID))
        this.getSideBarHTML().getPeerVS(peerID).classList.remove("inVC");
      if (
        this.help.appIsActive() &&
        this.getVCScreenHTML().hasRegisteredPeerVSVC(peerID)
      )
        this.getVCScreenHTML().getPeerVSVC(peerID).classList.remove("inVC");
    }
  };
}
