import { AppUIHTML } from "../frontend/html/AppUIHTML";
import { BusLogicVC } from "../buslogic/BusLogicVC";
import { UIHandler } from "../frontend/UIHandler";
import { Logger } from "../utils/Logger";
import { SIGNALING_PURPOSE } from "./enums/SIGNALING_PURPOSE";
import { SocketData } from "./SocketData";
import { SignalingVC } from "../signaling/SignalingVC";

export class VCDataManager {
  private _VCMaxUserLimit: number = null; //defines max number of users that can join a VC - currently limited to max of 5 to account for limitations of P2P architecture
  private _timeLimitForAutoUnregisterOfPendingRequests: number = null; // incoming or outgoing merge requests as well as simple local connection requests get auto-unregistered after a certain period of time to unblock the client in case of malfunctions / to not keep those peers and the local UI blocked forever

  private logger: Logger;
  //private socketD: SocketData;
  private signaling: SignalingVC;
  private appUI: AppUIHTML;
  private busLogic: BusLogicVC;
  private uiHandler: UIHandler;

  private _VCPeers: string[]; //array holding peer IDs of all peers currently connected to the local client in a video conference (only one concurrent conference possible at this point in time)
  private _VCConfirmedJoiner: string[]; //array holding peer IDs of all peers that sent the confirmation that they are joining the VC, but for which no VC-type media stream has been received yet
  private _VCActiveOnLocalClient; // flag whether local client is currently part of a VC (only one concurrent conference possible at this point in time)
  private _VCCurrentHost: string; // peer ID of the client that is currently the host of the VC which the local client is part of (if any)
  private _VCCurrentCoHost: string; // peer ID of the client that serves as backup to the host - the coHost is entitled to announce a new host in the VC, should the host have dropped off without doing so (e.g. tech problems)
  private _VCOpenInvites: Map<string, string> = null; // if local client is the host of the current VC, the map holds all clients to which invites have been sent (but not responded yet) - key is invitee - value is person who suggested invite (or host in case invite is host-initiated)
  private _VCOpenInvitesAutoDeleteIDs: Map<string, number> = null; //additional map in support of the above that stores the peerID as key and a random number as value - the random number is created upon registration - when an auto-unregister process looks at the value few seconds later, it checks whether the registration number is still the same and then unregisters the item / leaves it intact if the number is a new one
  private _VCCurrentType: SIGNALING_PURPOSE; // stores mediastream purpose reflective of video type / resolution used by local client - primary distinction is high/med for display in separate VC window and low resolution for display on video bar only

  private _authorizationsToJoinOrMergeOtherActiveVCs: Set<string> = null; //data structure that captures whether user has tried to connect to a peer in the last x seconds and received the feedback that this peer is already in another VC, but current user could join this VC or merge with its own VC if it clicked again within x seconds. As long as the respective peer is captured in this data structure, clicking the button again will cause join/merge of active VC. User automatically gets removed after x seconds. data structure captures peerID of peer with whom join/merge is pre-authorized.
  //private _joinOrMergeInProgress: boolean = false; // flag that indicates whether local client is processing a request to join or merge with another VC (either direction) - consideration is to use this as a timing-based flag resetting itself to false after 5sec
  //private _peerConnectionsInProgress: Set<string> = new Set<string>(); // data structure that captures all peers of local client with whom there is currently an ongoing effort to establish a connection - when there is a corresponding item for the peer contained in this data structure, clicking things on the UI should not trigger additional connection attempts

  private _outgoingMerge_originator: string; //peerID in local VC that requested an outgoing merge
  private _outgoingMerge_otherActiveHost: string; //peerID of host of other active VC
  private _outgoingMerge_peersToMerge: string[]; // array of peerIDs from current VC to be merged into other active VC
  private _outgoingMerge_autoDeleteID: number; // ID that is set to a random number upon time of registration - if number is still the same after couple of seconds, outgoing merge info is auto-reset to null
  private _incomingMerge_PeersToMerge: Map<string, string[]> = null; //map that holds all incoming merge requests while these are in pre-authorized state - key is the peerID of the incoming VCs Host, value is an array of peers to be merged
  private _incomingMerge_PeersToMergeAutoDeleteIDs: Map<string, number> = null; //map that holds all incoming host as key and random number as value - random number is created at time of registration and serves as identifier for auto-unregister function to see whether entry is still the same or was renewed in the interim - this allows to clear entries after a couple of seconds if still there

  constructor(
    _log: Logger,
    _signaling: SignalingVC,
    //_sockD: SocketData,
    _uiHandler: UIHandler,
    _busLogic: BusLogicVC
  ) {
    this.logger = _log;
    this.signaling = _signaling;
    //this.socketD = _sockD;
    this.uiHandler = _uiHandler;
    this.appUI = this.uiHandler.getAppUIHTML();
    this.busLogic = _busLogic;

    this._VCMaxUserLimit = 8;
    this._timeLimitForAutoUnregisterOfPendingRequests = 7000; //pending connections to be dropped after 7 seconds
  }

  initialize = () => {
    this._authorizationsToJoinOrMergeOtherActiveVCs = new Set<string>();
    this._outgoingMerge_originator = null;
    this._outgoingMerge_otherActiveHost = null;
    this._outgoingMerge_peersToMerge = null;
    this._outgoingMerge_autoDeleteID = null;
    this.resetVC();
  };

  private resetVC = () => {
    this._VCPeers = [];
    this._VCConfirmedJoiner = [];
    this._VCActiveOnLocalClient = false;
    this._VCCurrentHost = null;
    this._VCCurrentCoHost = null;
    if (this._VCOpenInvites == null)
      this._VCOpenInvites = new Map<string, string>();
    else this._VCOpenInvites.clear();
    if (this._VCOpenInvitesAutoDeleteIDs == null)
      this._VCOpenInvitesAutoDeleteIDs = new Map<string, number>();
    else this._VCOpenInvitesAutoDeleteIDs.clear();
    this._VCCurrentType = null;

    if (this._incomingMerge_PeersToMerge == null)
      this._incomingMerge_PeersToMerge = new Map<string, string[]>();
    else this._incomingMerge_PeersToMerge.clear();
    if (this._incomingMerge_PeersToMergeAutoDeleteIDs == null)
      this._incomingMerge_PeersToMergeAutoDeleteIDs = new Map<string, number>();
    else this._incomingMerge_PeersToMergeAutoDeleteIDs.clear();
  };

  registerVCPeer = (peerID: string): void => {
    this._VCPeers.push(peerID);
  };

  unregisterVCPeer = (peerID: string): void => {
    this.logger.logLocal(["unregistering peer ", peerID, " from VC"]);
    let posOfPeerToBeRemoved = this._VCPeers.indexOf(peerID);
    if (posOfPeerToBeRemoved >= 0) {
      this._VCPeers.splice(posOfPeerToBeRemoved, 1);
    } //remove peer from VCPeers
  };

  getVCPeers = (): string[] => {
    return this._VCPeers;
  };

  resetVCPeers = (): void => {
    this._VCPeers = [];
  };

  registerConfirmedVCJoiner = (peerID: string): void => {
    this._VCConfirmedJoiner.push(peerID);
  };

  unregisterConfirmedVCJoiner = (peerID: string): void => {
    this.logger.logLocal([
      "unregistering peer ",
      peerID,
      " from list of confirmed VC joiners",
    ]);
    let posOfPeerToBeRemoved = this._VCConfirmedJoiner.indexOf(peerID);
    if (posOfPeerToBeRemoved >= 0) {
      this._VCConfirmedJoiner.splice(posOfPeerToBeRemoved, 1);
    } //remove peer from VCPeers
  };

  getConfirmedVCJoiners = (): string[] => {
    return this._VCConfirmedJoiner;
  };

  resetVCJoiners = (): void => {
    this._VCConfirmedJoiner = [];
  };

  isVCOpen = (): boolean => {
    return this._VCActiveOnLocalClient;
  };

  setVCOpen = (isOpen: boolean): void => {
    if (isOpen) this.resetVC();
    this._VCActiveOnLocalClient = isOpen;
    if (isOpen) {
      // when opening a VC wait for a short moment before changing the UI - this prevents effects of the UI jumping when VC cannot be established
      setTimeout(() => {
        if (this._VCActiveOnLocalClient) {
          this.uiHandler.setVCActive(isOpen);
        }
      }, 1000);
    } else this.uiHandler.setVCActive(isOpen);
  };

  isPartOfVC = (peerID: string): boolean => {
    return this.getVCPeers().includes(peerID);
  };

  getVCCurrentHost = (): string => {
    return this._VCCurrentHost;
  };

  setVCCurrentHost = (newHost: string): void => {
    this._VCCurrentHost = newHost;
  };

  determineNextVCCoHost = (
    oldHostToBeExcluded: string,
    newHostToBeExcluded: string = null
  ): string => {
    let smallestIDThatIsNotOldOrNewHost = null;
    for (let i = 0; i < this._VCPeers.length; i++) {
      let curPeer = this._VCPeers[i];
      if (
        curPeer != oldHostToBeExcluded &&
        curPeer != newHostToBeExcluded &&
        (smallestIDThatIsNotOldOrNewHost == null ||
          curPeer < smallestIDThatIsNotOldOrNewHost)
      ) {
        smallestIDThatIsNotOldOrNewHost = curPeer;
      }
    }
    return smallestIDThatIsNotOldOrNewHost;
  };

  localClientIsVCCurrentHost = (): boolean => {
    return this.getVCCurrentHost() === this.signaling.getUniqueClientID();
  };

  localClientIsVCCurrentCoHost = (): boolean => {
    return this.getVCCurrentCoHost() === this.signaling.getUniqueClientID();
  };

  getVCCurrentCoHost = (): string => {
    return this._VCCurrentCoHost;
  };

  setVCCurrentCoHost = (newCoHost: string): void => {
    this._VCCurrentCoHost = newCoHost;
  };

  getVCCurrentType = (): SIGNALING_PURPOSE => {
    return this._VCCurrentType;
  };

  setVCCurrentType = (newType: SIGNALING_PURPOSE): void => {
    this._VCCurrentType = newType;
  };

  registerPendingVCInvite = (invitee: string, originator: string): void => {
    if (this.isVCInvitePending(invitee)) {
      this.logger.logError([
        "Host registering more than one pending invite for peer ",
        invitee,
        " at the same time. While previous invite was originated by ",
        this.getPendingVCInviteOriginator(invitee),
        ", new invite was originated by ",
        originator,
      ]);
    }
    this._VCOpenInvites.set(invitee, originator);
    let _uniqueRegistrationID = Math.random();
    this._VCOpenInvitesAutoDeleteIDs.set(invitee, _uniqueRegistrationID);
    setTimeout(() => {
      if (
        this.isVCInvitePending(invitee) &&
        this._VCOpenInvitesAutoDeleteIDs.get(invitee) == _uniqueRegistrationID
      ) {
        this.unregisterPendingVCInvite(invitee);
        this._VCOpenInvitesAutoDeleteIDs.delete(invitee);
        this.logger.logWarning([
          "local client auto-unregistering pending vc invite for peer ",
          invitee,
          " that did not get closed via default procedures within 7 seconds",
        ]);
        this.busLogic.closeVCIfEmptyAndNothingPending();
      }
    }, this.getTimeLimitForAutoUnregisterOfPendingRequests());
  };

  resetPendingVCInvites = (): void => {
    this._VCOpenInvites.clear();
    this._VCOpenInvitesAutoDeleteIDs.clear();
  };

  unregisterPendingVCInvite = (invitee: string): void => {
    if (this.isVCInvitePending(invitee)) {
      this._VCOpenInvites.delete(invitee);
    } else {
      this.logger.logWarning([
        "trying to unregister pending VC invite for peer ",
        invitee,
        ", but no such invite pending",
      ]);
    }
  };

  isVCInvitePending = (invitee: string): boolean => {
    return this._VCOpenInvites.has(invitee);
  };

  getPendingVCInviteOriginator = (invitee: string): string => {
    return this._VCOpenInvites.get(invitee);
  };

  getNumberOfPendingVCInvites = (): number => {
    return this._VCOpenInvites.size;
  };

  /*registerJoinOrMergeRequest() {
    if (!(this.isJoinOrMergeInProgress())) this._joinOrMergeInProgress=true;
    setTimeout(function() { this._joinOrMergeInProgress = false, };5000);
    };
    isJoinOrMergeInProgress() { return this._joinOrMergeInProgress;};*/

  /*registerConnectionInProgress(_peerID) { 
    if (this.isVCConnectionInProgress(_peerID)) { this.logger.logError(['Trying to register a peer more than once as "connection in progress" - should only occur once / block all subsequent requests >> peer= ', _peerID]);
    } else {  this._peerConnectionsInProgress.add(_peerID), }
    };
    unregisterConnectionInProgress(_peerID) { this._peerConnectionsInProgress.delete(_peerID);};
    isVCConnectionInProgress (_peerID) { return this._peerConnectionsInProgress.has(invitee);};
    resetConnectionsInProgress() { this._peerConnectionsInProgress.clear();};*/

  registerOutgoingMerge = (
    _requestOriginator: string,
    _otherActiveHost: string,
    _peersToMerge: string[]
  ): void => {
    if (this._outgoingMerge_originator == null) {
      this._outgoingMerge_originator = _requestOriginator;
      this._outgoingMerge_otherActiveHost = _otherActiveHost;
      this._outgoingMerge_peersToMerge = _peersToMerge;
      let _uniqueRegistrationID = Math.random();
      this._outgoingMerge_autoDeleteID = _uniqueRegistrationID;
      setTimeout(() => {
        if (
          this.isOutgoingMergeOngoing() &&
          this._outgoingMerge_autoDeleteID == _uniqueRegistrationID
        ) {
          this.unregisterOutgoingMerge(true);
          this.logger.logWarning([
            "local client auto-unregistering outgoing merge for active VC host ",
            _otherActiveHost,
            " that did not get closed via default procedures within 7 seconds",
          ]);
          this.busLogic.closeVCIfEmptyAndNothingPending();
        }
      }, this.getTimeLimitForAutoUnregisterOfPendingRequests());
    } else {
      this.logger.logError([
        "additional outgoing merge encountered while only one may be registered - already registered as originator / active host / peersToMerge= ",
        this._outgoingMerge_originator,
        " / ",
        this._outgoingMerge_otherActiveHost,
        " / ",
        this._outgoingMerge_peersToMerge,
        " - active host as per new request is ",
        _otherActiveHost,
        " - dropping additional request",
      ]);
    }
  };

  isOutgoingMergeOngoing = (): boolean => {
    return this._outgoingMerge_otherActiveHost != null;
  };

  isOutgoingMergeOngoingForPeer = (_otherActiveHost: string): boolean => {
    return (
      this._outgoingMerge_otherActiveHost != null &&
      this._outgoingMerge_otherActiveHost == _otherActiveHost
    );
  };

  unregisterOutgoingMerge = (warnIfNoneRegistered: boolean = false): void => {
    if (warnIfNoneRegistered && this._outgoingMerge_originator == null)
      this.logger.logLocal([
        "local client tried to unregister outgoing merge, but none was registered",
      ]);
    this._outgoingMerge_originator = null;
    this._outgoingMerge_otherActiveHost = null;
    this._outgoingMerge_peersToMerge = null;
    this._outgoingMerge_autoDeleteID = null;
  };

  getInviteOriginatorForOutoingMerge = (): string => {
    return this._outgoingMerge_originator;
  };

  getActiveHostOfOutgoingMerge = (): string => {
    return this._outgoingMerge_otherActiveHost;
  };

  registerIncomingMerge = (_incomingHost: string, _peersToMerge: string[]) => {
    if (!this._incomingMerge_PeersToMerge.has(_incomingHost)) {
      this._incomingMerge_PeersToMerge.set(_incomingHost, _peersToMerge);
      let _uniqueRegistrationID = Math.random();
      this._incomingMerge_PeersToMergeAutoDeleteIDs.set(
        _incomingHost,
        _uniqueRegistrationID
      );
      setTimeout(() => {
        if (
          this.isIncomingMergeOngoingForPeer(_incomingHost) &&
          this._incomingMerge_PeersToMergeAutoDeleteIDs.get(_incomingHost) ==
            _uniqueRegistrationID
        ) {
          this.unregisterIncomingMerge(_incomingHost);
          this._incomingMerge_PeersToMergeAutoDeleteIDs.delete(_incomingHost);
          this.logger.logWarning([
            "local client auto-unregistering incoming merge for incoming VC host ",
            _incomingHost,
            " that did not get closed via default procedures within 7 seconds",
          ]);
          this.busLogic.closeVCIfEmptyAndNothingPending();
        }
      }, this.getTimeLimitForAutoUnregisterOfPendingRequests());
    } else {
      this.logger.logError([
        "additional incoming merge for host ",
        _incomingHost,
        " encountered while there is already a registered request - dropping additional request",
      ]);
    }
  };

  isIncomingMergeOngoing = (): boolean => {
    return this._incomingMerge_PeersToMerge.size > 0;
  };

  isIncomingMergeOngoingForPeer = (_otherActiveHost: string): boolean => {
    return this._incomingMerge_PeersToMerge.has(_otherActiveHost);
  };

  unregisterIncomingMerge = (_incomingHost: string): void => {
    if (this.isIncomingMergeOngoingForPeer(_incomingHost)) {
      this._incomingMerge_PeersToMerge.delete(_incomingHost);
    } else {
      this.logger.logWarning([
        "local client tried to unregister incoming merge for incoming host ",
        _incomingHost,
        ", but none was registered",
      ]);
    }
  };

  getPeersForIncomingMerge = (_incomingHost: string): string[] => {
    return this._incomingMerge_PeersToMerge.get(_incomingHost);
  };

  getNumberOfIncomingPeersFromMergeRequests = (
    _additionalPeersToConsider: string[] = []
  ): number => {
    let _peersInclDuplicates: string[] = [];
    let _vals = this._incomingMerge_PeersToMerge.values() as Iterable<string>;
    for (let _val of _vals) {
      _peersInclDuplicates.push(_vals as string);
    }
    _peersInclDuplicates = _peersInclDuplicates.concat(
      _additionalPeersToConsider
    );
    return [...new Set(_peersInclDuplicates)].length;
  };

  currentVCCanAccomodateIncomingMerge = (_incomingPeers: string[]): boolean => {
    return (
      this.getNumberOfIncomingPeersFromMergeRequests(
        this.getVCPeers()
          .concat([this.signaling.getUniqueClientID()])
          .concat(this.getConfirmedVCJoiners())
          .concat(_incomingPeers)
      ) <= this.getVCMaxUserLimit()
    );
  };

  resetIncomingMerges = (): void => {
    this._incomingMerge_PeersToMerge.clear();
    this._incomingMerge_PeersToMergeAutoDeleteIDs.clear();
  };

  getVCMaxUserLimit = (): number => {
    return this._VCMaxUserLimit;
  };

  getTimeLimitForAutoUnregisterOfPendingRequests = (): number => {
    return this._timeLimitForAutoUnregisterOfPendingRequests;
  };

  registerAuthorizationToJoinOrMergeOtherActiveVC = (
    peerID: string,
    _unregisterPendingVCInvite: boolean = true
  ): void => {
    // in order for a user to connect into another user's active VC, it needs to click twice (to signal it understands that it enters somebody elses conversation) - when clicking for the first time, the user registers its connection attempt here for the second click to know that it's the second one and can execute the VC join/merge..
    this._authorizationsToJoinOrMergeOtherActiveVCs.add(peerID);
    if (_unregisterPendingVCInvite) this.unregisterPendingVCInvite(peerID);
    setTimeout(() => {
      this._authorizationsToJoinOrMergeOtherActiveVCs.delete(peerID);
    }, 5000);
    //idea for furture todo= changeButtonColorForXSecs
  };

  preRegisterAuthorizationToJoinOrMergeOtherActiveVC = (
    _peerPositionOnUI: number
  ): void => {
    let _peerID = this.appUI.getSideBarHTML().getPeerIDForVS(_peerPositionOnUI);
    this.logger.logLocal([
      "double-clicking a peer caused pre-registration of authorization to join/merge active VC of client ",
      _peerID,
    ]);
    //this.registerPendingVCInvite(_peerID, this.getLocalSocketID());
    this.registerAuthorizationToJoinOrMergeOtherActiveVC(_peerID, false);
  };

  isAuthorizedToJoinOrMergeOtherActiveVC = (peerID: string): boolean => {
    return this._authorizationsToJoinOrMergeOtherActiveVCs.has(peerID);
  };
}
