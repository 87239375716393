/* COPYRIGHT 2021 Michael Maur - any form of reuse requires written consent by the author */
import { VRTCPeerConnection } from "../signaling/VRTCPeerConnection";
import { Logger } from "../utils/Logger";
import { SIGNALING_PURPOSE } from "./enums/SIGNALING_PURPOSE";

export class DataMgmt {
  private logger: Logger;

  constructor(_logger: Logger) {
    console.log("Local client loading extension: Data Management");
    this.logger = _logger;
  }

  initialize = () => {};

  //////////////////////////////////////////////////////////////////
  // MULTI-PEER DATA STRUCTURES
  //////////////////////////////////////////////////////////////////

  /* global this.logger.logLocal */
  //private _keepTryingToInformPeers: boolean = null; //data field to hold ID returned by "set interval" call to "keep trying to inform peers once local setup ready"

  private _peerConnectionsBC = new Map<string, VRTCPeerConnection>(); //map to hold local VRTCPeerConnections, mapped against socket.IDs of peers - this is to cover basic connection (data comms / low res video)
  private _peerConnectionsHRV = new Map<string, VRTCPeerConnection>(); //map to hold local VRTCPeerConnections, mapped against socket.IDs of peers - this is to cover high resolution video for VC purposes
  private _peerConnectionsMRV = new Map<string, VRTCPeerConnection>(); //map to hold local VRTCPeerConnections, mapped against socket.IDs of peers - this is to cover med resolution video for VC purposes
  private _peerConnectionsLRV = new Map<string, VRTCPeerConnection>(); //map to hold local VRTCPeerConnections, mapped against socket.IDs of peers - this is to cover low resolution video for VC purposes
  private _peerConnectionsSS = new Map<string, VRTCPeerConnection>(); //map to hold local VRTCPeerConnections, mapped against socket.IDs of peers - this is to cover screen sharing
  private _peerConnectionsAO = new Map<string, VRTCPeerConnection>(); //map to hold local VRTCPeerConnections, mapped against socket.IDs of peers - this is to cover audio only
  //TODO= SEPARATE OUT A CONNECTION UI USAGE MAP - i.e. make PC independent of room or UI usage scenario
  //TODO= SEPARATE OUT A CONNECTION UI USAGE SETUP QUEUE - i.e. make PC independent of room or UI usage scenario
  //careful - FIFO does not work here as multiple UI USAGES can relate to one PC

  /*__Purpose_BaseConnection = "Base"; //target= data connection + low resolution video connectivity
  __Purpose_HRVideo = "HRVideo";
  __Purpose_MRVideo = "MRVideo";
  __Purpose_LRVideo = "LRVideo";
  __Purpose_ScreenShare = "ScreenS";
  __Purpose_AudioOnly = "AudioOnly";
  __Purpose_Undefined = "UndefinedPurpose";
  __Purpose_ALL = "AppliesToAllPurposes";*/

  private _peerMediaStreams_BC = new Map<string, MediaStream>(); //map to hold remote media streams, mapped against socket.IDs of peers - base connection /LR video (w/o audio)
  private _peerMediaStreams_LR = new Map<string, MediaStream>(); //map to hold remote media streams, mapped against socket.IDs of peers - low resolution video
  private _peerMediaStreams_MR = new Map<string, MediaStream>(); //map to hold remote media streams, mapped against socket.IDs of peers - med resolution video
  private _peerMediaStreams_HR = new Map<string, MediaStream>(); //map to hold remote media streams, mapped against socket.IDs of peers - high resolution video
  private _peerMediaStreams_AO = new Map<string, MediaStream>(); //map to hold remote media streams, mapped against socket.IDs of peers - audio only / talk video
  private _peerMediaStreams_SS = new Map<string, MediaStream>(); //map to hold remote media streams, mapped against socket.IDs of peers - screen share video
  //notinuse _localMediaStreams= new Map(); //reference to different quality local client's media streams - mapped against stream quality label
  private _localMediaStream: MediaStream = null; //reference to local client's media stream, once obtained
  private _localMediaStreamScreenShare: MediaStream = null; //reference to local client's screen share media stream, once obtained
  private _localMediaStreamH: MediaStream = null; //stores a reduced version of the local video stream - video tracks with high quality
  private _localMediaStreamM: MediaStream = null; //stores a reduced version of the local video stream - video tracks with medium quality
  private _localMediaStreamL: MediaStream = null; //stores a reduced version of the local video stream - video tracks with low quality
  //_localVideoStreamTrack= null; //stores unconstraint version of local video stream track
  //_localVideoStreamTrackH= null; //stores a clone of local video stream track - high quality
  //_localVideoStreamTrackM= null; //stores a clone of local video stream track - medium quality
  //_localVideoStreamTrackL= null; //stores a clone of local video stream track - low quality
  //_localAudioStreamTrack= null; //stores unconstraint version of local audio stream track

  private _authorizedSignalingDisconnectUnderwaySupressingWarnings: boolean =
    false; // set to true if user triggers reload of app - temporarily suppresses server disconnect warnings

  /*__pauseState_notPaused= 'businessAsUsual';
  __pauseState_pausedVideoActive= 'videoOnButNooneCanConnect'; //requires new response states going forward
  __pauseState_pausedVideoDisconnected= 'videoOfflineNooneCanConnect'; //requires new response states going forward
  _visavisPauseState= __pauseStateNotPaused; //pause state*/

  //provide data structure to store and access VRTCPeerConnections
  registerPC = (
    peerID: string,
    PC: VRTCPeerConnection,
    PCSigP: SIGNALING_PURPOSE
  ): void => {
    if (PCSigP === SIGNALING_PURPOSE.BASE_CONNECTION) {
      this._peerConnectionsBC.set(peerID, PC);
    } else if (PCSigP === SIGNALING_PURPOSE.HR_VIDEO) {
      this._peerConnectionsHRV.set(peerID, PC);
    } else if (PCSigP === SIGNALING_PURPOSE.MR_VIDEO) {
      this._peerConnectionsMRV.set(peerID, PC);
    } else if (PCSigP === SIGNALING_PURPOSE.LR_VIDEO) {
      this._peerConnectionsLRV.set(peerID, PC);
    } else if (PCSigP === SIGNALING_PURPOSE.SCREEN_SHARE) {
      this._peerConnectionsSS.set(peerID, PC);
    } else if (PCSigP === SIGNALING_PURPOSE.AUDIO_ONLY) {
      this._peerConnectionsAO.set(peerID, PC);
    } else {
      console.error("Could not register peer connection for undefined purpose");
    }
  };
  getPC = (peerID: string, PCSigP: SIGNALING_PURPOSE): VRTCPeerConnection => {
    if (PCSigP === SIGNALING_PURPOSE.BASE_CONNECTION) {
      return this._peerConnectionsBC.get(peerID);
    } else if (PCSigP === SIGNALING_PURPOSE.HR_VIDEO) {
      return this._peerConnectionsHRV.get(peerID);
    } else if (PCSigP === SIGNALING_PURPOSE.MR_VIDEO) {
      return this._peerConnectionsMRV.get(peerID);
    } else if (PCSigP === SIGNALING_PURPOSE.LR_VIDEO) {
      return this._peerConnectionsLRV.get(peerID);
    } else if (PCSigP === SIGNALING_PURPOSE.SCREEN_SHARE) {
      return this._peerConnectionsSS.get(peerID);
    } else if (PCSigP === SIGNALING_PURPOSE.AUDIO_ONLY) {
      return this._peerConnectionsAO.get(peerID);
    } else {
      return null;
    }
  };
  getAllPCsOfSignalingPurpose = (
    PCSigP: SIGNALING_PURPOSE
  ): Map<string, VRTCPeerConnection> => {
    if (PCSigP == SIGNALING_PURPOSE.BASE_CONNECTION) {
      return this._peerConnectionsBC;
    } else if (PCSigP == SIGNALING_PURPOSE.HR_VIDEO) {
      return this._peerConnectionsHRV;
    } else if (PCSigP == SIGNALING_PURPOSE.MR_VIDEO) {
      return this._peerConnectionsMRV;
    } else if (PCSigP == SIGNALING_PURPOSE.LR_VIDEO) {
      return this._peerConnectionsLRV;
    } else if (PCSigP == SIGNALING_PURPOSE.SCREEN_SHARE) {
      return this._peerConnectionsSS;
    } else if (PCSigP == SIGNALING_PURPOSE.AUDIO_ONLY) {
      return this._peerConnectionsAO;
    } else {
      return null;
    }
  };
  /*getPCForPeer(peerID) {
    if (this.existsPCForPeer(peerID)) {return this._peerConnectionsBC.get(peerID);}
    else {return null;}
  };*/
  existsPC = (peerID: string, PCSigP: SIGNALING_PURPOSE): boolean => {
    if (PCSigP === SIGNALING_PURPOSE.BASE_CONNECTION) {
      return this._peerConnectionsBC.has(peerID);
    } else if (PCSigP === SIGNALING_PURPOSE.HR_VIDEO) {
      return this._peerConnectionsHRV.has(peerID);
    } else if (PCSigP === SIGNALING_PURPOSE.MR_VIDEO) {
      return this._peerConnectionsMRV.has(peerID);
    } else if (PCSigP === SIGNALING_PURPOSE.LR_VIDEO) {
      return this._peerConnectionsLRV.has(peerID);
    } else if (PCSigP === SIGNALING_PURPOSE.SCREEN_SHARE) {
      return this._peerConnectionsSS.has(peerID);
    } else if (PCSigP === SIGNALING_PURPOSE.AUDIO_ONLY) {
      return this._peerConnectionsAO.has(peerID);
    } else if (PCSigP === SIGNALING_PURPOSE.ALL) {
      return (
        this._peerConnectionsBC.has(peerID) ||
        this._peerConnectionsHRV.has(peerID) ||
        this._peerConnectionsMRV.has(peerID) ||
        this._peerConnectionsLRV.has(peerID) ||
        this._peerConnectionsSS.has(peerID) ||
        this._peerConnectionsAO.has(peerID)
      );
    } else {
      return false;
    }
  };
  getSigPOfExistingVideoPC = (peerID: string): SIGNALING_PURPOSE => {
    if (this._peerConnectionsBC.has(peerID)) {
      return SIGNALING_PURPOSE.BASE_CONNECTION;
    } else if (this._peerConnectionsHRV.has(peerID)) {
      return SIGNALING_PURPOSE.HR_VIDEO;
    } else if (this._peerConnectionsMRV.has(peerID)) {
      return SIGNALING_PURPOSE.MR_VIDEO;
    } else if (this._peerConnectionsLRV.has(peerID)) {
      return SIGNALING_PURPOSE.LR_VIDEO;
    } else if (this._peerConnectionsAO.has(peerID)) {
      return SIGNALING_PURPOSE.AUDIO_ONLY;
    } else {
      return null;
      //screenshare not included here as this is just a check used to reestablish bloken video connections
    }
  };
  /*existsPCForPeer(peerID) { //base connection to serve as one and only connection going forward - no mor multiple connections based on purpose
    return this._peerConnectionsBC.has(peerID);
  };*/
  countPCs = (PCSigP: SIGNALING_PURPOSE): number => {
    if (PCSigP === SIGNALING_PURPOSE.BASE_CONNECTION) {
      return this._peerConnectionsBC.size;
    } else if (PCSigP === SIGNALING_PURPOSE.HR_VIDEO) {
      return this._peerConnectionsHRV.size;
    } else if (PCSigP === SIGNALING_PURPOSE.MR_VIDEO) {
      return this._peerConnectionsMRV.size;
    } else if (PCSigP === SIGNALING_PURPOSE.LR_VIDEO) {
      return this._peerConnectionsLRV.size;
    } else if (PCSigP === SIGNALING_PURPOSE.SCREEN_SHARE) {
      return this._peerConnectionsSS.size;
    } else if (PCSigP === SIGNALING_PURPOSE.AUDIO_ONLY) {
      return this._peerConnectionsAO.size;
    } else {
      return null;
    }
  };
  retireAllPCForPeerExclBC = (peerID: string): void => {
    this.retirePC(peerID, SIGNALING_PURPOSE.ALL, true);
  };
  retirePC = (
    peerID: string,
    PCSigP: SIGNALING_PURPOSE,
    excludeBaseConnection: boolean = false
  ): void => {
    //todo= close PC / free memory
    if (PCSigP === SIGNALING_PURPOSE.ALL) {
      if (
        !excludeBaseConnection &&
        this.existsPC(peerID, SIGNALING_PURPOSE.BASE_CONNECTION)
      ) {
        this.getPC(peerID, SIGNALING_PURPOSE.BASE_CONNECTION).close();
        this._peerConnectionsBC.delete(peerID);
      }
      if (this.existsPC(peerID, SIGNALING_PURPOSE.HR_VIDEO)) {
        this.getPC(peerID, SIGNALING_PURPOSE.HR_VIDEO).close();
        this._peerConnectionsHRV.delete(peerID);
      }
      if (this.existsPC(peerID, SIGNALING_PURPOSE.MR_VIDEO)) {
        this.getPC(peerID, SIGNALING_PURPOSE.MR_VIDEO).close();
        this._peerConnectionsMRV.delete(peerID);
      }
      if (this.existsPC(peerID, SIGNALING_PURPOSE.LR_VIDEO)) {
        this.getPC(peerID, SIGNALING_PURPOSE.LR_VIDEO).close();
        this._peerConnectionsLRV.delete(peerID);
      }
      if (this.existsPC(peerID, SIGNALING_PURPOSE.SCREEN_SHARE)) {
        this.getPC(peerID, SIGNALING_PURPOSE.SCREEN_SHARE).close();
        this._peerConnectionsSS.delete(peerID);
      }
      if (this.existsPC(peerID, SIGNALING_PURPOSE.AUDIO_ONLY)) {
        this.getPC(peerID, SIGNALING_PURPOSE.AUDIO_ONLY).close();
        this._peerConnectionsAO.delete(peerID);
      }
    } else if (this.existsPC(peerID, PCSigP)) {
      //not __purpose_all, but specific pcsigp for which connection exists
      this.getPC(peerID, PCSigP).close();
      if (PCSigP === SIGNALING_PURPOSE.BASE_CONNECTION) {
        this._peerConnectionsBC.delete(peerID);
      } else if (PCSigP === SIGNALING_PURPOSE.HR_VIDEO) {
        this._peerConnectionsHRV.delete(peerID);
      } else if (PCSigP === SIGNALING_PURPOSE.MR_VIDEO) {
        this._peerConnectionsMRV.delete(peerID);
      } else if (PCSigP === SIGNALING_PURPOSE.LR_VIDEO) {
        this._peerConnectionsLRV.delete(peerID);
      } else if (PCSigP === SIGNALING_PURPOSE.SCREEN_SHARE) {
        this._peerConnectionsSS.delete(peerID);
      } else if (PCSigP === SIGNALING_PURPOSE.AUDIO_ONLY) {
        this._peerConnectionsAO.delete(peerID);
      }
    } else {
      console.warn(
        "Could not unregister peer connection for purpose " +
          PCSigP +
          " - does it exist= " +
          this.existsPC(peerID, PCSigP)
      );
    }

    //this.logger.logLocal('VRTCPeerConnection to client ' + peerID + ' closed/retired')
  };
  retireAllScreenSharePCs = (): void => {
    /*this._peerMediaStreams_SS.forEach(function(val;key;map){
      this.retirePMS(key;this.__Purpose_ScreenShare);
    });*/
    this._peerConnectionsSS.forEach((val, key, map) => {
      this.retirePC(key, SIGNALING_PURPOSE.SCREEN_SHARE);
    });
  };

  stopAllPCs = (): void => {
    this._peerConnectionsBC.forEach((val, key, map) => {
      val.close();
      this.retirePC(key, SIGNALING_PURPOSE.BASE_CONNECTION);
    });
    this._peerConnectionsAO.forEach((val, key, map) => {
      val.close();
      this.retirePC(key, SIGNALING_PURPOSE.AUDIO_ONLY);
    });
    this._peerConnectionsHRV.forEach((val, key, map) => {
      val.close();
      this.retirePC(key, SIGNALING_PURPOSE.HR_VIDEO);
    });
    this._peerConnectionsMRV.forEach((val, key, map) => {
      val.close();
      this.retirePC(key, SIGNALING_PURPOSE.MR_VIDEO);
    });
    this._peerConnectionsLRV.forEach((val, key, map) => {
      val.close();
      this.retirePC(key, SIGNALING_PURPOSE.LR_VIDEO);
    });
    this._peerConnectionsSS.forEach((val, key, map) => {
      val.close();
      this.retirePC(key, SIGNALING_PURPOSE.SCREEN_SHARE);
    });
  };

  retireAllStoppedPCsInclMatchingPMSs = (): void => {
    this.retirePCsAndMatchingPMSsIfPCNotInProperState(
      this._peerConnectionsBC,
      SIGNALING_PURPOSE.BASE_CONNECTION
    );
    this.retirePCsAndMatchingPMSsIfPCNotInProperState(
      this._peerConnectionsAO,
      SIGNALING_PURPOSE.AUDIO_ONLY
    );
    this.retirePCsAndMatchingPMSsIfPCNotInProperState(
      this._peerConnectionsHRV,
      SIGNALING_PURPOSE.HR_VIDEO
    );
    this.retirePCsAndMatchingPMSsIfPCNotInProperState(
      this._peerConnectionsMRV,
      SIGNALING_PURPOSE.MR_VIDEO
    );
    this.retirePCsAndMatchingPMSsIfPCNotInProperState(
      this._peerConnectionsLRV,
      SIGNALING_PURPOSE.LR_VIDEO
    );
    this.retirePCsAndMatchingPMSsIfPCNotInProperState(
      this._peerConnectionsSS,
      SIGNALING_PURPOSE.SCREEN_SHARE
    );
  };

  retireAllInActivePMSInclMatchingPCs = (): void => {
    this.retirePMSsAndMatchingPCsIfPMSNotInProperState(
      this._peerMediaStreams_BC,
      SIGNALING_PURPOSE.BASE_CONNECTION
    );
    this.retirePMSsAndMatchingPCsIfPMSNotInProperState(
      this._peerMediaStreams_AO,
      SIGNALING_PURPOSE.AUDIO_ONLY
    );
    this.retirePMSsAndMatchingPCsIfPMSNotInProperState(
      this._peerMediaStreams_HR,
      SIGNALING_PURPOSE.HR_VIDEO
    );
    this.retirePMSsAndMatchingPCsIfPMSNotInProperState(
      this._peerMediaStreams_MR,
      SIGNALING_PURPOSE.MR_VIDEO
    );
    this.retirePMSsAndMatchingPCsIfPMSNotInProperState(
      this._peerMediaStreams_LR,
      SIGNALING_PURPOSE.LR_VIDEO
    );
    this.retirePMSsAndMatchingPCsIfPMSNotInProperState(
      this._peerMediaStreams_SS,
      SIGNALING_PURPOSE.SCREEN_SHARE
    );
  };

  private retirePCsAndMatchingPMSsIfPCNotInProperState = (
    pcs: Map<string, VRTCPeerConnection>,
    pcSigP: SIGNALING_PURPOSE
  ): void => {
    pcs.forEach((val, key, map) => {
      if (
        val.connectionState != "connected"
        /*val.connectionState == "disconnected" ||
        val.connectionState == "failed" ||
        val.connectionState == "closed"*/
      ) {
        this.retirePC(key, pcSigP);
        this.retirePMS(key, pcSigP);
      }
    });
  };

  private retirePMSsAndMatchingPCsIfPMSNotInProperState = (
    pms: Map<string, MediaStream>,
    pcSigP: SIGNALING_PURPOSE
  ): void => {
    pms.forEach((val, key, map) => {
      if (!val.active) {
        this.retirePC(key, pcSigP);
        this.retirePMS(key, pcSigP);
      }
    });
  };

  //provide data structure to store and access peers' media streams (PMS)
  registerPMS = (
    peerID: string,
    PMS: MediaStream,
    peerMSType: SIGNALING_PURPOSE
  ): void => {
    if (peerMSType === SIGNALING_PURPOSE.BASE_CONNECTION) {
      this._peerMediaStreams_BC.set(peerID, PMS);
    } else if (peerMSType === SIGNALING_PURPOSE.LR_VIDEO) {
      this._peerMediaStreams_LR.set(peerID, PMS);
    } else if (peerMSType === SIGNALING_PURPOSE.MR_VIDEO) {
      this._peerMediaStreams_MR.set(peerID, PMS);
    } else if (peerMSType === SIGNALING_PURPOSE.HR_VIDEO) {
      this._peerMediaStreams_HR.set(peerID, PMS);
    } else if (peerMSType === SIGNALING_PURPOSE.SCREEN_SHARE) {
      this._peerMediaStreams_SS.set(peerID, PMS);
    } else if (peerMSType === SIGNALING_PURPOSE.AUDIO_ONLY) {
      this._peerMediaStreams_AO.set(peerID, PMS);
    }
  };
  getPMS = (peerID: string, peerMSType: SIGNALING_PURPOSE) => {
    if (peerMSType === SIGNALING_PURPOSE.BASE_CONNECTION) {
      return this._peerMediaStreams_BC.get(peerID);
    } else if (peerMSType === SIGNALING_PURPOSE.LR_VIDEO) {
      return this._peerMediaStreams_LR.get(peerID);
    } else if (peerMSType === SIGNALING_PURPOSE.MR_VIDEO) {
      return this._peerMediaStreams_MR.get(peerID);
    } else if (peerMSType === SIGNALING_PURPOSE.HR_VIDEO) {
      return this._peerMediaStreams_HR.get(peerID);
    } else if (peerMSType === SIGNALING_PURPOSE.SCREEN_SHARE) {
      return this._peerMediaStreams_SS.get(peerID);
    } else if (peerMSType === SIGNALING_PURPOSE.AUDIO_ONLY) {
      return this._peerMediaStreams_AO.get(peerID);
    }
  };
  existsPMS = (peerID: string, peerMSType: SIGNALING_PURPOSE) => {
    if (peerMSType === SIGNALING_PURPOSE.BASE_CONNECTION) {
      return this._peerMediaStreams_BC.has(peerID);
    } else if (peerMSType === SIGNALING_PURPOSE.LR_VIDEO) {
      return this._peerMediaStreams_LR.has(peerID);
    } else if (peerMSType === SIGNALING_PURPOSE.MR_VIDEO) {
      return this._peerMediaStreams_MR.has(peerID);
    } else if (peerMSType === SIGNALING_PURPOSE.HR_VIDEO) {
      return this._peerMediaStreams_HR.has(peerID);
    } else if (peerMSType === SIGNALING_PURPOSE.SCREEN_SHARE) {
      return this._peerMediaStreams_SS.has(peerID);
    } else if (peerMSType === SIGNALING_PURPOSE.AUDIO_ONLY) {
      return this._peerMediaStreams_AO.has(peerID);
    }
  };
  countPMSs = (peerMSType: SIGNALING_PURPOSE) => {
    if (peerMSType === SIGNALING_PURPOSE.BASE_CONNECTION) {
      return this._peerMediaStreams_BC.size;
    } else if (peerMSType === SIGNALING_PURPOSE.LR_VIDEO) {
      return this._peerMediaStreams_LR.size;
    } else if (peerMSType === SIGNALING_PURPOSE.MR_VIDEO) {
      return this._peerMediaStreams_MR.size;
    } else if (peerMSType === SIGNALING_PURPOSE.HR_VIDEO) {
      return this._peerMediaStreams_HR.size;
    } else if (peerMSType === SIGNALING_PURPOSE.SCREEN_SHARE) {
      return this._peerMediaStreams_SS.size;
    } else if (peerMSType === SIGNALING_PURPOSE.AUDIO_ONLY) {
      return this._peerMediaStreams_AO.size;
    }
  };
  retireAllPMSForPeerExclBC = (peerID: string): void => {
    this.retirePMS(peerID, SIGNALING_PURPOSE.ALL, true);
  };
  retirePMS = (
    peerID: string,
    peerMSType: SIGNALING_PURPOSE,
    excludeBaseConnection: boolean = false
  ): void => {
    //todo= close PMS / free memory
    if (
      !excludeBaseConnection &&
      (peerMSType === SIGNALING_PURPOSE.ALL ||
        peerMSType === SIGNALING_PURPOSE.BASE_CONNECTION)
    ) {
      if (this._peerMediaStreams_BC.has(peerID)) {
        this._peerMediaStreams_BC
          .get(peerID)
          .getTracks()
          .forEach((track) => track.stop());
        this._peerMediaStreams_BC.delete(peerID);
      }
    }
    if (
      peerMSType === SIGNALING_PURPOSE.ALL ||
      peerMSType === SIGNALING_PURPOSE.LR_VIDEO
    ) {
      if (this._peerMediaStreams_LR.has(peerID)) {
        this._peerMediaStreams_LR
          .get(peerID)
          .getTracks()
          .forEach((track) => track.stop());
        this._peerMediaStreams_LR.delete(peerID);
      }
    }
    if (
      peerMSType === SIGNALING_PURPOSE.ALL ||
      peerMSType === SIGNALING_PURPOSE.MR_VIDEO
    ) {
      if (this._peerMediaStreams_MR.has(peerID)) {
        this._peerMediaStreams_MR
          .get(peerID)
          .getTracks()
          .forEach((track) => track.stop());
        this._peerMediaStreams_MR.delete(peerID);
      }
    }
    if (
      peerMSType === SIGNALING_PURPOSE.ALL ||
      peerMSType === SIGNALING_PURPOSE.HR_VIDEO
    ) {
      if (this._peerMediaStreams_HR.has(peerID)) {
        this._peerMediaStreams_HR
          .get(peerID)
          .getTracks()
          .forEach((track) => track.stop());
        this._peerMediaStreams_HR.delete(peerID);
      }
    }
    if (
      peerMSType === SIGNALING_PURPOSE.ALL ||
      peerMSType === SIGNALING_PURPOSE.SCREEN_SHARE
    ) {
      if (this._peerMediaStreams_SS.has(peerID)) {
        this._peerMediaStreams_SS
          .get(peerID)
          .getTracks()
          .forEach((track) => track.stop());
        this._peerMediaStreams_SS.delete(peerID);
      }
    }
    if (
      peerMSType === SIGNALING_PURPOSE.ALL ||
      peerMSType === SIGNALING_PURPOSE.AUDIO_ONLY
    ) {
      if (this._peerMediaStreams_AO.has(peerID)) {
        this._peerMediaStreams_AO
          .get(peerID)
          .getTracks()
          .forEach((track) => track.stop());
        this._peerMediaStreams_AO.delete(peerID);
      }
    }
    this.logger.logLocal([
      "MediaStream from client " + peerID + " closed/retired",
    ]);
  };
  retireAllPMS = (): void => {
    this._peerMediaStreams_BC.forEach((val, key, map) => {
      this.retirePMS(key, SIGNALING_PURPOSE.BASE_CONNECTION);
    });
    this._peerMediaStreams_LR.forEach((val, key, map) => {
      this.retirePMS(key, SIGNALING_PURPOSE.LR_VIDEO);
    });
    this._peerMediaStreams_MR.forEach((val, key, map) => {
      this.retirePMS(key, SIGNALING_PURPOSE.MR_VIDEO);
    });
    this._peerMediaStreams_HR.forEach((val, key, map) => {
      this.retirePMS(key, SIGNALING_PURPOSE.MR_VIDEO);
    });
    this._peerMediaStreams_SS.forEach((val, key, map) => {
      this.retirePMS(key, SIGNALING_PURPOSE.SCREEN_SHARE);
    });
    this._peerMediaStreams_AO.forEach((val, key, map) => {
      this.retirePMS(key, SIGNALING_PURPOSE.AUDIO_ONLY);
    });
  };
  retireAllLMS = (): void => {
    //todo= close PMS / free memory
    this.retireMSByStoppingAllTracks(this._localMediaStream);
    this.retireMSByStoppingAllTracks(this._localMediaStreamH);
    this.retireMSByStoppingAllTracks(this._localMediaStreamM);
    this.retireMSByStoppingAllTracks(this._localMediaStreamL);
    this.retireMSByStoppingAllTracks(this._localMediaStreamScreenShare);
    this._localMediaStream = null;
    this._localMediaStreamH = null;
    this._localMediaStreamM = null;
    this._localMediaStreamL = null;
    this._localMediaStreamScreenShare = null;
    this.logger.logLocal(["Local MediaStreams closed/retired"]);
  };

  retireMSByStoppingAllTracks = (MS: MediaStream) => {
    if (MS != null) {
      MS.getTracks().forEach((track) => track.stop());
    }
  };
  setLocalMS = (MS: MediaStream): void => {
    this._localMediaStream = MS;
  };
  getLocalMS = (): MediaStream => {
    return this._localMediaStream;
  };
  isLocalMSPresent = (): boolean => {
    return this._localMediaStream != null;
  };

  getLocalMSByQuality = (qualityLevel: string): MediaStream => {
    if (qualityLevel === "H") {
      return this._localMediaStreamH;
    } else if (qualityLevel === "M") {
      return this._localMediaStreamM;
    } else if (qualityLevel === "L") {
      return this._localMediaStreamL;
    } else {
      console.error(
        "media stream of unknown quality level requested= ",
        qualityLevel
      );
    }
  };

  setLocalMSByQuality = (_mediaS: MediaStream, qualityLevel: string): void => {
    if (qualityLevel === "H") {
      this._localMediaStreamH = _mediaS;
    } else if (qualityLevel === "M") {
      this._localMediaStreamM = _mediaS;
    } else if (qualityLevel === "L") {
      this._localMediaStreamL = _mediaS;
    } else {
      console.error(
        "could not set media stream of unknown quality level = ",
        qualityLevel
      );
    }
  };

  setLocalMSScreenShare = (MS: MediaStream): void => {
    this._localMediaStreamScreenShare = MS;
  };
  getLocalMSScreenShare = (): MediaStream => {
    return this._localMediaStreamScreenShare;
  };
  getPeerMSScreenShare = (): Map<string, MediaStream> => {
    return this._peerMediaStreams_SS;
  };

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  // other special case treatment
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  registerTemporaryPermissionToDisconnectFromSignalingWithoutWarning = (
    _durationOfWarningSuppression: number = 2000
  ): void => {
    this._authorizedSignalingDisconnectUnderwaySupressingWarnings = true;
    setTimeout(() => {
      this._authorizedSignalingDisconnectUnderwaySupressingWarnings = false;
    }, _durationOfWarningSuppression);
  };
  unregisterTemporaryPermissionToDisconnectFromSignalingWithoutWarning =
    (): void => {
      this._authorizedSignalingDisconnectUnderwaySupressingWarnings = false;
    };
  isTemporaryPermissionToDisconnectFromSignalingWithoutWarningRegistered =
    (): boolean => {
      return this._authorizedSignalingDisconnectUnderwaySupressingWarnings;
    };

  /*getAllConnectedPeerIDs = (): string[] => {
    //note: this is a costly function - to be simplified going forward
    let idsBC: Set<string> = new Set<string>(this._peerConnectionsBC.keys());
    let idsAO: Set<string> = new Set<string>(this._peerConnectionsAO.keys());
    let idsLRV: Set<string> = new Set<string>(this._peerConnectionsLRV.keys());
    let idsMRV: Set<string> = new Set<string>(this._peerConnectionsMRV.keys());
    let idsHRV: Set<string> = new Set<string>(this._peerConnectionsHRV.keys());

    let ids = new Set<string>(
      ...idsBC,
      ...idsAO,
      ...idsLRV,
      ...idsMRV,
      ...idsHRV
    );
    return Array.from(ids);
  };*/

  peerConnectionExists = (peerID: string): boolean => {
    if (this._peerConnectionsBC.has(peerID)) return true;
    else if (this._peerConnectionsAO.has(peerID)) return true;
    else if (this._peerConnectionsHRV.has(peerID)) return true;
    else if (this._peerConnectionsLRV.has(peerID)) return true;
    else if (this._peerConnectionsMRV.has(peerID)) return true;
    else return this._peerConnectionsSS.has(peerID);
  };

  healthyPeerConnectionExistsForSigP = (
    peerID: string,
    pcSigP: SIGNALING_PURPOSE
  ): boolean => {
    return (
      this.existsPC(peerID, pcSigP) &&
      this.getPC(peerID, pcSigP).connectionState == "connected" &&
      this.existsPMS(peerID, pcSigP) &&
      this.getPMS(peerID, pcSigP).active
      //this.getPMS(peerID, pcSigP).getVideoTracks()[0].readyState=="live"
    );
  };

  healthyPeerConnectionExists = (
    peerID: string,
    considerScreenShare: boolean = false
  ): boolean => {
    return (
      this.healthyPeerConnectionExistsForSigP(
        peerID,
        SIGNALING_PURPOSE.BASE_CONNECTION
      ) ||
      this.healthyPeerConnectionExistsForSigP(
        peerID,
        SIGNALING_PURPOSE.AUDIO_ONLY
      ) ||
      this.healthyPeerConnectionExistsForSigP(
        peerID,
        SIGNALING_PURPOSE.HR_VIDEO
      ) ||
      this.healthyPeerConnectionExistsForSigP(
        peerID,
        SIGNALING_PURPOSE.MR_VIDEO
      ) ||
      this.healthyPeerConnectionExistsForSigP(
        peerID,
        SIGNALING_PURPOSE.LR_VIDEO
      ) ||
      (considerScreenShare &&
        this.healthyPeerConnectionExistsForSigP(
          peerID,
          SIGNALING_PURPOSE.SCREEN_SHARE
        ))
    );
  };
}
