import { I_DialogueBackgroundUI } from "frontend/decoupler/I_DialogueBackgroundUI";

export class DialogueBackgroundWebMeet implements I_DialogueBackgroundUI {
  /*   #############  DO NOTHING AS THERE IS NO DIALOGUE BACKGROUND ON WEB MEETINGS   ############# */

  initialize = (): void => {};

  createMaximizedScreenShareDialogueBackgroundWindow = (): Promise<Window> => {
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  };

  hideMaximizedScreenShareBackgroundWindow = (): void => {};

  closeMaximizedScreenShareBackgroundWindow = (): void => {};

  isMaximizedScreenShareBackgroundWindowOpen = (): boolean => {
    return false;
  };
}
