/* COPYRIGHT 2021 Michael Maur - any form of reuse requires written consent by the author */
import { DataMgmt } from "data/DataMgmt";

import { Config } from "config/Config";
import { BusLogicCore } from "buslogic/BusLogicCore";
import { SettingStorage } from "data/SettingStorage";
import { Logger } from "utils/Logger";
import { UIMessenger } from "utils/UIMessenger";
import { BandwidthMgmt } from "../infra/BandwidthMgmt";
import { SIGNALING_PURPOSE } from "data/enums/SIGNALING_PURPOSE";
import { PeerConnectionQueue } from "../data/PeerConnectionQueue";
import { SignalingRaiseHand } from "./featureSignalingRoom/SignalingRaiseHand";
import { I_MessageListenerFunction121PeerMessage } from "./featureSignalingRoom/I_MessageListenerFunction121PeerMessage";
import { I_OneToOneSignalingProvider } from "./featureSignalingRoom/I_OneToOneSignalingProvider";
import { ServerConnector } from "./ServerConnector";
import { Socket } from "socket.io-client";
import { Helpers } from "utils/Helpers";
import { QueuedPeerConnection } from "../data/QueuedPeerConnection";
import { BOOL } from "data/enums/BOOL";
import { VRTCPeerConnection } from "./VRTCPeerConnection";
import { PeerConnector } from "./PeerConnector";
import { I_StateMgr } from "infra/I_StageMgr";
import { SignalingUserNameLabel } from "./featureSignalingRoom/SignalingUserNameLabel";
import { SignalingRemoteMute } from "./featureSignalingRoom/SignalingRemoteMute";
import { AppContextHandler } from "infra/AppContextHandler";
import { APP_CONTEXT } from "data/enums/APP_CONTEXT";
import { CodecManager } from "../infra/CodecManager";
import { VIDEO_CODEC } from "data/enums/VIDEO_CODEC";

export abstract class SignalingCore implements I_OneToOneSignalingProvider {
  protected help: Helpers;
  private config: Config;
  private settings: SettingStorage;
  protected data: DataMgmt;
  protected logger: Logger;
  protected uiMessenger: UIMessenger;
  protected context: AppContextHandler;

  protected serverConnector: ServerConnector;
  protected peerConnector: PeerConnector;
  //protected soDa: SocketData;
  protected peerCQ: PeerConnectionQueue;

  protected busLogic: BusLogicCore;
  private stateMgr: I_StateMgr;

  protected raiseHandSignaling: SignalingRaiseHand;
  protected userNameLabelSignaling: SignalingUserNameLabel;
  protected remoteMuteSignaling: SignalingRemoteMute;
  //private wakeUp: WakeUpDetector;

  private readonly _defaultRoomName: string;
  private _pcConfig: any; // stun/turn config
  private bandwidth: BandwidthMgmt;
  private codec: CodecManager;
  //private serial: Serializer;
  //private _socket: Socket;

  // FOUR INDICATORS DETERMINE WHETHER LOCAL CLIENT STARTS TO COMMUNICATE WITH OTHER PEERS
  private _signalingSetupReady: boolean; //is set to true once the client joined the "main room"
  //indicator, whether local client received list of ICE servers from signaling server - this is a precondition for successful handling of some firewall constellations
  private _iceServersReady: boolean;
  //indicator, whether constraints have successfully been applied to local media stream variants (H/M/L quality)
  private _localConstraintApplicationReady: boolean;
  //indicator, whether connection requests can be sent to peers - this is possible only if local media stream is available
  private _localStreamReady: boolean;

  private registered121MessageListeners: Map<
    string,
    I_MessageListenerFunction121PeerMessage
  >; //registry for any listeners to 121 messages that go beyond basic signaling

  private iceServersReceived: boolean;

  private localClientWithNoExistingPeerConnections: BOOL;

  private connectionStatusConnectedCallback: (peerID: string) => void;

  constructor(
    _help: Helpers,
    _context: AppContextHandler,
    _config: Config,
    _settings: SettingStorage,
    _logger: Logger,
    _uiMessenger: UIMessenger,
    _data: DataMgmt
  ) {
    console.log("Local client loading js module: signalingCore");
    this.help = _help;
    this.config = _config;
    this.settings = _settings;
    this.data = _data;
    this.logger = _logger;
    this.uiMessenger = _uiMessenger;
    this.context = _context;

    //this.wakeUp = new WakeUpDetector(this.logger);

    this.serverConnector = new ServerConnector(
      this.help,
      this.logger,
      this.config,
      this.data,
      this.settings,
      this.uiMessenger
      //this.wakeUp
    );
    this.peerConnector = new PeerConnector(
      this.help,
      this.data,
      this,
      this.serverConnector
    );
    this.peerCQ = new PeerConnectionQueue(this.logger);

    this._defaultRoomName = this.config.getDefaultRoomName();

    this.registered121MessageListeners = new Map<
      string,
      I_MessageListenerFunction121PeerMessage
    >();

    this.bandwidth = new BandwidthMgmt(this.config);
    this.codec = new CodecManager(this.help, this.logger);
    //this.serial = new Serializer();

    this.raiseHandSignaling = new SignalingRaiseHand(this.logger);
    this.userNameLabelSignaling = new SignalingUserNameLabel(this.logger);
    this.remoteMuteSignaling = new SignalingRemoteMute(this.logger);

    this.iceServersReceived = false;
    this.localClientWithNoExistingPeerConnections = BOOL.B_TRUE;
    this.connectionStatusConnectedCallback = (peerID: string) => {};
  }

  protected initializeCore = (_stageMgr: I_StateMgr) => {
    this.stateMgr = _stageMgr;
    //var this._socket = io.connect(_signalingServerURL, { autoConnect: false });
    //this.wakeUp.initialize();
    this.serverConnector.initialize(
      this.getRoomName(this._defaultRoomName),
      _stageMgr
    );
    this.peerConnector.initialize();
    /*this.connector.registerReconnectCallback(
      this.requiredRepairActivitiesAfterConnectionLossAndReconnect
    );*/
    //room on signaling server that determines which other users the client can see on his workplace video bar
    //the main room sets the maximum bounds for interconnectivity / holds all clients that can see one another

    // configuration of STUN and TURN servers
    // is later overwritten by server info including temporary logins as received from the signaling server
    this._pcConfig = {
      iceServers: [{ urls: "stun:stun3.l.google.com:19302" }],
    }; //server list for peer connection element

    this.resetReadinessIndicators();

    this.configureSocketOnResponses();

    this.peerCQ.initialize();
    this.bandwidth.initialize();
    this.codec.initialize();
    this.raiseHandSignaling.initialize(this);
    this.userNameLabelSignaling.initialize(this);
    this.remoteMuteSignaling.initialize(this);
  };

  protected setBusLogicReference(_busLogic: BusLogicCore) {
    this.busLogic = _busLogic;
    this.raiseHandSignaling.setBusLogicReference(_busLogic);
    this.userNameLabelSignaling.setBusLogicReference(_busLogic);
    this.remoteMuteSignaling.setBusLogicReference(_busLogic);
  }

  getUniqueClientID = (): string => {
    return this.serverConnector.getUniqueClientID();
  };
  getMainRoom = (): string => {
    return this.serverConnector.getSocketData().getMainRoom();
  };
  /*getMainRoomPure = (): string => {
    return this.serverConnector.getSocketData().getMainRoomPure();
  };*/
  disconnectFromServer = (): void => {
    this.serverConnector.disconnectAndResetServer();
  };

  getPeerConnector = (): PeerConnector => {
    return this.peerConnector;
  };

  getPeerConnectionsQueue = (): PeerConnectionQueue => {
    return this.peerCQ;
  };

  getRaiseHandSignaling = (): SignalingRaiseHand => {
    return this.raiseHandSignaling;
  };

  getUserNameLabelSignaling = (): SignalingUserNameLabel => {
    return this.userNameLabelSignaling;
  };

  getRemoteMuteSignaling = (): SignalingRemoteMute => {
    return this.remoteMuteSignaling;
  };

  getServerConnector = (): ServerConnector => {
    return this.serverConnector;
  };

  resetReadinessIndicators = (alsoResetICE: boolean = false) => {
    //all 4 indicators for local readiness to connect are false initially
    this._signalingSetupReady = false;
    if (alsoResetICE) {
      this._iceServersReady = false; //assumptions: iceServers stay valid - thus no reset and no re-request
      this.iceServersReceived = false;
    }
    this._localConstraintApplicationReady = false;
    this._localStreamReady = false;
    this.localClientWithNoExistingPeerConnections = BOOL.B_TRUE;
  };
  registerLocalStreamReadiness = (_newReadinessState: boolean = true) => {
    this._localStreamReady = _newReadinessState;
  };
  registerLocalConstraintApplicationReadiness = (
    _newReadinessState: boolean = true
  ) => {
    this._localConstraintApplicationReady = _newReadinessState;
  };

  registerConnectionStatusConnectedCallback = (
    callback: (peerID: string) => void
  ): void => {
    this.connectionStatusConnectedCallback = callback;
  };

  //////////////////////////////////////////////////////////////////
  // Signaling server connection handling
  //////////////////////////////////////////////////////////////////

  //establish connection to signaling server / make client known to server to obtain an ID - see this._socket.on('connect') below
  connectToSignalingServer = () => {
    this.serverConnector.connectToSignalingServer();
  };

  //contact signaling server to join a particular room
  joinRoom = (room: string) => {
    if (room !== "") {
      this.serverConnector.emitCreateOrJoin(room);
      this.logger.logLocal(["Contacted server to create or join room: ", room]);
    }
  };

  private isReadyToConnect = (): boolean => {
    return (
      this._signalingSetupReady &&
      this._localStreamReady &&
      this._localConstraintApplicationReady &&
      this._iceServersReady
    );
  };

  // 4 conditions required to be ready to connect - this function is called after each of the four conditions turns true - i.e. it is ensured that the required conditions are met on its 4th call
  startConnectionsIfLocalSetupReadyToConnect = () => {
    if (this.isReadyToConnect()) {
      this.informPeersWhenReadyToConnect(this.getMainRoom());
      this.serverConnector.shareUserInfoForMailings();
      this.processPendingPccIfReady();
    }
  };

  private informPeersWhenReadyToConnect = (room: string) => {
    this.logger.logLocal([
      "executing informPeersWhenReadyToConnect with parameters _signalingSetupReady ",
      this._signalingSetupReady,
      ", _localStreamReady ",
      this._localStreamReady,
      ", _localConstraintApplicationReady ",
      this._localConstraintApplicationReady,
      ", _iceServersReady ",
      this._iceServersReady,
    ]);
    if (this.isReadyToConnect()) {
      //processPendingPccIfReady(); //trigger processing of items on the queue - i.e. create them and send offer or answer (dependent on queuing request)
      this.logger.logLocal([
        "ReadyToConnect! - Informing other peers in room ",
        room,
        " via signaling server",
      ]);
      this.serverConnector.emitClientReadyToConnect(
        room,
        this.localClientWithNoExistingPeerConnections
      );
      this.localClientWithNoExistingPeerConnections = BOOL.B_FALSE;

      this.busLogic
        .getUserNameLabel()
        .informAllPeersAboutLocalUserNameLabelTimeout();
      //stopTryingToInformPeersWhenReadyToConnect();      //clearInterval(keepTryingToInformPeers);
      //processPendingPccIfReady(); // to be migrated to regular execution via setInterval! - yet, this requires additional check in processPendingPCCIfReady to see whether processing is already underway / to not start it multiple times in parallel (or would that work?)
      //informUserSticky('you are now connected to the visav.is prototype - ask others to access the prototype URL to be able to connect and interact with them');
      return true;
    } else {
      //if not ready to connect on 1st attept, try again after 0.5 seconds
      return false;
    }
  };

  informServerAboutIntentToDisconnect = (reason: string) => {
    this.logger.logLocal([
      "executing informServerAboutIntentToDisconnect with parameter reason: ",
      reason,
    ]);
    this.serverConnector.emitWillDisconnect(this.getMainRoom(), reason);
  };

  pingToStayAlive = () => {
    this.logger.logLocal(["sending stay-alive ping to signaling server"]);
    this.serverConnector.emitActivePing();
  };

  //request ICE servers (STUN and TURN) from signaling server (including temporary login to those)
  private requestIceServers = () => {
    if (this.iceServersReceived) {
      this.logger.logLocal([
        "Dropped request to server to obtain ICE Servers (STUN/TURN) as these have already been retrieved on previous connect",
      ]);
    } else {
      this.serverConnector.emitGetIceServers();
      this.logger.logLocal([
        "Contacted server to obtain ICE Servers (STUN/TURN)",
      ]);
    }
  };

  ////////////////////////////////////////////////

  // this sends a message to the server - the server then redistributes the message to a particular other client in the room
  send121Message = (peerID: string, room: string, message: any) => {
    this.send121MessagePCS(peerID, room, message, SIGNALING_PURPOSE.UNDEFINED);
  };

  // this sends a message to the server - the server then redistributes the message to a particular other client in the room
  private send121MessagePCS = (
    peerID: string,
    room: string,
    message: any,
    PCSigP: SIGNALING_PURPOSE
  ) => {
    //TODO: signaling to only go via server if no direct p2p connection available
    this.logger.logLocal([
      "Local client ",
      this.getUniqueClientID(),
      " sending message for client ",
      peerID,
      " in room ",
      room,
      " to signaling server: ",
      message,
      " - signaling purpose: ",
      PCSigP,
    ]);
    this.serverConnector.emit121Message(peerID, room, message, PCSigP);
  };

  ////////////////////////////////////////////////

  private configureSocketOnResponses = () => {
    let localSocket: Socket = this.serverConnector
      .getSocketData()
      .getLocalSocket();

    //once client is connected to signaling server, display ID on console
    //NOTE: THIS SEEMS TO BE TRIGGERED ALSO AFTER RECONNECT ON CONNECTION LOSS - I.E. THIS WOULD BE THE PLACE TO call any subsequent operations that need to be re-executed
    localSocket.on("connect", () => {
      this.serverConnector.connectorActionOnConnect();
    });

    localSocket.on(
      "SuccessfulClientRegistration",
      (
        peerUniqueIDConfirmed,
        peerPassConfirmed,
        passVersionConfirmed,
        newIDAndPassAssigned
      ) => {
        this.serverConnector.connectorActionOnSuccessfulClientRegistration(
          peerUniqueIDConfirmed,
          peerPassConfirmed,
          passVersionConfirmed,
          newIDAndPassAssigned
        );
        this.requestIceServers();
        this.joinRoom(this.getMainRoom());
        //to be checked whether parallel execution of above steps without promise works
      }
    );

    //this is triggered if the room is full / the calling client cannot join
    localSocket.on("full", (room) => {
      this.logger.logLocal(["Room ", room, " is full"]);
      this.uiMessenger.warningToUser(
        "Cannot join room " +
          room +
          " as it is full. Please try again later / try another room"
      );
    });

    //this is triggered whenever a client joined the room - after joining completed successfully
    localSocket.on("joined", (room, id) => {
      this.logger.logLocal([
        "Client ",
        id,
        " joined: ",
        room,
        " - local client has ID ",
        this.getUniqueClientID(),
        " and is part of room ",
        this.getMainRoom(),
      ]);
      //logLocal(['going into the verification with room=',room,', _mainRoom=',this.soDa.getMainRoom(),', id=',id,', this.soDa.getLocalSocket().id=',_socket.id]);
      if (room === this.getMainRoom() && id === this.getUniqueClientID()) {
        this._signalingSetupReady = true;
        /*if (!(informPeersWhenReadyToConnect(this.soDa.getMainRoom()))) { 
              this.logger.logLocal(['startign interval execution of informPeersWhenReadyToConnect']);
              keepTryingToInformPeersWhenReadyToConnect();        //keepTryingToInformPeers = setInterval(function(){informPeersWhenReadyToConnect(this.soDa.getMainRoom());},500);
              }*/
        this.startConnectionsIfLocalSetupReadyToConnect();
      }
      //else if (room === this.soDa.getMainRoom()) {    this.data.enqueuePeerConnectionCreation(id,room,'offer',null);  } //register newly joined peer in queue from which connections get created
      //assumption: this is not needed here / covered by startConnections... above //processPendingPccIfReady(); //this is one among a few points that should cause the client to process pending peer connections, if ready to do so
    });

    //this is triggered whenever a client joined the room - after joining completed successfully
    localSocket.on(
      "clientReadyToConnect",
      (peerID: string, room: string, clientStartedFresh: BOOL) => {
        this.processServerMessageClientReadyToConnect(
          peerID,
          room,
          clientStartedFresh
        );
        /*if (this.isReadyToConnect()) {
          this.logger.logLocal([
            "Local client received <clientReadyToConnect> signal from client ",
            peerID,
            " in room ",
            room,
          ]);
          if (
            room === this.getMainRoom() &&
            peerID !== this.getUniqueClientID()
          ) {
            this.peerConnector.registerPeerThatSentReadyToConnectOrWelcomeSignal(
              peerID
            );
            if (
              !this.help.localPeerIDIsTheOneToInitiateConnection(
                this.getUniqueClientID(),
                peerID
              )
            ) {
              this.logger.logLocal([
                "Local client responding to remote client ",
                peerID,
                " with <welcomePleaseConnect> signal",
              ]);
              this.serverConnector.emitWelcomePleaseConnect(
                peerID,
                room,
                BOOL.B_FALSE
              );
              return;
            } else {
              this.establishOrFixConnectionToPeer(
                peerID,
                room,
                clientStartedFresh
              );
            }
          } else if (
            room !== this.getMainRoom() &&
            peerID !== this.getUniqueClientID()
          ) {
            console.error(
              "Received <clientReadyToConnect> signal for room other than main room: ",
              room
            );
          }
          this.processPendingPccIfReady(); //this is one among a few points that should cause the client to process pending peer connections, if ready to do so*/
        /*} else {
          // ignoring incoming clientReadyToConnect messages prevents concurring connection attempts that are caused by the local client being able to receive those signals while itself not being fully ready yet - when then ready, it tries to adress the old incoming signal and triggers its own outgoing signal to all peers, causing two equivalent connections to be built, failing due to conflicting states on both ends
          this.logger.logLocal([
            "Local client IGNORING <clientReadyToConnect> signal from Client ",
            peerID,
            " in room ",
            room,
            " as local setup is not ready yet",
          ]);
        }*/
      }
    );

    //this is triggered whenever a client joined the room - after joining completed successfully
    localSocket.on(
      "welcomePleaseConnect",
      (peerID, room, clientStartedFresh) => {
        if (this.isReadyToConnect()) {
          this.logger.logLocal([
            "Local client received <welcomePleaseConnect> signal from client ",
            peerID,
            " in room ",
            room,
            " with clientStartedFresh as ",
            clientStartedFresh,
          ]);
          if (
            room === this.getMainRoom() &&
            peerID !== this.getUniqueClientID()
          ) {
            if (
              !this.help.localPeerIDIsTheOneToInitiateConnection(
                this.getUniqueClientID(),
                peerID
              )
            ) {
              this.logger.logError([
                "Local client received <welcomePleaseConnect> signal from remote client ",
                peerID,
                " despite that peer being responsible to initiate the connection - signal thus must not be triggered",
              ]);
              return;
            } else {
              this.peerConnector.registerPeerThatSentReadyToConnectOrWelcomeSignalOrBCOffer(
                peerID
              );
              this.establishOrFixConnectionToPeer(
                peerID,
                room,
                clientStartedFresh
              );
            }
          } else if (
            room !== this.getMainRoom() &&
            peerID !== this.getUniqueClientID()
          ) {
            console.error(
              "Received <welcomePleaseConnect> signal for room other than main room: ",
              room
            );
          }
          this.processPendingPccIfReady(); //this is one among a few points that should cause the client to process pending peer connections, if ready to do so*/
        } else {
          // ignoring incoming clientReadyToConnect messages prevents concurring connection attempts that are caused by the local client being able to receive those signals while itself not being fully ready yet - when then ready, it tries to adress the old incoming signal and triggers its own outgoing signal to all peers, causing two equivalent connections to be built, failing due to conflicting states on both ends
          this.logger.logLocal([
            "Local client IGNORING <welcomePleaseConnect> signal from Client ",
            peerID,
            " in room ",
            room,
            " as local setup is not ready yet",
          ]);
        }
      }
    );

    //log reconnection attempts to console on connection loss
    localSocket.on("reconnecting", (number) => {
      this.logger.logWarning([
        "Connection to signaling server lost ('reconnecting' event) - attempting to reconnect... (attempt #",
        number,
        ")",
      ]);
      /*this.busLogic.warnUserStickyIfOnVC(
        "your network connection appears to be unstable - please allow for the app to reconnect prior starting any actions",
        600000,
        false
      );*/
    });

    //log reconnection attempts to console on connection loss
    localSocket.on("disconnect", () => {
      this.logger.logWarning([
        "Connection to signaling server lost ('disconnect' event) - attempting to reconnect...(unless visav.is has been paused)",
      ]);
      /*if (
        !this.data.isTemporaryPermissionToDisconnectFromSignalingWithoutWarningRegistered()
      )
        this.busLogic.warnUserStickyIfOnVC(
          "your network connection appears to be unstable - please allow for the app to reconnect prior starting any actions",
          600000,
          false
        );*/

      this._signalingSetupReady = false;
      this.serverConnector.connectorActionOnDisconnect();
    });

    //log failed reconnection attempt
    localSocket.on("reconnect_failed", () => {
      this.logger.logLocal(["Reconnection attempt failed"]);
      this.uiMessenger.warningToUserSticky(
        "weak network connection persits - unable to connect to server - please restart once connected to the internet...",
        600000
      );
    });

    //log successful reconnection after connection loss
    localSocket.on("reconnect", (number) => {
      this.logger.logLocal([
        "Successfully reconnected to signaling server after temporary connection loss - attempts needed: ",
        number,
      ]);
      //INSERT ANY RECONNECT ACTIVITY HERE - NOTE THAT CONNECT EVENT IS ALSO FIRED / activity described in respective paragraph is also executed
      //this.busLogic.requiredRepairActivitiesAfterConnectionLossAndReconnect();
    });

    localSocket.on("leaving", (room, id) => {
      this.logger.logLocal([
        "Info received: Client ",
        id,
        " leaving room ",
        room,
      ]);
      if (id === this.getUniqueClientID()) {
        this.logger.logError([
          "Local client received 'leaving' message from server with local client ID - this should never be the case",
        ]);
        return;
      }
      this.busLogic.handleRemoteHangup(id, room, false, true); // todo: in multi-room scenarios, one should consider whether client is still in another room!!
      //further actions may be needed on advanced scenarios dealing with multiple connections
    });

    //outputs any logging information received from the server to the console
    localSocket.on("log", (array) => {
      this.logger.logFromServer(array);
    });

    localSocket.on("IceServers", (token) => {
      this._pcConfig = token;
      this.logger.logLocal([
        "Info received: ICE Servers were provided by signaling server",
      ]); //, _pcConfig
      this.iceServersReceived = true;
      this._iceServersReady = true; //TODO: check whether received token holds adequate server info in property iceServers, e.g. property exists with >0 or >1 items (STUN+TURN)
      //processPendingPccIfReady();//trigger processing of queue if iceServers were the last missing piece
      this.startConnectionsIfLocalSetupReadyToConnect();
    });

    // When this client receives a 121 message from another client..
    localSocket.on("121message", (peerID, room, message, PCSigP) => {
      //let serial : Serializer = new Serializer();
      //var PCSigP = serial.deserializeSignalingPurpose(_PCSigP);
      this.logger.logLocal([
        'Local client received "message" element from client ',
        peerID,
        " in room ",
        room,
        ":",
        message,
      ]); //log any messages to console
      //var _PCSigP = message.PCSignalingPurpose;
      //when another client initiated an interaction by submitting an offer, create connection setup on this end and answer
      if (message.type === "offer") {
        //other client initiates a connection - base case is a new unknown peer - todo: investigate re-connects etc and how they fit in..

        //if there's no peer connection for that peer yet..
        if (this.data.existsPC(peerID, PCSigP))
          this.logger.logWarning([
            "received offer from client ",
            peerID,
            " despite PC existing already - will attempt to reconnect",
          ]);
        this.peerCQ.cancelPendingPeerConnectionsForPeer(
          peerID,
          room,
          PCSigP,
          true
        );
        //consideration for the future: instead of cancelling only PC for current signaling purpose, all pc's should be cancelled and re-established from scratch
        this.peerCQ.enqueuePeerConnectionCreation(
          peerID,
          room,
          "answer",
          new RTCSessionDescription(message),
          PCSigP
        ); //.. in any case, enqueue a new peer connection creation
        this.processPendingPccIfReady(); //trigger processing of items on the queue - i.e. create them and send offer or answer (dependent on queuing request)
        if (PCSigP == SIGNALING_PURPOSE.BASE_CONNECTION) {
          //when remote peer connects fresh after a connection loss of local client, accompany the creation of the base connection (code above) by shutting down everything else
          this.busLogic.removePeerFromExistingVCIfOnThere(peerID, true, true);
          this.data.retireAllPCForPeerExclBC(peerID);
          this.data.retireAllPMSForPeerExclBC(peerID);
          this.peerConnector.registerPeerThatSentReadyToConnectOrWelcomeSignalOrBCOffer(
            peerID
          );
        }
      } else if (message.type === "answer") {
        if (!this.isReadyToConnect()) {
          //todo: fix broken local setup - hypothetical case - if peer is answering, local setup should have been ready when it sent the offer
          console.error(
            "local setup not ready despite peers sending <answer> messages - reloading the local app recommended."
          );
        }
        if (this.data.existsPC(peerID, PCSigP)) {
          // only if pc actually got created, add iceCandidates - creation may have been cancelled in the interim - then candidates don't matter / the remote
          let _pc = this.data.getPC(peerID, PCSigP);
          let _pcState = _pc.connectionState;
          if (
            _pcState != "closed" /* &&
            _pcState != "failed" &&
            _pcState != "disconnected"*/
          ) {
            _pc.setRemoteDescription(new RTCSessionDescription(message));
          } else {
            this.logger.logError([
              "local client ",
              this.getUniqueClientID(),
              " dropping ANSWER from ",
              peerID,
              " with purpose ",
              PCSigP,
              " because existing PC is in state ",
              _pcState,
            ]);
          }
        } else {
          console.error(
            "Received ANSWER message from peer ",
            peerID,
            " for purpose ",
            PCSigP,
            ", but no local PC exists - thus dropping ANSWER"
          );
        }
      } else if (message.type === "candidate") {
        var candidate = new RTCIceCandidate({
          sdpMLineIndex: message.label,
          candidate: message.candidate,
        });
        if (!this.isReadyToConnect()) {
          //this is typically caused if the client receives the candidate messages following an initial offer message - before the client's media stream is ready
          this.peerCQ.addCandidateToQueuedPeerConnectionCreation(
            peerID,
            room,
            candidate,
            PCSigP
          );
          this.logger.logWarning([
            "Received candidate message while local setup not ready yet - addding candidate info to queued peer connection request.",
          ]);
        } else {
          this.processPendingPccIfReady(); //trigger processing of items on the queue - i.e. create them and send offer or answer (dependent on queuing request)
          if (this.data.existsPC(peerID, PCSigP)) {
            // only if pc actually got created, add iceCandidates - creation may have been cancelled in the interim - then candidates don't matter / the remote
            let _pc = this.data.getPC(peerID, PCSigP);
            let _pcState = _pc.connectionState;
            if (
              _pcState != "closed" /*&&
              _pcState != "failed" &&
              _pcState != "disconnected"*/
            ) {
              this.data.getPC(peerID, PCSigP).addIceCandidate(candidate);
            } else {
              this.logger.logWarning([
                "local client ",
                this.getUniqueClientID(),
                " dropping ICE candidate from ",
                peerID,
                " with purpose ",
                PCSigP,
                " because existing PC is in state ",
                _pcState,
              ]);
            }
          } else {
            console.error(
              "Received candidate messages from peer ",
              peerID,
              " local PC has not been created even though processing of pendingPCCss has been triggered - purpose of candidate: ",
              PCSigP
            );
          }
        }
      } else {
        this.handleAdditionalFeatures121Message(peerID, room, message, PCSigP);
      }
    });

    //when server rejects messages as client not properly authenticated
    localSocket.on(
      "SenderHasNoActiveAndAuthorizedConnectionToServer",
      (context) => {
        this.logger.logError([
          "Server responded to a request by the local client saying that local client has no authorized connection - message context was: ",
          context,
        ]);
        /*this.stateMgr.reloadVISAVIS(
        "ReloadingVisavisPostAuthorizationFailureWithSignalingServer",
        "Network issue requires visav.is to restart - will reconnect you to your team in just a moment",
        2000
      );*/
        /*this.logger.logError([
        "Local client received server response indicating authorization failure - restarting app to resolve...",
      ]);
      this.uiMessenger.warningToUserSticky(
        "Network issue requires visav.is to restart - will reconnect you to your team in just a moment",
        4500
      );
      setTimeout(() => {
        location.reload(); //@TODO: formal closure of connection / windows / etc
      }, 5000);*/
      }
    );

    //when server looses connection to another client
    localSocket.on("peerServerDisconnect", (room, peerID) => {
      this.logger.logWarning([
        "Server notified local client of other client in room having lost connection to the server > room / client is: ",
        room,
        " / ",
        peerID,
      ]);
      /*this.uiMessenger.warningToUser(
        "Note: One of your teammates is observing network issues - please wait for connection to recover prior starting any actions"
      );*/
    });

    //when server response indicates that recipient did not receive message as temporarily not connected to server
    localSocket.on(
      "121MessagePeerHasNoActiveAndAuthorizedConnectionToServer",
      (peerID, room, message, PCSignalingPurpose) => {
        this.logger.logWarning([
          "Local client received server response indicating that peer did not receive 121Message: ",
          "peer ",
          peerID,
          ", message ",
          message,
        ]);
        let messageType: string = message.type;
        if (
          messageType != "121UserNameLabelMessage" &&
          messageType != "121LocalMuteStateInfoMessage"
        ) {
          this.uiMessenger.warningToUserSticky(
            "Network issue observed - one of your teammates did not respond to your latest request - please try again once their video recovered - reload if any problems persist",
            4500
          );
        }
      }
    );

    //when another peer is checking signaling connectivity prior to restarting its peer connection
    localSocket.on(
      "areYouReadyToReconnect",
      (peerID, room, PCSignalingPurpose) => {
        this.logger.logWarning([
          "Local client received <areYouReadyToReconnect> connection check from client ",
          peerID,
          " and sigP ",
          PCSignalingPurpose,
        ]);
        if (
          this.data.existsPC(peerID, PCSignalingPurpose) //&&
          //this.data.getPC(peerID, PCSignalingPurpose).connectionState !="connected" //=="failed"//accept any state as this action is only triggered if one peer is "failed"
        ) {
          this.logger.logWarning([
            "Local client now responding to <areYouReadyToReconnect> as local PC for peer ",
            peerID,
            " and sigP ",
            PCSignalingPurpose,
            " exists (and is not properly connected)",
          ]);
          /*this.busLogic
            .getUserNameLabel()
            .informSinglePeerAboutLocalUserNameLabelTimeout(peerID);*/
          this.serverConnector.emitReadyToReconnectAnswer(
            peerID,
            room,
            PCSignalingPurpose
          );
        } else {
          this.logger.logError([
            "Local client NOT responding to <areYouReadyToReconnect> as local PC for peer ",
            peerID,
            " and sigP ",
            PCSignalingPurpose,
            " DOES NOT exist (or properly connected)",
          ]);
        }
      }
    );

    //when peer with whom connection state is failed responded that it is ready to reconnect - i.e. signaling works
    localSocket.on(
      "iAmReadyToReconnect",
      (peerID, room, PCSignalingPurpose) => {
        this.logger.logWarning([
          "Local client received <iAmReadyToReconnect> connection check response from client ",
          peerID,
          " with sigP ",
          PCSignalingPurpose,
          " - now starting attempt to reconnect the peer connection",
        ]);
        if (
          this.data.existsPC(peerID, PCSignalingPurpose) &&
          this.data.getPC(peerID, PCSignalingPurpose).connectionState ==
            "failed" //!= "connected" //this is triggered only on failed - so it should still be failed when starting the connect
        ) {
          this.processPeerReadyToReconnect(peerID, room, PCSignalingPurpose);
        } else {
          this.logger.logError([
            "Local client NOT reconnecting in response to <iAmReadyToReconnect> as local PC for peer ",
            peerID,
            " and sigP ",
            PCSignalingPurpose,
            " DOES NOT exist (or is already properly connected)",
          ]);
        }
      }
    );
  };

  register121MessageListener = (
    messageType: string,
    listenerFunction: I_MessageListenerFunction121PeerMessage
  ): void => {
    this.registered121MessageListeners.set(messageType, listenerFunction);
  };

  private handleAdditionalFeatures121Message = (
    senderID: string,
    roomName: string,
    message: any,
    PCSigP: SIGNALING_PURPOSE
  ) => {
    this.logger.logLocal([
      "SignalingCore received additional feature 121 message of type: ",
      message.type,
    ]);
    if (!this.registered121MessageListeners.has(message.type)) {
      this.logger.logError([
        "SignalingCore received unknown message type - message will be ignored: ",
        message.type,
      ]);
      return;
    } else
      this.registered121MessageListeners.get(message.type)(
        senderID,
        roomName,
        message,
        PCSigP
      );
  };

  /////////////////////////////////////////////////////////

  //creates local peerconnection element and initializes relevant event handlers
  private createPeerConnection = (
    peerID: string,
    room: string,
    PCSigP: SIGNALING_PURPOSE
  ): VRTCPeerConnection => {
    try {
      //var _pc: any = new RTCPeerConnection(this._pcConfig);
      var _pc: VRTCPeerConnection = new VRTCPeerConnection(
        this._pcConfig,
        peerID,
        room,
        PCSigP
      );
      _pc.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
        this.handleIceCandidate121(event, PCSigP);
      };
      _pc.ontrack = (event: RTCTrackEvent) => {
        this.busLogic.handleRemoteTrackAdded(event, PCSigP);
      };

      _pc.onconnectionstatechange = (event: Event) => {
        let newCS = _pc.connectionState;
        /*this.logger.logWarning([
          "Local client noticed peer connection going to connectionState ",
          newCS,
          " for client ",
          _pc.getPeerID(),
        ]);*/
        if (
          newCS == "failed" /*|| newCS == "disconnected" || newCS == "closed"*/
        ) {
          this.logger.logWarning([
            "Local client noticed peer connection going to state ",
            newCS,
            " for client ",
            _pc.getPeerID(),
            " - now performing cleanup activities",
          ]);

          if (
            this.help.localPeerIDIsTheOneToInitiateConnection(
              this.getUniqueClientID(),
              peerID
            )
          ) {
            this.logger.logLocal([
              "Now checking with peer ",
              peerID,
              " whether it is ready to reconnect - local client is responsible to initiate connection",
            ]);
            this.serverConnector.emitReadyToReconnectQuestion(
              peerID,
              room,
              PCSigP
            );
          } else {
            this.logger.logLocal([
              "Not taking any action to reconnect to peer ",
              peerID,
              " as peer is responsible to initiate connection",
            ]);
          }
          /*this.serverConnector.initiateServerConnectionCheck(
            () => {
              this.busLogic.localCleanUpToDealWithRemoteButNotLocalConnectionFailure(
                _pc.getPeerID()
              );
            },
            () => {
              this.busLogic.localCleanUpToDealWithLocalConnectionFailure(); // end VC locally if not connected to signaling
            },
            10000
          );*/
        } else if (newCS == "connected") {
          this.connectionStatusConnectedCallback(_pc.getPeerID());
        }
      };
      //}
      //_pc.peerID = peerID;
      //_pc.room = room;
      this.logger.logLocal([
        "Created local RTCPeerConnnection for peer ",
        peerID,
        " in room ",
        room,
        ", purpose: ",
        PCSigP,
      ]);
      return _pc;
    } catch (e) {
      this.logger.logWarning([
        "Failed to create local PeerConnection for peer ",
        peerID,
        " in room ",
        room,
        ", purpose: ",
        PCSigP,
        ">> exception: ",
        e.toString(),
      ]);
      this.uiMessenger.errorToUser(
        "An unexpected error occured when trying to connect to your teammate. You may need to restart the app if the problem persists."
      );
      return null;
    }
  };

  private doCall121 = (
    peerID: string,
    room: string,
    PCSigP: SIGNALING_PURPOSE,
    renegotiationOnly: boolean = false
  ) => {
    this.logger.logLocal([
      "doCall: Starting to send offer to peer ",
      peerID,
      " in room ",
      room,
      " - renegotiation: ",
      renegotiationOnly,
    ]);
    var peerC = this.data.getPC(peerID, PCSigP);
    this.logger.logLocal([
      "Link to peer connection object for debugging purposes: ",
      peerC,
    ]);
    let offerOptions = { iceRestart: false };
    if (renegotiationOnly) offerOptions = { iceRestart: true };
    peerC.createOffer(offerOptions).then((sessionDescription) => {
      // Set Opus as the preferred codec in SDP if Opus is present.
      // sessionDescription.sdp = preferOpus(sessionDescription.sdp);
      peerC.setLocalDescription(sessionDescription);
      sessionDescription.sdp = this.bandwidth.setMediaBitratesOnSDP(
        sessionDescription.sdp,
        PCSigP
      );
      //logLocal(['Sending offer message incl. session description to client ',peerID,': ', sessionDescription]);
      this.send121MessagePCS(peerID, room, sessionDescription, PCSigP); //finally submit offer/answer to signaling server
    }, this.handleCreateOfferError);
  };

  private doAnswer121 = (
    peerID: string,
    room: string,
    PCSigP: SIGNALING_PURPOSE
  ) => {
    this.logger.logLocal([
      "doAnswer121: Starting to send answer to peer ",
      peerID,
      " in room ",
      room,
    ]);
    var peerC = this.data.getPC(peerID, PCSigP);
    this.logger.logLocal(["peerConnection retrieved: ", peerC]);
    peerC.createAnswer().then((sessionDescription) => {
      // Set Opus as the preferred codec in SDP if Opus is present.
      // sessionDescription.sdp = preferOpus(sessionDescription.sdp);
      peerC.setLocalDescription(sessionDescription);
      sessionDescription.sdp = this.bandwidth.setMediaBitratesOnSDP(
        sessionDescription.sdp,
        PCSigP
      );
      this.logger.logLocal([
        "Sending answer message incl. description message to client ",
        peerID,
        ": ",
        sessionDescription,
      ]);
      this.send121MessagePCS(peerID, room, sessionDescription, PCSigP); //finally submit offer/answer to signaling server
    }, this.onCreateSessionDescriptionError);
  };

  private handleCreateOfferError = (event: Error) => {
    this.logger.logLocal(["createOffer() error: ", event.toString()]);
  };

  private onCreateSessionDescriptionError = (error: Error) => {
    console.trace(
      "Failed to create local session description: ",
      error.toString()
    );
    this.logger.logLocal([
      "Failure to create session description - error >> ",
      error.toString(),
    ]);
  };

  // ####### COMMON ERROR SOURCE RE ICE: When looking for ICE candidate problems (candidates coming in after connection +/- established) - then check for connection having been triggered twice, e.g. from both ends or by host and client!

  private handleIceCandidate121 = (event, PCSigP: SIGNALING_PURPOSE) => {
    //yet to be adjusted to consider 121 comms only
    this.logger.logLocal([
      "icecandidate event - client ",
      this.getUniqueClientID(),
      " sending ice candidate to client ",
      event.target.peerID,
      ": ",
      event,
      ", connection purpose: ",
      PCSigP,
    ]);
    //if (event.candidate) { //old version until 20210916
    if (event.candidate !== null) {
      this.send121MessagePCS(
        event.target.peerID,
        event.target.room,
        {
          type: "candidate",
          label: event.candidate.sdpMLineIndex,
          id: event.candidate.sdpMid,
          candidate: event.candidate.candidate,
        },
        PCSigP
      );
    } else {
      this.logger.logLocal([
        "icecandidate event - end of candidate (121) submission to signaling server.",
      ]);
    }
  };

  /*handleConnectionStateChangeSignaling = (
    event: Event,
    peerID: string,
    pc: VRTCPeerConnection
  ): void => {
    let cState = pc.iceConnectionState;
    if (
      pc.isPriorFirstConnection() &&
      (cState == "completed" || cState == "connected")
    ) {
      setTimeout(() => {
        pc.registerFirstSuccessfulConnect();
      }, 1000);
    } else if (
      !pc.isPriorFirstConnection() &&
      cState != "completed" &&
      cState != "connected"
    ) {
      this.logger.logLocal([
        "Issues on network connection with peer observed ( ",
        peerID,
        " )- connection state changed to: ",
        cState,
      ]);
      this.uiMessenger.informUserSticky(
        "one of your teammates is observing network issues - please allow for them to recover prior starting any actions",
        10000
      );
    } else if (!pc.isPriorFirstConnection()) {
      //i.e. post first connect
      this.uiMessenger.informUser(
        "your teammate was able to reconnect - you can now start interacting again"
      );
    }
  };*/

  //////////////////////////////////////////////////////////////////
  // Multi-peer connection handling
  //////////////////////////////////////////////////////////////////

  //
  // Design decision: When a peer joins the room, all peers that are already in the room initiate a connection to the new peer.
  // They get aware about the new peer and his ID via the 'joined' event shared by the signaling server.
  // The peer itself is initially not yet aware of the other clients in the room.
  //

  // Open question: What if individual peers do not manage to establish a p2p connection - how to handle that in a n-party conversation / on the UI / etc

  // Open question: where to handle cases in which peers do not respond?

  // Architecture vision: signaling server does only establish p2p Data exchange which then evolves into self-managing network without major signaling requirements beyond initial connect

  processPendingPccIfReady = () => {
    //logLocal(['request to process pending pcc started with variable values: _localStreamReady=',_localStreamReady,', _signalingSetupReady=',_signalingSetupReady]);
    if (this.isReadyToConnect()) {
      this.processPendingPeerConnectionCreations();
    } // else not ready - todo: check whether any actions should get initiated here - e.g. reload local media stream under certain conditions or unblock signaling process in case stuck..
    else {
      this.logger.logLocal([
        "Processing pending peer connections cancelled because not ready - variables are _localStreamReady=",
        this._localStreamReady,
        ", _signalingSetupReady=",
        this._signalingSetupReady,
        ", _localConstraintApplicationReady=",
        this._localConstraintApplicationReady,
        ", _iceServersReady=",
        this._iceServersReady,
      ]);
    }
  };

  private processPendingPeerConnectionCreations = (): void => {
    this.logger.logLocal(["Processing pending peer connections"]);
    //todo: validate whether local stream is ready - as part of below while condition
    while (this.peerCQ.arePeerConnectionCreationsPending()) {
      let _newPeer: QueuedPeerConnection =
        this.peerCQ.getNextPendingPeerConnectionCreation();
      let _PCSigP: SIGNALING_PURPOSE = _newPeer.PCSignalingPurpose;
      this.logger.logLocal([
        "now processing request for new peer connection: ",
        _newPeer.peerID,
        " with signaling purpose ",
        _PCSigP,
        ", cancelled state ",
        _newPeer.requestCancelled,
        ", and where pc for peer exists: ",
        this.data.existsPC(_newPeer.peerID, _PCSigP),
      ]);
      if (
        !_newPeer.requestCancelled /*|| this.data.existsPC(_newPeer.peerID, _PCSigP)*/
      ) {
        this.logger.logLocal([
          "Processing request to create local peer connection instance for client ",
          _newPeer.peerID,
          " / ",
          _newPeer.room,
          " / ",
          _newPeer.PCSignalingPurpose,
        ]);
        // old todo: check whether peer is still in the room? may not be needed if all leavers get removed from queue and existing PCs

        let thisIsRenegotiationOfExistingPC = this.data.existsPC(
          _newPeer.peerID,
          _PCSigP
        );

        let peerC: VRTCPeerConnection = null;
        if (thisIsRenegotiationOfExistingPC) {
          //this is reconnect on old PC only
          peerC = this.data.getPC(_newPeer.peerID, _PCSigP);
          //assumption - previously used local media stream is still working, even if PC has a problem / needs restart
        } else {
          //this is new PC
          peerC = this.createPeerConnection(
            _newPeer.peerID,
            _newPeer.room,
            _PCSigP
          ); //creates local peer connection element and initializes event handlers
          this.data.registerPC(_newPeer.peerID, peerC, _PCSigP);
          this.buildAdequateMediaStreamAndAddItToNewPC(
            peerC,
            _newPeer,
            _PCSigP
          );
        }

        if (_newPeer.localActionRequired === "offer") {
          this.doCall121(
            _newPeer.peerID,
            _newPeer.room,
            _PCSigP,
            thisIsRenegotiationOfExistingPC
          );
        } else if (_newPeer.localActionRequired === "answer") {
          this.data
            .getPC(_newPeer.peerID, _PCSigP)
            .setRemoteDescription(_newPeer.remoteDescription);
          //add any ice candidates received prior to the media stream being ready to the newly created peer connection
          while (_newPeer.candidatesReceived.length > 0) {
            this.logger.logLocal([
              "adding new candidate to PC: ",
              this.data.getPC(_newPeer.peerID, _PCSigP),
            ]);
            this.data
              .getPC(_newPeer.peerID, _PCSigP)
              .addIceCandidate(_newPeer.candidatesReceived.shift());
          }
          this.doAnswer121(_newPeer.peerID, _newPeer.room, _PCSigP);
        } else {
          console.error(
            "When processing queued peer connection for client ",
            _newPeer.peerID,
            " and purpose ",
            _PCSigP,
            ", unknown request type encountered: ",
            _newPeer.localActionRequired
          );
        }
      } else {
        this.logger.logLocal([
          "Dropping request to create peer connection for client ",
          _newPeer.peerID,
          " as this has been flagged as cancelled or because PC existed already",
        ]);
      }
    }
    this.logger.logLocal([
      "Processing of pending peer connections finished - queue is empty",
    ]);
  };

  private buildAdequateMediaStreamAndAddItToNewPC = (
    peerC: VRTCPeerConnection,
    _newPeer: QueuedPeerConnection,
    _PCSigP: SIGNALING_PURPOSE
  ) => {
    let targetMS: MediaStream = null;
    if (
      _PCSigP === SIGNALING_PURPOSE.BASE_CONNECTION ||
      _PCSigP === SIGNALING_PURPOSE.LR_VIDEO ||
      _PCSigP === SIGNALING_PURPOSE.AUDIO_ONLY
    ) {
      targetMS = this.data.getLocalMSByQuality("L");
    } //note: audio-only also gets low resolution stream added as this stream will replace basic audio-less stream on video bar
    else if (_PCSigP === SIGNALING_PURPOSE.MR_VIDEO) {
      targetMS = this.data.getLocalMSByQuality("M");
    } else if (_PCSigP === SIGNALING_PURPOSE.HR_VIDEO) {
      targetMS = this.data.getLocalMSByQuality("H");
    } else if (_PCSigP === SIGNALING_PURPOSE.SCREEN_SHARE) {
      targetMS = this.data.getLocalMSScreenShare();
    }
    if (
      _newPeer.localActionRequired === "answer" &&
      _PCSigP === SIGNALING_PURPOSE.SCREEN_SHARE &&
      targetMS !== null
    ) {
      targetMS = null;
    } //screen share stream is 1-way - no stream on response
    if (targetMS !== null) {
      targetMS.getVideoTracks().forEach((track) => {
        //peerC.videoSender =
        peerC.addTrack(track, targetMS);
      });
    } // add only video tracks to peer connection - audio tracks to follow further down, if adequate wrt purpose
    if (
      targetMS !== null &&
      _PCSigP !== SIGNALING_PURPOSE.BASE_CONNECTION &&
      _PCSigP !== SIGNALING_PURPOSE.SCREEN_SHARE
    ) {
      //everyone except base connection and screenshare gets audio tracks added
      this.data
        .getLocalMSByQuality("H")
        .getAudioTracks()
        .forEach((track) => {
          //peerC.audioSender =
          peerC.addTrack(track, targetMS);
        }); //idea: high res video has audio attached
    }
    // disable h264 codec on the app as app (while using chrome parameters for GPU disablement) is unable to decode hardware-encoded h264 streams from safari
    if (this.help.appIsActive())
      this.codec.removeCodecFromSupportedCodecsOfPC(
        peerC,
        VIDEO_CODEC.MIME_TYPE_H264
      );
  };

  //let keepTryingToProcessPendingPeerConnections = setInterval(processPendingPccIfReady(),500);

  //////////////////////////////////////////////////////////////////
  // Multi-room room matching
  //////////////////////////////////////////////////////////////////

  private getRoomName = (defaultRoomName: string): string => {
    this.logger.logLocal(["Determining room to be used by local client ..."]);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const locallyStoredRoom = this.settings.getMainRoom();

    if (
      this.context.getCurrentContext() ==
      APP_CONTEXT.WEB_MEETING_CLIENT_VIA_BROWSER_OKAY
    ) {
      //web meetings must have mandatory url parameter 'meetid'
      if (urlParams.has("meetid")) {
        const room_para = urlParams.get("meetid");
        this.logger.logLocal(["Using meetid URL parameter"]);
        return room_para;
      } else {
        //error - must not happen / needs to show error message on UI via CSS adjustments
        this.logger.logError([
          "Meeting web client accessed without meetid parameter",
        ]);
        return null;
      }
    } else if (
      this.context.getCurrentContext() ==
        APP_CONTEXT.WEB_GO_CLIENT_VIA_BROWSER_OKAY &&
      !this.settings.existsWebClientAutoLoginFlag()
    ) {
      //web meetings must have mandatory url parameter 'room' - unless using auto login
      if (urlParams.has("room")) {
        let roomReturnValue = urlParams.get("room");
        this.logger.logLocal(["Using room URL parameter"]);
        if (urlParams.has("sizing")) {
          let passPara: string = urlParams.get("sizing");
          let saltyDelta: number = 7 * 81761087;
          let defaultPassPhrase: string = "webgo-teamRoom.html";
          if (
            "" + passPara !=
            "" +
              (this.help.hash(this.help.standardizeString(defaultPassPhrase)) +
                saltyDelta)
          ) {
            //only if pass phrase is not default placeholder
            let passNumber: number =
              (passPara as unknown as number) - saltyDelta;
            roomReturnValue = roomReturnValue + "____" + passNumber;
            this.logger.logLocal(["applying provided room pass"]);
          }
        }
        return roomReturnValue;
      } else {
        //error - must not happen / needs to show error message on UI via CSS adjustments
        this.logger.logError(["webGO client accessed without room parameter"]);
        return null;
      }
    } else if (locallyStoredRoom != null && locallyStoredRoom != "") {
      //if user stored room on local client, choose this one
      let roomReturnValue: string = locallyStoredRoom;
      if (
        this.settings.existsMainRoomPass() &&
        this.settings.getMainRoomPass() != ""
      ) {
        let pwHash = this.settings.getMainRoomPass();
        roomReturnValue = roomReturnValue + "____" + pwHash;
      }
      this.logger.logLocal([
        "Using locally stored room - ignoring URL parameters if any",
      ]);
      return roomReturnValue;
    } else if (urlParams.has("room")) {
      // if no room stored by user, use room parameter from URL if any
      const room_para = urlParams.get("room");
      this.logger.logLocal(["Using URL room parameter"]);
      return room_para;
    } else {
      // else, use default room
      this.logger.logLocal(["Using default room as no other input provided"]);
      return defaultRoomName;
    }
  };

  //////////////////////////////////////////////////////////////////
  // Rump functions forwarding calls to child class signalingVC, where they get overwritten
  //////////////////////////////////////////////////////////////////

  requestStreamChange121(peerID: string, purpose: SIGNALING_PURPOSE) {
    //do nothing - this function is overwritten in class signalingVC, triggering respective behavior there
    throw new Error(
      "requestStreamChange121 must be called on signalingVC (overwritten function) and not on signaling"
    );
  }

  //////////////////////////////////////////////////////////////////
  // HOW TO RECONNECT AFTER CONNECTION FAILURE
  //////////////////////////////////////////////////////////////////

  private repairAllBrokenConnectionsWithPeer = (peerID: string): void => {
    if (
      !this.help.localPeerIDIsTheOneToInitiateConnection(
        this.getUniqueClientID(),
        peerID
      )
    )
      return;
    this.logger.logLocal([
      "trying to restart broken connections with peer",
      peerID,
    ]);
    this.repairBrokenConnectionWithPeerForSigP(
      peerID,
      SIGNALING_PURPOSE.BASE_CONNECTION
    );
    this.repairBrokenConnectionWithPeerForSigP(
      peerID,
      SIGNALING_PURPOSE.AUDIO_ONLY
    );
    this.repairBrokenConnectionWithPeerForSigP(
      peerID,
      SIGNALING_PURPOSE.HR_VIDEO
    );
    this.repairBrokenConnectionWithPeerForSigP(
      peerID,
      SIGNALING_PURPOSE.MR_VIDEO
    );
    this.repairBrokenConnectionWithPeerForSigP(
      peerID,
      SIGNALING_PURPOSE.LR_VIDEO
    );
    this.repairBrokenConnectionWithPeerForSigP(
      peerID,
      SIGNALING_PURPOSE.SCREEN_SHARE
    );
    this.processPendingPccIfReady();
  };

  private repairBrokenConnectionWithPeerForSigP = (
    peerID: string,
    pcSigP: SIGNALING_PURPOSE,
    triggerProcessingOfPendingPCsIfAny: boolean = false
  ) => {
    if (
      this.data.existsPC(peerID, pcSigP) &&
      this.data.getPC(peerID, pcSigP).connectionState == "failed" //!= "connected"
    ) {
      this.peerCQ.enqueuePeerConnectionCreation(
        peerID,
        this.getMainRoom(),
        "offer",
        null,
        pcSigP
      );
      this.logger.logWarning([
        "Trying to restart connection with peerID / sigP: ",
        peerID,
        " / ",
        pcSigP,
      ]);
      if (triggerProcessingOfPendingPCsIfAny) this.processPendingPccIfReady();
    }
  };

  private repairAnyBrokenConnections = () => {
    //this is anything that needs to happen in terms of cleanup from old connections prior to starting any reconnection activities post the initial socket connection
    this.logger.logWarning([
      "trying to restart broken connections on reconnect",
    ]);
    //this.data.retireAllStoppedPCsInclMatchingPMSs();
    //this.data.retireAllInActivePMSInclMatchingPCs();

    this.restartAllBrokenConnectionsForSigP(
      true,
      SIGNALING_PURPOSE.BASE_CONNECTION
    );
    this.restartAllBrokenConnectionsForSigP(true, SIGNALING_PURPOSE.AUDIO_ONLY);
    this.restartAllBrokenConnectionsForSigP(true, SIGNALING_PURPOSE.HR_VIDEO);
    this.restartAllBrokenConnectionsForSigP(true, SIGNALING_PURPOSE.MR_VIDEO);
    this.restartAllBrokenConnectionsForSigP(true, SIGNALING_PURPOSE.LR_VIDEO);
    this.restartAllBrokenConnectionsForSigP(
      true,
      SIGNALING_PURPOSE.SCREEN_SHARE
    );

    //this.busLogic.requiredRepairActivitiesAfterConnectionLossAndReconnect();
  };

  private restartAllBrokenConnectionsForSigP = (
    filterIDsToStartOnlyFromOneSide: boolean,
    pcSigP: SIGNALING_PURPOSE
  ): void => {
    let pcs = this.data.getAllPCsOfSignalingPurpose(pcSigP);
    pcs.forEach((val: VRTCPeerConnection, key: string, map) => {
      if (
        /*val.connectionState != "connected"
        /*val.connectionState == "disconnected" ||*/
        val.connectionState == "failed" /*||
        val.connectionState == "closed"*/
      ) {
        this.logger.logWarning([
          "peeer connection in failed state encoutered with peerID / sigP: ",
          key,
          " / ",
          pcSigP,
        ]);
        if (
          !filterIDsToStartOnlyFromOneSide ||
          this.help.localPeerIDIsTheOneToInitiateConnection(
            this.getUniqueClientID(),
            key
          )
        ) {
          this.peerCQ.enqueuePeerConnectionCreation(
            key,
            this.getMainRoom(),
            "offer",
            null,
            pcSigP
          );
          this.logger.logWarning([
            "Trying to restart connection with peerID / sigP: ",
            key,
            " / ",
            pcSigP,
          ]);
        }
        //this.retirePC(key, SIGNALING_PURPOSE.BASE_CONNECTION);
        //this.retirePMS(key, SIGNALING_PURPOSE.BASE_CONNECTION);
      }
    });
    this.processPendingPccIfReady();
  };

  triggerHostReassignmentIfHostDisconnected(clientIDThatLeft: string): void {
    //stub - to be overwritten by buslogicVC child class
  }

  establishOrFixConnectionToPeer = (
    peerID: string,
    room: string,
    clientStartedFresh: BOOL
  ): void => {
    if (clientStartedFresh == BOOL.B_FALSE)
      this.repairAllBrokenConnectionsWithPeer(peerID);
    /*if (this.data.healthyPeerConnectionExists(peerID, false)) {
      this.logger.logLocal([
        "local client ignoring clientReadyToConnect signal from client ",
        peerID,
        " because healthy connection and media stream with client was found.",
      ]);
    } else {*/
    if (
      clientStartedFresh == BOOL.B_TRUE &&
      this.data.peerConnectionExists(peerID)
    ) {
      //close all open PC and PMS if any
      /*this.data.retirePC(id, SIGNALING_PURPOSE.ALL);
      this.data.retirePMS(id, SIGNALING_PURPOSE.ALL);*/
      this.busLogic.handleRemoteHangup(peerID, room, true);
      this.peerCQ.cancelPendingPeerConnectionsForPeer(
        peerID,
        room,
        SIGNALING_PURPOSE.ALL,
        true
      );
    }
    //register newly joined peer in queue from which connections get created
    if (
      clientStartedFresh == BOOL.B_TRUE ||
      !this.data.peerConnectionExists(peerID)
    ) {
      // if broken PC exists, ignore clientreadytoconnect - the client who just reconnected to server needs to initiate reconnect for all its broken peers - - in any case, reconnect needs to happen on same signalingpurpose as old connection
      /*this.peerCQ.cancelPendingPeerConnectionsForPeer(
        peerID,
        room,
        SIGNALING_PURPOSE.BASE_CONNECTION,
        true
      );*/
      this.peerCQ.enqueuePeerConnectionCreation(
        peerID,
        room,
        "offer",
        null,
        SIGNALING_PURPOSE.BASE_CONNECTION // this being a fresh connection, always connect base_connection as a first step
      );
    }
  };

  //this is triggered whenever a client joined the room - after joining completed successfully
  processServerMessageClientReadyToConnect = (
    peerID: string,
    room: string,
    clientStartedFresh: BOOL
  ) => {
    if (this.isReadyToConnect()) {
      this.logger.logLocal([
        "Local client received <clientReadyToConnect> signal from client ",
        peerID,
        " in room ",
        room,
        " with clientStartedFresh as ",
        clientStartedFresh,
      ]);
      if (room === this.getMainRoom() && peerID !== this.getUniqueClientID()) {
        this.peerConnector.registerPeerThatSentReadyToConnectOrWelcomeSignalOrBCOffer(
          peerID
        );
        this.busLogic
          .getUserNameLabel()
          .informSinglePeerAboutLocalUserNameLabelTimeout(peerID);
        if (
          !this.help.localPeerIDIsTheOneToInitiateConnection(
            this.getUniqueClientID(),
            peerID
          )
        ) {
          this.logger.logLocal([
            "Local client responding to remote client ",
            peerID,
            " with <welcomePleaseConnect> signal",
          ]);
          if (
            //if initiating client started fresh - clean up here as well - any old connections cannot be re-connected as initiator has none
            clientStartedFresh == BOOL.B_TRUE &&
            this.data.peerConnectionExists(peerID)
          ) {
            //close all open PC and PMS if any
            this.busLogic.handleRemoteHangup(peerID, room, true);
            this.peerCQ.cancelPendingPeerConnectionsForPeer(
              peerID,
              room,
              SIGNALING_PURPOSE.ALL,
              true
            );
          }
          let clientStartedFreshResponse: BOOL = clientStartedFresh;
          if (!this.data.peerConnectionExists(peerID))
            clientStartedFreshResponse = BOOL.B_TRUE;
          this.serverConnector.emitWelcomePleaseConnect(
            peerID,
            room,
            clientStartedFreshResponse
          );
          return;
        } else {
          this.establishOrFixConnectionToPeer(peerID, room, clientStartedFresh);
        }
      } else if (
        room !== this.getMainRoom() &&
        peerID !== this.getUniqueClientID()
      ) {
        console.error(
          "Received <clientReadyToConnect> signal for room other than main room: ",
          room
        );
      }
      this.processPendingPccIfReady(); //this is one among a few points that should cause the client to process pending peer connections, if ready to do so*/
    } else {
      // ignoring incoming clientReadyToConnect messages prevents concurring connection attempts that are caused by the local client being able to receive those signals while itself not being fully ready yet - when then ready, it tries to adress the old incoming signal and triggers its own outgoing signal to all peers, causing two equivalent connections to be built, failing due to conflicting states on both ends
      this.logger.logLocal([
        "Local client IGNORING <clientReadyToConnect> signal from Client ",
        peerID,
        " in room ",
        room,
        " as local setup is not ready yet",
      ]);
    }
  };

  //this is triggered whenever a client joined the room - after joining completed successfully
  processPeerReadyToReconnect = (
    peerID: string,
    room: string,
    pcSigP: SIGNALING_PURPOSE
  ) => {
    if (this.isReadyToConnect()) {
      if (room === this.getMainRoom() && peerID !== this.getUniqueClientID()) {
        if (
          !this.help.localPeerIDIsTheOneToInitiateConnection(
            this.getUniqueClientID(),
            peerID
          )
        ) {
          this.logger.logError([
            "Local client received <iAmReadyToReconnect> message from remote client ",
            peerID,
            " - yet, the remote client should be the one initiating the reconnect - local client taking no further action",
          ]);
          return;
        } else {
          this.repairBrokenConnectionWithPeerForSigP(peerID, pcSigP);
        }
      } else if (
        room !== this.getMainRoom() &&
        peerID !== this.getUniqueClientID()
      ) {
        console.error(
          "Received <iAmReadyToReconnect> signal for room other than main room: ",
          room
        );
      }
      this.processPendingPccIfReady(); //this is one among a few points that should cause the client to process pending peer connections, if ready to do so*/
    } else {
      this.logger.logLocal([
        "Local client IGNORING <iAmReadyToReconnect> signal from Client ",
        peerID,
        " in room ",
        room,
        " as local setup is not ready yet",
      ]);
    }
  };
}
