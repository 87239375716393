import { App } from "./App";

console.log("starting app");

try {
  //clear NWJS cache
  //@ts-ignore
  nw.App.clearCache();
} catch (e) {
  //do nothing
}

const app = new App();
app.startup();
