import { Config } from "config/Config";
import { Logger } from "utils/Logger";
import { UINwjsHandler } from "frontend/nwjs/UINwjsHandler";
import { I_HTMLReferenceUser } from "./I_HTMLReferenceUser";
import { ConfUserSettings } from "configMgmt/ConfUserSettings";
import { ConfUserSettingsHTML } from "./configElementsHTML/ConfUserSettingsHTML";
import { ConfigHandler } from "configMgmt/ConfigHandler";
import { I_ConfigElement } from "configMgmt/I_ConfigElement";
import { ConfTeamSettingsHTML } from "./configElementsHTML/ConfTeamSettingsHTML";
import { ConfCamSettingsHTML } from "./configElementsHTML/ConfCamSettingsHTML";
import { ConfMicSettingsHTML } from "./configElementsHTML/ConfMicSettingsHTML";
import { I_UITechHandler } from "frontend/decoupler/I_UITechHandler";

export class ConfigHTML implements I_HTMLReferenceUser {
  private uiTechHandler: I_UITechHandler;

  private logger: Logger;
  private config: Config;

  private doc: Document;

  private confScreenURL: string;

  private rootHTML: HTMLElement;
  private confEntireAppRootHTML: HTMLDivElement;
  private confScreenTopHTML: HTMLDivElement;
  private confCategories: HTMLDivElement[];
  private confCategoryButtons: HTMLButtonElement[];
  private confContentArea: HTMLDivElement;
  private confContentItems: HTMLDivElement[];
  private confCancelButton: HTMLButtonElement;
  private confSaveButton: HTMLButtonElement;

  private userConf: ConfUserSettingsHTML;
  private teamConf: ConfTeamSettingsHTML;
  private camConf: ConfCamSettingsHTML;
  private micConf: ConfMicSettingsHTML;
  private confHandler: I_ConfigElement = null;

  constructor(_config: Config, _logger: Logger, _nwjsHandler: I_UITechHandler) {
    this.config = _config;
    this.logger = _logger;
    this.uiTechHandler = _nwjsHandler;

    this.confScreenURL = null;
    this.setAllHTMLReferencesToNull();

    this.userConf = new ConfUserSettingsHTML(this.logger);
    this.teamConf = new ConfTeamSettingsHTML(this.logger);
    this.camConf = new ConfCamSettingsHTML(this.logger);
    this.micConf = new ConfMicSettingsHTML(this.logger);
  }

  /*getConfWindowDocument = (): Document => {
    return this.doc;
  };*/

  getUserConfSettingsHTML = (): ConfUserSettingsHTML => {
    return this.userConf;
  };

  getTeamConfSettingsHTML = (): ConfTeamSettingsHTML => {
    return this.teamConf;
  };

  getCamConfSettingsHTML = (): ConfCamSettingsHTML => {
    return this.camConf;
  };

  getMicConfSettingsHTML = (): ConfMicSettingsHTML => {
    return this.micConf;
  };

  initialize = (): void => {
    this.initializeConfScreenURL();
    this.userConf.initialize();
    this.teamConf.initialize();
    this.camConf.initialize();
    this.micConf.initialize();
    this.logger.logLocal(["config html element initialized"]);
  };

  private initializeConfScreenURL() {
    switch (window.location.hostname) {
      case this.config.devEnvHostname: {
        this.confScreenURL = this.config.devEnvBaseURL;
        break;
      }
      case this.config.localEnvHostname: {
        this.confScreenURL = this.config.localEnvBaseURL;
        break;
      }
      case this.config.prodEnvHostnameNEW: {
        this.confScreenURL = this.config.prodEnvBaseURLNEW;
        break;
      }
      default: {
        //if in doubt, use prod code
        this.confScreenURL = this.config.prodEnvBaseURL;
        break;
      }
    }
    this.confScreenURL =
      this.confScreenURL +
      this.config.confWindowFileName +
      "?rand=" +
      Math.random() * 10000;
  }

  private setAllHTMLReferencesToNull = (): void => {
    for (let i = 0; i < 4; i++) {
      if (this.confCategoryButtons != null) {
        this.confCategories[i] = null;
        this.confCategoryButtons[i] = null;
        this.confContentItems[i] = null;
      }
    }
    this.confEntireAppRootHTML = null;
    this.confScreenTopHTML = null;
    this.confCategories = new Array<HTMLDivElement>(4);
    this.confCategoryButtons = new Array<HTMLButtonElement>(4);
    this.confContentArea = null;
    this.confContentItems = Array<HTMLDivElement>(4);
    this.confCancelButton = null;
    this.confSaveButton = null;
    this.doc = null;
  };

  private initializeHTMLReferencesFromDoc = (_doc: Document): void => {
    try {
      this.doc = _doc;
    } catch (e) {
      this.logger.logError([
        "Error obtaining NWJS window references for conf UI: ",
        e,
      ]);
    }
    try {
      //get elements for VC section
      this.rootHTML = this.doc.querySelector("html") as HTMLElement;
      this.confEntireAppRootHTML = this.doc.getElementById(
        "entireApp"
      ) as HTMLDivElement;
      this.confScreenTopHTML = this.doc.getElementById(
        "ConfRoot"
      ) as HTMLDivElement;
      this.confContentArea = this.doc.getElementById(
        "ConfContent"
      ) as HTMLDivElement;
      this.confCancelButton = this.doc.getElementById(
        "ConfCancelButton"
      ) as HTMLButtonElement;
      this.confSaveButton = this.doc.getElementById(
        "ConfSaveButton"
      ) as HTMLButtonElement;

      this.confCategories[0] = this.doc.getElementById(
        "CCategoryUser"
      ) as HTMLDivElement;
      this.confCategories[1] = this.doc.getElementById(
        "CCategoryTeam"
      ) as HTMLDivElement;
      this.confCategories[2] = this.doc.getElementById(
        "CCategoryCam"
      ) as HTMLDivElement;
      this.confCategories[3] = this.doc.getElementById(
        "CCategoryMic"
      ) as HTMLDivElement;

      this.confCategoryButtons[0] = this.doc.getElementById(
        "ConfCategoryButtonUser"
      ) as HTMLButtonElement;
      this.confCategoryButtons[1] = this.doc.getElementById(
        "ConfCategoryButtonTeam"
      ) as HTMLButtonElement;
      this.confCategoryButtons[2] = this.doc.getElementById(
        "ConfCategoryButtonCam"
      ) as HTMLButtonElement;
      this.confCategoryButtons[3] = this.doc.getElementById(
        "ConfCategoryButtonMic"
      ) as HTMLButtonElement;

      this.confContentItems[0] = this.doc.getElementById(
        "ConfContentUser"
      ) as HTMLDivElement;
      this.confContentItems[1] = this.doc.getElementById(
        "ConfContentTeam"
      ) as HTMLDivElement;
      this.confContentItems[2] = this.doc.getElementById(
        "ConfContentCam"
      ) as HTMLDivElement;
      this.confContentItems[3] = this.doc.getElementById(
        "ConfContentMic"
      ) as HTMLDivElement;
      //this._UIMessages[0].addEventListener("click", this.hideUIMessageWindow);
      for (let i = 0; i < 4; i++) {
        this.confCategoryButtons[i].addEventListener("click", () =>
          this.markConfCategoryActive(i)
        );
      }
      this.confCancelButton.addEventListener("click", () => {
        this.confHandler.onUserCancel();
        this.closeConfWindow();
      });
      this.confSaveButton.addEventListener("click", () => {
        this.confHandler.onUserSave();
        this.closeConfWindow();
      });
    } catch (e) {
      this.logger.logError([
        "Error obtaining HTML handles for conf window elements: ",
        e,
      ]);
    }
  };

  private markConfCategoryActive = (position: number) => {
    this.markAllConfCategoriesInactive();
    this.confCategories[position].classList.add("activeConfCategory");
    this.confContentItems[position].classList.add("activeConfCategory");
  };

  private markAllConfCategoriesInactive = () => {
    for (let i = 0; i < 4; i++) {
      this.confCategories[i].classList.remove("activeConfCategory");
      this.confContentItems[i].classList.remove("activeConfCategory");
    }
  };

  /*  private showConfWindow = () => {
    this.nwjsHandler.getConfWindowNWJS().showConfWindow();
  };
  private hideConfWindow = () => {
    this.nwjsHandler.getConfWindowNWJS().hideConfWindow();
  };*/

  openConfWindow = () => {
    this.uiTechHandler.getConfWindow().openConfWindow(this.confScreenURL, this);
  };
  closeConfWindow = () => {
    this.uiTechHandler.getConfWindow().closeConfWindow();
  };
  toggleConfWindow = () => {
    this.uiTechHandler
      .getConfWindow()
      .toggleConfWindow(this.confScreenURL, this);
  };

  initializeUI = (_doc: Document): void => {
    this.initializeHTMLReferencesFromDoc(_doc);
    //init children post parent
    this.userConf.initializeUI(_doc);
    this.teamConf.initializeUI(_doc);
    this.camConf.initializeUI(_doc);
    this.micConf.initializeUI(_doc);
    //init conf object (and its children) post UI elements
    this.confHandler.initializeUI();
  };

  tearDownUIReferencesPriorClosure = (): void => {
    this.userConf.tearDownUIReferencesPriorClosure();
    this.teamConf.tearDownUIReferencesPriorClosure();
    this.camConf.tearDownUIReferencesPriorClosure();
    this.micConf.tearDownUIReferencesPriorClosure();
    //tear down parent post children
    this.markAllConfCategoriesInactive();
    this.setAllHTMLReferencesToNull();
  };

  registerConfElement = (_confElement: I_ConfigElement) => {
    this.confHandler = _confElement;
  };
}
