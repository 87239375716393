import { Logger } from "../../utils/Logger";
import { BusLogicVC } from "../../buslogic/BusLogicVC";
import { Helpers } from "../../utils/Helpers";
import { AppUIHTML } from "../html/AppUIHTML";
import { UIMessenger } from "../../utils/UIMessenger";
import { UIMessengerCustom } from "../../utils/UIMessengerCustom";
import { URLBrowserWindowsNWJS } from "./URLBrowserWindowsNWJS";
import { MultiScreenHandler } from "./MultiScreenHandler";
import { DialogueBackgroundNWJS } from "./DialogueBackgroundNWJS";
import { SidebarWindowNWJS } from "./SidebarWindowNWJS";
import { VCWindowNWJS } from "./VCWindowNWJS";
import { UIMessengerWindowNWJS } from "./UIMessengerWindowNWJS";
import { Config } from "../../config/Config";
import { ConfWindowNWJS } from "./ConfWindowNWJS";
import { I_UITechHandler } from "frontend/decoupler/I_UITechHandler";
import { CSSClassModifier } from "frontend/CSSModifierOverarching";

export class UINwjsHandler implements I_UITechHandler {
  private help: Helpers;
  private config: Config;
  private logger: Logger;
  private uiMessenger: UIMessengerCustom;

  private appUI: AppUIHTML;
  private cssModifier: CSSClassModifier;
  private busLogic: BusLogicVC;

  private screenHandler: MultiScreenHandler;
  private urlBrowserWindows: URLBrowserWindowsNWJS;
  private dialogueBackground: DialogueBackgroundNWJS;
  private sidebarWindow: SidebarWindowNWJS;
  private messengerWindow: UIMessengerWindowNWJS;
  private vcWindow: VCWindowNWJS;
  private confWindow: ConfWindowNWJS;

  constructor(
    _help: Helpers,
    _config: Config,
    _logger: Logger,
    _uiMessenger: UIMessenger
  ) {
    console.log("Local client loading extension: UI NWJS Actions");

    this.help = _help;
    this.config = _config;
    this.logger = _logger;
    this.uiMessenger = _uiMessenger as UIMessengerCustom;

    this.screenHandler = new MultiScreenHandler(this.help, this.logger);
    this.sidebarWindow = new SidebarWindowNWJS(
      this.help,
      this.logger,
      this.screenHandler
    );
    this.urlBrowserWindows = new URLBrowserWindowsNWJS(this.logger);
    this.dialogueBackground = new DialogueBackgroundNWJS(
      this.help,
      this.config,
      this.logger
    );
    this.messengerWindow = new UIMessengerWindowNWJS(
      this.help,
      this.logger,
      this.uiMessenger,
      this.sidebarWindow
    );
    this.confWindow = new ConfWindowNWJS(
      this.help,
      this.logger,
      this.uiMessenger,
      this.screenHandler
    );
    this.vcWindow = new VCWindowNWJS(
      this.help,
      this.logger,
      this.sidebarWindow
    );
  }

  initialize = (
    _appUI: AppUIHTML,
    _cssModifier: CSSClassModifier
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.cssModifier = _cssModifier;
      this.setAppUIReference(_appUI);
      //initialize UI accordingly if app is active
      if (this.help.appIsActive()) {
        //this.closeOrphanedWindowsFromPreviousAppStarts(); //commented out to avoid closure of newly opened windows (did not happen yet, but could as timing of open vs close is not ensured via promises)

        this.screenHandler.initialize(this.sidebarWindow);
        this.sidebarWindow.initialize(this.appUI);
        this.urlBrowserWindows.initialize();
        this.dialogueBackground.initialize();
        this.messengerWindow.initialize(this.appUI);
        this.confWindow.initialize(this.cssModifier);
        this.vcWindow.initialize(this.appUI);

        this.uiMessenger
          .UITechInitializationTriggerPostUIInitialization(this)
          /*.then(() =>
            this.appUI
              .getConfHTML()
              .NWJSInitializationTriggerPostUIInitialization(this)
          )*/
          .then(() => {
            resolve(true);
            return;
          })
          .catch((e) => {
            this.logger.logError([
              "Error initializing NWJS-based UI messenger, ",
              e,
            ]);
            reject(false);
            return;
          });
      } else {
        resolve(true);
        return;
      }
    });
  };

  setBusLogicReference = (_busLogic: BusLogicVC): void => {
    this.busLogic = _busLogic;
  };

  private setAppUIReference = (_appUI: AppUIHTML): void => {
    this.appUI = _appUI;
  };

  getURLBrowserWindows = (): URLBrowserWindowsNWJS => {
    return this.urlBrowserWindows;
  };

  initializationClosure = (): void => {
    this.configureAppClosureCleanupCall();
  };

  configureAppClosureCleanupCall = (): void => {
    if (!(this.busLogic instanceof BusLogicVC)) return null;
    try {
      this.sidebarWindow.getSidebarNWJSWindowReference().on("close", () => {
        //nw.Window.get().on("close", () => {
        this.hideEntireAppForClosure(); //avoids app being visible for 1.5secs if VC open
        let timeLagIfVCActive = 500;
        if (this.busLogic.getVCDataMgr().isVCOpen()) {
          timeLagIfVCActive = 1500;
        }
        this.busLogic.fullLocalHangupOnExit(
          "DisconnectAsPartOfOnCloseEventForSidebarWindow"
        );
        setTimeout(() => {
          this.sidebarWindow.getSidebarNWJSWindowReference().close(true);
          //nw.Window.get().close(true);
          //@ts-ignore
          nw.App.closeAllWindows();
          //@ts-ignore
          nw.App.quit();
        }, timeLagIfVCActive);
      });
    } catch (e) {
      this.logger.logLocal([
        "not in app - thus window closure process for app was not configured",
        e,
      ]);
    }
  };

  private closeOrphanedWindowsFromPreviousAppStarts = (): void => {
    if (this.help.appIsActive()) {
      try {
        //@ts-ignore
        nw.Window.getAll((wins: nw.Window[]) => {
          wins.forEach((curWin) => {
            let cTitle: string = curWin.title;
            let cID: string = curWin.id;
            if (
              cTitle == "visav.is feedback" ||
              cTitle == "visav.is team invite" ||
              cTitle == "visav.is video huddle" ||
              cTitle == "visav.is notification" ||
              cTitle == "feedback - visav.is" ||
              cTitle == "support - visav.is" ||
              cTitle == "feedback - visav.is early access" ||
              cTitle == "support - visav.is early access" ||
              cTitle == "visavis full screen background" ||
              cTitle == "visav.is full screen background" ||
              cTitle == "visav.is video huddle screen" ||
              cTitle == "visav.is ui messenger" ||
              cTitle == "visav.is config" ||
              cTitle == "visavis config" ||
              cTitle == "visav.s full screen blurred background" /*||
              (cTitle != null &&
                (cTitle.indexOf("chrome-extension") == 0 || // not working to close parent windows
                  cTitle.indexOf("app-hook.visav.is") >= 0)) ||  // not working to close parent windows
              cID == "vis_app_hook" ||
              cID == "vis_local_launcher" ||
              cID == "vis_remote_launcher"*/
            ) {
              curWin.close(true);
              this.logger.logWarning([
                "Closing a njws window from previous app start",
              ]);
            }
          });
        });
      } catch (e) {
        this.logger.logError([
          "error trying to close orphaned windows from previous app starts, ",
          e,
        ]);
      }
    }
  };

  closeAllWindowsExceptSideBar = (): void => {
    this.dialogueBackground.closeMaximizedScreenShareBackgroundWindow();
    this.vcWindow.closeVCWindowIfOnApp();
    this.messengerWindow.closeUIMWindowIfOnApp();
    this.confWindow.closeConfWindow();
    this.urlBrowserWindows.closeFeedbackFormWindow();
    this.urlBrowserWindows.closeSupportFormWindow();
    this.urlBrowserWindows.closewebRTCInternalsWindow();
    this.closeOrphanedWindowsFromPreviousAppStarts();
  };

  hideEntireAppForClosure = (): void => {
    //final call before app closes - thus CSS can be written to style directly / does not need to be undoable..
    this.appUI.getEntireApp().style.visibility = "hidden";
    this.appUI.getEntireApp().style.display = "none";
  };

  getSidebarWindow = (): SidebarWindowNWJS => {
    return this.sidebarWindow;
  };

  getVCWindow = (): VCWindowNWJS => {
    return this.vcWindow;
  };

  getUIMessengerWindow = (): UIMessengerWindowNWJS => {
    return this.messengerWindow;
  };

  getDialogueBackgroundWindow = (): DialogueBackgroundNWJS => {
    return this.dialogueBackground;
  };

  getConfWindow = (): ConfWindowNWJS => {
    return this.confWindow;
  };
}
