import { AppUIHTML } from "frontend/html/AppUIHTML";
import { Helpers } from "utils/Helpers";
import { Logger } from "utils/Logger";

export class VCWindowSizer {
  private logger: Logger;
  private appUI: AppUIHTML;

  private sideBarWidth: number = 200;

  constructor(_logger: Logger, _sideBarWidth: number) {
    this.logger = _logger;
    this.sideBarWidth = _sideBarWidth;
  }

  initialize = (_appUI: AppUIHTML): void => {
    this.appUI = _appUI;
  };

  setVCWindowSizeForNumberOfParticipantsIfOnApp = (
    numberOfParticipants: number = 2,
    chatActive: boolean = false,
    resetPosition: boolean = false,
    setWindowSizeIfOnApp: (
      width: number,
      height: number,
      resetPos: boolean
    ) => void
  ): void => {
    let numberOfRequiredUISlots = numberOfParticipants;
    if (numberOfRequiredUISlots == 1) numberOfRequiredUISlots++;
    if (chatActive) numberOfRequiredUISlots++;

    let headingHeight: number = 35;
    let extraHeight: number = 60;
    let extraWidth: number = 60;
    let widthPerVideo: number = 320 + 20;
    let heightPerVideo: number = 240 + 20;
    let matrixHeight: number = 1;
    let matrixWidth: number = 1;

    //determine how many videos max fit onto the screen aside one another
    let screenWidth: number = screen.availWidth - this.sideBarWidth;
    let maxMatrixWidth: number = Math.max(
      1,
      Math.floor((screenWidth - extraWidth) / widthPerVideo)
    );

    if (numberOfRequiredUISlots <= 2) matrixWidth = 1;
    else if (numberOfRequiredUISlots == 3 && chatActive) matrixWidth = 3;
    else if (numberOfRequiredUISlots <= 4) matrixWidth = 2;
    else if (numberOfRequiredUISlots <= 6) matrixWidth = 3;
    else if (numberOfRequiredUISlots <= 8) matrixWidth = 4;
    else if (numberOfRequiredUISlots == 9) matrixWidth = 3;
    else matrixWidth = 4;

    matrixWidth = Math.min(matrixWidth, maxMatrixWidth);
    matrixHeight = Math.ceil(numberOfRequiredUISlots / matrixWidth);
    let wid: number = matrixWidth * widthPerVideo + extraWidth;
    let hig: number =
      headingHeight + matrixHeight * heightPerVideo + extraHeight;

    setWindowSizeIfOnApp(wid, hig, resetPosition);
  };

  setVCWindowSizeScreenShareIfOnApp = (
    relativeWidth: number = 16,
    relativeHeight: number = 9,
    resetPosition: boolean = true,
    setWindowSizeIfOnApp: (
      width: number,
      height: number,
      resetPos: boolean
    ) => void
  ): void => {
    this.logger.logLocal([
      "starting to size vc window - input parameters are: ",
      relativeWidth,
      ", ",
      relativeHeight,
      ", ",
      resetPosition,
    ]);
    let headingHeight: number = 40;
    let extraHeight: number = 0;
    let extraWidth: number = 0;

    let maxScreenShareWidth: number =
      screen.availWidth - this.sideBarWidth - extraWidth;
    let maxScreenShareHeight: number =
      screen.availHeight - headingHeight - extraHeight;

    this.logger.logLocal([
      "computed max width and height of screen space as: ",
      maxScreenShareWidth,
      ", ",
      maxScreenShareHeight,
    ]);

    let wid: number = 320;
    let hig: number = 240;

    if (
      (relativeWidth >= relativeHeight &&
        maxScreenShareWidth >= maxScreenShareHeight) ||
      (relativeWidth <= relativeHeight &&
        maxScreenShareWidth <= maxScreenShareHeight)
    ) {
      //common case - landscape format in both source and target screen
      if (
        relativeWidth / relativeHeight >=
        maxScreenShareWidth / maxScreenShareHeight
      ) {
        wid = maxScreenShareWidth;
        hig = Math.floor((wid * relativeHeight) / relativeWidth);
      } else {
        hig = maxScreenShareHeight;
        wid = Math.floor((hig * relativeWidth) / relativeHeight);
      }
    } else if (
      relativeWidth >= relativeHeight &&
      maxScreenShareWidth <= maxScreenShareHeight
    ) {
      //landscape format in source and vertical format in target screen
      wid = maxScreenShareWidth;
      hig = Math.floor((wid * relativeHeight) / relativeWidth);
    } else if (
      relativeWidth <= relativeHeight &&
      maxScreenShareWidth >= maxScreenShareHeight
    ) {
      //vertical format in source and landscape format in target screen
      hig = maxScreenShareHeight;
      wid = Math.floor((hig * relativeWidth) / relativeHeight);
    } else {
      // else - should never get triggered
      this.logger.logError(["error sizing vc screen for screen-share"]);
    }
    wid = wid + extraWidth;
    hig = hig + extraHeight + headingHeight;
    this.logger.logLocal([
      "calling window sizing function with values: ",
      wid,
      ", ",
      hig,
      ", ",
      resetPosition,
    ]);
    setWindowSizeIfOnApp(wid, hig, resetPosition);
    //confirm("wid/hig: " + wid + ", " + hig);
  };

  initializeScreenShareAutoSizingIfOnApp = (
    setVCWindowSizeScreenShareIfOnApp: (
      width: number,
      height: number,
      resetPos: boolean
    ) => void
  ): void => {
    this.appUI
      .getVCScreenShareHTML()
      .getRemoteScreenShareHTMLVideoElementVC()
      .addEventListener("loadedmetadata", () => {
        let wid: number = this.appUI
          .getVCScreenShareHTML()
          .getRemoteScreenShareHTMLVideoElementVC().videoWidth;
        let hig: number = this.appUI
          .getVCScreenShareHTML()
          .getRemoteScreenShareHTMLVideoElementVC().videoHeight;
        setVCWindowSizeScreenShareIfOnApp(wid, hig, true);
      });
  };
}
