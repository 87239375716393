import { SettingStorage } from "data/SettingStorage";
import { Helpers } from "./Helpers";

export class Logger {
  private help: Helpers = null;
  private settings: SettingStorage = null;

  //logging prefixes
  private readonly _llp = "[Local logging    ]";
  private readonly _lep = "[Local error      ]";
  private readonly _lwp = "[Local warning    ]";
  private readonly _lsp = "[Server log output]";

  //determine whether (1) only errors, (2) errors+warning, (3) all logs incl. server-logs, or (0) nothing shall be logged
  private logLevel: number;

  private enableErrorMonitoringForAllErrors: boolean;
  private suppressBugsnagReportingWhileInHardcoreDevelopment: boolean = false;

  constructor(_help: Helpers, _settings: SettingStorage) {
    console.log("Local client loading extension: Logging");
    this.help = _help;
    this.settings = _settings;
  }

  initialize = () => {
    return new Promise((resolve, reject) => {
      //prod logging
      this.logLevel = 3;
      this.enableErrorMonitoringForAllErrors = true;
      let userID = "no user ID present";
      let companyName = "no company name identified";
      if (this.settings.existsUIDSetting()) userID = this.settings.getUID();
      if (this.settings.existsMainRoomSetting())
        companyName = this.settings.getMainRoom();
      if (this.help.isDevEnv()) {
        //dev logging
        this.logLevel = 3;
        this.enableErrorMonitoringForAllErrors = true; // to be disabled going forward
        //@ts-ignore
        if (typeof Bugsnag !== "undefined" && Bugsnag !== null)
          //@ts-ignore
          Bugsnag.start({
            apiKey: "bcde6433d3df4fa2ea79fdc5de14960c",
            releaseStage: "development",
            maxBreadcrumbs: 50,
            enabledBreadcrumbTypes: [
              "error",
              "log",
              "navigation",
              "request",
              "user",
            ],
            user: {
              id: userID,
            },
            metadata: {
              company: {
                name: companyName,
              },
            },
          });
        else
          console.error(
            "Unable to initialize bugsnag - this may be a conflict with an ad blocker"
          );
      } else {
        //@ts-ignore
        if (typeof Bugsnag !== "undefined" && Bugsnag !== null)
          //@ts-ignore
          Bugsnag.start({
            apiKey: "bcde6433d3df4fa2ea79fdc5de14960c",
            maxBreadcrumbs: 50,
            enabledBreadcrumbTypes: [
              "error",
              "log",
              "navigation",
              "request",
              "user",
            ],
            user: {
              id: userID,
            },
            metadata: {
              company: {
                name: companyName,
              },
            },
          });
        else
          console.error(
            "Unable to initialize bugsnag - this may be a conflict with an ad blocker"
          );
      }
      resolve(true);
      return;
    });
  };

  //////////////////////////////////////////////////////////////////
  // BASIC TOOL-KIT
  //////////////////////////////////////////////////////////////////

  logLocal = (argumentsX: any[]) => {
    if (this.logLevel < 3) return;
    var args = [];
    args.push(new Date().toLocaleTimeString());
    args.push(this._llp);
    for (var i = 0; i < argumentsX.length; i++) {
      args.push(argumentsX[i]);
    }
    console.log.apply(console, args);
  };

  // prefix local error logs
  logError = (argumentsX: any[]) => {
    if (this.logLevel < 1) return;
    var args = [];
    args.push(new Date().toLocaleTimeString());
    args.push(this._lep);
    for (var i = 0; i < argumentsX.length; i++) {
      args.push(argumentsX[i]);
    }
    console.error.apply(console, args);
    if (!this.suppressBugsnagReportingWhileInHardcoreDevelopment) {
      //@ts-ignore
      if (typeof Bugsnag !== "undefined" && Bugsnag !== null) {
        //@ts-ignore
        //@ts-ignore
        Bugsnag.notify(new Error(argumentsX[0]), (event) => {
          //event.context = argumentsX[0];
          event.groupingHash = argumentsX[0];
          event.addMetadata("allArgs", argumentsX);
          //event.addMetadata('room', 'name', XXX.getmainroom());
        });
      }
    }
  };

  // prefix local warning logs
  logWarning = (argumentsX: any[]) => {
    if (this.logLevel < 2) return;
    var args = [];
    args.push(new Date().toLocaleTimeString());
    args.push(this._lwp);
    for (var i = 0; i < argumentsX.length; i++) {
      args.push(argumentsX[i]);
    }
    console.warn.apply(console, args);
  };
  // prefix local warning logs
  /*var logWarning = function () {
      var args = [];
      args.push(new Date().toLocaleTimeString());
      args.push(_lwp);
      for (var i = 0; i < arguments.length; i++) {
        args.push(arguments[i]);
      }
      console.warn.apply(console, args);
    };*/

  // prefix server logs
  logFromServer = (argumentsX: any[]) => {
    if (this.logLevel < 3) return;
    var args = [];
    args.push(new Date().toLocaleTimeString());
    args.push(this._lsp);
    for (var i = 0; i < argumentsX.length; i++) {
      args.push(argumentsX[i]);
    }
    console.log.apply(console, args);
  };
}
