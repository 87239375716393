/* COPYRIGHT 2021 Michael Maur - any form of reuse requires written consent by the author */

import { Helpers } from "utils/Helpers";
import { Logger } from "utils/Logger";
import { AppUIHTML } from "../frontend/html/AppUIHTML";

export class SoundEffects {
  private help: Helpers;
  private logger: Logger;
  private appUI: AppUIHTML;

  constructor(_help: Helpers, _logger: Logger, _appUI: AppUIHTML) {
    console.log("Local client loading extension: SoundEffects");
    this.help = _help;
    this.logger = _logger;
    this.appUI = _appUI;
  }

  initialize = (_logger: Logger) => {
    //Things to be executed on startup
    this.appUI.getAudioElementJoinVC().autoplay = false;
    this.appUI.getAudioElementLeaveVC().autoplay = false;
    this.appUI.getAudioElementRaiseHand().autoplay = false;
    this.appUI.getAudioElementJoinVC().src = "./sounds/beep_on.mp3";
    this.appUI.getAudioElementLeaveVC().src = "./sounds/beep_off.mp3";
    this.appUI.getAudioElementRaiseHand().src = "./sounds/beep_prompt_2x.mp3";
    this.appUI.getAudioElementJoinVC().load();
    this.appUI.getAudioElementLeaveVC().load();
    this.appUI.getAudioElementRaiseHand().load();
    _logger.logLocal(["Sound module initialized"]);
  };

  playPeerJoiningSound = () => {
    try {
      if (this.help.chromiumIsActive())
        this.appUI.getAudioElementJoinVC().play();
    } catch (e) {
      this.logger.logWarning(["Error playing sound for peer joining: ", e]);
    }
  };

  playPeerLeavingSound = () => {
    try {
      if (this.help.chromiumIsActive())
        this.appUI.getAudioElementLeaveVC().play();
    } catch (e) {
      this.logger.logWarning(["Error playing sound for peer leaving: ", e]);
    }
  };

  playRaiseHandSound = () => {
    try {
      if (this.help.chromiumIsActive())
        this.appUI.getAudioElementRaiseHand().play();
    } catch (e) {
      this.logger.logWarning(["Error playing sound for hand raised: ", e]);
    }
  };
}
