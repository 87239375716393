//this class is not really in use except for keeping track of current peers in room - it is a prep for future object-oriented peer representations

import { DataMgmt } from "data/DataMgmt";
import { Helpers } from "utils/Helpers";
import { Peer } from "./Peer";
import { ServerConnector } from "./ServerConnector";
import { SignalingCore } from "./SignalingCore";

export class PeerConnector {
  private help: Helpers;
  private data: DataMgmt;
  private signaling: SignalingCore;
  private serverConnector: ServerConnector;

  private connectedPeers: Map<string, Peer>;

  constructor(
    _help: Helpers,
    _data: DataMgmt,
    _signaling: SignalingCore,
    _serverConnector: ServerConnector
  ) {
    this.help = _help;
    this.data = _data;
    this.signaling = _signaling;
    this.serverConnector = _serverConnector;

    this.connectedPeers = new Map<string, Peer>();
  }

  initialize = () => {};

  reset = () => {
    this.connectedPeers.clear();
  };

  registerPeerThatSentReadyToConnectOrWelcomeSignalOrBCOffer = (
    _peerID: string
  ): void => {
    if (!this.connectedPeers.has(_peerID))
      this.connectedPeers.set(_peerID, new Peer(_peerID));
  };

  unregisterPeerWhoLeft = (_peerIDThatLeft: string): void => {
    if (!this.connectedPeers.has(_peerIDThatLeft))
      this.connectedPeers.delete(_peerIDThatLeft);
  };

  peerIsRegistered = (_peerID: string): boolean => {
    return this.connectedPeers.has(_peerID);
  };

  getRegisteredPeers = (): string[] => {
    return Array.from(this.connectedPeers.keys());
  };

  /*    askPeerToConnectIfLocalClientDoesNotLead = (_peerID: string) : void => {

    }

    establishOrFixConnectionToPeerIfLocalClientLeads = (_peerID: string) : void => {
        if (!this.peerIsRegistered(_peerID)) return;
        if (!this.help.localPeerIDIsTheOneToInitiateConnection(this.serverConnector.getUniqueClientID(),_peerID)) return; 
        

        if (connectionExists And is Healthy) {

        } else {

        }
    }*/
}
