/* COPYRIGHT 2021 Michael Maur - any form of reuse requires written consent by the author */

import { Config } from "../config/Config";
import { UINwjsHandler } from "../frontend/nwjs/UINwjsHandler";
import { Logger } from "./Logger";
import { UIMessenger } from "./UIMessenger";
import { UIMessengerCore } from "./UIMessengerCore";

export class UIMessengerCustom extends UIMessengerCore implements UIMessenger {
  private config: Config;
  private logger: Logger;
  private nwjsHandler: UINwjsHandler;

  private UIMScreenURL: string;

  private nwjsWindow: any;
  private doc: Document;

  private _rootHTML: HTMLElement;
  private _UIMessengerUI: HTMLDivElement;
  private _UIMessages: HTMLDivElement[];
  private _UIMessageTexts: HTMLDivElement[];

  private readonly numberOfMessageElementsOnUI: number = 1;

  constructor(_conf: Config, _log: Logger) {
    super();
    try {
      this.config = _conf;
      this.logger = _log;
      console.log("Local client loading extension: UserMessages Custom");

      this.nwjsWindow = null;
      this.doc = null;
    } catch (e) {
      console.error("Error in constructor of UIMessengerCustom" + e);
    }
  }

  initialize = (): void => {
    this.initializeCore();
    this.initializeUIMScreenURL();
  };

  //perform rest of initialization post NWJS initialization - is called from NWJS
  UITechInitializationTriggerPostUIInitialization = (
    _nwjsHandler: UINwjsHandler
    /*initFuncEntireAppVC: {
    (HTMLDivElement): void;
  }*/
  ): Promise<void> => {
    this.nwjsHandler = _nwjsHandler;
    return new Promise((resolve, reject) => {
      //this.initializeVCScreenURL();
      this.initializeHTMLReferences(/*initFuncEntireAppVC*/)
        /*.then(() => {
          this.nwjsHandler.initializeScreenShareAutoSizingIfOnApp();
        })*/
        .then(() => {
          resolve();
          return;
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  private initializeUIMScreenURL = (): void => {
    switch (window.location.hostname) {
      case this.config.devEnvHostname: {
        this.UIMScreenURL = this.config.devEnvBaseURL;
        break;
      }
      case this.config.localEnvHostname: {
        this.UIMScreenURL = this.config.localEnvBaseURL;
        break;
      }
      case this.config.prodEnvHostnameNEW: {
        this.UIMScreenURL = this.config.prodEnvBaseURLNEW;
        break;
      }
      default: {
        //if in doubt, use prod code
        this.UIMScreenURL = this.config.prodEnvBaseURL;
        break;
      }
    }
    this.UIMScreenURL =
      this.UIMScreenURL +
      this.config.customUIMessagingWindowFileName +
      "?rand=" +
      Math.random() * 10000;
  };

  initializeHTMLReferences = (/*initFuncMessengerWindow: {
    (HTMLDivElement): void;
  }*/): Promise<void> => {
    //opens hidden VC window and initializes references based on DOM in dedicated window - if not on app, initializes references bsed on div in current window
    return new Promise((resolve, reject) => {
      this.nwjsHandler
        .getUIMessengerWindow()
        .initializeCustomUIMessengerWindow(
          document,
          this.UIMScreenURL,
          this.initializeHTMLReferencesFromDoc /*,
          initFuncMessengerWindow*/
        )
        .then(() => {
          this.logger.logLocal([
            "Completed procedure to initialize NWJS UI messenger window",
          ]);
        })
        .catch((e) => {
          this.logger.logError([
            "Error initializing NWJS UI messenger window, ",
            e,
          ]);
        })
        .finally(() => {
          this.logger.logLocal([
            "Closing procedure to initialize NWJS UI messenger window",
          ]);
          resolve();
          return;
        });
    });
  };

  private initializeHTMLReferencesFromDoc = (
    _win: any,
    _doc: Document
  ): void => {
    try {
      this.nwjsWindow = _win;
      this.doc = _doc;
    } catch (e) {
      this.logger.logError([
        "Error obtaining NWJS window references for messaging UI: ",
        e,
      ]);
    }
    try {
      //get elements for VC section
      this._rootHTML = this.doc.querySelector("html") as HTMLElement;
      this._UIMessengerUI = this.doc.getElementById(
        "messageWindow"
      ) as HTMLDivElement;
      this._UIMessages = new Array<HTMLDivElement>(0);
      this._UIMessageTexts = new Array<HTMLDivElement>(0);
      for (let i = 1; i <= this.numberOfMessageElementsOnUI; i++) {
        this._UIMessages.push(
          this.doc.getElementById("message0" + i) as HTMLDivElement
        );
        this._UIMessageTexts.push(
          this.doc.getElementById("messageText0" + i) as HTMLDivElement
        );
      }
      this._UIMessages[0].addEventListener("click", this.hideUIMessageWindow);
    } catch (e) {
      this.logger.logError([
        "Error obtaining HTML handles for VC local video and screen share elements: ",
        e,
      ]);
    }
  };

  getRootHTML = (): HTMLElement => {
    return this._rootHTML;
  };

  private lastNotificationID: number = null;

  private customNotify = (message: string, timeout: number = 3000): void => {
    if (this.nwjsHandler == null)
      throw new Error(
        "Custom UI Messenger requires NWJS Handler to display notifications"
      );
    this.logger.logLocal([
      "notification request received with message, ",
      message,
    ]);

    this.showUIMessageWindow();
    this.replaceMessage1(message);
    this.nwjsHandler
      .getUIMessengerWindow()
      .updateUIMWindowSizeAndPositionToReflectSizeRequirementOfCurrentText();
    this.lastNotificationID = Math.floor(Math.random() * 10000);

    let lnid = this.lastNotificationID;
    setTimeout((lastID: number = lnid) => {
      this.hideUIMessageWindowOnMessageTimeout(lastID);
    }, timeout);
  };

  private hideUIMessageWindowOnMessageTimeout(messageID: number) {
    if (this.lastNotificationID == messageID) {
      //this.lastNotificationID = null;
      this.hideUIMessageWindow();
    }
  }

  informUser = (message: string): void => {
    this.customNotify(message);
    this.markMessageAsInfo();
  };

  confirmToUser = (message: string): void => {
    this.customNotify(message);
    this.markMessageAsInfo();
  };

  warningToUser = (message: string): void => {
    this.customNotify(message);
    //this.customNotify("Please note: " + message);
    this.markMessageAsWarning();
  };

  errorToUser = (message: string): void => {
    this.customNotify("Something went wrong: " + message);
    this.markMessageAsError();
  };

  informUserSticky = (message: string, delayUntilHide: number = 7000): void => {
    this.customNotify(message, delayUntilHide);
    this.markMessageAsInfo();
  };

  confirmToUserSticky = (
    message: string,
    delayUntilHide: number = 7000
  ): void => {
    this.customNotify(message, delayUntilHide);
    this.markMessageAsInfo();
  };

  warningToUserSticky = (
    message: string,
    delayUntilHide: number = 7000
  ): void => {
    //this.customNotify("Please note: " + message, delayUntilHide);
    this.customNotify(message, delayUntilHide);
    this.markMessageAsWarning();
  };

  errorToUserSticky = (
    message: string,
    delayUntilHide: number = 7000
  ): void => {
    this.customNotify("Something went wrong: " + message, delayUntilHide);
    this.markMessageAsError();
  };

  showUIMessageWindow = () => {
    if (this.lastNotificationID !== null) return;
    this._rootHTML.classList.add("UIMessageWindowActive");
    this._UIMessages[0].classList.add("MessageActive");
    this.nwjsHandler.getUIMessengerWindow().showCustomUIMessengerWindow();
  };
  hideUIMessageWindow = () => {
    this.lastNotificationID = null;
    this.nwjsHandler.getUIMessengerWindow().hideCustomUIMessengerWindow();
    this._rootHTML.classList.remove("UIMessageWindowActive");
    this._UIMessages[0].classList.remove("MessageActive");
  };
  private replaceMessage1 = (newMessage: string): void => {
    this._UIMessageTexts[0].innerHTML = newMessage;
  };

  markMessageAsWarning = (messageID: number = 1) => {
    this._UIMessages[messageID - 1].classList.add("isWarning");
    this._UIMessages[messageID - 1].classList.remove("isError");
  };
  markMessageAsError = (messageID: number = 1) => {
    this._UIMessages[messageID - 1].classList.add("isError");
    this._UIMessages[messageID - 1].classList.remove("isWarning");
  };
  markMessageAsInfo = (messageID: number = 1) => {
    this._UIMessages[messageID - 1].classList.remove("isWarning");
    this._UIMessages[messageID - 1].classList.remove("isError");
  };

  getRequiredWidthFromUIMWindow = (): number => {
    return this._UIMessages[0].offsetWidth;
  };
  getRequiredHeightFromUIMWindow = (): number => {
    return this._UIMessages[0].offsetHeight;
  };
}
