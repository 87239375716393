import { Logger } from "../utils/Logger";

export class MediaDeviceMgr {
  private logger: Logger;

  constructor(_log: Logger) {
    this.logger = _log;
  }

  initialize = () => {};

  //_availableMediaDevices= []; //array of all available media devices (microphones, cameras, audio-outs)
  private _availableMediaDevicesMicrophones: MediaDeviceInfo[] = []; //array of all available microphones
  private _availableMediaDevicesCameras: MediaDeviceInfo[] = []; //array of all available cameras
  private _currentMediaDeviceIndexMicrophone: number = 0; // index in array of available micrrophones, marking the current microphone (should the user change it)
  private _currentMediaDeviceIndexCamera: number = 0; // index in array of available cameras, marking the current camera (should the user change it)
  private _reloadTriggerCountForMediaDeviceChange: number = 0; // counts how many reload timers have been started to trigger a reload based on media devices having been changed - to trigger reload if a timer ends when triggerCount is 1 - i.e. this is the last active timer

  //////////////////////////////////////////////////////////////////
  // handling of available media devices
  //////////////////////////////////////////////////////////////////

  clearAvailableMediaDevices = (): void => {
    //this._availableMediaDevices = [];
    this._availableMediaDevicesMicrophones = [];
    this._availableMediaDevicesCameras = [];
  };
  registerAvailableMediaDevices = (mediaDevices: MediaDeviceInfo[]): void => {
    this.clearAvailableMediaDevices();
    for (let i = 0; i < mediaDevices.length; i++) {
      this.registerAvailableMediaDevice(mediaDevices[i]);
      this.logger.logLocal([
        "identified available media device= ",
        mediaDevices[i],
      ]);
    }
  };
  registerAvailableMediaDevice = (mediaDevice: MediaDeviceInfo): void => {
    //this._availableMediaDevices.push(mediaDevice);
    if (mediaDevice.kind === "audioinput") {
      this._availableMediaDevicesMicrophones.push(mediaDevice);
    } else if (mediaDevice.kind === "videoinput") {
      this._availableMediaDevicesCameras.push(mediaDevice);
    }
  };
  getAvailableMediaDevicesMicrophones = (): MediaDeviceInfo[] => {
    return this._availableMediaDevicesMicrophones;
  };
  getAvailableMediaDevicesCameras = (): MediaDeviceInfo[] => {
    return this._availableMediaDevicesCameras;
  };
  unregisterAvailableMediaDeviceMicrophone = (index: number): void => {
    if (index < this._availableMediaDevicesMicrophones.length) {
      this._availableMediaDevicesMicrophones.splice(index, 1);
    } /*remove 1 item at index */
  };
  unregisterAvailableMediaDeviceCamera = (index: number): void => {
    if (index < this._availableMediaDevicesCameras.length) {
      this._availableMediaDevicesCameras.splice(index, 1);
    } /*remove 1 item at index */
  };
  unregisterDuplicateAvailableMediaDevices = (): void => {
    //compare all devices in arrays based on their groupID - if groupID is the same, remove as duplicate
    this.logger.logLocal(["now scanning identified devices for duplicates"]);
    for (
      let i = this._availableMediaDevicesMicrophones.length - 1;
      i >= 0;
      i--
    ) {
      //move through array from back to front - so that deletions at back don't mess with front that is still to be processed
      for (let j = 0; j < i; j++) {
        if (
          this.getAvailableMediaDevicesMicrophones()[i].groupId ===
          this.getAvailableMediaDevicesMicrophones()[j].groupId
        ) {
          this.logger.logLocal([
            "dropping duplicate microphone >> at i=",
            i,
            " is microphone ",
            this.getAvailableMediaDevicesMicrophones()[i],
            " and at j=",
            j,
            " is microphone ",
            this.getAvailableMediaDevicesMicrophones()[j],
          ]);
          this.unregisterAvailableMediaDeviceMicrophone(i);
          break;
        }
      }
    }
    let updatedMicrophoneList = this.getAvailableMediaDevicesMicrophones();
    if (
      updatedMicrophoneList.length == 0 ||
      (updatedMicrophoneList.length == 1 &&
        updatedMicrophoneList[0].label == "")
    )
      this.logger.logWarning([
        "local client with inadequate list of microphones that will be displayed to user (either 0 devices or 1 with empty label)",
      ]);
    for (let i = this._availableMediaDevicesCameras.length - 1; i >= 0; i--) {
      //move through array from back to front - so that deletions at back don't mess with front that is still to be processed
      for (let j = 0; j < i; j++) {
        if (
          this.getAvailableMediaDevicesCameras()[i].groupId ===
          this.getAvailableMediaDevicesCameras()[j].groupId
        ) {
          this.logger.logLocal([
            "dropping duplicate camera= ",
            this.getAvailableMediaDevicesCameras()[i],
            " at i=",
            i,
            " and j=",
            j,
          ]);
          this.logger.logLocal([
            "dropping duplicate camera= at i=",
            i,
            " is camera ",
            this.getAvailableMediaDevicesCameras()[i],
            " and at j=",
            j,
            " is camera ",
            this.getAvailableMediaDevicesCameras()[j],
          ]);
          this.unregisterAvailableMediaDeviceCamera(i);
          break;
        }
      }
    }
    let updatedCameraList = this.getAvailableMediaDevicesCameras();
    if (
      updatedCameraList.length == 0 ||
      (updatedCameraList.length == 1 && updatedCameraList[0].label == "")
    )
      this.logger.logWarning([
        "local client with inadequate list of cameras that will be displayed to user (either 0 devices or 1 with empty label)",
      ]);
  };
  initializeCurrentMediaDeviceIndexes = (
    _camIndexOfCurrentDevice: number = 0,
    _micIndexOfCurrentDevice: number = 0
  ) => {
    this._currentMediaDeviceIndexCamera = _camIndexOfCurrentDevice;
    this._currentMediaDeviceIndexMicrophone = _micIndexOfCurrentDevice;
  };
  setCurrentMediaDeviceIndexMicrophone = (newIndex: number = 0): void => {
    this._currentMediaDeviceIndexMicrophone = newIndex;
  };
  setCurrentMediaDeviceIndexCamera = (newIndex: number = 0): void => {
    this._currentMediaDeviceIndexCamera = newIndex;
  };
  getCurrentMediaDeviceIndexMicrophone = (): number => {
    return this._currentMediaDeviceIndexMicrophone;
  };
  getCurrentMediaDeviceIndexCamera = (): number => {
    return this._currentMediaDeviceIndexCamera;
  };
  iterateToNextMediaDeviceIndexMicrophone = (): void => {
    // increase index by 1 if list not ended yet, else reset to 0
    if (
      this.getCurrentMediaDeviceIndexMicrophone() + 1 <
      this.getAvailableMediaDevicesMicrophones().length
    ) {
      this._currentMediaDeviceIndexMicrophone =
        this.getCurrentMediaDeviceIndexMicrophone() + 1;
    } else this._currentMediaDeviceIndexMicrophone = 0;
  };
  iterateToNextMediaDeviceIndexCamera = (): void => {
    // increase index by 1 if list not ended yet, else reset to 0
    if (
      this.getCurrentMediaDeviceIndexCamera() + 1 <
      this.getAvailableMediaDevicesCameras().length
    ) {
      this._currentMediaDeviceIndexCamera =
        this.getCurrentMediaDeviceIndexCamera() + 1;
    } else this._currentMediaDeviceIndexCamera = 0;
  };

  getCurrentReloadTriggerCount = (): number => {
    return this._reloadTriggerCountForMediaDeviceChange;
  };
  increaseReloadTriggerCount = (): void => {
    this._reloadTriggerCountForMediaDeviceChange =
      this._reloadTriggerCountForMediaDeviceChange + 1;
  };
  decreaseReloadTriggerCount = (): void => {
    this._reloadTriggerCountForMediaDeviceChange =
      this._reloadTriggerCountForMediaDeviceChange - 1;
  };
}
