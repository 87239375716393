import { CSSClassModifier } from "frontend/CSSModifierOverarching";
import { I_ConfigUI } from "frontend/decoupler/I_ConfigUI";
import { I_HTMLReferenceUser } from "frontend/html/I_HTMLReferenceUser";
import { UIHandler } from "frontend/UIHandler";
import { Helpers } from "utils/Helpers";
import { Logger } from "utils/Logger";
import { UIMessenger } from "utils/UIMessenger";

export class ConfWebMeet implements I_ConfigUI {
  private help: Helpers;
  private logger: Logger;
  private uiMessenger: UIMessenger;
  private cssModifier: CSSClassModifier;

  private confDocument: Document;
  //@ts-ignore
  //private confWindow: nw.Window; //reference to nwjs window
  private htmlRefUsers: I_HTMLReferenceUser;

  constructor(_help: Helpers, _logger: Logger, _uiMessenger: UIMessenger) {
    this.help = _help;
    this.logger = _logger;
    this.uiMessenger = _uiMessenger;

    this.confDocument = null;

    //this.confWindow = null;
    this.htmlRefUsers = null;
  }

  initialize = (_cssModifier: CSSClassModifier) => {
    this.cssModifier = _cssModifier;
  };

  private initializeConfWindow = (
    _ConfScreenURL: string,
    initfunc: { (doc: Document): void }
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (this.help.isWebApp()) {
        try {
          this.createHTMLConfDialogue(_ConfScreenURL, window.document);
          let myFrame: HTMLIFrameElement = window.document.getElementById(
            "webClientConfDialogue"
          ) as HTMLIFrameElement;
          myFrame.onload = () => {
            this.logger.logLocal([
              "iFrame with conf dialogue loaded - now initializing conf components",
            ]);
            this.confDocument = myFrame.contentWindow.document;
            this.confDocument.querySelector("html").classList.add("webClient");
            initfunc(this.confDocument);
            setTimeout(() => {
              myFrame.style.height =
                myFrame.contentWindow.document.body.scrollHeight + 25 + "px";
            }, 500);
            myFrame.onload = () => {};
            resolve();
            return;
          };
        } catch (e) {
          this.logger.logError(["Error initializing visavis conf window, ", e]);
          reject(e);
          return;
        }
      } else {
        this.logger.logError([
          "Error initializing visavis conf window - no web client usage detected, ",
        ]);
        reject(false);
        return;
      }
    });
  };

  showConfWindow = () => {
    if (!this.help.isWebApp() || this.confDocument == null) return;
    this.cssModifier.addClassOnRootHTML("webClientConfActive");
  };

  hideConfWindow = () => {
    if (!this.help.isWebApp()) return;
    this.cssModifier.removeClassOnRootHTML("webClientConfActive");
  };

  openConfWindow = (
    _confWindowURL: string,
    _htmlRefUser: I_HTMLReferenceUser
  ): void => {
    try {
      if (!this.help.isWebApp()) return;
      if (this.confDocument == null) {
        this.htmlRefUsers = _htmlRefUser;
        this.initializeConfWindow(
          _confWindowURL,
          this.htmlRefUsers.initializeUI
        ).then(this.showConfWindow);
      } else this.showConfWindow();
    } catch (e) {
      this.logger.logError(["error opening conf window", e]);
    }
  };

  private createHTMLConfDialogue = (
    confURL: string,
    rootDocSideBar: Document
  ): void => {
    //insert adequate HTML code if entry point is present - else, assume it's already there
    try {
      if (!rootDocSideBar.getElementById("webClientConfDialogueInsertPoint"))
        return;
      this.logger.logLocal([
        "Now inserting HTML for web client conf dialogue UI",
      ]);
      let remSSDiv: HTMLDivElement = rootDocSideBar.getElementById(
        "webClientConfDialogueInsertPoint"
      ) as HTMLDivElement;
      remSSDiv.innerHTML =
        "<iframe " +
        '  id="webClientConfDialogue" ' +
        '  src="' +
        confURL +
        '" ' +
        '  width="100%" ' +
        '  height="50px" ' +
        //'  style="border: none" ' +
        "> " +
        "</iframe>  ";
    } catch (e) {
      this.logger.logError([
        "Error creating HTML for web client conf dialogue: ",
        e,
      ]);
    }
  };

  closeConfWindow = (): void => {
    try {
      if (!this.help.isWebApp()) return;
      this.hideConfWindow();
      if (this.htmlRefUsers != null) {
        this.htmlRefUsers.tearDownUIReferencesPriorClosure();
        this.htmlRefUsers = null;
      }
      if (this.confDocument != null) {
        this.confDocument = null;
      } else {
        //this.uiMessenger.warningToUser(
        //  "There was an error closing the settings window. You may need to re-apply any setting changes. Please excuse the inconvenience."
        //);
      }
    } catch (e) {
      this.logger.logError(["error closing conf window", e]);
    }
  };

  toggleConfWindow = (
    _confWindowURL: string,
    _htmlRefUser: I_HTMLReferenceUser
  ): void => {
    if (this.confDocument != null) {
      this.closeConfWindow();
    } else {
      this.openConfWindow(_confWindowURL, _htmlRefUser);
    }
  };
}
