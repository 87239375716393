import { SIGNALING_PURPOSE } from "../data/enums/SIGNALING_PURPOSE";

export class VRTCPeerConnection extends RTCPeerConnection {
  private peerID: string;
  private room: string;
  private sigP: SIGNALING_PURPOSE;
  private isPriorFirstConnect: boolean;

  constructor(_pcConfig: any, _peerID: string, _room: string, _pcSigP) {
    super(_pcConfig);
    this.peerID = _peerID;
    this.room = _room;
    this.sigP = _pcSigP;

    this.isPriorFirstConnect = true;
  }

  getPeerID = (): string => {
    return this.peerID;
  };

  getRoom = (): string => {
    return this.room;
  };

  getSignalingPurpose = (): SIGNALING_PURPOSE => {
    return this.sigP;
  };

  registerFirstSuccessfulConnect = (): void => {
    this.isPriorFirstConnect = false;
  };

  isPriorFirstConnection = (): boolean => {
    return this.isPriorFirstConnect;
  };
}
