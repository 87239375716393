import { I_SidebarUI } from "frontend/decoupler/I_SidebarUI";
import { AppUIHTML } from "frontend/html/AppUIHTML";
import { MultiScreenHandler } from "frontend/nwjs/MultiScreenHandler";
import { Helpers } from "utils/Helpers";
import { Logger } from "utils/Logger";

export class SidebarWebMeet implements I_SidebarUI {
  private help: Helpers;
  private appUI: AppUIHTML;
  private logger: Logger;

  constructor(
    _help: Helpers,
    _logger: Logger //, _screenHandler: MultiScreenHandler
  ) {
    this.help = _help;
    this.logger = _logger;
  }

  initialize = (_appUI: AppUIHTML): void => {
    this.appUI = _appUI;
    // do nothing unless there needs to be any additional UI initialization going forward  - potentially calling the below updateSideBarWindowSizeAndPositionIfOnApp
  };

  getSidebarNWJSWindowReference = (): any => {
    return null;
  };

  updateSideBarWindowSizeAndPositionIfOnApp = (
    useStaticValuesForInitialization?: boolean,
    resetPositionXOnResizeAfterInitialization?: boolean,
    resetPositionYOnResizeAfterInitialization?: boolean
  ): void => {
    // do nothing unless sidebar needs to be moved to an initial position when loaded for the first time
  };

  getSideBarWindowHeightIfOnApp = (): number => {
    return screen.availHeight;
  };

  registerWindowMoveEventOnSideBar = (): void => {};

  moveSideBarWindowBackToSideIfOnApp = (): void => {};

  focusMainApp = (): void => {};

  refreshSideBarAlwaysOnTop = (): void => {};
}
