import { SettingStorage } from "data/SettingStorage";
import { ConfCamSettingsHTML } from "frontend/html/configElementsHTML/ConfCamSettingsHTML";
import { ConfMicSettingsHTML } from "frontend/html/configElementsHTML/ConfMicSettingsHTML";
import { ConfTeamSettingsHTML } from "frontend/html/configElementsHTML/ConfTeamSettingsHTML";
import { BrowserMediaHandler } from "media/BrowserMediaHandler";
import { Logger } from "utils/Logger";
import { UIMessenger } from "utils/UIMessenger";
import { I_ConfigElement } from "./I_ConfigElement";

export class ConfMicSettings implements I_ConfigElement {
  private logger: Logger;
  private settings: SettingStorage;
  private uiMessenger: UIMessenger;
  private media: BrowserMediaHandler;

  private confMicHTML: ConfMicSettingsHTML;

  constructor(
    _logger: Logger,
    _storage: SettingStorage,
    _uiMessenger: UIMessenger,
    _media: BrowserMediaHandler
  ) {
    this.logger = _logger;
    this.settings = _storage;
    this.uiMessenger = _uiMessenger;
    this.media = _media;
  }

  initialize = (_micHTML: ConfMicSettingsHTML): void => {
    this.confMicHTML = _micHTML;
  };

  private mics: MediaDeviceInfo[];
  private currentlyActiveMic: number;

  initializeUI = () => {
    try {
      this.media.getUniqueAvailableMediaDevices(true).then(() => {
        this.mics = this.media
          .getMediaDeviceMgr()
          .getAvailableMediaDevicesMicrophones();
        this.currentlyActiveMic =
          this.media
            .getMediaDeviceMgr()
            .getCurrentMediaDeviceIndexMicrophone() + 1;
        let micChoices: string = "";
        for (let i = 0; i < this.mics.length; i++) {
          if (i + 1 == this.currentlyActiveMic) {
            micChoices =
              micChoices +
              "<b>     " +
              (i + 1) +
              ":  " +
              this.mics[i].label +
              "[ACTIVE]</b></br>";
          } else {
            micChoices =
              micChoices +
              "     " +
              (i + 1) +
              ":  " +
              this.mics[i].label +
              "</br>";
          }
        }
        this.confMicHTML.setAvailableMicrophonesList(
          "<p>" + micChoices + "</p>"
        );
        this.confMicHTML.setMicChoiceInputValue(this.currentlyActiveMic + "");
      });
    } catch (e) {
      this.logger.logError([
        "Error initializing microphone settings on conf window, ",
        e,
      ]);
    }
  };

  onUserSave = (): void => {
    try {
      let chosenMicID = this.confMicHTML.getMicChoiceInputValue();
      if (chosenMicID == "") {
        this.settings.setMicDeviceID("");
        /*} else if (
        +chosenMicID == currentlyActiveMic &&
        this.data.getLocalMS() != null
      ) {
        //store chosen mic even if unchanged - this overwrites leftovers from prev sessions in the settings
        let devID = mics[+chosenMicID - 1].deviceId;
        this.settings.setMicDeviceID(devID);
        //when setting unchanged and current cam access working, do nothing
        /*this.uiMessenger.informUser(
          "No new microphone selected - leaving settings unchanged"
        );*/
      } else if (
        +chosenMicID < 1 ||
        +chosenMicID > this.mics.length ||
        isNaN(+chosenMicID)
      ) {
        this.uiMessenger.warningToUser(
          "Invalid microphone ID selected - plese try again / chose only valid numbers from shown list"
        );
      } else {
        //TODO: correct changes could actually be applied without restart - to investigate later whether this can be re-enabled - to be differentiated from cases where mic ID is unchanged and active
        /*this.media
          .getMediaDeviceMgr()
          .setCurrentMediaDeviceIndexMicrophone(+chosenMicID - 1);*/
        let devID = this.mics[+chosenMicID - 1].deviceId;
        this.settings.setMicDeviceID(devID);
        //this.media.applyMediaDeviceChange();
      }
    } catch (e) {
      this.logger.logError([
        "Error saving microphone settings via conf window, ",
        e,
      ]);
    }
  };

  onUserCancel = (): void => {};
}
