export class RaiseHandMessage {
  private senderID: string;
  private recipientID: string;
  private handRaised: boolean;

  constructor(_senderID: string, _recipientID: string, _handRaised: boolean) {
    this.senderID = _senderID;
    this.recipientID = _recipientID;
    this.handRaised = _handRaised;
  }

  getSenderID = (): string => {
    return this.senderID;
  };

  getRecipientID = (): string => {
    return this.recipientID;
  };

  getHandRaisedValue = (): boolean => {
    return this.handRaised;
  };
}
