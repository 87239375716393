import { I_URLBrowserWindowUI } from "frontend/decoupler/I_URLBrowserWindowUI";
import { Logger } from "../../utils/Logger";

export class URLBrowserWindowsNWJS implements I_URLBrowserWindowUI {
  private logger: Logger;

  private feedbackWindow: any; //reference to nwjs window
  private supportWindow: any; //reference to nwjs window
  private inviteTeamWindow: any; //reference to nwjs window
  private webRTCInternalsWindow: any; //reference to nwjs window

  constructor(_logger: Logger) {
    this.logger = _logger;
    this.feedbackWindow = null;
    this.supportWindow = null;
    this.inviteTeamWindow = null;
    this.webRTCInternalsWindow = null;
  }

  initialize = () => {};

  private openURLInNewBrowserWindow = (
    targetURL: string,
    windowTitle: string,
    initfunc: { (any): void },
    closefunc: { (): void }
  ): void => {
    try {
      this.logger.logLocal([
        "now attempting to open URL in new nw.js window: ",
        targetURL,
      ]);
      //@ts-ignore
      return nw.Window.open(
        targetURL,
        {
          title: windowTitle,
          //id: windowTitle,
          position: "center",
          icon: "favicon.png" /* difference here */,
          show: true,
          show_in_taskbar: true,
          visible_on_all_workspaces: true,
        },
        (win) => {
          win.setVisibleOnAllWorkspaces(true);
          win.on("loaded", () => {
            //if (this.help.isMacApp()) win.setShadow(false);
            initfunc(win);
            return;
          });
          win.on("close", () => {
            closefunc();
            win.close(true);
            return;
          });
        }
      );
      //}
    } catch (e) {
      this.logger.logLocal([
        "not in app - thus not opening URL in new browser window",
      ]);
    }
  };

  openFeedbackFormWindow = (feedbackURL: string): void => {
    if (this.feedbackWindow != null) {
      this.feedbackWindow.show();
      this.feedbackWindow.restore();
    } else {
      this.openURLInNewBrowserWindow(
        feedbackURL,
        "visavis feedback" + Math.ceil(Math.random() * 10000),
        (win) => {
          this.feedbackWindow = win;
        },
        () => {
          this.feedbackWindow = null;
        }
      );
    }
  };

  closeFeedbackFormWindow = (): void => {
    if (this.feedbackWindow != null) this.feedbackWindow.close();
    this.feedbackWindow = null;
  };

  openSupportFormWindow = (supportURL: string): void => {
    if (this.supportWindow != null) {
      this.supportWindow.show();
      this.supportWindow.restore();
    } else {
      this.openURLInNewBrowserWindow(
        supportURL,
        "visavis support",
        (win) => {
          this.supportWindow = win;
        },
        () => {
          this.supportWindow = null;
        }
      );
    }
  };

  closeSupportFormWindow = (): void => {
    if (this.supportWindow != null) this.supportWindow.close();
    this.supportWindow = null;
  };

  openInviteTeamWindow = (inviteTeamURL: string) => {
    if (this.inviteTeamWindow != null) {
      this.inviteTeamWindow.show();
      this.inviteTeamWindow.restore();
    } else {
      this.openURLInNewBrowserWindow(
        inviteTeamURL,
        "visavis team invite",
        (win) => {
          this.inviteTeamWindow = win;
        },
        () => {
          this.inviteTeamWindow = null;
        }
      );
    }
  };

  closeInviteTeamWindow = () => {
    if (this.inviteTeamWindow != null) this.inviteTeamWindow.close();
    this.inviteTeamWindow = null;
  };

  openwebRTCInternalsWindow = (webRTCInternalsURL: string): void => {
    if (this.webRTCInternalsWindow != null) {
      this.webRTCInternalsWindow.show();
      this.webRTCInternalsWindow.restore();
    } else {
      this.openURLInNewBrowserWindow(
        webRTCInternalsURL,
        "webRTC internals",
        (win) => {
          this.webRTCInternalsWindow = win;
        },
        () => {
          this.webRTCInternalsWindow = null;
        }
      );
    }
  };

  closewebRTCInternalsWindow = (): void => {
    if (this.webRTCInternalsWindow != null) this.webRTCInternalsWindow.close();
    this.webRTCInternalsWindow = null;
  };
}
