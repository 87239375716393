import { Helpers } from "utils/Helpers";
import { Logger } from "../../utils/Logger";

export class WebClientSideBarEnhancementsHTML {
  private logger: Logger;
  private help: Helpers;

  private _pauseLeaveButton: HTMLButtonElement;
  private _confButton: HTMLButtonElement;
  private _logoutButton: HTMLButtonElement;
  private _inVCLeaveButton: HTMLButtonElement;
  private _inVCMuteButton: HTMLButtonElement;
  private _inVCPauseVideoButton: HTMLButtonElement;
  private _inVCChatButton: HTMLButtonElement;

  private rootDocSideBar: Document;
  private confDocument: Document;

  constructor(_logger: Logger, _help: Helpers) {
    this.logger = _logger;
    this.help = _help;

    this.confDocument = null;

    this._pauseLeaveButton = null;
    this._confButton = null;
    this._logoutButton = null;
    this._inVCLeaveButton = null;
    this._inVCMuteButton = null;
    this._inVCPauseVideoButton = null;
    this._inVCChatButton = null;
  }

  initialize = (rootDocSideBar: Document) => {
    this.rootDocSideBar = rootDocSideBar;

    this.createHTMLTopBar();
    this.createHTMLWebClientFooterMessage();
    this.createHTMLWebClientMeetingInfoAndButtonSpace();

    this.initializeHTMLReferencesTopBar();
    this.initializeHTMLReferencesWebClientMeetingInfoAndButtonSpace();

    this.logger.logLocal(["Web client top bar UI initialized"]);
  };

  private createHTMLTopBar = (): void => {
    //insert adequate HTML code if entry point is present - else, assume it's already there
    try {
      if (!this.rootDocSideBar.getElementById("webClientTopBarInsertPoint"))
        return;
      this.logger.logLocal(["Now inserting HTML for web client top bar UI"]);
      let remSSDiv: HTMLDivElement = this.rootDocSideBar.getElementById(
        "webClientTopBarInsertPoint"
      ) as HTMLDivElement;
      remSSDiv.innerHTML =
        '<div class="meetTopLogo"> ' +
        "  <img " +
        '    class="meetTopLogoIMG" ' +
        '    src="./img/visavisGO.png" ' +
        '    alt="visav.is on-the-go" ' +
        "  /> " +
        "</div> " +
        '<div class="meetMeetingOuterButtonArea"> ' +
        "  <button " +
        '    title="Settings" ' +
        '    class="basicButton optionsButton webMeetingUIButton webMeetingOuterButton settingsButton" ' +
        '    id="settingsDialogueOuterButton" ' +
        "  ></button> " +
        "  <button " +
        '    title="Logout" ' +
        '    class="basicButton optionsButton webMeetingUIButton webMeetingOuterButton logoutButton" ' +
        '    id="logoutWebAppOuterButton" ' +
        "  ></button> " +
        "  <button " +
        '    title="Pause / Leave Room" ' +
        '    class="basicButton optionsButton webMeetingUIButton webMeetingOuterButton pauseVISAVISButton" ' +
        '    id="webClientPauseLeaveWebAppOuterButton" ' +
        "  ></button> " +
        "</div> ";
    } catch (e) {
      this.logger.logError(["Error creating HTML for web client top bar: ", e]);
    }
  };

  private createHTMLWebClientFooterMessage = (): void => {
    //insert adequate HTML code if entry point is present - else, assume it's already there
    try {
      if (
        !this.rootDocSideBar.getElementById("webClientFooterMessageInsertPoint")
      )
        return;
      this.logger.logLocal(["Now inserting HTML for footer message"]);
      let remSSDiv: HTMLDivElement = this.rootDocSideBar.getElementById(
        "webClientFooterMessageInsertPoint"
      ) as HTMLDivElement;
      remSSDiv.innerHTML =
        '<div class="webClientFooterMsg"> ' +
        "  <label " +
        "    >For more info on visav.is and our free beta, please visit " +
        '    <a href="https://visav.is">https://visav.is</a></label> ' +
        "</div> ";
    } catch (e) {
      this.logger.logError(["Error creating HTML for web client footer: ", e]);
    }
  };

  private createHTMLWebClientMeetingInfoAndButtonSpace = (): void => {
    //insert adequate HTML code if entry point is present - else, assume it's already there
    try {
      if (
        !this.rootDocSideBar.getElementById(
          "webClientMeetingInfoAndButtonSpaceInsertPoint"
        )
      )
        return;
      this.logger.logLocal(["Now inserting HTML for meeting info and buttons"]);
      let remSSDiv: HTMLDivElement = this.rootDocSideBar.getElementById(
        "webClientMeetingInfoAndButtonSpaceInsertPoint"
      ) as HTMLDivElement;
      remSSDiv.innerHTML =
        '<div class="webClientMeetingInfoAlternative webClientMeetingInfoUserAlone"> ' +
        "  <img " +
        '    class="meetInfoUserAloneIMG" ' +
        '    src="./img/guide-cam-wait.webp" ' +
        '    alt="please grant access to camera and wait for teammates to join" ' +
        "  /> " +
        "</div> " +
        '<div class="webClientMeetingInfoAlternative webClientMeetingInfoStartComms"> ' +
        "  <img " +
        '    class="meetInfoStartCommsIMG" ' +
        '    src="./img/guide-start.webp" ' +
        '    alt="tap on a teammate to get started" ' +
        "  /> " +
        "</div> " +
        '<div class="webClientMeetingInfoAlternative webClientMeetingInfoAfterMeeting"> ' +
        "  <img " +
        '    class="meetInfoAfterMeetingIMG" ' +
        '    src="./img/guide-smile.webp" ' +
        '    alt="enjoy" ' +
        "  /> " +
        "</div> " +
        '<div class="webClientMeetingInfoAlternative webClientMeetingButtonArea"> ' +
        '<div class="webClientMeetingInfoVerticalButtonGroup webClientMeetingInfoVerticalButtonGroupHangUp"> ' +
        "  <button " +
        '    title="leave conversation" ' +
        '    class=" ' +
        "    basicButton " +
        "    additionalInVCButton " +
        "    webClientInMeetingButton " +
        '    leaveVCButton2" ' +
        '    id="webClientButtonLeaveVC" ' +
        "  ></button> " +
        "</div> " +
        '<div class="webClientMeetingInfoVerticalButtonGroup webClientMeetingInfoVerticalButtonGroupOthers"> ' +
        "  <button " +
        '    title="mute local microphone" ' +
        '    class=" ' +
        "    basicButton " +
        "    additionalInVCButton " +
        "    webClientInMeetingButton " +
        '    muteButton" ' +
        '    id="webClientButtonMuteLocal" ' +
        "  ></button> " +
        "  <button " +
        '    title="pause video" ' +
        '    class=" ' +
        "    basicButton " +
        "    additionalInVCButton " +
        "    webClientInMeetingButton " +
        '    pauseButton" ' +
        '    id="webClientButtonPauseVideo" ' +
        "  ></button> " +
        "  <button " +
        '    title="open chat" ' +
        '    class=" ' +
        "    basicButton " +
        "    additionalInVCButton " +
        "    webClientInMeetingButton " +
        '    chatButton" ' +
        '    id="webClientButtonOpenChat" ' +
        "  ></button> " +
        "</div> " +
        "</div> ";
    } catch (e) {
      this.logger.logError([
        "Error creating HTML for meeting info and buttons: ",
        e,
      ]);
    }
  };

  private initializeHTMLReferencesTopBar = () => {
    this._pauseLeaveButton = this.rootDocSideBar.getElementById(
      "webClientPauseLeaveWebAppOuterButton"
    ) as HTMLButtonElement;
    this._confButton = this.rootDocSideBar.getElementById(
      "settingsDialogueOuterButton"
    ) as HTMLButtonElement;
    this._logoutButton = this.rootDocSideBar.getElementById(
      "logoutWebAppOuterButton"
    ) as HTMLButtonElement;
  };

  private initializeHTMLReferencesWebClientMeetingInfoAndButtonSpace = () => {
    this._inVCLeaveButton = this.rootDocSideBar.getElementById(
      "webClientButtonLeaveVC"
    ) as HTMLButtonElement;
    this._inVCMuteButton = this.rootDocSideBar.getElementById(
      "webClientButtonMuteLocal"
    ) as HTMLButtonElement;
    this._inVCPauseVideoButton = this.rootDocSideBar.getElementById(
      "webClientButtonPauseVideo"
    ) as HTMLButtonElement;
    this._inVCChatButton = this.rootDocSideBar.getElementById(
      "webClientButtonOpenChat"
    ) as HTMLButtonElement;
  };

  getUILogoutButton = (): HTMLButtonElement => {
    return this._logoutButton;
  };
  getUIPauseLeaveButton = (): HTMLButtonElement => {
    return this._pauseLeaveButton;
  };
  getSettingsDialogueButton = (): HTMLButtonElement => {
    return this._confButton;
  };

  getInVCLeaveButton = (): HTMLButtonElement => {
    return this._inVCLeaveButton;
  };
  getInVCMuteButton = (): HTMLButtonElement => {
    return this._inVCMuteButton;
  };
  getInVCPauseVideoButton = (): HTMLButtonElement => {
    return this._inVCPauseVideoButton;
  };
  getInVCChatButton = (): HTMLButtonElement => {
    return this._inVCChatButton;
  };
}
