import { APP_SCREEN_SIDE } from "../data/enums/APP_SCREEN_SIDE";
import { I_UITechHandler } from "frontend/decoupler/I_UITechHandler";

export class UIMessengerCore {
  private screenSide: APP_SCREEN_SIDE;

  constructor() {
    try {
      this.screenSide = null;
    } catch (e) {
      console.error("error in concstructor of uiMessengerCore");
    }
  }

  initializeCore = (): void => {
    this.screenSide = APP_SCREEN_SIDE.RIGHT;
  };

  updateAppScreenSide = (_newSS: APP_SCREEN_SIDE) => {
    this.screenSide = _newSS;
  };

  isRightScreenSide = (): boolean => {
    return this.screenSide === APP_SCREEN_SIDE.RIGHT;
  };

  isLeftScreenSide = (): boolean => {
    return this.screenSide === APP_SCREEN_SIDE.LEFT;
  };

  // Shorten a string to less than maxLen characters without truncating words.
  shortenString = (str: string, maxLen: number, separator: string = " ") => {
    let strWSpace = str + " ";
    if (str.length <= maxLen) return str;
    return strWSpace.substring(0, strWSpace.lastIndexOf(separator, maxLen));
  };

  UITechInitializationTriggerPostUIInitialization = (
    _nwjsHandler: I_UITechHandler
  ): Promise<void> => {
    // do nothing - to be overwritten by child classes that require this feature
    return new Promise((resolve, reject) => {
      resolve();
    });
    //return null;
  };
}
