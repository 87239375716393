import { Helpers } from "../../utils/Helpers";
import { APP_SCREEN_SIDE } from "../../data/enums/APP_SCREEN_SIDE";
import { Logger } from "../../utils/Logger";
import { UIMessengerCustom } from "../../utils/UIMessengerCustom";
import { AppUIHTML } from "../html/AppUIHTML";
import { SidebarWindowNWJS } from "./SidebarWindowNWJS";
import { I_UIMessengerWindow } from "frontend/decoupler/I_UIMessengerWindow";

export class UIMessengerWindowNWJS implements I_UIMessengerWindow {
  private help: Helpers;
  private logger: Logger;
  private uiMessenger: UIMessengerCustom;
  private appUI: AppUIHTML;
  private sidebarWindow: SidebarWindowNWJS;

  private customUIMessengerWindow: any; //reference to nwjs window

  constructor(
    _help: Helpers,
    _logger: Logger,
    _uiMessenger: UIMessengerCustom,
    _sidebarWindow: SidebarWindowNWJS
  ) {
    this.help = _help;
    this.logger = _logger;
    this.uiMessenger = _uiMessenger;
    this.sidebarWindow = _sidebarWindow;
  }

  initialize = (_appUI: AppUIHTML): void => {
    this.appUI = _appUI;
  };

  closeUIMWindowIfOnApp = (): void => {
    if (this.help.appIsActive()) this.customUIMessengerWindow.close(true);
  };

  initializeCustomUIMessengerWindow = (
    _doc: Document,
    _UIMScreenURL: string,
    initfunc: { (any, Document): void }
    /*initFuncEntireUIMMesWin: { (HTMLDivElement): void }*/
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (this.help.appIsActive()) {
        try {
          //@ts-ignore
          nw.Window.open(
            _UIMScreenURL,
            {
              title: "visavis ui messenger",
              position: "center",
              //icon: "favicon.icns",
              show: false,
              frame: false,
              transparent: true,
              always_on_top: true,
              show_in_taskbar: false,
              visible_on_all_workspaces: true,
              focus: false,
              //focus: true,
            },
            (win) => {
              win.on("loaded", () => {
                this.customUIMessengerWindow = win;
                initfunc(win, win.window.document);
                /*initFuncEntireUIMMesWin(
                      win.window.document.getElementById(
                        "messageWindow"
                      ) as HTMLDivElement
                    );*/
                if (this.help.isMacApp())
                  this.customUIMessengerWindow.setShadow(false);
                this.customUIMessengerWindow.setVisibleOnAllWorkspaces(true);
                this.sidebarWindow.focusMainApp();
                resolve();
                return;
              });
            }
          );
        } catch (e) {
          this.logger.logError([
            "Error initializing visavis ui messenger window, ",
            e,
          ]);
          reject(e);
          return;
        }
      } else {
        this.logger.logError([
          "Error initializing visavis ui messenger window - no app usage detected, ",
        ]);
        reject(false);
        return;
      }
    });
  };

  private UIMWindowHasBeenInitialized = false;

  showCustomUIMessengerWindow = (): void => {
    if (!this.help.appIsActive()) return;
    try {
      if (!this.UIMWindowHasBeenInitialized) {
        this.UIMWindowHasBeenInitialized = true;
        this.customUIMessengerWindow.setResizable(true);
        this.customUIMessengerWindow.resizeTo(570, 90);
        let distanceFromLeft = screen.availWidth - 585;
        let distanceFromTop = screen.availHeight - 120;
        this.customUIMessengerWindow.moveTo(distanceFromLeft, distanceFromTop); //20, 20);
        this.customUIMessengerWindow.setResizable(false);
        if (this.help.isMacApp()) {
          this.customUIMessengerWindow.setShadow(false);
          this.customUIMessengerWindow.blur();
        }
      }
      this.customUIMessengerWindow.show();
    } catch (e) {
      this.logger.logError(["error showing ui messenger window", e]);
    }
  };

  hideCustomUIMessengerWindow = (): void => {
    if (!this.help.appIsActive()) return;
    try {
      this.customUIMessengerWindow.hide();
    } catch (e) {
      this.logger.logError(["error hiding ui messenger window", e]);
    }
  };

  switchUIMessengerWindowToScreenSide = (
    newScreenSide: APP_SCREEN_SIDE
  ): void => {
    if (!this.help.appIsActive()) return;
    /*let distanceFromLeft = screen.availWidth - 585;
        let distanceFromTop = screen.availHeight - 120;
    
        if (newScreenSide == APP_SCREEN_SIDE.LEFT) distanceFromLeft = 15;*/
    try {
      //this.customUIMessengerWindow.moveTo(distanceFromLeft, distanceFromTop);
      this.updateUIMWindowSizeAndPositionToReflectSizeRequirementOfCurrentText();
    } catch (e) {
      this.logger.logError(["error switching sides for UI messenger", e]);
    }
  };

  private getUIMLeftDistance = (): number => {
    if (this.appUI.getAppScreenSide() == APP_SCREEN_SIDE.LEFT) {
      return 15;
    } else {
      let requiredWidth: number =
        this.uiMessenger.getRequiredWidthFromUIMWindow() / 0.98;
      let requiredDistanceLeft: number = screen.availWidth - requiredWidth - 15;
      return Math.floor(requiredDistanceLeft);
    }
  };

  private getUIMTopDistance = (): number => {
    let requiredHeight =
      this.uiMessenger.getRequiredHeightFromUIMWindow() / 0.94;
    let requiredDistanceTop: number = screen.availHeight - requiredHeight - 15;
    return Math.floor(requiredDistanceTop);
  };

  updateUIMWindowSizeAndPositionToReflectSizeRequirementOfCurrentText =
    (): void => {
      if (!this.help.appIsActive()) return;
      let requiredWidth = Math.ceil(
        this.uiMessenger.getRequiredWidthFromUIMWindow() / 0.98
      );
      let requiredHeight = Math.ceil(
        this.uiMessenger.getRequiredHeightFromUIMWindow() / 0.94
      );
      try {
        this.customUIMessengerWindow.setResizable(true);
        this.customUIMessengerWindow.resizeTo(requiredWidth, requiredHeight);
        this.customUIMessengerWindow.setResizable(false);
        this.customUIMessengerWindow.moveTo(
          this.getUIMLeftDistance(),
          this.getUIMTopDistance()
        );
      } catch (e) {
        this.logger.logError([
          "error updating UIM window size and position to account for current message size",
          e,
        ]);
      }
    };
}
