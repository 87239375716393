import { Config } from "../config/Config";
import { CSSClassModifier } from "../frontend/CSSModifierOverarching";
import { Helpers } from "../utils/Helpers";
import { BusLogicVC } from "../buslogic/BusLogicVC";
import { UINwjsHandler } from "../frontend/nwjs/UINwjsHandler";
import { UIMessenger } from "utils/UIMessenger";
import { I_UITechHandler } from "frontend/decoupler/I_UITechHandler";

export class EnvironmentSwitcher {
  private clickCountDev: number;
  private clickCountProd: number;

  private help: Helpers;
  private config: Config;
  private cssModifier: CSSClassModifier;
  private uiTechHandler: I_UITechHandler;
  private busLogic: BusLogicVC;
  private uiMessenger: UIMessenger;

  constructor(
    _help: Helpers,
    _config: Config,
    _cssMod: CSSClassModifier,
    _nwjs: I_UITechHandler,
    _uiMessenger: UIMessenger
  ) {
    this.help = _help;
    this.config = _config;
    this.cssModifier = _cssMod;
    this.uiTechHandler = _nwjs;
    this.uiMessenger = _uiMessenger;

    this.clickCountDev = 0;
    this.clickCountProd = 0;
  }

  initialize = (_busLogic: BusLogicVC) => {
    this.busLogic = _busLogic;
    this.detectDevEnv();
  };

  detectDevEnv = (): void => {
    if (this.help.isDevEnv()) {
      this.cssModifier.addClassOnEntireApp("devEnvActive");
    }
  };

  prodEnvTriggerClick = (): void => {
    this.clickCountProd = this.clickCountProd + 1;
    if (this.clickCountProd >= 10) this.loadProdEnv();
    setTimeout(() => {
      this.clickCountProd = 0;
    }, 3000);
  };

  devEnvTriggerClick = (): void => {
    this.clickCountDev = this.clickCountDev + 1;
    if (this.clickCountDev >= 10) this.loadDevEnv();
    setTimeout(() => {
      this.clickCountDev = 0;
    }, 3000);
  };

  loadDevEnv = (): void => {
    console.warn("attempting to load dev environment");
    let targetURL = this.config.devEnvURL + "?";
    if (this.help.appIsActive()) {
      targetURL = targetURL + "app=" + this.help.determineAppVersion() + "&";
    }
    if (this.help.URLParameterPresent("room")) {
      targetURL = targetURL + "room=" + this.help.getURLParameter("room") + "&";
    }
    this.busLogic.fullLocalHangupOnExit("RestartToSwitchToDevEnv");
    this.uiTechHandler.closeAllWindowsExceptSideBar();
    //this.nwjsHandler.closeOrphanedWindowsFromPreviousAppStarts();
    setTimeout(() => {
      window.location.href = targetURL + "rand=" + this.getRandomInt(10000);
    }, 2000);
  };

  loadProdEnv = (): void => {
    console.warn("attempting to load prod environment");
    let targetURL = this.config.prodEnvURL + "?";
    if (this.help.appIsActive()) {
      targetURL = targetURL + "app=" + this.help.determineAppVersion() + "&";
    }
    if (this.help.URLParameterPresent("room")) {
      targetURL = targetURL + "room=" + this.help.getURLParameter("room") + "&";
    }
    this.busLogic.fullLocalHangupOnExit("RestartToSwitchToProdEnv");
    this.uiTechHandler.closeAllWindowsExceptSideBar();
    //this.nwjsHandler.closeOrphanedWindowsFromPreviousAppStarts();
    setTimeout(() => {
      window.location.href = targetURL + "rand=" + this.getRandomInt(10000);
    }, 2000);
  };

  getRandomInt = (max: number): number => {
    return Math.floor(Math.random() * max);
  };

  getBaseURLOfCurrentEnvironment = (): string => {
    if (
      window.location.hostname == this.config.devEnvHostname ||
      window.location.hostname == this.config.devEnvHostnameLEGACY
    )
      // TO BE REMOVED ONCE MIGRATED
      return this.config.devEnvURL;
    else if (window.location.hostname == this.config.localEnvHostname)
      return this.config.localEnvURL;
    else if (window.location.hostname == this.config.prodEnvHostnameNEW)
      return this.config.prodEnvURLNEW;
    /*if (window.location.hostname == this.config.prodEnvHostname)*/ else
      return this.config.prodEnvURL; // in case of doubt, redirect to production
    /*else return null;*/
  };

  reloadCurrentEnvWithParameters = (): void => {
    if (!this.help.isDeviceConnectedToTheInternet()) {
      this.uiMessenger.warningToUser(
        "App could not reload as you are currently not connected to the internet. Please ensure proper connection and try again."
      );
      return;
    }
    console.warn("attempting to reload current environment");
    let targetURL = this.getBaseURLOfCurrentEnvironment() + "?";
    if (this.help.appIsActive()) {
      targetURL = targetURL + "app=" + this.help.determineAppVersion() + "&";
    } else if (this.help.isWebApp()) {
      targetURL = targetURL + "web=" + this.help.getURLParameter("web") + "&";
    }
    if (this.help.URLParameterPresent("room")) {
      targetURL = targetURL + "room=" + this.help.getURLParameter("room") + "&";
    }
    if (this.help.URLParameterPresent("username")) {
      targetURL =
        targetURL + "username=" + this.help.getURLParameter("username") + "&";
    }
    if (this.help.URLParameterPresent("sizing")) {
      targetURL =
        targetURL + "sizing=" + this.help.getURLParameter("sizing") + "&";
    }
    //this.nwjsHandler.getVCWindowNWJS().reloadIgnoringCache();
    //@ts-ignore
    //nw.Window.get().reloadIgnoringCache();
    window.location.href = targetURL + "rand=" + this.getRandomInt(10000);
    //@ts-ignore
    //nw.Window.get().reloadDev();
  };
}
