import { Socket } from "socket.io-client";
import { Helpers } from "utils/Helpers";

export class SocketData {
  private help: Helpers;

  constructor(_help: Helpers) {
    this.help = _help;
  }

  private localSocket: Socket = null; //stores local socket as used to connect to the signaling server
  private localSocketID: string = null; //stores local socket ID as received on connecting to the signaling server - allows to check whether ID changed after a temporary loss of the server connection

  private mainRoom: string = null; //stores the name of the main room in which the local client / user resides
  //private mainRoomPure: string = null; //stores the name of the main room in which the local client / user resides - excluding a possible pwdHash component

  initialize = (socket: Socket, mainRoomName: string) => {
    this.setLocalSocket(socket);
    this.setMainRoom(mainRoomName);
  };

  setLocalSocketID = (id: string): void => {
    this.localSocketID = id;
  };

  getLocalSocketID = (): string => {
    return this.localSocketID;
  };

  private setLocalSocket = (socket: Socket): void => {
    if (this.getLocalSocket() !== null) {
      this.getLocalSocket().disconnect();
    } // if for whatever reason 2nd socket is created / stored in dataMgmt_localSocket, close old one
    this.localSocket = socket;
    if (socket.id !== undefined) {
      this.setLocalSocketID(socket.id);
    }
  };

  getLocalSocket = (): Socket => {
    return this.localSocket;
  };

  private setMainRoom = (roomName: string): void => {
    this.mainRoom = this.help.standardizeString(roomName);
    /*let roomNameEnd: number = this.mainRoom.lastIndexOf("____")
    if (roomNameEnd >= 0) {
      this.mainRoomPure = this.mainRoom.substring(0,roomNameEnd);
    } else {
      this.mainRoomPure = this.mainRoom;
    }*/
  };

  getMainRoom = (): string => {
    return this.mainRoom;
  };

  /*getMainRoomPure = (): string => {
    return this.mainRoomPure;
  }*/

  /*private standardizeRoomName = (roomNameInput: string): string => {
    return roomNameInput.toLowerCase().replace(/\s+/g, "");
  };*/
}
