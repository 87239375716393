/* COPYRIGHT 2021 Michael Maur - any form of reuse requires written consent by the author */

"use strict";

import { Config } from "config/Config";
import { DataMgmt } from "data/DataMgmt";
import { Helpers } from "utils/Helpers";
import { SoundEffects } from "media/SoundEffects";
import { UIHandler } from "frontend/UIHandler";
import { SignalingCore } from "signaling/SignalingCore";
import { BrowserMediaHandler } from "media/BrowserMediaHandler";
import { SettingStorage } from "data/SettingStorage";
import { Logger } from "utils/Logger";
import { UIMessenger } from "utils/UIMessenger";
import { SIGNALING_PURPOSE } from "data/enums/SIGNALING_PURPOSE";
import { AppUIHTML } from "frontend/html/AppUIHTML";
import { VCDataManager } from "data/VCDataManager";
import { RaiseHand } from "./raiseHand/RaiseHand";
import { VRTCPeerConnection } from "signaling/VRTCPeerConnection";
import { UserNameLabel } from "./userNameLabel/UserNameLabel";
import { StayAliveMgr } from "../infra/StayAliveMgr";
import { RemoteMute } from "./remoteMute/RemoteMute";
import { AppContextHandler } from "../infra/AppContextHandler";
import { MobileWakeLock } from "infra/MobileWakeLock";

export abstract class BusLogicCore {
  protected help: Helpers;
  protected context: AppContextHandler;
  protected config: Config;
  protected settings: SettingStorage;
  protected data: DataMgmt;
  protected logger: Logger;
  protected uiMessenger: UIMessenger;
  protected sound: SoundEffects;
  protected uiHandler: UIHandler;
  protected signaling: SignalingCore;
  protected media: BrowserMediaHandler;
  protected appUI: AppUIHTML;
  protected vcData: VCDataManager;
  private raiseHand: RaiseHand;
  protected remoteMute: RemoteMute;
  protected userNameLabel: UserNameLabel;
  private stayAlive: StayAliveMgr;
  protected wakeLocker: MobileWakeLock;

  private priorToFirstUserJoining: boolean; //flag to display guidance message when first user (except yourself) joins the visav.is session

  constructor(
    _help: Helpers,
    _context: AppContextHandler,
    _config: Config,
    _settings: SettingStorage,
    _logger: Logger,
    _uiMessenger: UIMessenger,
    _data: DataMgmt,
    _signaling: SignalingCore,
    _sound: SoundEffects,
    _uiHandler: UIHandler,
    _wakeLocker: MobileWakeLock
  ) {
    console.log("Local client loading js module: busLogicCore");
    this.help = _help;
    this.context = _context;
    //this.config = _config;
    this.settings = _settings;
    this.data = _data;
    this.logger = _logger;
    this.signaling = _signaling;
    this.uiMessenger = _uiMessenger;
    this.sound = _sound;
    this.uiHandler = _uiHandler;
    this.wakeLocker = _wakeLocker;
    this.appUI = this.uiHandler.getAppUIHTML();

    this.priorToFirstUserJoining = true;

    this.raiseHand = new RaiseHand(
      this.signaling,
      this.uiMessenger,
      this.uiHandler,
      this.sound
    );
    this.userNameLabel = new UserNameLabel(
      this.settings,
      this.help,
      this.signaling,
      this.uiMessenger,
      this.uiHandler
    );
    this.remoteMute = new RemoteMute(
      this.logger,
      this.signaling,
      this.uiMessenger,
      this.uiHandler,
      this.sound
    );
    this.stayAlive = new StayAliveMgr(this.signaling, this.uiHandler);
  }

  initializeCore = (_media: BrowserMediaHandler) => {
    this.setMediaReference(_media);
    this.raiseHand.initialize();
    this.userNameLabel.initialize();
    this.remoteMute.initialize();
    this.stayAlive.initialize();
  };

  private setMediaReference = (_media: BrowserMediaHandler) => {
    this.media = _media;
  };

  abstract getVCDataMgr(): VCDataManager;

  getRaiseHand = (): RaiseHand => {
    return this.raiseHand;
  };

  getUserNameLabel = (): UserNameLabel => {
    return this.userNameLabel;
  };

  getRemoteMute = (): RemoteMute => {
    return this.remoteMute;
  };

  //this is where it all starts!
  launchApp = () => {
    if (this.help.appIsActive())
      this.uiMessenger.informUserSticky(
        "...starting up - please grant access to camera and microphone if prompted"
      );
    this.media.applyStoredMediaDeviceSettingsIfAny().then(() => {
      this.launchMediaAccessAndServerConnectivity();
    });
  };

  //this is the core function launching the app's connectivity and media access - this is called on app launch, on media device change, and on app unpause (i.e. getting access to video back)
  launchMediaAccessAndServerConnectivity = () => {
    //function to act as starting point on app launch and on re-launch after media device changes
    //this.data.configureCompressionCanvas(this.config.getVideoConstraintsSimulcast());
    this.uiHandler.getUITechHandler().getSidebarWindow().focusMainApp();
    this.media
      .getAndDisplayLocalVideoStream()
      .then(this.uiHandler.resetUIButtons);
    this.getUserNameLabel().updateUserNameLabelOnUIForLocalClient();
    //this.data.setMainRoom(viwo_UIActions.getRoomNameFromUser(_defaultRoomName));
    this.getRoomNameFromUserOnStartup(); // ATTENTION: ORDER is important - getRoomName dialogue blocks the App until complete - thereafter, app is reloaded with new stored room, connection to signaling is established, which automatically joins room retrieved via this line. Should this be decoupled at some point (e.g. by making it non-blocking), it is important to ensure that room name is captured BEFORE joining room
    this.signaling.connectToSignalingServer();
    //this.signaling.joinRoom(this.signaling.getMainRoom());
    //this.uiHandler.resetUIButtons();
    this.stayAlive.start(); //might be disabled in stayAlive class
  };

  getRoomNameFromUserOnStartup = (): void => {
    //NOTE: THIS FUNCTION ONLY DOES USER MESSAGING for stored rooms + URL parameters, it does not configure the app in this respect - stored rooms and url parameters are read in equivalent function called from constructor of signalingCore
    this.logger.logLocal(["Prompting user for name of team room"]);
    let locallyStoredRoom = this.settings.getMainRoom();
    if (locallyStoredRoom != null && locallyStoredRoom != "") {
      //if user stored room on local client, choose this one
      this.logger.logLocal([
        "Not displaying prompt to user - using locally stored room",
      ]);
      this.uiMessenger.informUser(
        "visav.is is connecting you to your team room: " +
          locallyStoredRoom +
          "                 ."
      );
      //return locallyStoredRoom;
    } else if (this.help.URLParameterPresent("room")) {
      // if no room stored by user, use room parameter from URL if any
      let room_para = this.help.getURLParameter("room");
      this.logger.logLocal([
        "Not displaying prompt to user - Using URL room parameter",
      ]);
      if (this.help.appIsActive())
        this.uiMessenger.informUser(
          "visav.is is connecting you to pre-configured team room: " + room_para
        );
      //return room_para;
    } else {
      // else, prompt user
      this.logger.logLocal(["Prompting user for name of team room"]);
      this.uiHandler.getRoomNameFromUserOnStartup();
      //return defaultRoomName;
    }
  };

  handleRemoteTrackAdded = (event, PCPurpose: SIGNALING_PURPOSE) => {
    var _peerID = event.target.peerID;
    this.logger.logLocal([
      "Adding remote track for peer ",
      _peerID,
      " - the track.kind is ",
      event.track.kind,
      ", track.id is ",
      event.track.id,
      ", purpose is",
      PCPurpose,
      ", track constraints are ",
      event.streams[0].getTracks()[0].getConstraints(),
    ]);
    if (event.track.kind === "video") {
      this.data.registerPMS(_peerID, event.streams[0], PCPurpose);

      if (
        PCPurpose === SIGNALING_PURPOSE.BASE_CONNECTION ||
        PCPurpose === SIGNALING_PURPOSE.AUDIO_ONLY ||
        PCPurpose === SIGNALING_PURPOSE.HR_VIDEO ||
        PCPurpose === SIGNALING_PURPOSE.MR_VIDEO ||
        PCPurpose === SIGNALING_PURPOSE.LR_VIDEO
      ) {
        //generally register any new peers as part of the VC
        if (
          this.vcData.isVCOpen() &&
          !this.vcData.isPartOfVC(_peerID) &&
          PCPurpose !== SIGNALING_PURPOSE.BASE_CONNECTION
        ) {
          this.vcData.registerVCPeer(_peerID); // register peer as part of the VC
          this.vcData.unregisterConfirmedVCJoiner(_peerID); // unregister pre-VC pending status that was assigned once peer confirmed intent to join
          this.vcData.unregisterPendingVCInvite(_peerID); // allows new connect-clicks only after connection is fully established or cancelled
          this.sound.playPeerJoiningSound();
          //if screenshare is active, host informs new participant about the peer from which it can request the screensharestream
          this.includeNewPeerInScreenShareIfScreenShareActive(_peerID);
          this.raiseHand.lowerRaisedHandThatHasBecomeObsolete(_peerID);
          this.remoteMute.informSinglePeerAboutLocalMuteStateChange(
            _peerID,
            this.media.isLocalAudioMuted()
          );
        }

        //display peer on video bar - update stream for existing peers / register unused section and add stream for new peers
        if (!this.appUI.getSideBarHTML().hasRegisteredPeerVS(_peerID)) {
          this.logger.logLocal([
            "Registering remote stream for peer ",
            _peerID,
            " in respective data structures for video bar and adding it to the UI",
          ]);
          this.appUI.getSideBarHTML().registerUnusedPeerVS(_peerID);
        }
        if (this.appUI.getSideBarHTML().hasRegisteredPeerVS(_peerID)) {
          this.appUI
            .getSideBarHTML()
            .getPeerHTMLVideoElement(_peerID).srcObject = this.data.getPMS(
            _peerID,
            PCPurpose
          );
          this.uiHandler.showPeerVideoSectionOnUI(_peerID);
        }

        if (this.vcData.isVCOpen() && this.vcData.isPartOfVC(_peerID)) {
          // only VC members get added to VC UI, no base connections
          //register peer on VC UI, independent of whether VC UI is currently displayed - this allows to switch it on/off immediately - any required stream changes can follow, rather than causing a delay for stream changes
          if (!this.appUI.getVCScreenHTML().hasRegisteredPeerVSVC(_peerID)) {
            // register any new peers as part of the VC
            this.logger.logLocal([
              "Registering remote stream for peer ",
              _peerID,
              " in respective data structures for VC and adding it to the VC UI",
            ]);
            this.appUI.getVCScreenHTML().registerUnusedPeerVSVC(_peerID);
            this.appUI.markVideoSectionsAsPartOfActiveVCOnBothSidebarAndVCWindow(
              _peerID,
              true
            );
            this.remoteMute.refreshMutedStatusForPeerOnUI(_peerID);
          }
          if (this.appUI.getVCScreenHTML().hasRegisteredPeerVSVC(_peerID)) {
            this.appUI
              .getVCScreenHTML()
              .getPeerHTMLVideoElementVC(_peerID).srcObject = this.data.getPMS(
              _peerID,
              PCPurpose
            );

            if (PCPurpose == SIGNALING_PURPOSE.HR_VIDEO) {
              /*
              !this.localScreenShareIsActive() &&
              !this.remoteScreenShareIsActive() &&
              PCPurpose !== SIGNALING_PURPOSE.AUDIO_ONLY &&
              PCPurpose !== SIGNALING_PURPOSE.BASE_CONNECTION &&
              PCPurpose !== SIGNALING_PURPOSE.LR_VIDEO
            )*/
              this.uiHandler.updateVCWindowSizeBasedOnVCParticipantsAndChat();
              /*let requiredVCScreenSize = this.vcData.getVCPeers().length + 1;
              if (this.uiHandler.isVCChatActive()) requiredVCScreenSize++;
              this.uiHandler
                .getUINWJSHandler()
                .getVCWindowNWJS()
                .setVCWindowSizeForNumberOfParticipantsIfOnApp(
                  requiredVCScreenSize,
                  false
                );*/
            }

            this.uiHandler.showVCPeerVideoSectionOnUI(_peerID);
            /*this.logger.logLocal([
              "Remote video stream assigned to UI with following constraints",
              event.streams[0],
              event.streams[0].getTracks()[0].getConstraints(),
            ]);*/
          }
        }

        //some user guidance on how to interact with other - displayed only once when first additional user joins the app
        if (this.priorToFirstUserJoining) {
          if (this.help.appIsActive())
            this.uiMessenger.informUserSticky(
              "Start interacting by clicking the headset icon on one of your teammates",
              10000
            );
          //if (this.help.isWebApp())
          this.uiHandler
            .getCSSClassModifier()
            .addClassOnEntireApp("afterFirstUserJoining");
          this.priorToFirstUserJoining = false;
        }

        //retire pms and pc for purposes other than PCPurpose if those exist (note this excludes things like screen share purposes)
        this.retireAllPeersMSAndPCNotMatchingTargetPurposeExceptSS(
          _peerID,
          PCPurpose
        );

        //mark talk/VC buttons as pressed
        //if (this.data.isPartOfVC(_peerID)) {
        if (PCPurpose != SIGNALING_PURPOSE.BASE_CONNECTION) {
          this.uiHandler.markTalkButtonsAsPressed(_peerID, true);
          this.uiHandler
            .getAppUIHTML()
            .getSideBarHTML()
            .getPeerHTMLVideoElement(_peerID)
            .classList.add("conversationActive");
          //this.uiHandler.markVCButtonsAsPressed(_peerID,true); // no longer in use
        } else {
          this.uiHandler.markTalkButtonsAsPressed(_peerID, false);
          this.uiHandler
            .getAppUIHTML()
            .getSideBarHTML()
            .getPeerHTMLVideoElement(_peerID)
            .classList.remove("conversationActive");
          //this.uiHandler.markVCButtonsAsPressed(_peerID,false); // no longer in use
        }
      } else if (PCPurpose === SIGNALING_PURPOSE.SCREEN_SHARE) {
        this.appUI
          .getVCScreenShareHTML()
          .getRemoteScreenShareHTMLVideoElementVC().srcObject = this.data.getPMS(
          _peerID,
          PCPurpose
        );
        this.uiHandler.showVCRemoteScreenShareVideoSectionOnUI();

        /*setTimeout(() => {
          this.logger.logLocal([
            "Remote screen share stream assigned to UI with following constraints",
            event.streams[0],
            event.streams[0].getVideoTracks()[0],
            event.streams[0].getVideoTracks()[0].getCapabilities(),
            event.streams[0].getVideoTracks()[0].getConstraints(),
            event.streams[0].getVideoTracks()[0].getSettings(),
            this.appUI
              .getVCScreenHTML()
              .getRemoteScreenShareHTMLVideoElementVC().videoWidth,
            this.appUI
              .getVCScreenHTML()
              .getRemoteScreenShareHTMLVideoElementVC().videoHeight,
          ]);
        }, 1000);*/

        /*/size vc window to take available space with right aspect ratio based on stream width / height
        let wid: number = this.data
          .getPMS(_peerID, PCPurpose)
          .getTracks()[0]
          .getConstraints().width as number;
        let hig: number = this.data
          .getPMS(_peerID, PCPurpose)
          .getTracks()[0]
          .getConstraints().height as number;
        //confirm("input for wid hig is " + wid + "/" + hig);
        this.uiHandler
          .getUINWJSHandler()
          .setVCWindowSizeScreenShareIfOnApp(wid, hig, true);*/

        //informUser('Note: You can view the screen share in full screen mode by double-clicking it - double-click again to return to normal mode')

        // handle situation when someone clicked "Stop sharing"
        this.data.getPMS(_peerID, PCPurpose).getVideoTracks()[0].onended =
          () => {
            this.uiHandler.hideVCRemoteScreenShareVideoSectionOnUI();
            this.data.retirePMS(_peerID, SIGNALING_PURPOSE.SCREEN_SHARE);
            this.data.retirePC(_peerID, SIGNALING_PURPOSE.SCREEN_SHARE);
            this.logger.logLocal([
              "Remote screen share stream ended - hiding remoteScreenSharingSection",
            ]);
          }; //this had to be aded when typescript migration was performed - this ensures that the function called on the videotrack identifies "this" with the BusinessLogic object
      } else {
        this.logger.logLocal([
          "remoteTrackAdded identified video track with unknown purpose: " +
            PCPurpose,
        ]);
      }
    }
  };

  //if there's connections and media streams for a specific peer that do not match a specific targetPurpose, retire them
  retirePeersMSAndPCNotMatchingTargetPurpose = (
    peer: string,
    retirePurpose: SIGNALING_PURPOSE,
    targetPurpose: SIGNALING_PURPOSE
  ) => {
    if (
      targetPurpose !== retirePurpose &&
      this.data.existsPMS(peer, retirePurpose)
    ) {
      try {
        this.data.retirePMS(peer, retirePurpose);
      } catch (e) {
        this.logger.logError([e]);
      }
    }
    if (
      targetPurpose !== retirePurpose &&
      this.data.existsPC(peer, retirePurpose)
    ) {
      try {
        this.data.retirePC(peer, retirePurpose);
      } catch (e) {
        this.logger.logError([e]);
      }
    }
  };

  retireAllPeersMSAndPCNotMatchingTargetPurposeExceptSS = (
    _peerID: string,
    _targetPurpose: SIGNALING_PURPOSE
  ) => {
    if (_targetPurpose == SIGNALING_PURPOSE.SCREEN_SHARE) return false; // incoming screen share streams should not kill any other connections!
    //retire pms and pc for purposes other than PCPurpose if those exist (note this excludes things like screen share purposes)
    this.retirePeersMSAndPCNotMatchingTargetPurpose(
      _peerID,
      SIGNALING_PURPOSE.BASE_CONNECTION,
      _targetPurpose
    );
    this.retirePeersMSAndPCNotMatchingTargetPurpose(
      _peerID,
      SIGNALING_PURPOSE.AUDIO_ONLY,
      _targetPurpose
    );
    this.retirePeersMSAndPCNotMatchingTargetPurpose(
      _peerID,
      SIGNALING_PURPOSE.HR_VIDEO,
      _targetPurpose
    );
    this.retirePeersMSAndPCNotMatchingTargetPurpose(
      _peerID,
      SIGNALING_PURPOSE.MR_VIDEO,
      _targetPurpose
    );
    this.retirePeersMSAndPCNotMatchingTargetPurpose(
      _peerID,
      SIGNALING_PURPOSE.LR_VIDEO,
      _targetPurpose
    );
  };

  handleRemoteStreamRemoved = (event, PCPurpose: SIGNALING_PURPOSE) => {
    var _peerID = event.target.peerID;
    this.logger.logLocal([
      "Removing remote stream for peer ",
      _peerID,
      " from data structures & UI >> purpose is ",
      PCPurpose,
      ", event is: ",
      event,
    ]);
    this.logger.logError([
      "uncommon function call - onRemoveStream event was triggered - this is deprecated / reasonable functionality needs to be added",
    ]);
    if (
      PCPurpose === SIGNALING_PURPOSE.BASE_CONNECTION ||
      PCPurpose === SIGNALING_PURPOSE.AUDIO_ONLY ||
      PCPurpose === SIGNALING_PURPOSE.HR_VIDEO ||
      PCPurpose === SIGNALING_PURPOSE.MR_VIDEO ||
      PCPurpose === SIGNALING_PURPOSE.LR_VIDEO
    ) {
      /* disabled below code as this method a) is deprecated, b) does not get called at present, c) does not reflect all relevant scenarios as of present code/logic
        //for now, whenever either low or high resolution stream gets removed, shut down all streams on local UI
        if ((PCPurpose === this.data.__Purpose_BaseConnection || PCPurpose === this.data.__Purpose_AudioOnly) && this.data.hasRegisteredPeerVS(_peerID)) {
          this.uiHandler.hidePeerVideoSectionOnUI(_peerID);
          this.data.unregisterPeerVS(_peerID);
          this.data.retirePMS(_peerID, PCPurpose);
          this.data.retirePC(_peerID, this.data.__Purpose_BaseConnection);
        }
        if (this.data.hasRegisteredPeerVSVC(_peerID)) {
          //todo?: check whether LQ stream is still active - switch to that one if so - else shut down VC + workbench similar to the above
          //if (this.data.hasRegisteredPeerVS(_peerID)) {
          //  this.data.getPeerHTMLVideoElement(_peerID).srcObject = this.data.getPMS(_peerID, this.data.__Purpose_BaseConnection);
          //  this.uiHandler.showPeerVideoSectionOnUI(_peerID);
          //}
          this.uiHandler.hideVCPeerVideoSectionOnUI(_peerID);
          this.data.unregisterPeerVSVC(_peerID);
          this.data.retirePMS(_peerID, this.data.__Purpose_HRVideo);
          this.data.retirePC(_peerID,this.data.__Purpose_HRVideo);
        }
        if (PCPurpose === this.data.__Purpose_BaseConnection){*/
      this.handleRemoteHangup(_peerID, this.signaling.getMainRoom()); // this is to ensure full closure of any connection to the respective peer, e.g. to ensure that no media is sent to the peer any longer
      this.uiMessenger.informUser(
        "Closed connections to peer " +
          _peerID +
          " as peer stopped providing media stream"
      );
      //}
    }
  };

  handleConnectionStateChangeBusiness = (
    event: Event,
    peerID: string,
    pc: VRTCPeerConnection
  ): void => {
    let cState = pc.iceConnectionState;
    if (cState != "completed" && cState != "connected") {
      //this.uiMessenger.informUserSticky("one of your teammates is observing network issues - please allow for them to recover prior starting any actions",10000);
    } else {
    }
  };

  fullLocalHangupOnExit(reason: string) {
    this.logger.logLocal([
      "Local client exiting / performing formal connection closure...",
    ]);
    this.wakeLocker.enableScreenSleep();
    //this.leaveVC(); now called from busLogicVC.fullLocalHangupOnExit()
    this.data.stopAllPCs(); //close all peer connections
    this.data.retireAllPMS();
    this.data.retireAllLMS();
    this.signaling.getPeerConnectionsQueue().clearPeerConnectionCreationQueue(); //clear all pending pc creation requests
    // this.data.getLocalSocket().emit('leaveAll'); // actively leave all rooms on signaling server prior disconnect
    this.data.registerTemporaryPermissionToDisconnectFromSignalingWithoutWarning();
    this.signaling.informServerAboutIntentToDisconnect(reason);
    this.signaling.disconnectFromServer();
    this.media.getVideoDownScaler().stopCompressionOfLocalVideoStream();
    this.stayAlive.stop();

    //reset core variables to application start state
    this.signaling.resetReadinessIndicators();
    this.signaling.getPeerConnector().reset(); //force-unregister any remaining peers so that no errors occur when local client reconnects and tries to reach them
  }

  handleRemoteHangup(
    peerID: string,
    room: string,
    suppressAllPeerInteractionForSilentLocalClosureOnly: boolean = false,
    noNewConnectionAsEitherLocalOrRemotePeersDisconnecting: boolean = false
  ) {
    //todo: in multi-room scenarios, it needs to be distinguished whether peer is still part of another room - i.e. whether peer connection may need to remain open
    this.signaling
      .getPeerConnectionsQueue()
      .cancelPendingPeerConnectionsForPeer(
        peerID,
        room,
        SIGNALING_PURPOSE.ALL,
        false
      );
    if (this.vcData.isPartOfVC(peerID)) {
      this.removePeerFromExistingVC(
        peerID,
        suppressAllPeerInteractionForSilentLocalClosureOnly,
        noNewConnectionAsEitherLocalOrRemotePeersDisconnecting
      );
    }
    //this.signaling.getPeerConnectionsQueue().cancelPendingPeerConnectionsForPeer(peerID, this.signaling.getMainRoom());
    if (this.data.existsPC(peerID, SIGNALING_PURPOSE.ALL)) {
      this.data.retirePC(peerID, SIGNALING_PURPOSE.ALL);
    }
    if (this.data.existsPMS(peerID, SIGNALING_PURPOSE.ALL)) {
      this.data.retirePMS(peerID, SIGNALING_PURPOSE.ALL);
    }
    if (
      this.uiHandler.getAppUIHTML().getSideBarHTML().hasRegisteredPeerVS(peerID)
    ) {
      this.raiseHand.lowerRaisedHandThatHasBecomeObsolete(peerID);
      this.remoteMute.removeMuteSignThatHasBecomeObsolete(peerID);
      this.uiHandler.hidePeerVideoSectionOnUI(peerID);
      this.uiHandler.getAppUIHTML().getSideBarHTML().unregisterPeerVS(peerID);
    }
    if (this.data.existsPMS(peerID, SIGNALING_PURPOSE.BASE_CONNECTION)) {
      //what is this good for?? - should anyways be covered above!
      this.data.retirePMS(peerID, SIGNALING_PURPOSE.ALL);
    }
    this.signaling.getPeerConnector().unregisterPeerWhoLeft(peerID);
    this.logger.logLocal(["Peer Connection with client ", peerID, " closed"]);
  }

  /*function stopIndividualPC(peerID, PCSigP) {
      if (PCSigP === this.data.__Purpose_ALL) {
        try {      this.data.getPC(peerID, this.data.__Purpose_BaseConnection).close();      this.data.retirePC(peerID, this.data.__Purpose_BaseConnection);      } catch (err) {let x = 1;}
        try {      this.data.getPC(peerID, this.data.__Purpose_HRVideo).close();      this.data.retirePC(peerID, this.data.__Purpose_HRVideo);      } catch (err) {let x = 1;}
        try {      this.data.getPC(peerID, this.data.__Purpose_ScreenShare).close();      this.data.retirePC(peerID, this.data.__Purpose_ScreenShare);      } catch (err) {let x = 1;}
      } else {
        this.data.getPC(peerID, PCSigP).close();
        this.data.retirePC(peerID, PCSigP);
      }
    }*/

  /*/ NO LONGER IN USE AS TALK FEATURE IS NOW BUILT ON VC ARCHITECTURE
    function connectViaTalk(peerID, positionOnUI) {
      //if (!this.data.isVCOpen()) {initiateVC(this.data.getLocalSocketID());}
      //if (this.data.getLocalSocketID() === this.data.getVCCurrentHost()) {
      //  this.data.registerPendingVCInvite(peerID,this.data.getLocalSocketID());
      //  addPeerToExistingVC(peerID); // if local client is the VC host, add/invite new peer
      //} else {
      //  suggestToInviteToVC121(peerID); //if local client is not the VC host, suggest to VC host to invite peer
      //}
      if (!this.data.isVCOpen()) {
        if (this.data.existsPMS(peerID, this.data.__Purpose_AudioOnly)) {
            establishSuitableStreams(peerID,this.data.__Purpose_BaseConnection);
            // button color triggered based on factual video stream received - in handleRemoteTrackAdded //this.uiHandler.markButtonsAsPressed(new Array(this.data.getUIPeerButtonByPos(this.data.__button_section_CB, positionOnUI, this.data.__button_objective_talk)),false);
            confirmToUser("Audio connection closed");
        } else {
            establishSuitableStreams(peerID,this.data.__Purpose_AudioOnly);
            // button color triggered based on factual video stream received - in handleRemoteTrackAdded //this.uiHandler.markButtonsAsPressed(new Array(this.data.getUIPeerButtonByPos(this.data.__button_section_CB, positionOnUI, this.data.__button_objective_talk)),true);
            confirmToUser("Audio connection established");
            if (!(this.data.getLocalMS().getAudioTracks()[0].enabled)) { //auto-unmute if muted
              muteLocalMicrophoneButtonClick();
              informUser("Pls note that your microphone has been unmuted to allow you to talk");
            }
        }
      } else {
        informUser("Pls note: You cannot open individual audio channels while in a VC")
      }
      //todo: add logic to determine whether talk is active / deactivate it in this case (by requesting a base connection)
      //todo: add logic to (un)color the button
    }*/

  //function for future use when changing architecture from one-pc per purpose to one-pc-for-all-purposes - in latter case, rather than establishing a new
  //pc via a function call towards requestStreamChange121, one would need to amend a single existing pc and add or replace tracks there
  establishSuitableStreams = (peerID: string, purpose: SIGNALING_PURPOSE) => {
    //requests peer to start new connection, sending stream for desired purpose
    /*
      //if all track were to go via a single connection, local client would need to send relevant tracks here / add them to peer connection
        if (this.data.existsPCForPeer(peerID)) { // if there is already a connection, start submitting own stream as required by purpose

        var _basePeerConnection = this.data.getPCForPeer(peerID);
        var _targetTrackToBeAdded = null;
        if (purpose === this.data.__Purpose_BaseConnection) {
          //obtain base track
          _targetTrackToBeAdded = this.data.getLocalMSByQuality("L").getVideoTracks()[0];
          //check whether base track track already exists on connection, otherwise add
          var _trackExistsOnBasePeerConnection = false;

              //sample code copy: this.data.getLocalMS().getAudioTracks().forEach(function(track){      track.enabled = false;    });
          //tracks are added by calling _basePeerConnection.addTrack(_targetTrackToBeAdded); //there may be the need to specify a media stream as 2nd parameter
        
          //remove all audio tracks from connection
          //remove all video tracks from connection that are not the same as just added
        }
        else if (purpose === this.data.__Purpose_HRVideo) {}
        else if (purpose === this.data.__Purpose_MRVideo) {}
        else if (purpose === this.data.__Purpose_LRVideo) {}
        else if (purpose === this.data.__Purpose_ScreenShare) {}
        else if (purpose === this.data.__Purpose_AudioOnly) {}
        else {
          //TBD
        }

      }    */
    this.requestSuitableStreamDeliveryFromPeer(peerID, purpose);
  };

  requestSuitableStreamDeliveryFromPeer = (
    peerID: string,
    purpose: SIGNALING_PURPOSE
  ) => {
    //requests peer to start new connection, sending stream for desired purpose
    this.signaling.requestStreamChange121(peerID, purpose);
  };

  peerConnectionExists = (peerID: string) => {
    return this.data.peerConnectionExists(peerID);
  };

  /*//deprecated - replaced by requestSuitableStreamDeliveryFromPeer
    function requestSuitableStream(peerID, purpose) { //requests peer to start new connection, sending stream for desired purpose
      requestStreamChange121(peerID, purpose);
    }*/

  //////////////////////////////////////////////////////////////////
  // Rump functions forwarding calls to child class busLogicVC, where they get overwritten
  //////////////////////////////////////////////////////////////////

  abstract removePeerFromExistingVCIfOnThere(
    peerID: string,
    suppressAllPeerInteractionForSilentLocalClosureOnly: boolean,
    noNewConnectionAsEitherLocalOrRemotePeersDisconnecting: boolean
  ): void;

  abstract removePeerFromExistingVC(
    peerID: string,
    suppressAllPeerInteractionForSilentLocalClosureOnly: boolean,
    noNewConnectionAsEitherLocalOrRemotePeersDisconnecting: boolean
  ): void; /*{
    //do nothing - this function is overwritten in class busLogicVC, triggering respective VC behavior there
    throw new Error(
      "removePeerFromExistingVC must be called on busLogicVC (overwritten function) and not on busLogic"
    );
  }*/

  abstract includeNewPeerInScreenShareIfScreenShareActive(peerID: string); /* {
    //do nothing - this function is overwritten in class busLogicVC, triggering respective VC behavior there
    throw new Error(
      "includeNewPeerInScreenShareIfScreenShareActive must be called on busLogicVC (overwritten function) and not on busLogic"
    );
  }*/

  abstract localScreenShareIsActive();
  abstract remoteScreenShareIsActive();

  //////////////////////////////////////////////////////////////////
  // HOW TO RECONNECT AFTER CONNECTION FAILURE
  //////////////////////////////////////////////////////////////////

  /*public requiredRepairActivitiesAfterConnectionLossAndReconnect = () => {
    //this is anything that needs to happen in terms of cleanup from old connections prior to starting any reconnection activities post the initial socket connection
    /*this.logger.logWarning(["performing connection cleanup on reconnect"]);
    this.data.retireAllStoppedPCsInclMatchingPMSs();
    this.data.retireAllInActivePMSInclMatchingPCs();*/
  /*};*/

  public localCleanUpToDealWithLocalConnectionFailure() {}
  public localCleanUpToDealWithRemoteButNotLocalConnectionFailure(
    peerID: string
  ) {}

  abstract warnUserStickyIfOnVC: (
    message: string,
    displayDurationInMS: number,
    warnOnlyIfOnVC: boolean
  ) => void;
}
