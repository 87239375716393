export class ScreenDevice {
  private xoffsetOfScreensTopLeftCorner: number;
  private yoffsetOfScreensTopLeftCorner: number;
  private widthOfScreen: number;
  private heightOfScreen: number;

  constructor(
    _xoffsetScreen: number,
    _yoffsetScreen: number,
    _widthScreen: number,
    _heightScreen: number
  ) {
    this.xoffsetOfScreensTopLeftCorner = _xoffsetScreen;
    this.yoffsetOfScreensTopLeftCorner = _yoffsetScreen;
    this.widthOfScreen = _widthScreen;
    this.heightOfScreen = _heightScreen;
  }

  initialize = () => {};

  getXOffsetOfTopLeftScreenCorner = (): number => {
    return this.xoffsetOfScreensTopLeftCorner;
  };

  getYOffsetOfTopLeftScreenCorner = (): number => {
    return this.yoffsetOfScreensTopLeftCorner;
  };

  getWidth = (): number => {
    return this.widthOfScreen;
  };

  getHeight = (): number => {
    return this.heightOfScreen;
  };
}
