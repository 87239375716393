import { Config } from "../config/Config";
import { SIGNALING_PURPOSE } from "../data/enums/SIGNALING_PURPOSE";

export class BandwidthMgmt {
  private config: Config;

  constructor(_config: Config) {
    this.config = _config;
  }

  initialize = () => {};

  setMediaBitratesOnSDP = (sdp: string, pcSigP: SIGNALING_PURPOSE) => {
    let videoBitRate = this.config.getVideoBitrate(pcSigP);
    let audioBitRate = this.config.getAudioBitrate(pcSigP);
    return this.setMediaBitrateOnSDP(
      this.setMediaBitrateOnSDP(sdp, "video", videoBitRate),
      "audio",
      audioBitRate
    );
  };

  private setMediaBitrateOnSDP = (
    sdp: string,
    media: string,
    bitrate: number = 200
  ) => {
    var lines = sdp.split("\n");
    var line = -1;
    for (var i = 0; i < lines.length; i++) {
      if (lines[i].indexOf("m=" + media) === 0) {
        line = i;
        break;
      }
    }
    if (line === -1) {
      console.debug("Could not find the m line for", media);
      return sdp;
    }
    console.debug("Found the m line for", media, "at line", line);

    // Pass the m line
    line++;

    // Skip i and c lines
    while (lines[line].indexOf("i=") === 0 || lines[line].indexOf("c=") === 0) {
      line++;
    }

    // If we're on a b line, replace it
    if (lines[line].indexOf("b") === 0) {
      console.debug("Replaced b line at line", line);
      lines[line] = "b=AS:" + bitrate;
      return lines.join("\n");
    }

    // Add a new b line
    console.debug("Adding new b line before line", line);
    var newLines = lines.slice(0, line);
    newLines.push("b=AS:" + bitrate);
    newLines = newLines.concat(lines.slice(line, lines.length));
    return newLines.join("\n");
  };
}
