export class UserNameLabelMessage {
  private senderID: string;
  private recipientID: string;
  private userNameLabel: string;

  constructor(_senderID: string, _recipientID: string, _userNameLabel: string) {
    this.senderID = _senderID;
    this.recipientID = _recipientID;
    this.userNameLabel = _userNameLabel;
  }

  getSenderID = (): string => {
    return this.senderID;
  };

  getRecipientID = (): string => {
    return this.recipientID;
  };

  getUserNameLabel = (): string => {
    return this.userNameLabel;
  };
}
