export class RemoteMuteRequestMessage {
  private senderID: string;
  private recipientID: string;

  constructor(_senderID: string, _recipientID: string) {
    this.senderID = _senderID;
    this.recipientID = _recipientID;
  }

  getSenderID = (): string => {
    return this.senderID;
  };

  getRecipientID = (): string => {
    return this.recipientID;
  };
}
