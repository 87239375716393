import { APP_SCREEN_SIDE } from "../../data/enums/APP_SCREEN_SIDE";
import { Helpers } from "../../utils/Helpers";
import { AppUIHTML } from "../html/AppUIHTML";
import { Logger } from "../../utils/Logger";
import { MultiScreenHandler } from "./MultiScreenHandler";
import { I_SidebarUI } from "frontend/decoupler/I_SidebarUI";

export class SidebarWindowNWJS implements I_SidebarUI {
  private help: Helpers;
  private appUI: AppUIHTML;
  private logger: Logger;
  private screenHandler: MultiScreenHandler;

  private sideBarWindow: any; //reference to nwjs window

  private sideBarScreenSizeBlockedToBeMaximized: boolean;

  constructor(
    _help: Helpers,
    _logger: Logger,
    _screenHandler: MultiScreenHandler
  ) {
    this.help = _help;
    this.logger = _logger;
    this.screenHandler = _screenHandler;
  }

  initialize = (_appUI: AppUIHTML): void => {
    this.appUI = _appUI;

    this.sideBarScreenSizeBlockedToBeMaximized = false;

    //@ts-ignore
    this.sideBarWindow = nw.Window.get();

    if (this.help.isMacApp() || !this.help.isWindowsApp()) {
      //2nd condition re 'is not windows app' is for backwards compatibility reasons to mac version 1.2 that
      this.initializeAppUIForMac();
    }
    this.initializeAppUI();
  };

  getSidebarNWJSWindowReference = () => {
    return this.sideBarWindow;
  };

  //maximize app to cover entire screen
  private initializeAppUI = (): void => {
    try {
      //this.sideBarWindow.setShowInTaskbar(true);//appears to not change things on mac - app still not appearing in task bar
      //@ts-ignore
      nw.Window.get().setVisibleOnAllWorkspaces(true);
      this.updateSideBarWindowSizeAndPositionIfOnApp(true, false, false);
      this.registerWindowMoveEventOnSideBar();
    } catch (e) {
      this.logger.logLocal([
        "not in app - thus app window could not be maximized, ",
        e,
      ]);
    }
  };

  //disable UI shadows on mac app to avoid disturbing UI fingerprints / traces when moved around
  private initializeAppUIForMac = (): void => {
    try {
      //@ts-ignore
      nw.Window.get().setShadow(false);
    } catch (e) {
      this.logger.logLocal([
        "not in apple app - thus window parameters for apple app were not applied, ",
        e,
      ]);
    }
  };

  updateSideBarWindowSizeAndPositionIfOnApp = (
    useStaticValuesForInitialization: boolean = false,
    resetPositionXOnResizeAfterInitialization: boolean = true,
    resetPositionYOnResizeAfterInitialization: boolean = false
    //enforceMaxWidthForPseudoMaximization: boolean = false
  ): void => {
    if (this.help.appIsActive()) {
      if (this.sideBarScreenSizeBlockedToBeMaximized) return;
      let roundingPixels = 2;
      let appWidth: number = 120;
      let appWidthDeltaForLeftDistanceOnWindows: number = 0;
      let appHeight: number = Math.min(screen.availHeight, 300);
      let appScreenSide = APP_SCREEN_SIDE.RIGHT;
      let additionalSpaceRight: number = 20;
      //let widthDeviationTrialError: number = 12; // factor accounting for naturally enforced min distance from left side + border width etc
      let distanceFromTop: number = 25;
      let minimumDistanceFromLeftSide = 5;
      let xOffsetInMultiScreenEnvironment = 0;

      let zoomFactor = this.appUI.getSideBarHTML().getZoomFactor();

      if (!useStaticValuesForInitialization) {
        //use actual size of sidebar html element if function is called post initialization - works only if UI already initialized - i.e. does not work on initial app load
        appWidth = Math.min(
          screen.availWidth,
          Math.ceil(
            this.appUI.getSideBarHTML().getContactBenchUI().offsetWidth *
              zoomFactor
          )
        );
        //if (enforceMaxWidthForPseudoMaximization)
        //    appWidth = screen.availWidth - additionalSpaceRight;
        appHeight = Math.min(
          screen.availHeight,
          Math.ceil(
            this.appUI.getSideBarHTML().getContactBenchUI().offsetHeight *
              zoomFactor
          ) + roundingPixels
        );
        appScreenSide = this.appUI._appScreenSide;
        xOffsetInMultiScreenEnvironment =
          this.screenHandler.getXOffsetInHorizontalMultiScreenEnvironment(
            this.sideBarWindow.x
          );
      }
      let distanceFromLeft: number = Math.floor(
        xOffsetInMultiScreenEnvironment +
          screen.availWidth -
          appWidth -
          appWidthDeltaForLeftDistanceOnWindows -
          additionalSpaceRight // - widthDeviationTrialError
      );
      if (appScreenSide == APP_SCREEN_SIDE.LEFT) {
        distanceFromLeft =
          xOffsetInMultiScreenEnvironment + minimumDistanceFromLeftSide;
      }
      if (!useStaticValuesForInitialization) {
        let currentX = this.sideBarWindow.x;
        let currentY = this.sideBarWindow.y;
        if (!resetPositionXOnResizeAfterInitialization)
          distanceFromLeft = currentX;
        if (!resetPositionYOnResizeAfterInitialization)
          distanceFromTop = currentY;
      }
      try {
        //@ts-ignore
        this.sideBarWindow.setResizable(true);
        //@ts-ignore
        //this.sideBarWindow.restore();
        //@ts-ignore
        this.sideBarWindow.resizeTo(appWidth, appHeight);
        if (
          useStaticValuesForInitialization ||
          resetPositionXOnResizeAfterInitialization ||
          resetPositionYOnResizeAfterInitialization
        ) {
          if (
            //!useStaticValuesForInitialization &&
            appScreenSide == APP_SCREEN_SIDE.RIGHT
          ) {
            let realWidth = this.sideBarWindow.width;
            distanceFromLeft = distanceFromLeft + appWidth - realWidth;
          }
          //@ts-ignore
          this.sideBarWindow.moveTo(distanceFromLeft, distanceFromTop);
        }
        //@ts-ignore
        this.sideBarWindow.setResizable(false);
        //@ts-ignore
        //this.sideBarWindow.show();
      } catch (e) {
        this.logger.logError([
          "error configuring nwjs-based sidebar size and position, ",
          e,
        ]);
      }
    }
  };

  getSideBarWindowHeightIfOnApp = (): number => {
    if (this.help.appIsActive()) {
      return this.sideBarWindow.height;
    } else return screen.availHeight;
  };

  private repositionAppOnMoveActive: boolean = true;
  private lastWindowPositionTimeoutID: NodeJS.Timeout = null;

  registerWindowMoveEventOnSideBar = (): void => {
    if (this.help.appIsActive()) {
      this.sideBarWindow.on("move", (_newX, _newY) => {
        if (this.repositionAppOnMoveActive) {
          this.repositionAppOnMoveActive = false;
          //start timeout of 500ms when window is moved, reset timeout if already active
          if (this.lastWindowPositionTimeoutID !== null) {
            clearTimeout(this.lastWindowPositionTimeoutID);
            this.lastWindowPositionTimeoutID = null;
          }
          this.lastWindowPositionTimeoutID = setTimeout(() => {
            /*this.logger.logWarning([
                  "current parameters window.x: ",
                  this.sideBarWindow.x,
                ]);*/
            this.moveSideBarWindowBackToSideIfOnApp();
          }, 500);
          this.repositionAppOnMoveActive = true;
        }
      });
    }
  };

  moveSideBarWindowBackToSideIfOnApp = (): void => {
    if (this.help.appIsActive()) {
      try {
        if (this.sideBarScreenSizeBlockedToBeMaximized) return;
        let appScreenSide = this.appUI._appScreenSide;
        let additionalSpaceRight: number = 20;
        //let widthDeviationTrialError: number = 12; // factor accounting for naturally enforced min distance from left side + border width etc
        let minimumDistanceFromLeftSide = 5;
        let xOffsetInMultiScreenEnvironment =
          this.screenHandler.getXOffsetInHorizontalMultiScreenEnvironment(
            this.sideBarWindow.x
          );
        let distanceFromLeft: number = Math.floor(
          xOffsetInMultiScreenEnvironment +
            screen.availWidth -
            this.sideBarWindow.width -
            additionalSpaceRight //-          widthDeviationTrialError
        );
        if (appScreenSide == APP_SCREEN_SIDE.LEFT) {
          distanceFromLeft =
            xOffsetInMultiScreenEnvironment + minimumDistanceFromLeftSide;
        }
        let distanceFromTop = this.sideBarWindow.y;

        //@ts-ignore
        this.sideBarWindow.moveTo(distanceFromLeft, distanceFromTop);
      } catch (e) {
        this.logger.logError([
          "error aligning app with screen side after it was moved, ",
          e,
        ]);
      }
    }
  };

  focusMainApp = (): void => {
    if (this.help.appIsActive()) {
      try {
        this.sideBarWindow.focus();
      } catch (e) {
        this.logger.logError(["error setting focus on sidebar ui", e]);
      }
    }
  };

  refreshSideBarAlwaysOnTop = (): void => {
    if (this.help.appIsActive()) this.sideBarWindow.setAlwaysOnTop(true);
  };

  /*pseudoMaximizeSideBarForScreenShareDialogueIfOnApp = () : void => {
        if (this.help.appIsActive()) {
          try {
            this.updateSideBarWindowSizeAndPositionIfOnApp(false,true,true,true);
          } catch (e) {
            this.logger.logError([
              "error pseudo-maximizing sidebar window to allow for screen-share dialogue to be displayed, ",
              e,
            ]);
          }
        }
        
      }*/

  /*  maximizeSideBarWindowIfOnApp = (): void => {
    if (this.help.appIsActive()) {
      try {
        this.sideBarScreenSizeBlockedToBeMaximized = true;
        this.dedicatedVCWindowIsTmpHidden = true;
        //this.sideBarWindow.hide();
        this.sideBarWindow.setResizable(true);
        this.sideBarWindow.maximize();
        this.sideBarWindow.setAlwaysOnTop(false); //needed for mac when clickthrough not active
        //this.sideBarWindow.blur();
        this.sideBarWindow.setResizable(false);
        //this.sideBarWindow.show();
      } catch (e) {
        this.logger.logError(["error maximizing sidebar window", e]);
      }
    }
  };

  restoreSideBarWindowIfOnApp = (): void => {
    if (this.help.appIsActive()) {
      try {
        this.sideBarScreenSizeBlockedToBeMaximized = false;
        this.dedicatedVCWindowIsTmpHidden = false;
        this.sideBarWindow.setResizable(true);
        this.sideBarWindow.restore();
        this.sideBarWindow.setAlwaysOnTop(true); //needed for mac when clickthrough not active
        this.sideBarWindow.focus();
        this.updateSideBarWindowSizeAndPositionIfOnApp();
        this.sideBarWindow.setResizable(false);
      } catch (e) {
        this.logger.logError(["error restoring sidebar window", e]);
      }
    }
  };*/
}
