import { Logger } from "../utils/Logger";
import { Helpers } from "../utils/Helpers";
import { SIGNALING_PURPOSE } from "../data/enums/SIGNALING_PURPOSE";
import { SERVER_REGION } from "../data/enums/SERVER_REGION";

export class Config {
  private readonly _signalingServerURLEurope: string =
    "https://signaling.visav.is/"; //server enforces https - thus no need to specify port
  private readonly _signalingServerURLAsia: string =
    "https://signaling-05z3k8.visav.is/"; //server enforces https - thus no need to specify port
  //const _signalingServerURL = 'https://signaling.visav.is:443/'; //port 443 in order to use https
  //const _signalingServerURL = 'https://signaling.video.maur.name:443/'; //port 443 in order to use https

  public readonly prodEnvHostname: string = "prototype.visav.is";
  public readonly prodEnvHostnameNEW: string = "app-main.visav.is";
  public readonly devEnvHostname: string = "app-main-dev.visav.is";
  public readonly devEnvHostnameLEGACY: string = "dev.prototype.visav.is";
  public readonly localEnvHostname: string = "localhost";
  public readonly meetWebClientProdHostname: string = "meet.visav.is";
  public readonly meetWebClientDevHostname: string = "meet-dev.visav.is";
  public readonly teamWebClientProdHostname: string = "go.visav.is";
  public readonly teamWebClientDevHostname: string = "go-dev.visav.is";
  /* ATTENTION: Helpers has a copy of above host name - must be kept in synch! ATTENTION */
  public readonly prodEnvURL: string = "https://prototype.visav.is/index.html";
  public readonly prodEnvURLNEW: string =
    "https://app-main.visav.is/index.html";
  public readonly devEnvURL: string =
    "https://app-main-dev.visav.is/index.html";
  public readonly localEnvURL: string = "http://localhost:8080/index.html";
  public readonly prodEnvBaseURL: string = "https://prototype.visav.is/";
  public readonly prodEnvBaseURLNEW: string = "https://app-main.visav.is/";
  public readonly devEnvBaseURL: string = "https://app-main-dev.visav.is/";
  public readonly localEnvBaseURL: string = "http://localhost:8080/";

  public readonly downloadsURL: string = "https://downloads.visav.is";
  public readonly feedbackFormURL: string =
    "https://visav.is/index.php/in-app-feedback/";
  public readonly supportFormURL: string =
    "https://visav.is/index.php/in-app-support-request/";
  public readonly inviteTeamURL: string = "https://visav.is/ninja-forms/7fak8/";
  public readonly webRTCInternalsURL: string = "chrome://webrtc-internals/";

  //private readonly _defaultRoomName = "defaultRoom001";
  private readonly _randomRoomNamePrefix = "random-room-";
  private _defaultRoomName = "please_choose_a_team_room_name";
  protected appInUse: boolean = false;
  protected appVersion: string = null;

  public readonly dedicatedVCWindowFileName: string = "vh.html";
  public readonly customUIMessagingWindowFileName: string = "notify.html";
  public readonly dialogueBackgroundWindowFileName: string = "empty.html";
  public readonly confWindowFileName: string = "conf.html";

  private help: Helpers;
  protected logger: Logger;

  private _videoConstraintsSimulcast: any;
  private _screenSharingConstraints: MediaStreamConstraints;

  private _maxBitRateAudioOn: number = 100;
  private _maxBitRateAudioOff: number = 25;
  private _maxBitRateVideoHR: number = 200;
  private _maxBitRateVideoLR: number = 75;
  private _maxBitRateScreenS: number = 750;

  private _compressionActiveLowRes: boolean = true;
  private _compressionActiveMedRes: boolean = false;

  constructor(_help: Helpers, _logger: Logger) {
    this.help = _help;
    this.logger = _logger;
  }

  initialize = () => {
    this.determineAppVersion();
    this._defaultRoomName =
      this._randomRoomNamePrefix + this.help.getRandomInt(10000);

    this.initializeMediaConstraints();
  };

  private initializeMediaConstraints = () => {
    //set screen share constraints
    this._screenSharingConstraints = {
      audio: false,
      video: {
        //@ts-ignore
        mandatory: {
          //chromeMediaSource: 'screen',
          maxFrameRate: 5,
          //maxWidth: screen.width > 1920 ? screen.width : 1920,         //maxHeight: screen.height > 1080 ? screen.height : 1080        // minAspectRatio: 1.77        //chromeMediaSourceId: sourceId
        },
      },
    };

    //set audio constraints
    let auco: MediaTrackConstraints;
    if (
      this.help.appIsActive() ||
      this.help.chromiumIsActive() //|| this.help.isApple()
    ) {
      auco = {
        autoGainControl: { ideal: true },
        echoCancellation: { ideal: true },
        noiseSuppression: { ideal: true },
        //@ts-ignore
        googEchoCancellation: { ideal: true },
        //@ts-ignore
        googAutoGainControl: { ideal: true },
        //@ts-ignore
        googNoiseSuppression: { ideal: true },
        //@ts-ignore
        googHighpassFilter: { ideal: true },
        //@ts-ignore
        googNoiseSuppression2: { ideal: true },
        //@ts-ignore
        googEchoCancellation2: { ideal: true },
        //@ts-ignore
        googAutoGainControl2: { ideal: true },
        /*//@ts-ignore
        optional: [
          //@ts-ignore
          { googEchoCancellation: { ideal: true } },
          //@ts-ignore
          { googAutoGainControl: { ideal: true } },
          //@ts-ignore
          { googNoiseSuppression: { ideal: true } },
          //@ts-ignore
          { googHighpassFilter: { ideal: true } },
          //@ts-ignore
          { googNoiseSuppression2: { ideal: true } },
          //@ts-ignore
          { googEchoCancellation2: { ideal: true } },
          //@ts-ignore
          { googAutoGainControl2: { ideal: true } },
          //channelCount: { ideal: 2 },
          //sampleRate: { ideal: 48000 },
          //{ echoCancellation: true },
          //{autoGainControl: true},
          //{noiseSuppression: true},
        ],

        //@ts-ignore
        /*optional: [
          //@ts-ignore
          { googEchoCancellation: true },
          { googAutoGainControl: true },
          { googNoiseSuppression: true },
          { googHighpassFilter: true },
          { googNoiseSuppression2: true },
          { googEchoCancellation2: true },
          { googAutoGainControl2: true },
          { channelCount: 2 },
          { sampleRate: 48000 },
          //{ echoCancellation: true },
          //{autoGainControl: true},
          //{noiseSuppression: true},
        ],*/
      };
    } else if (this.help.firefoxIsActive()) {
      auco = {
        //weakening mandatory constraints for firefox mobile because overconstrained in some cases
        //@ts-ignore
        autoGainControl: { ideal: true },
        echoCancellation: { ideal: true },
        noiseSuppression: { ideal: true },
        channelCount: { ideal: 2 },
        sampleRate: { ideal: 48000 },
      };
    } else {
      auco = {
        //@ts-ignore
        autoGainControl: true,
        echoCancellation: true,
        noiseSuppression: true,
        channelCount: 2,
        sampleRate: 48000,
      };
    }

    //set video constraints
    let vicoH: MediaTrackConstraints = {
      width: { ideal: 320 /* 480 */ },
      height: { ideal: 240 /*previously not set*/ },
      frameRate: { ideal: 15, min: 1, max: 20 },
    };
    if (this.help.firefoxIsActive()) {
      vicoH = {
        width: { ideal: 320 },
        height: { ideal: 240 },
        frameRate: { ideal: 15 }, //removed min / max for FFox mobile compatibility
      };
    }
    let vicoM: MediaTrackConstraints = {
      //please note that the below format used to specify the constraints is leveraged by the code performing the video compression - changes here require changes there
      width: { ideal: 240 },
      height: { ideal: 180 /*previously not set*/ },
      frameRate: { ideal: 7, min: 1, max: 20 }, //computational effort minimized by setting this to 1 refresh per second
    };
    let vicoL: MediaTrackConstraints = {
      //please note that the below format used to specify the constraints is leveraged by the code performing the video compression - changes here require changes there
      width: { ideal: 120 },
      height: { ideal: 90 /*previously not set*/ },
      frameRate: { ideal: 7, min: 1, max: 15 },
    };
    let vicoPicHF: MediaTrackConstraints = {
      width: { ideal: 120 },
      height: { ideal: 90 },
      frameRate: { ideal: 0.2, min: 0.01, max: 2 }, // 1 pic every 5 sec
    };
    let vicoPicMF: MediaTrackConstraints = {
      width: { ideal: 120 },
      height: { ideal: 90 },
      frameRate: { ideal: 0.0666, min: 0.01, max: 2 }, // 1 pic every 15 sec
    };
    let vicoPicLF: MediaTrackConstraints = {
      width: { ideal: 120 },
      height: { ideal: 90 },
      frameRate: { ideal: 0.0333, min: 0.01, max: 2 }, // 1 pic every 30 sec
    };

    this._videoConstraintsSimulcast = {
      H: {
        audio: auco,
        video: vicoH,
      },
      M: {
        audio: auco,
        video: vicoM,
      },
      L: {
        audio: auco,
        video: vicoL,
      },
      picHF: {
        audio: auco,
        video: vicoPicHF,
      },
      picMF: {
        audio: auco,
        video: vicoPicMF,
      },
      picLF: {
        audio: auco,
        video: vicoPicLF,
      },
    };
  };

  determineAppVersion = (): void => {
    this.logger.logLocal(["Determining whether app or web client is used ..."]);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("app")) {
      const app_para = urlParams.get("app");
      this.logger.logLocal(["app usage detected - version is ", app_para]);
      this.appInUse = true;
      this.appVersion = app_para;
    } else if (urlParams.has("web")) {
      const app_para = urlParams.get("web");
      this.logger.logLocal([
        "usage of web client detected - version is ",
        app_para,
      ]);
      this.appInUse = false;
      this.appVersion = app_para;
    }
  };

  getSignalingServerURL = (
    region: SERVER_REGION = SERVER_REGION.EUROPE
  ): string => {
    if (region == SERVER_REGION.EUROPE) return this._signalingServerURLEurope;
    else if (region == SERVER_REGION.ASIA) return this._signalingServerURLAsia;
    else return null;
  };

  getVideoConstraintsSimulcast = () => {
    return this._videoConstraintsSimulcast;
  };

  getVideoConstraintsByQuality = (_quality: string) => {
    return this._videoConstraintsSimulcast[_quality];
  };

  getScreenShareConstraints = (): MediaStreamConstraints => {
    return this._screenSharingConstraints;
  };

  getDefaultRoomName = (): string => {
    return this._defaultRoomName;
  };

  isAppInUse = (): boolean => {
    return this.appInUse;
  };

  getAppVersionString = (): string => {
    return this.appVersion;
  };

  getAudioBitrate = (PCSigP: SIGNALING_PURPOSE): number => {
    if (
      PCSigP == SIGNALING_PURPOSE.SCREEN_SHARE ||
      PCSigP == SIGNALING_PURPOSE.BASE_CONNECTION
    )
      return this._maxBitRateAudioOff;
    else return this._maxBitRateAudioOn;
  };

  getVideoBitrate = (PCSigP: SIGNALING_PURPOSE): number => {
    if (PCSigP == SIGNALING_PURPOSE.SCREEN_SHARE)
      return this._maxBitRateScreenS;
    else if (
      PCSigP == SIGNALING_PURPOSE.AUDIO_ONLY ||
      PCSigP == SIGNALING_PURPOSE.LR_VIDEO ||
      PCSigP == SIGNALING_PURPOSE.BASE_CONNECTION
    )
      return this._maxBitRateVideoLR;
    else return this._maxBitRateVideoHR;
  };

  isCompressionActiveLowRes = (): boolean => {
    return this._compressionActiveLowRes;
  };

  isCompressionActiveMedRes = (): boolean => {
    return this._compressionActiveMedRes;
  };
}
