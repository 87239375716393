import { APP_CONTEXT } from "data/enums/APP_CONTEXT";
import { Helpers } from "../utils/Helpers";

export class AppContextHandler {
  private help: Helpers;

  private context: APP_CONTEXT;

  constructor(_help: Helpers) {
    console.log("Local client loading extension: IncompatibleContextHandler");
    this.help = _help;
    this.context = null;
  }

  getCurrentContext = (computeFresh: boolean = false): APP_CONTEXT => {
    if (this.context == null || computeFresh == true)
      this.context = this.getCurrentContextFresh();
    return this.context;
  };

  private getCurrentContextFresh = (): APP_CONTEXT => {
    if (this.help.appIsActive()) {
      if (this.help.isWindowsSeven())
        return APP_CONTEXT.INCOMPATIBLE_APP_ACCESS_VIA_LEGACY_OS_ISSUE;
      else return APP_CONTEXT.APP_ACCESS_VIA_APP_OKAY;
    } else if (this.help.isWebAppGO()) {
      return APP_CONTEXT.WEB_GO_CLIENT_VIA_BROWSER_OKAY;
    } else if (this.help.isWebAppMeeting()) {
      return APP_CONTEXT.WEB_MEETING_CLIENT_VIA_BROWSER_OKAY;
    } else return APP_CONTEXT.INCOMPATIBLE_APP_ACCESS_VIA_BROWSER_ISSUE;
  };

  forwardToHomepageIfAppAccessedInadequatelyViaBrowser = (): boolean => {
    if (this.help.appIsActive() || this.help.isWebApp()) return false;
    else {
      console.warn(
        "warning: forwarding to homepage as app code is accessed from browser"
      );
      window.location.href = "https://visav.is";
      return true;
    }
  };

  warnIfOnIncompatibleOSVersion = (): void => {
    if (this.help.isWindowsSeven()) {
      //windows 7 detected
      confirm(
        "visav.is detected you're still using Windows 7. Please note that this operating system is no longer receiving security patches by Microsoft and must thus be considered insecure for both private and business use. " +
          "Note that there are free options to upgrade from Windows 7 to Windows 10 (just google for 'windows 7 free upgrade'). " +
          "Note further that visavis works only on current versions of Windows (i.e. Windows 10 and 11). While the app will now attempt to start, you are likely to face limitations, in particular around access to camera and microphone. Please consider upgrading with the help of someone knowledgeable." // to a current version of Windows - consult someone knowledgeable if unsure how to do this."
      );
    }
  };
}
