import { I_DialogueBackgroundUI } from "frontend/decoupler/I_DialogueBackgroundUI";
import { Config } from "../../config/Config";
import { Helpers } from "../../utils/Helpers";
import { Logger } from "../../utils/Logger";

export class DialogueBackgroundNWJS implements I_DialogueBackgroundUI {
  private help: Helpers;
  private config: Config;
  private logger: Logger;

  private screenShareDialogBackgroundWindow: any; //reference to nwjs window

  private DBScreenURL: string;

  constructor(_help: Helpers, _config: Config, _logger: Logger) {
    this.help = _help;
    this.config = _config;
    this.logger = _logger;

    this.screenShareDialogBackgroundWindow = null;
  }

  initialize = () => {
    this.initializeDBScreenURL();
  };

  private initializeDBScreenURL() {
    switch (window.location.hostname) {
      case this.config.devEnvHostname: {
        this.DBScreenURL = this.config.devEnvBaseURL;
        break;
      }
      case this.config.localEnvHostname: {
        this.DBScreenURL = this.config.localEnvBaseURL;
        break;
      }
      case this.config.prodEnvHostnameNEW: {
        this.DBScreenURL = this.config.prodEnvBaseURLNEW;
        break;
      }
      default: {
        //if in doubt, use prod code
        this.DBScreenURL = this.config.prodEnvBaseURL;
        break;
      }
    }
    this.DBScreenURL =
      this.DBScreenURL +
      this.config.dialogueBackgroundWindowFileName +
      "?rand=" +
      Math.random() * 10000;
  }

  createMaximizedScreenShareDialogueBackgroundWindow =
    (): /*_newWindowURL: string /*,
        initfunc: { (any, Document): void }*/
    Promise<Window> => {
      return new Promise((resolve, reject) => {
        if (this.help.appIsActive()) {
          try {
            //@ts-ignore
            nw.Window.open(
              this.DBScreenURL,
              {
                title: "visavis full screen blurred background",
                position: "center",
                //icon: "favicon.icns",
                show: true,
                frame: false,
                transparent: true,
                always_on_top: true,
                show_in_taskbar: false,
                visible_on_all_workspaces: true,
                focus: true,
              },
              (win) => {
                win.on("loaded", () => {
                  this.screenShareDialogBackgroundWindow = win;
                  //initfunc(win, win.window.document);
                  if (this.help.isMacApp())
                    this.screenShareDialogBackgroundWindow.setShadow(false);
                  this.screenShareDialogBackgroundWindow.setVisibleOnAllWorkspaces(
                    true
                  );
                  if (this.help.isMacApp()) {
                    this.screenShareDialogBackgroundWindow.maximize();
                  } else {
                    //for windows, normal maximize does not work when task bar is on auto-hide - in this case, window is maximized manually
                    this.manualMaximizeScreenShareBackgroundWindow();
                  }
                  this.screenShareDialogBackgroundWindow.setAlwaysOnTop(false);
                  //this.focusMainApp();
                  resolve(win.window);
                  return;
                });
              }
            );
          } catch (e) {
            this.logger.logError([
              "Error initializing full screen overlay window, ",
              e,
            ]);
            reject(e);
            return;
          }
        } else {
          //initfunc(null, null);
          resolve(null);
          return;
        }
      });
    };

  private manualMaximizeScreenShareBackgroundWindow = (): void => {
    //manual maximize is required for windows machines where taskbar is configured to auto-hide - the standard maximize does not work there
    this.screenShareDialogBackgroundWindow.moveTo(0, 0);
    this.screenShareDialogBackgroundWindow.resizeTo(
      screen.availWidth,
      screen.availHeight
    );
  };

  hideMaximizedScreenShareBackgroundWindow = (): void => {
    if (this.help.appIsActive()) {
      try {
        this.screenShareDialogBackgroundWindow.hide();
      } catch (e) {
        this.logger.logError(["error hiding full screen background window", e]);
      }
    }
  };
  closeMaximizedScreenShareBackgroundWindow = (): void => {
    if (
      this.help.appIsActive() &&
      this.screenShareDialogBackgroundWindow != null
    ) {
      try {
        this.screenShareDialogBackgroundWindow.close();
        this.screenShareDialogBackgroundWindow = null;
      } catch (e) {
        this.logger.logError([
          "error closing full screen background window",
          e,
        ]);
      }
    }
  };

  isMaximizedScreenShareBackgroundWindowOpen = (): boolean => {
    return (
      this.help.appIsActive() && this.screenShareDialogBackgroundWindow != null
    );
  };
}
