import { Helpers } from "../../utils/Helpers";
import { Logger } from "../../utils/Logger";
import { MultiScreenHandler } from "./MultiScreenHandler";
import { ScreenDevice } from "../../data/ScreenDevice";
import { I_HTMLReferenceUser } from "frontend/html/I_HTMLReferenceUser";
import { UIMessenger } from "utils/UIMessenger";
import { I_ConfigUI } from "frontend/decoupler/I_ConfigUI";
import { CSSClassModifier } from "frontend/CSSModifierOverarching";

export class ConfWindowNWJS implements I_ConfigUI {
  private help: Helpers;
  private logger: Logger;
  private multiScreen: MultiScreenHandler;
  private uiMessenger: UIMessenger;

  //@ts-ignore
  private confWindow: nw.Window; //reference to nwjs window
  private htmlRefUsers: I_HTMLReferenceUser;

  constructor(
    _help: Helpers,
    _logger: Logger,
    _uiMessenger: UIMessenger,
    _multiScreen: MultiScreenHandler
  ) {
    this.help = _help;
    this.logger = _logger;
    this.uiMessenger = _uiMessenger;
    this.multiScreen = _multiScreen;
    this.confWindow = null;
    this.htmlRefUsers = null;
  }

  initialize = (_cssModifier: CSSClassModifier) => {};

  private initializeConfWindow = (
    _ConfScreenURL: string,
    initfunc: { (doc: Document): void }
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (this.help.appIsActive()) {
        try {
          //@ts-ignore
          nw.Window.open(
            _ConfScreenURL,
            {
              title: "visavis config",
              position: "center",
              //icon: "favicon.icns",
              show: false, //false
              frame: false,
              transparent: false, // maybe true
              always_on_top: true, //maybe false
              show_in_taskbar: false, //false
              visible_on_all_workspaces: true,
              focus: true,
              width: 640,
              height: 480,
            },
            (win) => {
              win.on("loaded", () => {
                this.confWindow = win;
                initfunc(win.window.document);
                if (this.help.isMacApp()) this.confWindow.setShadow(false);
                this.confWindow.setVisibleOnAllWorkspaces(true);
                if (this.confWindow == null || win.window.document == null) {
                  this.logger.logError([
                    "Error initializing visavis conf window - confWindow and document reference are ",
                    this.confWindow,
                    ", ",
                    win.window.document,
                  ]);
                }
                resolve();
                return;
              });
            }
          );
        } catch (e) {
          this.logger.logError(["Error initializing visavis conf window, ", e]);
          reject(e);
          return;
        }
      } else {
        this.logger.logError([
          "Error initializing visavis conf window - no app usage detected, ",
        ]);
        reject(false);
        return;
      }
    });
  };

  showConfWindow = () => {
    if (!this.help.appIsActive()) return;
    if (this.confWindow == null) {
      this.logger.logError([
        "Error showing conf window - window has not been created yet / refrence is null",
      ]);
      return;
    }
    try {
      let curScreenOfMainApp: ScreenDevice =
        this.multiScreen.getCurrentScreenOfMainApp();

      let distanceFromLeft = Math.ceil(
        curScreenOfMainApp.getXOffsetOfTopLeftScreenCorner() +
          0.5 * curScreenOfMainApp.getWidth() -
          320
      );
      let distanceFromTop = Math.ceil(
        curScreenOfMainApp.getYOffsetOfTopLeftScreenCorner() +
          0.5 * curScreenOfMainApp.getHeight() -
          240
      );
      this.confWindow.moveTo(distanceFromLeft, distanceFromTop);
      this.confWindow.show();
    } catch (e) {
      this.logger.logError(["error showing conf window", e]);
    }
  };

  hideConfWindow = () => {
    if (!this.help.appIsActive()) return;
    try {
      this.confWindow.hide();
    } catch (e) {
      this.logger.logError(["error hiding conf window", e]);
    }
  };

  openConfWindow = (
    _confWindowURL: string,
    _htmlRefUser: I_HTMLReferenceUser
  ): void => {
    try {
      if (!this.help.appIsActive()) return;
      if (this.confWindow != null) {
        this.showConfWindow();
      } else {
        this.htmlRefUsers = _htmlRefUser;
        this.initializeConfWindow(
          _confWindowURL,
          this.htmlRefUsers.initializeUI
        ).then(this.showConfWindow);
      }
    } catch (e) {
      this.logger.logError(["error opening conf window", e]);
    }
  };

  closeConfWindow = (): void => {
    try {
      if (!this.help.appIsActive()) return;
      if (this.htmlRefUsers != null) {
        this.htmlRefUsers.tearDownUIReferencesPriorClosure();
        this.htmlRefUsers = null;
      }
      if (this.confWindow != null) {
        this.confWindow.close(true);
        this.confWindow = null;
      } else {
        //enforce closure if no link present
        this.closeOrphanedConfWindow();
        this.uiMessenger.warningToUser(
          "There was an error closing the settings window. You may need to re-apply any setting changes. Please excuse the inconvenience."
        );
      }
    } catch (e) {
      this.logger.logError(["error closing conf window", e]);
    }
  };

  toggleConfWindow = (
    _confWindowURL: string,
    _htmlRefUser: I_HTMLReferenceUser
  ): void => {
    if (this.confWindow != null) {
      this.closeConfWindow();
    } else {
      this.openConfWindow(_confWindowURL, _htmlRefUser);
    }
  };

  private closeOrphanedConfWindow = (): void => {
    if (this.help.appIsActive()) {
      try {
        //@ts-ignore
        nw.Window.getAll((wins: nw.Window[]) => {
          wins.forEach((curWin) => {
            let cTitle: string = curWin.title;
            let cID: string = curWin.id;
            if (cTitle == "visav.is config" || cTitle == "visavis config") {
              curWin.close(true);
              this.logger.logError(["Closing orphaned njws conf window"]);
            }
          });
        });
      } catch (e) {
        this.logger.logError([
          "error trying to close orphaned conf window, ",
          e,
        ]);
      }
    }
  };
}
