import { SIGNALING_PURPOSE } from "./enums/SIGNALING_PURPOSE";

export class QueuedPeerConnection {
  public peerID: string;
  public room: string;
  public localActionRequired: string; //'answer' or 'offer'
  public remoteDescription: any; // when 'answer', there's already a remoteDescription from the peer
  public candidatesReceived: RTCIceCandidate[]; // when 'answer', candidate messages will follow from the peer - these may come in prior to actual creation of the connection object
  public requestCancelled: boolean;
  public PCSignalingPurpose: SIGNALING_PURPOSE;

  constructor(
    _peerID: string,
    _room: string,
    _localActionRequired: string,
    _remoteDesc: any,
    _PCSigP: SIGNALING_PURPOSE
  ) {
    this.peerID = _peerID;
    this.room = _room;
    this.localActionRequired = _localActionRequired; //'answer' or 'offer'
    this.remoteDescription = _remoteDesc; // when 'answer', there's already a remoteDescription from the peer
    this.candidatesReceived = []; // when 'answer', candidate messages will follow from the peer - these may come in prior to actual creation of the connection object
    this.requestCancelled = false;
    this.PCSignalingPurpose = _PCSigP;
  }
}
