import { Logger } from "../utils/Logger";
import { QueuedPeerConnection } from "./QueuedPeerConnection";
import { SIGNALING_PURPOSE } from "./enums/SIGNALING_PURPOSE";

export class PeerConnectionQueue {
  //provide queue for peer connections that are yet to be established once local media stream is ready

  private logger: Logger;

  private _peerConnectionCreationQueue: QueuedPeerConnection[] = []; //queue to hold context info for peers for which RTCPeerConnection is t.b. set up once local client ready.

  constructor(_log: Logger) {
    this.logger = _log;
  }

  initialize = () => {};

  getPeerConnectionCreationQueueLength = (): number => {
    return this._peerConnectionCreationQueue.length;
  };

  arePeerConnectionCreationsPending = (): boolean => {
    return this.getPeerConnectionCreationQueueLength() > 0;
  };

  enqueuePeerConnectionCreation = (
    peerID: string,
    room: string,
    localActionRequired: string,
    remoteDesc: RTCSessionDescription | RTCSessionDescriptionInit,
    PCSigP: SIGNALING_PURPOSE
  ): void => {
    let queuedPeer = new QueuedPeerConnection(
      peerID,
      room,
      localActionRequired,
      remoteDesc,
      PCSigP
    );
    this._peerConnectionCreationQueue.push(queuedPeer);
    this.logger.logLocal([
      "item enqueued= request to create peer connection for peer ",
      peerID,
      " and purpose",
      PCSigP,
      " - full item is= ",
      queuedPeer,
    ]);
  };

  addCandidateToQueuedPeerConnectionCreation = (
    peerID: string,
    room: string,
    candidate: RTCIceCandidate,
    PCSigP: SIGNALING_PURPOSE
  ): void => {
    for (var i = 0; i < this._peerConnectionCreationQueue.length; i++) {
      if (
        this._peerConnectionCreationQueue[i].peerID === peerID &&
        this._peerConnectionCreationQueue[i].room === room &&
        this._peerConnectionCreationQueue[i].requestCancelled == false &&
        (this._peerConnectionCreationQueue[i].PCSignalingPurpose === PCSigP ||
          this._peerConnectionCreationQueue[i].PCSignalingPurpose ===
            SIGNALING_PURPOSE.ALL)
      ) {
        //queued PC found
        this.logger.logWarning([
          "Added candidate to PCCreationQueue - peerID ",
          peerID,
        ]);
        this._peerConnectionCreationQueue[i].candidatesReceived.push(candidate);
        //return true; //stop at first found item
      }
    }
    this.logger.logWarning([
      "could nott add candidate to queued peer connection as no queued peer connection found for peerID/room/PCSigP",
      peerID,
      "/",
      room,
      "/",
      PCSigP,
    ]);
  };

  getNextPendingPeerConnectionCreation = (): QueuedPeerConnection => {
    return this._peerConnectionCreationQueue.shift();
  };

  existsQueuedPeerConnectionCreation = (
    peerID: string,
    room: string,
    PCSigP: SIGNALING_PURPOSE
  ): boolean => {
    for (var i = 0; i < this._peerConnectionCreationQueue.length; i++) {
      if (
        this._peerConnectionCreationQueue[i].peerID === peerID &&
        this._peerConnectionCreationQueue[i].room === room &&
        this._peerConnectionCreationQueue[i].requestCancelled == false &&
        (this._peerConnectionCreationQueue[i].PCSignalingPurpose === PCSigP ||
          PCSigP === SIGNALING_PURPOSE.ALL)
      ) {
        return true;
      }
    }
    return false;
  };

  markQueuedPeerConnectionCreationAsCancelled = (
    peerID: string,
    room: string,
    PCSigP: SIGNALING_PURPOSE
  ): void => {
    for (var i = 0; i < this._peerConnectionCreationQueue.length; i++) {
      if (
        this._peerConnectionCreationQueue[i].peerID === peerID &&
        this._peerConnectionCreationQueue[i].room === room &&
        (this._peerConnectionCreationQueue[i].PCSignalingPurpose === PCSigP ||
          PCSigP === SIGNALING_PURPOSE.ALL)
      ) {
        this._peerConnectionCreationQueue[i].requestCancelled = true;
      }
    }
  };

  QueuedPeerConnectionCreation = (
    peerID: string,
    room: string,
    candidate: RTCIceCandidate,
    PCSigP: SIGNALING_PURPOSE
  ): void => {
    for (var i = 0; i < this._peerConnectionCreationQueue.length; i++) {
      if (
        this._peerConnectionCreationQueue[i].peerID === peerID &&
        this._peerConnectionCreationQueue[i].room === room &&
        this._peerConnectionCreationQueue[i].PCSignalingPurpose === PCSigP &&
        !this._peerConnectionCreationQueue[i].requestCancelled
      ) {
        this._peerConnectionCreationQueue[i].candidatesReceived.push(candidate);
      }
    }
  };

  cancelPendingPeerConnectionsForPeer = (
    peerID: string,
    room: string,
    sigP: SIGNALING_PURPOSE = SIGNALING_PURPOSE.ALL,
    warnIfSomethingCancelled: boolean = false
  ): void => {
    if (this.existsQueuedPeerConnectionCreation(peerID, room, sigP)) {
      this.markQueuedPeerConnectionCreationAsCancelled(peerID, room, sigP);
      if (warnIfSomethingCancelled)
        this.logger.logWarning([
          "dropping queued peer connection for peer ",
          peerID,
          " and signaling purpose ",
          sigP,
        ]);
    }
  };

  clearPeerConnectionCreationQueue = (): void => {
    this._peerConnectionCreationQueue = [];
  };
}
