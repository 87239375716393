import { SIGNALING_PURPOSE } from "../data/enums/SIGNALING_PURPOSE";
import { VRTCPeerConnection } from "../signaling/VRTCPeerConnection";
import { BusLogicVC } from "../buslogic/BusLogicVC";
import { DataMgmt } from "../data/DataMgmt";
import { ServerConnector } from "../signaling/ServerConnector";
import { Logger } from "../utils/Logger";
import { UIMessenger } from "../utils/UIMessenger";

export class DisconnectMgr {
  private data: DataMgmt;
  private logger: Logger;
  /*private busLogic: BusLogicVC;
    private uiMessenger: UIMessenger;
    private serverConnector: ServerConnector;*/

  private disconnectedPeers: Set<string>;
  private checkIntervalInMS: number;
  private checkIntervalID: any;
  private lastFullRecoveryTime: number;
  private readonly extraDelayOnFullClearanceAfterEndOfLastDisconnect: number = 1500;

  private peerDisconnectListener: (peerID: string) => void;
  private peerReconnectListener: (peerID: string) => void;
  private reconnectAllListener: () => void;
  private anyoneDisconnectedListener: () => void;

  constructor(
    _data: DataMgmt,
    _logger: Logger /*, _busLogic: BusLogicVC, _uiMessenger: UIMessenger, _serverConnector: ServerConnector*/
  ) {
    this.data = _data;
    this.logger = _logger;
    /*this.busLogic = _busLogic;
        this.uiMessenger = _uiMessenger;
        this.serverConnector = _serverConnector;*/

    this.checkIntervalID = null;
    this.checkIntervalInMS = 1000;
    this.disconnectedPeers = new Set<string>();
    this.lastFullRecoveryTime = performance.now();

    this.peerDisconnectListener = () => {};
    this.peerReconnectListener = () => {};
    this.reconnectAllListener = () => {};
    this.anyoneDisconnectedListener = () => {};
  }

  initialize = (): void => {
    this.startChecks();
  };

  isFrozen = (peerID: string): boolean => {
    return this.disconnectedPeers.has(peerID);
  };

  isNotFrozen = (peerID: string): boolean => {
    return !this.disconnectedPeers.has(peerID);
  };

  isAtLeastOnePeerFrozen = (): boolean => {
    let now = performance.now();
    return (
      this.disconnectedPeers.size > 0 ||
      now - this.lastFullRecoveryTime <
        this.extraDelayOnFullClearanceAfterEndOfLastDisconnect
    );
  };

  isNoPeerFrozen = (): boolean => {
    let now = performance.now();
    return (
      this.disconnectedPeers.size == 0 &&
      now - this.lastFullRecoveryTime >=
        this.extraDelayOnFullClearanceAfterEndOfLastDisconnect
    );
  };

  private regularCheck = (): void => {
    this.checkPCMap(
      this.data.getAllPCsOfSignalingPurpose(SIGNALING_PURPOSE.BASE_CONNECTION)
    );
    this.checkPCMap(
      this.data.getAllPCsOfSignalingPurpose(SIGNALING_PURPOSE.AUDIO_ONLY)
    );
    this.checkPCMap(
      this.data.getAllPCsOfSignalingPurpose(SIGNALING_PURPOSE.HR_VIDEO)
    );
    this.removeOrphanedDisconnects();
  };

  startChecks = (): void => {
    this.stopChecks();
    this.checkIntervalID = setInterval(
      this.regularCheck,
      this.checkIntervalInMS
    );
  };

  stopChecks = (): void => {
    if (this.checkIntervalID != null) clearInterval(this.checkIntervalID);
    this.disconnectedPeers.clear();
    this.checkIntervalID = null;
  };

  private checkPCMap = (peers: Map<string, VRTCPeerConnection>): void => {
    peers.forEach((peerRTCPC: VRTCPeerConnection, peerID: string, map: any) => {
      if (peerRTCPC.connectionState == "connected")
        peerRTCPC
          .getReceivers()
          .forEach(
            (
              receiver: RTCRtpReceiver,
              index: number,
              array: RTCRtpReceiver[]
            ) => {
              if (receiver.track.kind == "video") {
                receiver.getStats().then((myStatsReport: RTCStatsReport) => {
                  //console.log(myStatsReport);
                  myStatsReport.forEach(
                    (statValue: any, key: string, parent: RTCStatsReport) => {
                      if (statValue.type == "inbound-rtp") {
                        let fps: any = statValue["framesPerSecond"];
                        if (fps == undefined || fps == null || fps == 0) {
                          if (this.disconnectedPeers.has(peerID)) {
                            //alert listeners of disconnect on 2nd miss in a row (i.e. if peerID already registered based on 1st miss)
                            this.logger.logWarning([
                              "Disconnect of peer connection for peer ",
                              peerID,
                              " detected - FramesPerSecond were at zero for two+ validation cycles",
                            ]);
                            this.alertDisconnectListeners(peerID);
                          }
                          this.disconnectedPeers.add(peerID);
                        } else {
                          if (this.disconnectedPeers.has(peerID)) {
                            this.disconnectedPeers.delete(peerID);
                            this.logger.logWarning([
                              "Reconnect of peer connection for peer ",
                              peerID,
                              " detected - FramesPerSecond were non-zero after prior disconnect of min one cycle",
                            ]);
                            this.alertReconnectListeners(peerID);
                          }
                        }
                        /*console.log(
                            "Parsing the PC's stats object for peer " +
                              peerID +
                              " and purpose " +
                              peerRTCPC.getSignalingPurpose() +
                              " under statValue.type " +
                              statValue.type +
                              ", the key " +
                              key +
                              " returned the framesPerSecond value " +
                              statValue["framesPerSecond"]
                            //" returned the value " +
                            //JSON.stringify(statValue)
                          );*/
                      }
                    }
                  );
                });
              }
            }
          );
    });
  };

  removeOrphanedDisconnects = (): void => {
    this.disconnectedPeers.forEach(
      (peerID: string, val2: string, set: Set<string>) => {
        if (
          !this.data.existsPC(peerID, SIGNALING_PURPOSE.BASE_CONNECTION) &&
          !this.data.existsPC(peerID, SIGNALING_PURPOSE.AUDIO_ONLY) &&
          !this.data.existsPC(peerID, SIGNALING_PURPOSE.HR_VIDEO) &&
          !this.data.existsPC(peerID, SIGNALING_PURPOSE.MR_VIDEO) &&
          !this.data.existsPC(peerID, SIGNALING_PURPOSE.LR_VIDEO)
        ) {
          this.disconnectedPeers.delete(peerID);
          this.logger.logWarning([
            "Removed ",
            peerID,
            " from list of disconnected peers - all PCs for peer were found retired / none exist",
          ]);
          this.alertReconnectListeners(peerID);
        }
      }
    );
  };

  alertReconnectListeners = (peerID: string): void => {
    this.peerReconnectListener(peerID);
    if (this.disconnectedPeers.size == 0) {
      this.lastFullRecoveryTime = performance.now();
      this.alertReconnectAllListeners();
      this.logger.logWarning([
        "Reconnect of ALL peer connection for peer ",
        peerID,
        " detected",
      ]);
    }
  };
  alertDisconnectListeners = (peerID: string): void => {
    this.peerDisconnectListener(peerID);
    this.alertAnyoneDisconnectedListeners();
  };
  alertReconnectAllListeners = (): void => {
    this.reconnectAllListener();
  };
  alertAnyoneDisconnectedListeners = (): void => {
    this.anyoneDisconnectedListener();
  };

  registerPeerDisconnectListener = (
    _disconnectListener: (peerID: string) => void
  ): void => {
    this.peerDisconnectListener = _disconnectListener;
  };
  registerPeerReconnectListener = (
    _reconnectListener: (peerID: string) => void
  ): void => {
    this.peerReconnectListener = _reconnectListener;
  };
  registerEveryoneReconnectedListener = (
    _allReconnectedListener: () => void
  ): void => {
    this.reconnectAllListener = _allReconnectedListener;
  };
  registerAnyoneDisconnectedListener = (
    _anyoneDisconnectedListener: () => void
  ): void => {
    this.anyoneDisconnectedListener = _anyoneDisconnectedListener;
  };

  registerSuccessfulConnectionForEarlyUnfreezeDiscovery = (peerID: string) => {
    this.disconnectedPeers.delete(peerID);
    this.logger.logWarning([
      "Reconnect of peer connection for peer ",
      peerID,
      " detected - notification about successful connection received (as early indicator prior first frames sent)",
    ]);
    this.alertReconnectListeners(peerID);
  };
}
