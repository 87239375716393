import { BusLogicVC } from "../buslogic/BusLogicVC";
import { Config } from "../config/Config";
import { DataMgmt } from "../data/DataMgmt";
import { SettingStorage } from "../data/SettingStorage";
import { SignalingCore } from "../signaling/SignalingCore";
import { UIHandler } from "../frontend/UIHandler";
import { Logger } from "../utils/Logger";
import { UIMessenger } from "../utils/UIMessenger";
import { MediaDeviceMgr } from "./MediaDeviceMgr";
import { VideoScalerManualCompression } from "./VideoScalerManualCompression";
import { Helpers } from "../utils/Helpers";
import { VideoScalerNoCompression } from "./VideoScalerNoCompression";
import { I_VideoScaler } from "./I_VideoScaler";

export class BrowserMediaHandler {
  private config: Config;
  private help: Helpers;
  private settings: SettingStorage;
  private data: DataMgmt;
  private logger: Logger;
  private uiMessenger: UIMessenger;
  private signaling: SignalingCore;
  private uiHandler: UIHandler;

  private busLogic: BusLogicVC;

  private deviceMgr: MediaDeviceMgr;
  private videoScaler: I_VideoScaler;

  //settings applied to initial media request to the local browser
  private constraints: MediaStreamConstraints;
  private screenSharingConstraints: MediaStreamConstraints;

  // secondary trackers reflecting the last change of the settings - it reflects what the settings should be, knowing that the same may not have been applied if the media stream was not ready at that point in time
  private audioMutedAsPerLastSettingChange: boolean;
  private videoPausedAsPerLastSettingChange: boolean;

  constructor(
    _config: Config,
    _help: Helpers,
    _settings: SettingStorage,
    _logger: Logger,
    _uiMessenger: UIMessenger,
    _data: DataMgmt,
    _signaling: SignalingCore,
    _uiHandler: UIHandler
  ) {
    console.log("Local client loading module: mediaHandler+x");
    this.config = _config;
    this.help = _help;
    this.settings = _settings;
    this.data = _data;
    this.logger = _logger;
    this.uiMessenger = _uiMessenger;
    this.signaling = _signaling;
    this.uiHandler = _uiHandler;

    this.deviceMgr = new MediaDeviceMgr(this.logger);
    //if (this.help.appIsActive())
    this.videoScaler = new VideoScalerManualCompression(
      this.config,
      this.help,
      this.logger,
      this.data,
      this.uiHandler.getAppUIHTML()
    );
    /*else if (this.help.isWebApp())
      this.videoScaler = new VideoScalerNoCompression(
        this.config,
        this.logger,
        this.data,
        this.uiHandler.getAppUIHTML()
      );*/
  }

  initialize = (_busLogic: BusLogicVC) => {
    this.setBusLogicReference(_busLogic);
    this.requestDeviceAccess();

    this.deviceMgr.initialize();
    this.videoScaler.initialize();

    //if (this.help.appIsActive())
    this.constraints = this.config.getVideoConstraintsByQuality("H");
    //if (this.help.isWebApp()) //for web app and any other cases without proper parameter set
    //else this.constraints = this.config.getVideoConstraintsByQuality("L");
    this.screenSharingConstraints = this.config.getScreenShareConstraints();
    this.logger.logLocal(["MediaHandler initialized"]);
  };

  private setBusLogicReference = (_busLogic: BusLogicVC) => {
    this.busLogic = _busLogic;
  };

  getMediaDeviceMgr = (): MediaDeviceMgr => {
    return this.deviceMgr;
  };

  getVideoDownScaler = (): I_VideoScaler => {
    return this.videoScaler;
  };

  //////////////////////////////////////////////////////////////////
  // Local video stream handling
  //////////////////////////////////////////////////////////////////

  getAndDisplayLocalVideoStream = () => {
    return new Promise<void>((successCallback, failureCallback) => {
      this.logger.logLocal([
        "Starting to retrieve local media stream with constraints ",
        this.constraints,
      ]);
      navigator.mediaDevices
        .getUserMedia(this.constraints) //get local media stream
        .then(this.registerAndDisplayLocalStream)
        .then(() => {
          successCallback();
          return;
        })
        /*.then(function(deviceInfos) {
        console.error('The following devices were detected on local machine: ',deviceInfos);
      })*/
        .catch((e) => {
          console.error("error retrieving local media: ", e.toString());
          console.trace();
          //stopTryingToInformPeersWhenReadyToConnect(); //clearInterval(keepTryingToInformPeers);
          this.uiMessenger.errorToUserSticky(
            "Camera could not be accessed - please ensure camera is not in use and that browser is allowed to access it - then, reload the app"
          );
          failureCallback();
          return;
        });
      //listAvailableDevicesOnConsole();
    });
  };

  private requestDeviceAccess = (): void => {
    this.logger.logLocal(["Requesting access to cameras and microphones"]);
    try {
      //@ts-ignore
      if (this.help.chromiumIsActive() && chrome.contentSettings) {
        //@ts-ignore
        chrome.contentSettings.camera.set(
          { primaryPattern: "https://*.visav.is/*", setting: "allow" },
          () => {
            this.logger.logLocal(["Camera access requested"]);
          }
        );
        //@ts-ignore
        chrome.contentSettings.microphone.set(
          { primaryPattern: "https://*.visav.is/*", setting: "allow" },
          () => {
            this.logger.logLocal(["Microphone access requested"]);
          }
        );
        let camAccess: string;
        let micAccess: string;
        //@ts-ignore
        chrome.contentSettings.camera.get(
          { primaryUrl: "https://*.visav.is" },
          (details) => {
            camAccess = details.setting;
          }
        );
        //@ts-ignore
        chrome.contentSettings.microphone.get(
          { primaryUrl: "https://*.visav.is" },
          (details) => {
            micAccess = details.setting;
          }
        );
        setTimeout(() => {
          if (camAccess == "allow" && micAccess == "allow")
            this.logger.logLocal([
              "Result of after-the-fact-check: Camera and Microphone access have been confirmed as set to 'allow'",
            ]);
          else
            this.logger.logError([
              "Camera or Microphone access was not granted - status for Cam / Mic is: ",
              camAccess,
              " / ",
              micAccess,
            ]);
        }, 1500);
      }
    } catch (e) {
      this.logger.logError([
        "Error requesting access to cameras and microphones: ",
        e,
      ]);
    }
  };

  listAvailableDevicesOnConsole = () => {
    this.logger.logLocal(["Starting to retrieve local device information"]);
    //getUniqueAvailableMediaDevices();
  };

  getUniqueAvailableMediaDevices = (rebuildFresh: boolean = false) => {
    return new Promise((successCallback, failureCallback) => {
      //retrieve media devices only if no devices registered yet
      if (
        rebuildFresh ||
        this.deviceMgr.getAvailableMediaDevicesCameras().length +
          this.deviceMgr.getAvailableMediaDevicesMicrophones().length ===
          0
      ) {
        /*if (this.help.appIsActive())
          this.uiHandler.getUINWJSHandler().getSidebarWindowNWJS().focusMainApp();
        /*navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(() => {*/
        navigator.mediaDevices
          .enumerateDevices()
          .then((deviceInfos) => {
            this.logger.logLocal([
              "The following devices were detected on local machine: ",
              deviceInfos,
            ]);
            this.deviceMgr.registerAvailableMediaDevices(deviceInfos);
            this.deviceMgr.unregisterDuplicateAvailableMediaDevices();
            this.registerActiveDevices();
            successCallback(() => {}); // @TODO Check functionality
          })
          .catch((e) => {
            console.log(
              "navigator.MediaDevices.getUserMedia error: ",
              e.message,
              e.name
            );
            failureCallback(e);
          });
        //});
      } else {
        this.logger.logLocal([
          "Media devices not retrieved as there are already devices registered",
        ]);
        successCallback(() => {}); // @TODO Check functionality
      }
    });
  };

  registerActiveDevices = () => {
    let activeCamIndex: number = 0;
    let activeMicIndex: number = 0;
    let activeCamGroupID: string = null;
    let activeMicGroupID: string = null;

    if (this.data.getLocalMS() == null) return;

    activeCamGroupID = this.data
      .getLocalMS()
      .getVideoTracks()[0]
      .getSettings().groupId;
    activeMicGroupID = this.data
      .getLocalMS()
      .getAudioTracks()[0]
      .getSettings().groupId;

    let availableCameras: MediaDeviceInfo[] =
      this.deviceMgr.getAvailableMediaDevicesCameras();
    let availableMicrophones: MediaDeviceInfo[] =
      this.deviceMgr.getAvailableMediaDevicesMicrophones();

    //determine which index reflects the currently active device
    for (let i = 0; i < availableCameras.length; i++) {
      if (activeCamGroupID == availableCameras[i].groupId) {
        activeCamIndex = i;
        this.logger.logLocal([
          "Determined active camera device at index ",
          i,
          ": ",
          activeCamGroupID,
        ]);
      }
    }
    for (let i = 0; i < availableMicrophones.length; i++) {
      if (activeMicGroupID == availableMicrophones[i].groupId) {
        activeMicIndex = i;
        this.logger.logLocal([
          "Determined active microphone device at index ",
          i,
          ": ",
          activeMicGroupID,
        ]);
      }
    }

    this.deviceMgr.initializeCurrentMediaDeviceIndexes(
      activeCamIndex,
      activeMicIndex
    );
  };

  registerAndDisplayLocalStream = (stream: MediaStream) => {
    this.data.setLocalMS(stream);
    this.logger.logLocal([
      "Local media stream obtained - assigning it to UI element",
    ]);
    stream.getVideoTracks()[0].onended = () => {
      // Click on browser UI stop sharing button
      //this.uiHandler.getUINWJSHandler().hideDedicatedVCWindowIfOnApp();
      this.repairLocalMediaStream();
    };

    this.uiHandler
      .getAppUIHTML()
      .getSideBarHTML()
      .getLocalHTMLVideoElement().srcObject = stream; //todo: is this a UI action that should go via UIActions module?
    //this.data.getLocalHTMLVideoElement().play();
    this.uiHandler.showLocalVideoSectionOnUI(); //as all video items are hidden via css, show local video on startup
    //document.getElementsByClassName('compressionCalcArea')[0].style.display = 'inline';
    //this.data.getCompressionCanvasL().style.display = 'inline';
    this.signaling.registerLocalStreamReadiness();
    this.videoScaler.startCompressionOfLocalVideoStream();

    setTimeout(() => {
      this.uiHandler.configureCompressionCanvasToMatchVideoAspectRatio();
    }, 1000); //reset aspect ratio of compression canvas to match video aspect ration - needs to be repeated after a second as initial stream returns false width/heigt values when prompted immediately after stream retrieval
    //setTimeout(function() {this.uiHandler.configureCompressionCanvasToMatchVideoAspectRatio();},5000);
    //this.data.getLocalMS().addEventListener("inactive", function(e) {console.error('Local media stream turned inactive during session');}); //todo: does this need to be investigated further?
    //this.data.getLocalMS().addEventListener("removetrack", function(e) {console.error('Local media stream removed during session');}); //todo: does this need to be investigated further?
    //this.uiHandler.setStatusOnUI('You\'re up and running! Ready for others to join...');
    if (this.help.appIsActive())
      this.uiMessenger.confirmToUser(
        "you're now up and running - ready for others to join..."
      );
    try {
      this.videoScaler.createLocalMediaStreamVariants();
      this.signaling.registerLocalConstraintApplicationReadiness();
      //console.error('..completed creating media stream variants');
      //processPendingPccIfReady();
      //informPeersWhenReadyToConnect(this.data.getMainRoom());
      this.signaling.startConnectionsIfLocalSetupReadyToConnect();
    } catch (e) {
      this.logger.logLocal([
        "Failed to create local mediastream variants: ",
        e.toString(),
      ]);
      this.uiMessenger.errorToUser(
        "Failed to create local mediastream variants " + e.toString()
      );
    }
    this.logger.logLocal(["Local media stream obtained, UI updated"]);

    this.signaling.processPendingPccIfReady(); //this is one among a few points that should cause the client to process pending peer connections, if ready to do so
  };

  //at some stage one should take care of situations where the local media stream gets interrupted (device disconnected / browser revoked access / ...)
  repairLocalMediaStream = () => {
    //if local stream is no longer providing a video - unclear when this is changed - telling the browser to no longer provide access to video seems to cause false
    this.logger.logWarning([
      "readystate of local video track is ",
      this.data.getLocalMS().getVideoTracks()[0].readyState,
    ]);
    this.logger.logLocal([
      "readystate of local audio track is ",
      this.data.getLocalMS().getAudioTracks()[0].readyState,
    ]);
    this.logger.logLocal([
      "active state of local media stream is ",
      this.data.getLocalMS().active,
    ]);
    if (this.data.getLocalMS().getVideoTracks()[0].readyState == "ended") {
      this.uiHandler.pauseVISAVIS();
      this.uiMessenger.informUserSticky(
        "visav.is has been paused as the app lost access to the camera (e.g. when system goes to sleep mode) - please unpause to reconnect to your team",
        10000
      );
      //this.logger.logWarning(["Local media stream inactive"]);
      //todo: do something
      //todo: check whether anything needs to be done when peer video freezes or whether this needs to be dealt with by peer / whether this is always a all-receiving-peers-have-it-at-the-same-time-problem
      //todo: check whether one can detect the unplugging of a camera somehow - this freezes stream, but does not leave it inactive
    }
  };

  getAndRegisterLocalScreenShareStream = async (win: Window = window) => {
    return new Promise((successCallback, failureCallback) => {
      this.logger.logLocal([
        "Processing request to retrieve local screen stream.",
      ]);
      this.logger.logWarning([
        "Received reference to window / navigator: ",
        win,
        win.navigator,
        navigator,
      ]);
      //this.uiMessenger.informUser('Attempting to share your local screen - you may be prompted to select the content that you wish to share');
      win.navigator.mediaDevices
        .getDisplayMedia(this.screenSharingConstraints)
        .then((displayMediaStream) => {
          /*if (displayMediaStream.getAudioTracks().length > 0) { //remove audiotracks from screen share, if any
          displayMediaStream.removeTrack(displayMediaStream.getAudioTracks()[0]);
        }*/
          displayMediaStream.getVideoTracks()[0].onended = () => {
            // Click on browser UI stop sharing button
            //this.uiHandler.getUINWJSHandler().hideDedicatedVCWindowIfOnApp();
            this.uiHandler.shareLocalScreenButtonClick();
          };
          this.data.setLocalMSScreenShare(displayMediaStream);
          this.logger.logLocal(["Local screen stream obtained"]);
          successCallback(() => {});
        })
        .catch((e) => {
          if (this.help.isMacApp()) {
            this.uiMessenger.warningToUser(
              "Screen could not be shared - please note that screen share on Mac requires access to 'screen recording' feature in 'system preferences / security & privacy'"
            );
          } else {
            this.uiMessenger.warningToUser("Screen could not be shared");
          }
          if (
            e instanceof TypeError ||
            e instanceof OverconstrainedError ||
            e.name == "NotFoundError" ||
            e.name == "InvalidStateError"
          )
            this.logger.logError([
              "Local screen stream COULD NOT BE obtained via getDisplayMedia - error is: " +
                e.message,
            ]);
          else
            this.logger.logWarning([
              "Local screen stream COULD NOT BE obtained via getDisplayMedia - error is: " +
                e.message,
            ]);
          failureCallback(e);
        });
    });
  };

  /*
  //////////////////////////////////////////////////////////////////
  // Multi-peer connection handling
  //////////////////////////////////////////////////////////////////

  //
  // Design decision: When a peer joins the room, all peers that are already in the room initiate a connection to the new peer.
  // They get aware about the new peer and his ID via the 'joined' event shared by the signaling server.
  // The peer itself is initially not yet aware of the other clients in the room.
  //


  /*
  function addLocalTracksToPC(peerConnection,mediaStream, qualityLevelVideo,qualityLevelAudio) {
    if (this.data._localVideoStreamTrack!= null) {
      this.logger.logLocal(['DEBUG log: track / media stream:',track,this.data.getLocalMS]);
      if (qualityLevelVideo = "H") {peerConnection.addTrack(this.data.getLocalMediaStreamTrack("video","H"),this.data.getLocalMS())};
      if (qualityLevelVideo = "M") {peerConnection.addTrack(this.data.getLocalMediaStreamTrack("video","M"),this.data.getLocalMS())};
      if (qualityLevelVideo = "L") {peerConnection.addTrack(this.data.getLocalMediaStreamTrack("video","L"),this.data.getLocalMS())};
    }
    if (this.data._localAudioStreamTrack!= null) {
      peerConnection.addTrack(this.data.getLocalMediaStreamTrack("audio",null),this.data.getLocalMS());
    }
  }*/

  /////////////////////////////////////////////////////////

  /*
  // Set Opus as the default audio codec if it's present.
  function preferOpus(sdp) {
    var sdpLines = sdp.split('\r\n');
    var mLineIndex;
    // Search for m line.
    for (var i = 0; i < sdpLines.length; i++) {
      if (sdpLines[i].search('m=audio') !== -1) {
        mLineIndex = i;
        break;
      }
    }
    if (mLineIndex === null) {
      return sdp;
    }

    // If Opus is available, set it as the default in m line.
    for (i = 0; i < sdpLines.length; i++) {
      if (sdpLines[i].search('opus/48000') !== -1) {
        var opusPayload = extractSdp(sdpLines[i], /:(\d+) opus\/48000/i);
        if (opusPayload) {
          sdpLines[mLineIndex] = setDefaultCodec(sdpLines[mLineIndex],
            opusPayload);
        }
        break;
      }
    }

    // Remove CN in m line and sdp.
    sdpLines = removeCN(sdpLines, mLineIndex);

    sdp = sdpLines.join('\r\n');
    return sdp;
  }

  function extractSdp(sdpLine, pattern) {
    var result = sdpLine.match(pattern);
    return result && result.length === 2 ? result[1] : null;
  }

  // Set the selected codec to the first in m line.
  function setDefaultCodec(mLine, payload) {
    var elements = mLine.split(' ');
    var newLine = [];
    var index = 0;
    for (var i = 0; i < elements.length; i++) {
      if (index === 3) { // Format of media starts from the fourth.
        newLine[index++] = payload; // Put target payload to the first.
      }
      if (elements[i] !== payload) {
        newLine[index++] = elements[i];
      }
    }
    return newLine.join(' ');
  }

  // Strip CN from sdp before CN constraints is ready.
  function removeCN(sdpLines, mLineIndex) {
    var mLineElements = sdpLines[mLineIndex].split(' ');
    // Scan from end for the convenience of removing an item.
    for (var i = sdpLines.length - 1; i >= 0; i--) {
      var payload = extractSdp(sdpLines[i], /a=rtpmap:(\d+) CN\/\d+/i);
      if (payload) {
        var cnPos = mLineElements.indexOf(payload);
        if (cnPos !== -1) {
          // Remove CN payload from m line.
          mLineElements.splice(cnPos, 1);
        }
        // Remove CN line in sdp
        sdpLines.splice(i, 1);
      }
    }

    sdpLines[mLineIndex] = mLineElements.join(' ');
    return sdpLines;
  }*/

  /////////////////////////////////////////////////////////
  // Screensharing functionality
  /////////////////////////////////////////////////////////

  handleObtainedScreenStream = () => {
    let screenStream = this.data.getLocalMSScreenShare();
    this.logger.logLocal([
      "Stream for screen sharing obtained: ",
      screenStream,
      " >> mediaTracks: ",
      screenStream.getTracks(),
    ]);
    this.uiHandler
      .getAppUIHTML()
      .getVCScreenHTML()
      .getLocalScreenShareHTMLVideoElementVC().width = 640;
    this.uiHandler
      .getAppUIHTML()
      .getVCScreenHTML()
      .getLocalScreenShareHTMLVideoElementVC().height = 480;
    this.uiHandler
      .getAppUIHTML()
      .getVCScreenHTML()
      .getLocalScreenShareHTMLVideoElementVC().srcObject = screenStream;
    this.uiHandler.showVCLocalScreenShareVideoSectionOnUI();
    this.uiMessenger.confirmToUser("Local screen is now shared in VC");
  };

  onScreenStreamNotObtained = (err) => {
    console.error("error capturing local screen: ", err.toString());
    console.trace();
    /*this.uiMessenger.informUser(
      "Note that screen sharing is not supported in all browsers - please use Chrome"
    );*/
    this.uiMessenger.errorToUser(
      "Screen could not be shared - please ensure proper access has been granted"
    );
  };

  /////////////////////////////////////////////////////////
  // Camera and microphone selection
  /////////////////////////////////////////////////////////

  applyStoredMediaDeviceSettingsIfAny = () => {
    return new Promise((successCallback, failureCallback) => {
      this.getUniqueAvailableMediaDevices(true).then(() => {
        if (this.settings.existsCamSetting()) {
          if (this.camDeviceIDExists(this.settings.getCamDeviceID()))
            this.setCamInMediaConstraints(this.settings.getCamDeviceID());
          //this.uiMessenger.informUser('Using stored camera device: ' + this.settings.getCamDeviceID());
        }
        if (this.settings.existsMicSetting()) {
          if (this.micDeviceIDExists(this.settings.getMicDeviceID()))
            this.setMicInMediaConstraints(this.settings.getMicDeviceID());
          //this.uiMessenger.informUser('Using stored microphone device: ' + this.settings.getMicDeviceID());
        }
        successCallback(() => {});
      });
    });
  };

  /*  iterateMicrophone = () => {
    this.getUniqueAvailableMediaDevices(true).then(() => {
      this.deviceMgr.iterateToNextMediaDeviceIndexMicrophone();
      this.logger.logLocal([
        "iterating microphone index - value is now ",
        this.deviceMgr.getCurrentMediaDeviceIndexMicrophone(),
      ]);
      this.uiMessenger.informUser(
        "Switching to new microphone: " +
          this.deviceMgr.getAvailableMediaDevicesMicrophones()[
            this.deviceMgr.getCurrentMediaDeviceIndexMicrophone()
          ].label +
          " - wait a few seconds for changes to apply - click again for next device"
      );
      this.logger.logLocal([
        "Switching to new microphone: ",
        this.deviceMgr.getAvailableMediaDevicesMicrophones()[
          this.deviceMgr.getCurrentMediaDeviceIndexMicrophone()
        ],
      ]);
      this.registerReloadTriggerForMediaDeviceChange();
    });
  };

  iterateCamera = () => {
    this.getUniqueAvailableMediaDevices(true).then(() => {
      this.deviceMgr.iterateToNextMediaDeviceIndexCamera();
      this.logger.logLocal([
        "iterating camera index - value is now ",
        this.deviceMgr.getCurrentMediaDeviceIndexCamera(),
      ]);
      this.uiMessenger.informUser(
        "Switching to new camera: " +
          this.deviceMgr.getAvailableMediaDevicesCameras()[
            this.deviceMgr.getCurrentMediaDeviceIndexCamera()
          ].label +
          " - wait a few seconds for changes to apply - click again for next device"
      );
      this.logger.logLocal([
        "Switching to new camera: ",
        this.deviceMgr.getAvailableMediaDevicesCameras()[
          this.deviceMgr.getCurrentMediaDeviceIndexCamera()
        ],
      ]);
      this.registerReloadTriggerForMediaDeviceChange();
    });
  };*/

  registerReloadTriggerForMediaDeviceChange = () => {
    this.deviceMgr.increaseReloadTriggerCount();
    setTimeout(() => {
      this.reloadTriggerExecutionForMediaDeviceChange();
    }, 4000);
    this.logger.logLocal([
      "interval trigger for media device change registered - value now: ",
      this.deviceMgr.getCurrentReloadTriggerCount(),
    ]);
  };

  reloadTriggerExecutionForMediaDeviceChange = () => {
    this.deviceMgr.decreaseReloadTriggerCount();
    this.logger.logLocal([
      "interval trigger for media device change executed - value now: ",
      this.deviceMgr.getCurrentReloadTriggerCount(),
    ]);
    if (this.deviceMgr.getCurrentReloadTriggerCount() === 0) {
      this.applyMediaDeviceChange();
    }
  };

  applyMediaDeviceChange = () => {
    //todo: close all existing connections + reset all UI components and other key variables
    if (this.data.getLocalMS() !== null) {
      this.data
        .getLocalMS()
        .getTracks()
        .forEach((track: MediaStreamTrack) => {
          track.stop();
        });
    }
    this.busLogic.fullLocalHangupOnExit("RestartToApplyMediaDeviceChange");

    //retrieve video and audio media device ID
    let microphoneDeviceId =
      this.deviceMgr.getAvailableMediaDevicesMicrophones()[
        this.deviceMgr.getCurrentMediaDeviceIndexMicrophone()
      ].deviceId;
    let cameraDeviceId =
      this.deviceMgr.getAvailableMediaDevicesCameras()[
        this.deviceMgr.getCurrentMediaDeviceIndexCamera()
      ].deviceId;

    this.setCamInMediaConstraints(cameraDeviceId);
    this.setMicInMediaConstraints(microphoneDeviceId);
    this.logger.logLocal([
      "applied media device changes to media constraints: ",
      this.config.getVideoConstraintsSimulcast()["H"],
    ]); //: ', mediaConstraintsCopy['H']);

    //re-launch media access and connectivity with updated constraints
    this.uiMessenger.informUserSticky(
      "...now applying media device changes - visav.is will be back in few seconds"
    );
    /*this.uiMessenger.warningToUser(
      "Note: Change of media source causes any active conversations to be closed"
    );*/
    this.busLogic.launchMediaAccessAndServerConnectivity();
  };

  setCamInMediaConstraints = (devID: string) => {
    //configure respective media devices in constraints variable - across all pre-defined parameter sets for different connection / bandwidth scenarios
    let mediaConstraintsCopy: any = this.config.getVideoConstraintsSimulcast(); //simply link to initial parameter set, assuming that changes can be arbitrarily overwritten where needed - if this does not work out, suggestion would be to clone original parameters and work with clone
    //let mediaConstraintsCopy = JSON.parse(JSON.stringify(_videoConstraintsSimulcast)); //clone original constraints variable - keep original untouched to be on the safe side
    mediaConstraintsCopy["H"].video.deviceId = { exact: devID };
    mediaConstraintsCopy["M"].video.deviceId = { exact: devID };
    mediaConstraintsCopy["L"].video.deviceId = { exact: devID };
    mediaConstraintsCopy["picHF"].video.deviceId = { exact: devID };
    mediaConstraintsCopy["picMF"].video.deviceId = { exact: devID };
    mediaConstraintsCopy["picLF"].video.deviceId = { exact: devID };
  };

  setMicInMediaConstraints = (devID: string) => {
    //configure respective media devices in constraints variable - across all pre-defined parameter sets for different connection / bandwidth scenarios
    let mediaConstraintsCopy: any = this.config.getVideoConstraintsSimulcast(); //simply link to initial parameter set, assuming that changes can be arbitrarily overwritten where needed - if this does not work out, suggestion would be to clone original parameters and work with clone
    //let mediaConstraintsCopy = JSON.parse(JSON.stringify(_videoConstraintsSimulcast)); //clone original constraints variable - keep original untouched to be on the safe side
    mediaConstraintsCopy["H"].audio.deviceId = { exact: devID };
    /*mediaConstraintsCopy["H"].audio.optional.unshift({
      deviceId: { exact: devID },
    }); // there is just one audio constraint object - it automatically populates everywhere
    /*mediaConstraintsCopy["M"].audio.optional.push({ deviceId: { exact: devID } });
    mediaConstraintsCopy["L"].audio.optional.push({ deviceId: { exact: devID } });
    mediaConstraintsCopy["picHF"].audio.optional.push({ deviceId: { exact: devID } });
    mediaConstraintsCopy["picMF"].audio.optional.push({ deviceId: { exact: devID } });
    mediaConstraintsCopy["picLF"].audio.optional.push({ deviceId: { exact: devID } });
    /*mediaConstraintsCopy["H"].audio.deviceId = devID;
    mediaConstraintsCopy["M"].audio.deviceId = devID;
    mediaConstraintsCopy["L"].audio.deviceId = devID;
    mediaConstraintsCopy["picHF"].audio.deviceId = devID;
    mediaConstraintsCopy["picMF"].audio.deviceId = devID;
    mediaConstraintsCopy["picLF"].audio.deviceId = devID;*/
  };

  camDeviceIDExists = (
    devID: string,
    rebuildFresh: boolean = false
  ): boolean => {
    //ATTENTION: ONE MUST ENSURE THAT AVAILABLE DEVICES WERE LOADED PRIOR CALLING THIS
    let devs: MediaDeviceInfo[] =
      this.deviceMgr.getAvailableMediaDevicesCameras();
    if (devs == null || devs == undefined) return false;
    for (let i = 0; i < devs.length; i++) {
      if (devs[i].deviceId == devID) return true;
    }
    return false;
  };

  micDeviceIDExists = (
    devID: string,
    rebuildFresh: boolean = false
  ): boolean => {
    //ATTENTION: ONE MUST ENSURE THAT AVAILABLE DEVICES WERE LOADED PRIOR CALLING THIS
    let devs: MediaDeviceInfo[] =
      this.deviceMgr.getAvailableMediaDevicesMicrophones();
    if (devs == null || devs == undefined) return false;
    for (let i = 0; i < devs.length; i++) {
      if (devs[i].deviceId == devID) return true;
    }
    return false;
  };

  muteLocalAudio = () => {
    this.data
      .getLocalMS()
      .getAudioTracks()
      .forEach((track) => {
        track.enabled = false;
      });
    this.audioMutedAsPerLastSettingChange = true;
  };

  unmuteLocalAudio = () => {
    this.data
      .getLocalMS()
      .getAudioTracks()
      .forEach((track) => {
        track.enabled = true;
      });
    this.audioMutedAsPerLastSettingChange = false;
  };

  setLocalAudioMute = (newVal: boolean): void => {
    if (newVal) this.muteLocalAudio();
    else this.unmuteLocalAudio();
  };

  isLocalAudioActive = (): boolean => {
    return this.data.getLocalMS().getAudioTracks()[0].enabled;
  };
  isLocalAudioMuted = (): boolean => {
    return !this.isLocalAudioActive();
  };

  pauseLocalVideo = () => {
    this.data
      .getLocalMS()
      .getVideoTracks()
      .forEach((track) => {
        track.enabled = false;
      });
    this.videoPausedAsPerLastSettingChange = true;
  };
  unpauseLocalVideo = () => {
    this.data
      .getLocalMS()
      .getVideoTracks()
      .forEach((track) => {
        track.enabled = true;
      });
    this.videoPausedAsPerLastSettingChange = false;
  };
  setLocalVideoPause = (newVal: boolean): void => {
    if (newVal) this.pauseLocalVideo();
    else this.unpauseLocalVideo();
  };
  isLocalVideoActive = (): boolean => {
    return this.data.getLocalMS().getVideoTracks()[0].enabled;
  };
  isLocalVideoPaused = (): boolean => {
    return !this.isLocalVideoActive();
  };

  reapplyCurrentAudioMuteSetting = (): void => {
    this.setLocalAudioMute(this.audioMutedAsPerLastSettingChange);
  };
  reapplyCurrentVideoPauseSetting = (): void => {
    this.setLocalVideoPause(this.videoPausedAsPerLastSettingChange);
  };
}
