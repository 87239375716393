import { APP_SCREEN_SIDE } from "data/enums/APP_SCREEN_SIDE";
import { I_VCWindowUI } from "../decoupler/I_VCWindowUI";
import { VCWindowSizer } from "../decoupler/jointUITech/VCWindowSizer";
import { AppUIHTML } from "frontend/html/AppUIHTML";
import { Helpers } from "utils/Helpers";
import { Logger } from "utils/Logger";
import { SidebarWebMeet } from "./SidebarWebMeet";

export class VCWindowWebMeet implements I_VCWindowUI {
  private help: Helpers;
  private logger: Logger;
  private appUI: AppUIHTML;
  private sidebarWindow: SidebarWebMeet;
  private windowSizer: VCWindowSizer;

  private dedicatedVCWindow: any; //reference to nwjs window

  private dedicatedVCWindowIsTmpHidden: boolean;

  private readonly sideBarWidth: number = 200;

  constructor(_help: Helpers, _logger: Logger, _sidebarWindow: SidebarWebMeet) {
    this.help = _help;
    this.logger = _logger;
    this.sidebarWindow = _sidebarWindow;

    this.windowSizer = new VCWindowSizer(this.logger, this.sideBarWidth);

    this.dedicatedVCWindow = null;
  }

  initialize = (_appUI: AppUIHTML): void => {
    this.appUI = _appUI;
    this.dedicatedVCWindowIsTmpHidden = false;

    this.windowSizer.initialize(this.appUI);
  };

  initializeDedicatedVCWindow = (
    _doc: Document,
    _VCScreenURL: string,
    initfunc: { (any, Document): void },
    initFuncEntireAppVC: { (HTMLDivElement): void }
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      //TODO - insert init code here including window event code
    });
  };

  showDedicatedVCWindowIfOnApp = (): void => {
    if (this.help.isWebApp()) {
      try {
        //@ts-ignore
        //this.dedicatedVCWindow.show();  //todo - validate closure logic for non-nwjs
      } catch (e) {
        this.logger.logError(["error showing vc window, ", e]);
      }
    }
  };

  hideDedicatedVCWindowIfOnApp = (): void => {
    if (this.help.isWebApp()) {
      try {
        //@ts-ignore
        //this.dedicatedVCWindow.hide(); //todo - validate hide logic for non-nwjs
      } catch (e) {
        this.logger.logError(["error hiding vc window, ", e]);
      }
    }
  };

  tmpHideDedicatedVCWindowForScSDialogueIfOnApp = (
    activelyUnfocusVCWindow: boolean = false
  ): void => {
    // DO NOTHING
  };

  unhideDedicatedVCWindowAfterTMPHideForScSDialogueIfOnApp = (
    activelyShowWindowAfterRemovingTMPHideFlag: boolean = false
  ): void => {
    //do nothing
  };

  setWindowSizeIfOnApp = (
    _width: number,
    _height: number,
    _resetPosition: boolean = false,
    _win: Window = this.dedicatedVCWindow
  ): void => {
    if (this.help.isWebApp()) {
      try {
        /*  TODO: SET VC SCREEN SIZE
        //@ts-ignore
        _win.width = _width;
        //@ts-ignore
        _win.height = _height;
        }*/
      } catch (e) {
        this.logger.logError(["error setting vc window size, ", e]);
      }
    }
  };

  setVCWindowSizeForNumberOfParticipantsIfOnApp = (
    numberOfParticipants: number,
    chatActive: boolean,
    resetPosition: boolean
  ): void => {
    this.windowSizer.setVCWindowSizeForNumberOfParticipantsIfOnApp(
      numberOfParticipants,
      chatActive,
      resetPosition,
      this.setWindowSizeIfOnApp
    );
  };

  setVCWindowSizeScreenShareIfOnApp = (
    relativeWidth: number,
    relativeHeight: number,
    resetPosition: boolean
  ): void => {
    this.windowSizer.setVCWindowSizeScreenShareIfOnApp(
      relativeWidth,
      relativeHeight,
      resetPosition,
      this.setWindowSizeIfOnApp
    );
  };

  resetVCWindowPositionIfOnApp = (): void => {}; //do nothing

  initializeScreenShareAutoSizingIfOnApp = (): void => {
    if (this.help.isWebApp())
      this.windowSizer.initializeScreenShareAutoSizingIfOnApp(
        this.setVCWindowSizeScreenShareIfOnApp
      );
  };

  closeVCWindowIfOnApp = (): void => {
    if (this.help.isWebApp()) {
      //this.dedicatedVCWindow.close(); //todo - validate closure logic for non-nwjs
    }
  };

  refreshDedicatedVCWindowAlwaysOnTop = (): void => {
    //do nothing
  };
}
